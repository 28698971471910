import React, { useState } from "react";
import AuthRouter from "./authRouter";
import AdminRouter from "./adminRouter";
import AdminApi from "../api/admin";
import { Cookies } from "react-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CoupleRouter from "./coupleRouter";
const IndexRouter = () => {
  const cookies = new Cookies();
  const [userRole, setUserRole] = useState("");
  const navigate = new useNavigate();
  let adminApi = new AdminApi();
  useEffect(() => {}, [userRole]);

  const checkFunciton = () => {
    if (cookies.get("LoveStrongUser")) {
      let data = cookies.get("LoveStrongUser");
      if (data.userRole) {
        return data.userRole;
      } else {
        return "";
      }
    } else {
      return false;
    }
  };
  const updateuser = (data) => {
    setUserRole(data); // change role of user Admin or User
  };

  const logoutUser = async () => {
    let userData = cookies.get("LoveStrongUser");
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: `Logout`,
      action: "Click",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setUserRole("");
    cookies.remove("LoveStrongToken", { path: "/" });
    cookies.remove("LoveStrongUser", { path: "/" });
    // console.log('After logout check cookie',cookies.get('LoveStrongUser'))
    navigate("/");
  };

  return (
    <>
      {checkFunciton() === "ADMIN" || checkFunciton() === "SUBADMIN" ? (
        <AdminRouter logoutUser={logoutUser} />
      ) : checkFunciton() === "COUPLE" ? (
        <CoupleRouter logoutUser={logoutUser} />
      ) : (
        <AuthRouter updatedUser={updateuser} />
      )}
    </>
  );
};

export default IndexRouter;
