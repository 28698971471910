import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import DirectoryApi from "../../../api/directoryApi";
import ProgramApi from "../../../api/programApi";
import moment from "moment";
import { toast } from "react-toastify";
import ImagePlaceHolder from "../../../assets/images/image-placeholder.png";
import Profile from "../../../assets/images/couple-default.png";
import Loader from "../../../components/loader";
import DirectoryModel from "./DirectoryModel";
import DeleteModel from "../../../components/DeleteModel";
import RetreatApi from "../../../api/retreatApi";
import Helpers from "../../../components/Helpers";
import { Cookies } from "react-cookie";
import AdminApi from "../../../api/admin";

export const CreateDirectory = () => {
  const [directory, setDirectory] = useState([]);
  const [allRetreatRoasters, setAllRetreatRoasters] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [nameModel, setNameModel] = useState("");
  const [isTempUpload, setIsTempUpload] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [coupleTalkData, setCoupleTalkData] = useState([]);
  const [data, setData] = useState([]);
  const [loadFooter1, setLoadFooter1] = useState(false);
  const [loadFooter2, setLoadFooter2] = useState(false);
  const [loadMain, setLoadMain] = useState(false);
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const { id } = useParams();
  let directoryApi = new DirectoryApi();
  let programApi = new ProgramApi();
  let retreatApi = new RetreatApi();
  let adminApi = new AdminApi();

  useEffect(() => {
    getDirectoryDetail(id);
    getAllRoastersOfRetreat(id);
    getRetreatDetail(id);
  }, [id]);

  const getRetreatDetail = async (id) => {
    await retreatApi
      .getRetreatDetail({ id: id })
      .then((res) => {
        if (res.success) {
          const { schedule, ...rest } = res.data[0];
          const parsedSchedule = schedule ? JSON.parse(schedule) : [];
          const parsedData = {
            ...rest,
            schedule: parsedSchedule.map((entry) => ({
              date: new Date(entry.date),
              from: entry.from,
              to: entry.to,
            })),
            parish: res.data[0].parishId,
          };
          setData(parsedData);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getDirectoryDetail = async (id) => {
    await directoryApi
      .getDirectoryDetail({ rereatId: id })
      .then((response) => {
        setDirectory(response.data[0]);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getAllRoastersOfRetreat = async (id) => {
    await directoryApi
      .getAllRoastersOfRetreat({ rereatId: id })
      .then((response) => {
        setAllRetreatRoasters(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const changePublishStatus = (directory, select) => {
    programApi
      .changePublishStatus({ id: directory.id, select: select })
      .then((res) => {
        getDirectoryDetail(directory.retreatId);
        toast.success(res.msg);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const upload = (i) => {
    document.getElementById(`${i}` + "-" + "CoupleProfileUpload").click();
    setIsTempUpload(true);
  };

  const remove = (coupleDta, selection, checkImage) => {
    setIsTempUpload(false);
    if (selection === "directory") {
      directoryApi
        .removeDirectoryImage({ checkImage: checkImage, id: coupleDta.id })
        .then((res) => {
          toast.success(res.msg);
          getDirectoryDetail(coupleDta.retreatId);
        })
        .catch((error) => {
          console.error(error);
          toast.error("An error occurred while uploading the image.");
        });
    } else if (selection === "") {
      directoryApi
        .removeImage({ id: coupleDta.id })
        .then((res) => {
          toast.success(res.msg);
          getAllRoastersOfRetreat(coupleDta.retreatId);
        })
        .catch((error) => {
          console.error(error);
          toast.error("An error occurred while uploading the image.");
        });
    }
  };

  const hadleDirectoryModel = (a) => {
    setModalShow(true);
    setNameModel(a);
  };

  const handleallImagesChanges = (e, dir, imageSelection) => {
    if (imageSelection === "mainImage") {
      setLoadMain(true);
    } else if (imageSelection === "footerImage1") {
      setLoadFooter1(true);
    } else if (imageSelection === "footerImage2") {
      setLoadFooter2(true);
    }
    let { name, value } = e.target;
    if (name === "imageUrl" && e.target.files && e.target.files.length > 0) {
      let allData = new FormData();
      allData.append("file", e.target.files[0]);
      allData.append("directoryId", dir && dir.id);
      allData.append("retreatId", id);
      allData.append("imageSelection", imageSelection);
      directoryApi
        .createDirectory(allData)
        .then(async (res) => {
          if (res.mode === "create") {
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: `Created new retreat directory (Retreat - ${data.title})`,
            };
            await adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Added new log report");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            if (imageSelection === "mainImage") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Header image Changed in retreat directory (Retreat - ${data.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (imageSelection === "footerImage1") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Footer1 image Changed in retreat directory (Retreat - ${data.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Footer2 image Changed in retreat directory (Retreat - ${data.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
          toast.success(res.msg);
          getDirectoryDetail(id);
          setLoadMain(false);
          setLoadFooter1(false);
          setLoadFooter2(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error("An error occurred while uploading the image.");
        });
    } else {
      toast.error("Please select an image.");
    }
  };

  const handleImageChanges = (e, coupleDta) => {
    let { name, value } = e.target;
    if (name === "imageUrl" && e.target.files && e.target.files.length > 0) {
      let allData = new FormData();
      allData.append("file", e.target.files[0]);
      allData.append("roasterId", coupleDta.id);
      directoryApi
        .saveCoupleImage(allData)
        .then((res) => {
          toast.success("Couple image uploaded successfully.");
          getAllRoastersOfRetreat(coupleDta.retreatId);
          // setLoadData(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error("An error occurred while uploading the image.");
        });
    } else {
      toast.error("Please select an image.");
    }
  };

  const closeDirectoryModel = () => {
    setModalShow(false);
    getDirectoryDetail(id);
  };

  return (
    <>
      <Link to="/manageRetreat" className="back-link">
        &lt;Back
      </Link>

      <div className="row ext-space">
        <div className="col-lg-12 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col-12 grid-margin stretch-card">
              <div className="card custom-card">
                <div className="card-body ">
                  <Container fluid className="create-retreat-header ">
                    <div className="row w-100 mx-0">
                      <Col
                        xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        className="directorydata"
                      >
                        <h1 className="addRetHeading">Directory -</h1>
                        <div className="mt-2 program-upper-title">
                          <h6>
                            {data && data.title
                              ? `${
                                  data &&
                                  data.title &&
                                  data.title.charAt(0).toUpperCase() +
                                    data.title.slice(1)
                                }, `
                              : null}

                            {data.dateFrom == data.dateTo
                              ? moment(data && data.dateFrom).format(
                                  "MMM-DD-YYYY"
                                )
                              : moment(data && data.dateFrom).format("MMM DD") +
                                " - " +
                                moment(data && data.dateTo).format("MMM DD") +
                                ", " +
                                moment(data && data.dateTo).format("YYYY")}
                          </h6>
                        </div>
                      </Col>

                      <Col xs={12} sm={4} md={4} lg={4}>
                        {directory && directory.id ? (
                          <>
                            {" "}
                            <Button
                              onClick={() =>
                                changePublishStatus(directory, "directory")
                              }
                              className="btn-sm submitRet"
                            >
                              {directory && directory.status === "PUBLISH"
                                ? "Unpublish"
                                : "Publish"}
                            </Button>
                            <a
                              href={`/directory/${id}`}
                              className="font-size"
                              target="_blank"
                            >
                              <Button
                                variant="primary"
                                className="btn-sm submitRet"
                              >
                                Preview
                              </Button>
                            </a>
                          </>
                        ) : null}
                      </Col>
                      <hr />

                      <Row className="text-center">
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="directoryHeader footer-logo-image  howerOption"
                        >
                          <button
                            className="button-18 directory-header-image"
                            onClick={() =>
                              hadleDirectoryModel("directoryHeading")
                            }
                          >
                            Edit
                          </button>

                          <div>
                            {directory && directory.heading ? (
                              <h1>
                                {" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: directory && directory.heading,
                                  }}
                                />{" "}
                              </h1>
                            ) : (
                              <h1> Directory Heading....</h1>
                            )}
                          </div>
                        </Col>

                        {loadMain ? (
                          <div className="auth-form-light text-center main-register py-5 pt-0 px-4 px-sm-5">
                            <Row className="text-center">
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className=" mt-3 p-5"
                              >
                                <Loader />
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <Col
                            className="directoryImg howerOption mainHeaderImage p-0"
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            {!directory ||
                            directory.headerImage == "" ||
                            directory.headerImage == undefined ? (
                              <div className="text-center">
                                <img
                                  src={ImagePlaceHolder}
                                  alt=""
                                  srcset=""
                                  className="imageHeader"
                                />
                              </div>
                            ) : (
                              <div className="text-center">
                                <>
                                  <img
                                    src={directory && directory.headerImage}
                                    className="imageHeader"
                                  />
                                </>{" "}
                              </div>
                            )}{" "}
                            <div className="text-center setUploadField">
                              {!directory ||
                              directory.headerImage == "" ||
                              directory.headerImage == undefined ? (
                                <>
                                  <input
                                    name="imageUrl"
                                    className="pt-1"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id={
                                      `${directory && directory.id}` +
                                      30 +
                                      "-" +
                                      "CoupleProfileUpload"
                                    }
                                    onChange={(e) =>
                                      handleallImagesChanges(
                                        e,
                                        directory,
                                        "mainImage"
                                      )
                                    }
                                  />
                                  <button
                                    onClick={(e) => {
                                      upload(
                                        `${directory && directory.id}` + 30
                                      );
                                    }}
                                    className="middle_Icon button-18"
                                  >
                                    Upload
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="middle_Icon button-18"
                                  onClick={(e) => {
                                    remove(directory, "directory", "mainImage");
                                  }}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </Col>
                        )}

                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="directoryHeader"
                        >
                          <div>
                            <h1>
                              {" "}
                              <em>Retreat Directory</em>
                            </h1>
                            <h2>
                              <em>
                                {" "}
                                {data.dateFrom === data.dateTo ? (
                                  <span>
                                    {moment(data && data.dateFrom).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    {moment(data && data.dateFrom).format(
                                      "MMM"
                                    ) +
                                      " " +
                                      moment(data.dateFrom).format("DD") +
                                      "-" +
                                      moment(data.dateTo).format("DD") +
                                      ", " +
                                      moment(data && data.dateFrom).format(
                                        "YYYY"
                                      )}
                                  </span>
                                )}
                              </em>
                            </h2>
                            <img
                              src="https://lovestrong.koolderbyacademy.com/logo.png"
                              alt=""
                              srcset=""
                              className="mt-3"
                            />
                            <h2>
                              <em>Live. Love. strong.</em>
                            </h2>
                          </div>
                        </Col>
                        {allRetreatRoasters &&
                        allRetreatRoasters[0] &&
                        allRetreatRoasters[0].lead &&
                        allRetreatRoasters[0].lead.length ? (
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="colorTalks mt-3 p-5 leaderShip"
                          >
                            <h2 className="LeadHeading">Leadership</h2>
                            {allRetreatRoasters &&
                              allRetreatRoasters[0] &&
                              allRetreatRoasters[0].lead &&
                              allRetreatRoasters[0].lead.map((item, i) => {
                                return (
                                  <Row key={i} className="mt-5">
                                    <Col
                                      md={5}
                                      className="coupleImages coupleImageHower mainHeaderImage"
                                    >
                                      {(item && item.roasterImage == "") ||
                                      item.roasterImage == undefined ? (
                                        <div className="edit-profile-img mb-2  imageHeader">
                                          <img
                                            src={Profile}
                                            className="shadowImage"
                                          />
                                        </div>
                                      ) : (
                                        <div className="edit-profile-img mb-2  imageHeader">
                                          <>
                                            <img
                                              src={item && item.roasterImage}
                                              className="shadowImage"
                                            />
                                          </>{" "}
                                        </div>
                                      )}{" "}
                                      <div className="text-center setUploadField">
                                        {(item && item.roasterImage == "") ||
                                        item.roasterImage == undefined ? (
                                          <>
                                            <input
                                              name="imageUrl"
                                              className="pt-1"
                                              type="file"
                                              accept="image/*"
                                              style={{
                                                display: "none",
                                              }}
                                              id={
                                                `${i}` +
                                                "-" +
                                                "CoupleProfileUpload"
                                              }
                                              onChange={(e) =>
                                                handleImageChanges(e, item)
                                              }
                                            />
                                            <i
                                              onClick={(e) => {
                                                upload(i);
                                              }}
                                              className="mdi mdi-upload text-lg text-primary font-size middle_Icon1"
                                              title="Image Upload"
                                            ></i>
                                          </>
                                        ) : (
                                          <i
                                            className="mdi mdi-delete-circle text-md text-danger mx-2 font-size middle_Icon1"
                                            onClick={(e) => {
                                              remove(item, "", "");
                                            }}
                                            title="Remove"
                                          ></i>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={7} className="coupl_detail">
                                      {" "}
                                      <h2 className="mt-3 mb-3">
                                        {`${
                                          item && item.hisFirstName
                                            ? item.hisFirstName
                                            : ""
                                        } ${
                                          item && item.hisFirstName ? "&" : ""
                                        } ${
                                          item && item.herFirstName
                                            ? item.herFirstName
                                            : ""
                                        } ${
                                          item && item.hisLastName
                                            ? item.hisLastName
                                            : ""
                                        }`}
                                      </h2>
                                      <h4>
                                        {" "}
                                        {"His : " +
                                          `${
                                            item && item.hisMobile
                                              ? item.hisMobile
                                              : ""
                                          }`}
                                      </h4>
                                      <h4>{`${
                                        item && item.hisEmail
                                          ? item.hisEmail
                                          : ""
                                      }`}</h4>
                                      <h4>
                                        {" "}
                                        {"Her : " +
                                          `${
                                            item && item.herMobile
                                              ? item.herMobile
                                              : ""
                                          }`}
                                      </h4>
                                      <h4>
                                        {`${
                                          item && item.herEmail
                                            ? item.herEmail
                                            : ""
                                        }`}
                                      </h4>
                                      {item && item.anniversary_date ? (
                                        <h4>
                                          {"Anniversary Date : " +
                                            `${
                                              item && item.anniversary_date
                                                ? Helpers.convertUtcToTimezone(
                                                    item.anniversary_date,
                                                    "MM/DD"
                                                  )
                                                : ""
                                            }`}
                                        </h4>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}

                            {allRetreatRoasters &&
                              allRetreatRoasters[1] &&
                              allRetreatRoasters[1].coLead &&
                              allRetreatRoasters[1].coLead.map((item, i) => {
                                return (
                                  <Row key={i} className="mt-5">
                                    <Col
                                      md={5}
                                      className="coupleImages coupleImageHower mainHeaderImage"
                                    >
                                      {(item && item.roasterImage == "") ||
                                      item.roasterImage == undefined ? (
                                        <div className="edit-profile-img mb-2  imageHeader">
                                          <img
                                            src={Profile}
                                            className="shadowImage"
                                          />
                                        </div>
                                      ) : (
                                        <div className="edit-profile-img mb-2  imageHeader">
                                          <>
                                            <img
                                              src={item && item.roasterImage}
                                              className="shadowImage"
                                            />
                                          </>{" "}
                                        </div>
                                      )}{" "}
                                      <div className="text-center setUploadField">
                                        {(item && item.roasterImage == "") ||
                                        item.roasterImage == undefined ? (
                                          <>
                                            <input
                                              name="imageUrl"
                                              className="pt-1"
                                              type="file"
                                              accept="image/*"
                                              style={{
                                                display: "none",
                                              }}
                                              id={
                                                `${i}` +
                                                "-" +
                                                "CoupleProfileUpload"
                                              }
                                              onChange={(e) =>
                                                handleImageChanges(e, item)
                                              }
                                            />
                                            <i
                                              onClick={(e) => {
                                                upload(i);
                                              }}
                                              className="mdi mdi-upload text-lg text-primary font-size middle_Icon1"
                                              title="Image Upload"
                                            ></i>
                                          </>
                                        ) : (
                                          <i
                                            className="mdi mdi-delete-circle text-md text-danger mx-2 font-size middle_Icon1"
                                            onClick={(e) => {
                                              remove(item, "", "");
                                            }}
                                            title="Remove"
                                          ></i>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={7} className="coupl_detail">
                                      {" "}
                                      <h2 className="mt-3 mb-3">
                                        {`${
                                          item && item.hisFirstName
                                            ? item.hisFirstName
                                            : ""
                                        } ${
                                          item && item.hisFirstName ? "&" : ""
                                        } ${
                                          item && item.herFirstName
                                            ? item.herFirstName
                                            : ""
                                        } ${
                                          item && item.hisLastName
                                            ? item.hisLastName
                                            : ""
                                        }`}
                                      </h2>
                                      <h4>
                                        {" "}
                                        {"His : " +
                                          `${
                                            item && item.hisMobile
                                              ? item.hisMobile
                                              : ""
                                          }`}
                                      </h4>
                                      <h4>{`${
                                        item && item.hisEmail
                                          ? item.hisEmail
                                          : ""
                                      }`}</h4>
                                      <h4>
                                        {" "}
                                        {"Her : " +
                                          `${
                                            item && item.herMobile
                                              ? item.herMobile
                                              : ""
                                          }`}
                                      </h4>
                                      <h4>
                                        {`${
                                          item && item.herEmail
                                            ? item.herEmail
                                            : ""
                                        }`}
                                      </h4>{" "}
                                      {item && item.anniversary_date ? (
                                        <h4>
                                          {" "}
                                          {"Anniversary Date : " +
                                            `${
                                              item && item.anniversary_date
                                                ? Helpers.convertUtcToTimezone(
                                                    item.anniversary_date,
                                                    "MM/DD"
                                                  )
                                                : ""
                                            }`}
                                        </h4>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}

                            {allRetreatRoasters &&
                              allRetreatRoasters[2] &&
                              allRetreatRoasters[2].mentor &&
                              allRetreatRoasters[2].mentor.map((item, i) => {
                                return (
                                  <Row key={i} className="my-5">
                                    <Col
                                      md={5}
                                      className="coupleImages coupleImageHower mainHeaderImage"
                                    >
                                      {(item && item.roasterImage == "") ||
                                      item.roasterImage == undefined ? (
                                        <div className="edit-profile-img mb-2  imageHeader">
                                          <img
                                            src={Profile}
                                            className="shadowImage"
                                          />
                                        </div>
                                      ) : (
                                        <div className="edit-profile-img mb-2  imageHeader">
                                          <>
                                            <img
                                              src={item && item.roasterImage}
                                              className="shadowImage"
                                            />
                                          </>{" "}
                                        </div>
                                      )}{" "}
                                      <div className="text-center setUploadField">
                                        {(item && item.roasterImage == "") ||
                                        item.roasterImage == undefined ? (
                                          <>
                                            <input
                                              name="imageUrl"
                                              className="pt-1"
                                              type="file"
                                              accept="image/*"
                                              style={{
                                                display: "none",
                                              }}
                                              id={
                                                `${i}` +
                                                "-" +
                                                "CoupleProfileUpload"
                                              }
                                              onChange={(e) =>
                                                handleImageChanges(e, item)
                                              }
                                            />
                                            <i
                                              onClick={(e) => {
                                                upload(i);
                                              }}
                                              className="mdi mdi-upload text-lg text-primary font-size middle_Icon1"
                                              title="Image Upload"
                                            ></i>
                                          </>
                                        ) : (
                                          <i
                                            className="mdi mdi-delete-circle text-md text-danger mx-2 font-size middle_Icon1"
                                            onClick={(e) => {
                                              remove(item, "", "");
                                            }}
                                            title="Remove"
                                          ></i>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={7} className="coupl_detail">
                                      {" "}
                                      <h2 className="mt-3 mb-3">
                                        {`${
                                          item && item.hisFirstName
                                            ? item.hisFirstName
                                            : ""
                                        } ${
                                          item && item.hisFirstName ? "&" : ""
                                        } ${
                                          item && item.herFirstName
                                            ? item.herFirstName
                                            : ""
                                        } ${
                                          item && item.hisLastName
                                            ? item.hisLastName
                                            : ""
                                        }`}
                                      </h2>
                                      <h4>
                                        {" "}
                                        {"His : " +
                                          `${
                                            item && item.hisMobile
                                              ? item.hisMobile
                                              : ""
                                          }`}
                                      </h4>
                                      <h4>{`${
                                        item && item.hisEmail
                                          ? item.hisEmail
                                          : ""
                                      }`}</h4>
                                      <h4>
                                        {" "}
                                        {"Her : " +
                                          `${
                                            item && item.herMobile
                                              ? item.herMobile
                                              : ""
                                          }`}
                                      </h4>
                                      <h4>
                                        {`${
                                          item && item.herEmail
                                            ? item.herEmail
                                            : ""
                                        }`}
                                      </h4>{" "}
                                      {item && item.anniversary_date ? (
                                        <h4>
                                          {" "}
                                          {"Anniversary Date : " +
                                            `${
                                              item && item.anniversary_date
                                                ? Helpers.convertUtcToTimezone(
                                                    item.anniversary_date,
                                                    "MM/DD"
                                                  )
                                                : ""
                                            }`}
                                        </h4>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                          </Col>
                        ) : null}
                        {allRetreatRoasters &&
                        allRetreatRoasters[4] &&
                        allRetreatRoasters[4].retreatant.length &&
                        allRetreatRoasters[4].retreatant ? (
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="colorTalks mt-3 p-5 leaderShip"
                          >
                            <h2 className="LeadHeading">Retreatants</h2>
                            {allRetreatRoasters &&
                              allRetreatRoasters[4] &&
                              allRetreatRoasters[4].retreatant &&
                              allRetreatRoasters[4].retreatant.map(
                                (item, i) => {
                                  return (
                                    <Row className="mt-5" key={i}>
                                      <Col
                                        md={5}
                                        className="coupleImages coupleImageHower mainHeaderImage"
                                      >
                                        {(item && item.roasterImage == "") ||
                                        item.roasterImage == undefined ? (
                                          <div className="edit-profile-img mb-2 imageHeader ">
                                            <img
                                              src={Profile}
                                              className="shadowImage"
                                            />
                                          </div>
                                        ) : (
                                          <div className="edit-profile-img mb-2  imageHeader ">
                                            <>
                                              <img
                                                src={item && item.roasterImage}
                                                className="shadowImage"
                                              />
                                            </>{" "}
                                          </div>
                                        )}{" "}
                                        <div className="text-center setUploadField">
                                          {(item && item.roasterImage == "") ||
                                          item.roasterImage == undefined ? (
                                            <>
                                              <input
                                                name="imageUrl"
                                                className="pt-1"
                                                type="file"
                                                accept="image/*"
                                                style={{
                                                  display: "none",
                                                }}
                                                id={
                                                  `${i}` +
                                                  "-" +
                                                  "CoupleProfileUpload"
                                                }
                                                onChange={(e) =>
                                                  handleImageChanges(e, item)
                                                }
                                              />
                                              <i
                                                onClick={(e) => {
                                                  upload(i);
                                                }}
                                                className="mdi mdi-upload text-lg text-primary font-size middle_Icon1"
                                                title="Image Upload"
                                              ></i>
                                            </>
                                          ) : (
                                            <i
                                              className="mdi mdi-delete-circle text-md text-danger mx-2 font-size middle_Icon1"
                                              onClick={(e) => {
                                                remove(item, "", "");
                                              }}
                                              title="Remove"
                                            ></i>
                                          )}
                                        </div>
                                      </Col>
                                      <Col md={7} className="coupl_detail">
                                        {" "}
                                        <h2 className="mt-3 mb-3">
                                          {`${
                                            item && item.hisFirstName
                                              ? item.hisFirstName
                                              : ""
                                          } ${
                                            item && item.hisFirstName ? "&" : ""
                                          } ${
                                            item && item.herFirstName
                                              ? item.herFirstName
                                              : ""
                                          } ${
                                            item && item.hisLastName
                                              ? item.hisLastName
                                              : ""
                                          }`}
                                        </h2>
                                        <h4>
                                          {" "}
                                          {"His : " +
                                            `${
                                              item && item.hisMobile
                                                ? item.hisMobile
                                                : ""
                                            }`}
                                        </h4>
                                        <h4>{`${
                                          item && item.hisEmail
                                            ? item.hisEmail
                                            : ""
                                        }`}</h4>
                                        <h4>
                                          {" "}
                                          {"Her : " +
                                            `${
                                              item && item.herMobile
                                                ? item.herMobile
                                                : ""
                                            }`}
                                        </h4>
                                        <h4>
                                          {`${
                                            item && item.herEmail
                                              ? item.herEmail
                                              : ""
                                          }`}
                                        </h4>{" "}
                                        {item && item.anniversary_date ? (
                                          <h4>
                                            {" "}
                                            {"Anniversary Date : " +
                                              `${
                                                item && item.anniversary_date
                                                  ? Helpers.convertUtcToTimezone(
                                                      item.anniversary_date,
                                                      "MM/DD"
                                                    )
                                                  : ""
                                              }`}
                                          </h4>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                          </Col>
                        ) : null}
                        {allRetreatRoasters &&
                        allRetreatRoasters[3] &&
                        allRetreatRoasters[3].team &&
                        allRetreatRoasters[3].team.length ? (
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="colorTalks mt-3 p-5 leaderShip"
                          >
                            <img
                              src="https://lovestrong.koolderbyacademy.com/logo.png"
                              alt=""
                              srcset=""
                            />
                            <h2 className="mt-3 mb-5">
                              <em
                                style={{
                                  color: "#d2b74c",
                                  fontWeight: "700",
                                }}
                              >
                                Live. Love. strong.
                              </em>
                            </h2>
                            <h2 className="LeadHeading">Team</h2>
                            {allRetreatRoasters &&
                              allRetreatRoasters[3] &&
                              allRetreatRoasters[3].team &&
                              allRetreatRoasters[3].team.map((item, i) => {
                                return (
                                  <Row className="mt-5">
                                    <Col
                                      md={5}
                                      className="coupleImages coupleImageHower mainHeaderImage"
                                    >
                                      {(item && item.roasterImage == "") ||
                                      item.roasterImage == undefined ? (
                                        <div className="edit-profile-img mb-2 imageHeader">
                                          <img
                                            src={Profile}
                                            className="shadowImage"
                                          />
                                        </div>
                                      ) : (
                                        <div className="edit-profile-img mb-2 imageHeader">
                                          <>
                                            <img
                                              src={item && item.roasterImage}
                                              className="shadowImage"
                                            />
                                          </>{" "}
                                        </div>
                                      )}{" "}
                                      <div className="text-center setUploadField">
                                        {(item && item.roasterImage == "") ||
                                        item.roasterImage == undefined ? (
                                          <>
                                            <input
                                              name="imageUrl"
                                              className="pt-1"
                                              type="file"
                                              accept="image/*"
                                              style={{
                                                display: "none",
                                              }}
                                              id={
                                                `${i}` +
                                                "-" +
                                                "CoupleProfileUpload"
                                              }
                                              onChange={(e) =>
                                                handleImageChanges(e, item)
                                              }
                                            />
                                            <i
                                              onClick={(e) => {
                                                upload(i);
                                              }}
                                              className="mdi mdi-upload text-lg text-primary font-size middle_Icon1"
                                              title="Image Upload"
                                            ></i>
                                          </>
                                        ) : (
                                          <i
                                            className="mdi mdi-delete-circle text-md text-danger mx-2 font-size middle_Icon1"
                                            onClick={(e) => {
                                              remove(item, "", "");
                                            }}
                                            title="Remove"
                                          ></i>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={7} className="coupl_detail">
                                      {" "}
                                      <h2 className="mt-3 mb-3">
                                        {`${
                                          item && item.hisFirstName
                                            ? item.hisFirstName
                                            : ""
                                        } ${
                                          item && item.hisFirstName ? "&" : ""
                                        } ${
                                          item && item.herFirstName
                                            ? item.herFirstName
                                            : ""
                                        } ${
                                          item && item.hisLastName
                                            ? item.hisLastName
                                            : ""
                                        }`}
                                      </h2>
                                      <h4>
                                        {" "}
                                        {"His : " +
                                          `${
                                            item && item.hisMobile
                                              ? item.hisMobile
                                              : ""
                                          }`}
                                      </h4>
                                      <h4>{`${
                                        item && item.hisEmail
                                          ? item.hisEmail
                                          : ""
                                      }`}</h4>
                                      <h4>
                                        {" "}
                                        {"Her : " +
                                          `${
                                            item && item.herMobile
                                              ? item.herMobile
                                              : ""
                                          }`}
                                      </h4>
                                      <h4>
                                        {`${
                                          item && item.herEmail
                                            ? item.herEmail
                                            : ""
                                        }`}
                                      </h4>{" "}
                                      {item && item.anniversary_date ? (
                                        <h4>
                                          {" "}
                                          {"Anniversary Date : " +
                                            `${
                                              item && item.anniversary_date
                                                ? Helpers.convertUtcToTimezone(
                                                    item.anniversary_date,
                                                    "MM/DD"
                                                  )
                                                : ""
                                            }`}
                                        </h4>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                          </Col>
                        ) : null}
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="colorTalks mt-3 leaderShip directory_footer "
                        >
                          {loadFooter1 ? (
                            <div className="mainHeaderImage">
                              {" "}
                              <Row className="text-center">
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className=" mt-3 p-5"
                                >
                                  <Loader />
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div className="mainHeaderImage">
                              {!directory ||
                              directory.footerImage1 == "" ||
                              directory.footerImage1 == undefined ? (
                                <div className="mb-2 text-center footer_images">
                                  <img
                                    src={ImagePlaceHolder}
                                    alt=""
                                    srcset=""
                                    className="imageHeader"
                                  />
                                </div>
                              ) : (
                                <div className="mb-2 text-center footer_images">
                                  <>
                                    <img
                                      src={directory && directory.footerImage1}
                                      className="imageHeader"
                                    />
                                  </>{" "}
                                </div>
                              )}{" "}
                              <div className="text-center setUploadField">
                                {!directory ||
                                directory.footerImage1 == "" ||
                                directory.footerImage1 == undefined ? (
                                  <>
                                    <input
                                      name="imageUrl"
                                      className="pt-1"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      id={
                                        `${directory && directory.id}` +
                                        40 +
                                        "-" +
                                        "CoupleProfileUpload"
                                      }
                                      onChange={(e) =>
                                        handleallImagesChanges(
                                          e,
                                          directory,
                                          "footerImage1"
                                        )
                                      }
                                    />
                                    <button
                                      onClick={(e) => {
                                        upload(
                                          `${directory && directory.id}` + 40
                                        );
                                      }}
                                      className="middle_Icon button-18"
                                    >
                                      Upload
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="middle_Icon button-18"
                                    onClick={(e) => {
                                      remove(
                                        directory,
                                        "directory",
                                        "footerImage1"
                                      );
                                    }}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="retdet">
                            <h2>Holy Spirit Lovestrong Team</h2>
                            <h2>
                              <em>
                                {" "}
                                {data.dateFrom === data.dateTo ? (
                                  <span>
                                    {moment(data && data.dateFrom).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    {moment(data && data.dateFrom).format(
                                      "MMM"
                                    ) +
                                      " " +
                                      moment(data.dateFrom).format("DD") +
                                      "-" +
                                      moment(data.dateTo).format("DD") +
                                      ", " +
                                      moment(data && data.dateFrom).format(
                                        "YYYY"
                                      )}
                                  </span>
                                )}
                              </em>
                            </h2>
                          </div>{" "}
                          {loadFooter2 ? (
                            <div className="mainHeaderImage">
                              {" "}
                              <Row className="text-center">
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className=" mt-3 p-5"
                                >
                                  <Loader />
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div className="mainHeaderImage">
                              {!directory ||
                              directory.footerImage2 == "" ||
                              directory.footerImage2 == undefined ? (
                                <div className="mb-2 text-center footer_images">
                                  <img
                                    src={ImagePlaceHolder}
                                    alt=""
                                    srcset=""
                                    className="imageHeader"
                                  />
                                </div>
                              ) : (
                                <div className="mb-2 text-center footer_images">
                                  <>
                                    <img
                                      src={directory && directory.footerImage2}
                                      height={"286px"}
                                      width={"824px"}
                                      className="imageHeader"
                                    />
                                  </>
                                </div>
                              )}
                              <div className="text-center setUploadField footer_images">
                                {!directory ||
                                directory.footerImage2 == "" ||
                                directory.footerImage2 == undefined ? (
                                  <>
                                    <input
                                      name="imageUrl"
                                      className="pt-1"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      id={
                                        `${directory && directory.id}` +
                                        50 +
                                        "-" +
                                        "CoupleProfileUpload"
                                      }
                                      onChange={(e) =>
                                        handleallImagesChanges(
                                          e,
                                          directory,
                                          "footerImage2"
                                        )
                                      }
                                    />
                                    <button
                                      onClick={(e) => {
                                        upload(`${directory.id}` + 50);
                                      }}
                                      className="middle_Icon button-18"
                                    >
                                      Upload
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="middle_Icon button-18"
                                    onClick={(e) => {
                                      remove(
                                        directory,
                                        "directory",
                                        "footerImage2"
                                      );
                                    }}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                          <h2 className="mt-5 mb-2 dirTitle">
                            <em
                              className="mt-5 mb-2 dirTitle"
                              dangerouslySetInnerHTML={{
                                __html: directory && directory.heading,
                              }}
                            />{" "}
                          </h2>
                          <div className="howerOption">
                            {" "}
                            <div className="buttonContainer1 text-right">
                              <button
                                className="button-18"
                                onClick={() =>
                                  hadleDirectoryModel("directoryFooterHeading")
                                }
                              >
                                Edit
                              </button>
                            </div>
                            <h2 className="mb-4 footerTitle">
                              {directory && directory.footerText ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: directory && directory.footerText,
                                  }}
                                />
                              ) : (
                                "Footer Text..."
                              )}
                            </h2>
                          </div>
                          <img
                            src="https://lovestrong.koolderbyacademy.com/logo.png"
                            alt=""
                            srcset=""
                            className="logo_image footer-logo-image1"
                          />
                          <h2 className="mt-3 mb-3">
                            <em
                              style={{
                                color: "#d2b74c",
                                fontWeight: "700",
                              }}
                            >
                              Live. Love. strong.
                            </em>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>

        {modalShow ? (
          <DirectoryModel
            show={modalShow}
            directoryField={nameModel}
            directory={directory}
            retreatData={data}
            coupleTalkData={coupleTalkData}
            onHide={() => closeDirectoryModel()}
          />
        ) : null}
        {showDelete ? (
          <DeleteModel
            show={showDelete}
            // handleClose={handleClose}
            // DeleteData={deleteRoasterCouple}
          ></DeleteModel>
        ) : null}
      </div>
    </>
  );
};
