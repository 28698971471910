import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const CustomDataTable = ({
  loading = false,
  title = "",
  columns = [],
  data = [],
  onRowClicked,
  pagination = true,
  pointerOnHover = false,
  highlightOnHover = false,
  total,
  countPerPage = 20,
  dataFetchApi = null,
  isServerSide = false,
  sortServer = false,
  noDataComponent = <h5 className="mb-3 mt-2">There are no records to display</h5>,
  conditionalRowStyles,
  fixedHeader = false,
  fixedHeaderScrollHeight = "455px",
  selectedRetreat = "",
}) => {
  let location = useLocation();
  let url = location.pathname;
  const [perPage, setPerPage] = useState(20);
  let checkPagination = total && total > perPage ? true : false;
  const [pending, setPending] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({});
  const [colm, setColm] = useState(columns);

  const prevPageRef = useRef();
  useEffect(() => {
    if (dataFetchApi && prevPageRef.current !== undefined)
      dataFetchApi(page, perPage, null, sortBy, selectedRetreat);

    prevPageRef.current = page;
  }, [page, sortBy, perPage]);

  useEffect(() => {}, [window.innerWidth]);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f8f9fb",
        fontSize: "0.82rem !important",
        fontWeight: "700",
        innerHeight: "20px",
        height: "38px",
        // paddingLeft: '20px',
      },
    },
    head: {
      style: {
        height: "38px",
      },
    },
    rows: {
      style: {
        height: "auto !important",
        minHeight: "48px !important",
      },
    },
    cells: {
      style: {
        height: "auto !important",
        minHeight: "48px !important",
        verticalAlign: "middle",
        fontSize: "0.82rem",
        lineHeight: "1.5",
        // whiteSpace: "nowrap",
        fontWeight: "500",
      },
    },
    table: {
      style: {
        paddingBottom: "0",
      },
    },
  };

  const titleStyles = {
    fontWeight: "700",
    textTransform: "capitalize",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "16px",
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    // console.log('sort>',column.id, sortDirection);
    let direction = sortDirection == "asc" ? 1 : -1;
    let s = { ...sortBy };
    s.sortColumn = column.id;
    s.sortDirection = direction;
    setSortBy(s);
  };
  return (
    <span className="c-overflow">
      <DataTable
        pointerOnHover={pointerOnHover}
        highlightOnHover={highlightOnHover}
        title={title ? <span style={titleStyles}>{title}</span> : ""}
        columns={colm}
        data={data}
        progressPending={loading}
        progressComponent={
          <Spinner
            animation="border spinner-border"
            style={{ color: "red", margin: "20px" }}
          />
        }
        customStyles={customStyles}
        noDataComponent={noDataComponent}
        pagination={data.length > perPage || checkPagination}
        onRowClicked={onRowClicked}
        paginationServer={isServerSide}
        paginationTotalRows={total}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: false,
        }}
        onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
        onChangePage={(page) => setPage(page)}
        sortServer={sortServer}
        onSort={handleSort}
        conditionalRowStyles={conditionalRowStyles}
      />
    </span>
  );
};
export default CustomDataTable;
