import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Container,
  Table,
  Button,
  Card,
} from "react-bootstrap";
import SunEditor from "suneditor-react";
import AuthApi from "../../../api/authApi";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import TimePicker from "../../../components/TimePicker";
import moment from "moment-timezone";
import Helpers from "../../../components/Helpers";
import uploadRetreat from "../../../assets/images/uploadRetreat.png";
import Profile from "../../../assets/images/couple-default.png";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import RetreatApi from "../../../api/retreatApi";
import AdminApi from "../../../api/admin";
import RolesApi from "../../../api/RolesApi";
import RetreatRoasterApi from "../../../api/retreatRoasterApi";
import { Cookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader";
import DeleteModel from "../../../components/DeleteModel";
import { useTranslation } from "react-i18next";

export const CreateRetreat = () => {
  const [data, setData] = useState({
    id: "",
    retreatment: "",
    title: "",
    parish: "",
    language: "",
    location: "",
    schedule: [],
    image1: "",
    image2: "",
    heading: "",
    mission_statement: `"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."`,
    description: "",
    mail_msg: "",
  });

  const [timeDate, setTimeDate] = useState({
    date: new Date(),
    from: "00 : 00",
    to: "00 : 00",
  });

  const [newTitle, setNewTitle] = useState({
    parish: "",
    language: "",
    location: "",
  });

  const { t, i18n } = useTranslation();

  const [validated, setValidated] = useState(false);
  const [perishDataList, setPerishDataList] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [isTempUpload1, setIsTempUpload1] = useState(false);
  const [isTempUpload2, setIsTempUpload2] = useState(false);
  const [isTempUpload, setIsTempUpload] = useState(false);
  const [applyLoader, setApplyLoader] = useState(false);
  const [accessGreenTick, setAccessGreenTick] = useState(false);
  const [ediUserIdClicked, setEdiUserIdClicked] = useState("");
  const [oldData, setOldData] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [greenTick, setGreenTick] = useState(false);
  const [removeAccessModal, setRemoveAccessModal] = useState(false);
  const [Pass, setPass] = useState(false);
  const [check, setCheck] = useState("");
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rolesDataList, setRolesDataList] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [allCoupleData, setAllCoupleData] = useState([]);
  const [coupleSelectForRole, setCoupleSelectForRole] = useState([]);
  const [roleGreenTick, setRoleGreenTick] = useState(false);
  const [roles, setRoles] = useState([]);
  const [description, setDescription] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const [leadCoupleLoader, setLeadCoupleLoader] = useState(false);
  const [coLeadCoupleLoader, setCoLeadCoupleLoader] = useState(false);
  const [mentorLoader, setMentorLoader] = useState(false);
  const [spiritualDirectorLoader, setSpiritualDirectorLoader] = useState(false);
  const [spiritualMentorLoader, setSpiritualMentorLoader] = useState(false);
  const [allCoupleMails, setAllCoupleMails] = useState([]);
  const [coupleSelectForAccess, setCoupleSelectForAccess] = useState([]);
  const [removeCoupleModal, setRemoveCoupleModal] = useState("");
  const [missionStatement, setMissionStatement] = useState("");
  const [fromErrorMsg, setFromErrorMsg] = useState(false);
  const [toErrorMsg, setToErrorMsg] = useState(false);

  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  let authApi = new AuthApi();
  let retreatApi = new RetreatApi();
  let adminApi = new AdminApi();
  let rolesApi = new RolesApi();
  let retreatRoasterApi = new RetreatRoasterApi();
  const { id } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    if (!id) {
      getMissionStatement();
    }
    getPerishList();
    getrolesList();
    getAllCouples();
  }, []);

  useEffect(() => {
    if (id) {
      editRetreat(id);
      getRolesOfRetreats(id);
    }
  }, [id]);

  const formattedDate = (date) => {
    return moment(date).tz("America/New_York").format("YYYY-MM-DD");
  };

  const getAllCouples = async () => {
    await adminApi
      .allCouples()
      .then(async (res) => {
        if (res.success) {
          let allcoupleList = [];
          let allMailcoupleList = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.hisFirstName || item.hisLastName || item.herFirstName) {
                allcoupleList.push({
                  label:
                    `${item.hisLastName || ""}` +
                    `${item.hisLastName ? ", " : ""}` +
                    `${item.hisFirstName || ""}` +
                    `${item.hisFirstName && item.herFirstName ? " and " : ""}` +
                    `${item.herFirstName || ""}`,
                  value: item.id,
                });
              }
              if (item.hisEmail) {
                allMailcoupleList.push({
                  label: `${item.hisEmail} (${item.hisFirstName || ""} ${
                    item.hisLastName || ""
                  })`,
                  value: item.id,
                });
              }
              if (item.herEmail) {
                allMailcoupleList.push({
                  label: `${item.herEmail} (${item.herFirstName || ""} ${
                    item.herLastName || ""
                  })`,
                  value: item.id,
                });
              }
            });
          const sort = allcoupleList.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          setAllCoupleData(sort);
          await adminApi.outerUsers().then((res) => {
            if (res.success) {
              res.data &&
                res.data.forEach((item) => {
                  if (item.email) {
                    allMailcoupleList.push({
                      label: item.email,
                      value: item.id,
                    });
                  }
                });
              setAllCoupleMails(allMailcoupleList);
            }
          });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getrolesList = async () => {
    await rolesApi
      .getAllRoleList()
      .then(async (res) => {
        let roles = [];
        res.data &&
          res.data.forEach((item) => {
            if (item.role) {
              roles.push({
                label: item.role,
                value: item.id,
              });
            }
          });
        const filteredRoles = roles.filter(
          (role) =>
            role.label === "Lead Couple" ||
            role.label === "Co-Lead Couple" ||
            role.label === "Spiritual Director" ||
            role.label === "Spiritual Mentor" ||
            role.label === "Mentor Couple"
        );

        const roleOrder = [
          "Lead Couple",
          "Co-Lead Couple",
          "Spiritual Director",
          "Spiritual Mentor",
          "Mentor Couple",
        ];

        const sortedRoles = filteredRoles.sort(
          (a, b) => roleOrder.indexOf(a.label) - roleOrder.indexOf(b.label)
        );
        setRolesDataList(sortedRoles);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getRolesOfRetreats = async (id) => {
    await authApi.getRolesOfRetreats({ id: id }).then(async (response) => {
      setRoles(response.data);
    });
  };

  const getMissionStatement = async () => {
    await retreatApi
      .getMissionStatement()
      .then((response) => {
        if (response && response.data && response.data.mission_statement) {
          setMissionStatement(response.data.mission_statement);
        } else {
          setMissionStatement(
            `<p><em>"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."</em></p>`
          );
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getPerishList = async () => {
    await authApi
      .getPerishList()
      .then((response) => {
        const sortedList = response.data.sort((a, b) =>
          a.parish.localeCompare(b.parish)
        );
        setPerishDataList(sortedList);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const handleChanges = (name, e) => {
    let d = { ...data };
    let s = { ...newTitle };
    let o = { ...oldData };
    if (e.target.name == "title" && name == "") {
      o.title = oldValues.title;
    } else if (e.target.name == "parish" && name == "") {
      o.parish = oldValues.parish;
    } else if (e.target.name == "language" && name == "") {
      o.language = oldValues.language;
    } else if (e.target.name == "location" && name == "") {
      o.location = oldValues.location;
    } else if (e.target.name == "image1" && name == "") {
      o.image1 = oldValues.image1;
    } else if (e.target.name == "image2" && name == "") {
      o.image2 = oldValues.image2;
    } else if (e.target.name == "heading" && name == "") {
      o.heading = oldValues.heading;
    } else if (e.target.name == "mission_statement" && name == "") {
      o.mission_statement = oldValues.mission_statement;
    } else if (e.target.name == "description" && name == "") {
      o.description = oldValues.description;
    }
    setOldData(o);
    if (!name) {
      d[e.target.name] = e.target.value;
    }
    if (e.target.name === "parish") {
      let getPerish = perishDataList.find((m) => m.id == e.target.value);
      if (getPerish != undefined) {
        s["parish"] = getPerish.parish;
        setNewTitle(s);
        if (newTitle.language != "" && newTitle.location == "") {
          d.title =
            getPerish.parish +
            " (" +
            newTitle.language.charAt(0).toUpperCase() +
            newTitle.language.slice(1) +
            ") ";
        } else if (newTitle.language == "" && newTitle.location != "") {
          d.title = getPerish.parish + ", " + newTitle.location;
        } else if (newTitle.language != "" && newTitle.location != "") {
          d.title =
            getPerish.parish +
            " (" +
            newTitle.language.charAt(0).toUpperCase() +
            newTitle.language.slice(1) +
            ") " +
            newTitle.location;
        } else {
          d.title = getPerish.parish;
        }
      }
      if (!getPerish.parish) {
        d.title = d.title.substring(0, d.title.length - 2);
      }
      d.myparish = getPerish;
      if (d.language && d.location && d.schedule.length) {
        autoSaveFunction(d);
      }
      if (
        d &&
        d.schedule &&
        (!d.schedule[0] ||
          !d.schedule[0].from ||
          d.schedule[0].from === "00 : 00")
      ) {
        setFromErrorMsg(true);
      }
      if (
        d &&
        d.schedule &&
        (!d.schedule[0] || !d.schedule[0].to || d.schedule[0].to === "00 : 00")
      ) {
        setToErrorMsg(true);
      }
      setValidated(true);
    }
    if (e.target.name === "language") {
      s["language"] = e.target.value;
      let missionData = "";
      if (e.target.value === "spanish") {
        d.mission_statement = `"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."`;
        setMissionStatement(
          `<p><em>"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."</em></p>`
        );
        missionData = `<p><em>"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."</em></p>`;
      } else {
        d.mission_statement = `"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."`;
        setMissionStatement(
          `<p><em>"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."</em></p>`
        );
        missionData = `<p><em>"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."</em></p>`;
      }
      i18n.changeLanguage(e.target.value);
      setNewTitle(s);
      if (newTitle.parish != "" && newTitle.location == "") {
        d.title =
          newTitle.parish +
          " (" +
          e.target.value.charAt(0).toUpperCase() +
          e.target.value.slice(1) +
          ") ";
      } else if (newTitle.parish == "" && newTitle.location != "") {
        d.title =
          e.target.value.charAt(0).toUpperCase() +
          e.target.value.slice(1) +
          ", " +
          newTitle.location;
      } else if (newTitle.parish != "" && newTitle.location != "") {
        d.title =
          newTitle.parish +
          " (" +
          e.target.value.charAt(0).toUpperCase() +
          e.target.value.slice(1) +
          ") " +
          newTitle.location;
      } else {
        d.title =
          e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      }
      if (!e.target.value) {
        d.title = d.title.substring(0, d.title.length - 2);
      }
      if ((d.parishId || d.parish) && d.location && d.schedule.length) {
        autoSaveFunction(d, missionData);
      }
      if (
        d &&
        d.schedule &&
        (!d.schedule[0] ||
          !d.schedule[0].from ||
          d.schedule[0].from === "00 : 00")
      ) {
        setFromErrorMsg(true);
      }
      if (
        d &&
        d.schedule &&
        (!d.schedule[0] || !d.schedule[0].to || d.schedule[0].to === "00 : 00")
      ) {
        setToErrorMsg(true);
      }
      setValidated(true);
    }
    if (e.target.name === "location") {
      s["location"] = e.target.value;
      setNewTitle(s);
      if (newTitle.parish != "" && newTitle.language == "") {
        d.title = newTitle.parish + ", " + e.target.value;
      } else if (newTitle.parish == "" && newTitle.language != "") {
        d.title =
          newTitle.language.charAt(0).toUpperCase() +
          newTitle.language.slice(1) +
          ") " +
          e.target.value;
      } else if (newTitle.parish != "" && newTitle.language != "") {
        d.title =
          newTitle.parish +
          " (" +
          newTitle.language.charAt(0).toUpperCase() +
          newTitle.language.slice(1) +
          ") " +
          e.target.value;
      } else {
        d.title = e.target.value;
      }
      if (!e.target.value) {
        d.title = d.title.substring(0, d.title.length - 2);
      }
      // if (d.parish && d.language) {
      //   autoSaveFunction(d);
      // }
    }
    if (e.target.name == "image1") {
      d.image1 = e.target.files[0];
      if (d.parishId && d.language && d.location && d.schedule.length) {
        autoSaveFunction(d);
      }
      if (
        d &&
        d.schedule &&
        (!d.schedule[0] ||
          !d.schedule[0].from ||
          d.schedule[0].from === "00 : 00")
      ) {
        setFromErrorMsg(true);
      }
      if (
        d &&
        d.schedule &&
        (!d.schedule[0] || !d.schedule[0].to || d.schedule[0].to === "00 : 00")
      ) {
        setToErrorMsg(true);
      }
      setValidated(true);
    }
    if (e.target.name == "image2") {
      d.image2 = e.target.files[0];
      if (d.parishId && d.language && d.location && d.schedule.length) {
        autoSaveFunction(d);
      }
      if (
        d &&
        d.schedule &&
        (!d.schedule[0] ||
          !d.schedule[0].from ||
          d.schedule[0].from === "00 : 00")
      ) {
        setFromErrorMsg(true);
      }
      if (
        d &&
        d.schedule &&
        (!d.schedule[0] || !d.schedule[0].to || d.schedule[0].to === "00 : 00")
      ) {
        setToErrorMsg(true);
      }
      setValidated(true);
    }
    setData(d);
  };

  const handleSubmitOnBlur = (e, selectEditor) => {
    const url = window.location.href;
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];
    let d = { ...data };
    if (selectEditor == "location") {
      if ((d.parish || d.parishId) && d.language && d.schedule.length) {
        let data = {
          selectEditor: selectEditor,
          id: lastPart,
          value: e.target.value,
          title: d.title,
        };
        retreatApi
          .autoSaveRetreat(data)
          .then((res) => {
            if (res.success) {
              toast.success(res.msg);
              clearfield();
              editRetreat(data.id);
              // let report = {
              //   byWhom:
              //     `${userData && userData.userName ? userData.userName : null} ` +
              //     ` (${
              //       userData && userData.userRole ? userData.userRole : null
              //     })`,
              //   pagelink: window.location.href,
              //   description: `Created New  Retreat ( Title : ${data.title} )`,
              // };
              // adminApi
              //   .addLogReport(report)
              //   .then((response) => {
              //     if (response.success) {
              //       console.log("Added new log report");
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //   });
              setApplyLoader(false);
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        setValidated(true);
      }
    } else if (selectEditor == "heading") {
      if (
        (d.parish || d.parishId) &&
        d.language &&
        d.schedule.length &&
        d.location
      ) {
        let data = {
          selectEditor: selectEditor,
          id: lastPart,
          value: e.target.value,
        };
        retreatApi
          .autoSaveRetreat(data)
          .then((res) => {
            if (res.success) {
              toast.success(res.msg);
              clearfield();
              editRetreat(data.id);
              // let report = {
              //   byWhom:
              //     `${userData && userData.userName ? userData.userName : null} ` +
              //     ` (${
              //       userData && userData.userRole ? userData.userRole : null
              //     })`,
              //   pagelink: window.location.href,
              //   description: `Created New  Retreat ( Title : ${data.title} )`,
              // };
              // adminApi
              //   .addLogReport(report)
              //   .then((response) => {
              //     if (response.success) {
              //       console.log("Added new log report");
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //   });
              setApplyLoader(false);
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        setValidated(true);
      }
    } else if (selectEditor == "title") {
      if (
        (d.parish || d.parishId) &&
        d.language &&
        d.schedule.length &&
        d.location
      ) {
        let data = {
          selectEditor: selectEditor,
          id: lastPart,
          value: e.target.value,
        };
        retreatApi
          .autoSaveRetreat(data)
          .then((res) => {
            if (res.success) {
              toast.success(res.msg);
              clearfield();
              editRetreat(data.id);
              // let report = {
              //   byWhom:
              //     `${userData && userData.userName ? userData.userName : null} ` +
              //     ` (${
              //       userData && userData.userRole ? userData.userRole : null
              //     })`,
              //   pagelink: windoeditRetreatw.location.href,
              //   description: `Created New  Retreat ( Title : ${data.title} )`,
              // };
              // adminApi
              //   .addLogReport(report)
              //   .then((response) => {
              //     if (response.success) {
              //       console.log("Added new log report");
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //   });
              setApplyLoader(false);
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        setValidated(true);
      }
    } else {
      if (lastPart === "create-retreat") {
        toast.error("Please enter the required fields.");
        setValidated(true);
      } else {
        let data = {
          selectEditor: selectEditor,
          id: lastPart,
          value: e.target.innerHTML,
        };
        retreatApi
          .autoSaveRetreat(data)
          .then((res) => {
            if (res.success) {
              toast.success(res.msg);
              clearfield();
              editRetreat(data.id);
              // let report = {
              //   byWhom:
              //     `${userData && userData.userName ? userData.userName : null} ` +
              //     ` (${
              //       userData && userData.userRole ? userData.userRole : null
              //     })`,
              //   pagelink: window.location.href,
              //   description: `Created New  Retreat ( Title : ${data.title} )`,
              // };
              // adminApi
              //   .addLogReport(report)
              //   .then((response) => {
              //     if (response.success) {
              //       console.log("Added new log report");
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //   });
              setApplyLoader(false);
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      setIsTempUpload1(false);
      setIsTempUpload2(false);
      setApplyLoader(true);
      if (
        ediUserIdClicked &&
        ediUserIdClicked !== "" &&
        ediUserIdClicked !== null
      ) {
        let data1 = new FormData();
        if (data.image1 !== "") {
          data1.append("file1", data.image1);
        }
        if (data.image2 !== "") {
          data1.append("file2", data.image2);
        }
        let stateRef = { ...data };
        stateRef.mission_statement = missionStatement;
        stateRef.description = description;
        stateRef.mail_msg = emailMsg;
        // delete stateRef.imageUrl
        data1.append("fromData", JSON.stringify(stateRef));
        retreatApi
          .editRetreat(data1)
          .then((res) => {
            if (res.success) {
              toast.success(res.msg);
              clearfield();
              const { schedule, ...rest } = res.data;
              const parsedSchedule = schedule ? JSON.parse(schedule) : [];
              const parsedData = {
                ...rest,
                schedule: parsedSchedule.map((entry) => ({
                  date: new Date(entry.date),
                  from: entry.from,
                  to: entry.to,
                })),
              };
              let myparish = perishDataList.find(
                (i) => i.id === parsedData.parishId
              );
              if (myparish) {
                parsedData.myparish = myparish;
              }
              parsedData.schedule.forEach((item) => {
                item.dayName = getDayName(item.date, parsedData.language);
              });
              setData(parsedData);
              if (oldData.title) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Edited Title of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                    ` From "${oldData.title ? oldData.title : "Nil"}" to "${
                      res.data.title ? res.data.title : "Nil"
                    }"`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.location ||
                (oldData.location === "" &&
                  oldData.location !== res.data.location)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Edited Location of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                    ` From "${
                      oldData.location ? oldData.location : "Nil"
                    }" to "${res.data.location ? res.data.location : "Nil"}"`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.language ||
                (oldData.language === "" &&
                  oldData.language !== res.data.language)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Edited Language of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                    ` From "${
                      oldData.language ? oldData.language : "Nil"
                    }" to "${res.data.language ? res.data.language : "Nil"}"`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              let oldparish = perishDataList.find(
                (i) => i.id === oldData.parish
              );
              let newparish = perishDataList.find(
                (i) => i.id === res.data.parishId
              );
              if (oldData.parish || oldData.parish === "") {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Edited Parish of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                    ` From "${
                      oldparish.parish ? oldparish.parish : "Nil"
                    }" to "${newparish.parish ? newparish.parish : "Nil"}"`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.image1 ||
                (oldData.image1 === "" && oldData.image1 !== res.data.image1)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description: `Updated Image1 of Retreat ( Id : ${data.id} , Title : ${data.title} )`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.image2 ||
                (oldData.image2 === "" && oldData.image2 !== res.data.image2)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description: `Edited Image2 of Retreat ( Id : ${data.id} , Title : ${data.title} )`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.heading ||
                (oldData.heading === "" && oldData.heading !== res.data.heading)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Edited Theme of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                    ` From "${oldData.heading ? oldData.heading : "Nil"}" to "${
                      res.data.heading ? res.data.heading : "Nil"
                    }"`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (
                oldData.mission_statement ||
                (oldData.mission_statement === "" &&
                  oldData.mission_statement !== res.data.mission_statement)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Edited Mission Statement of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                    ` From "${
                      oldData.mission_statement
                        ? oldData.mission_statement
                        : "Nil"
                    }" to "${
                      res.data.mission_statement
                        ? res.data.mission_statement
                        : "Nil"
                    }"`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldData.description ||
                (oldData.description === "" &&
                  oldData.description !== res.data.description)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description:
                    `Edited Description of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                    ` From "${
                      oldData.description ? oldData.description : "Nil"
                    }" to "${
                      res.data.description ? res.data.description : "Nil"
                    }"`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              if (
                oldValues.mail_msg ||
                (oldValues.mail_msg === "" &&
                  oldValues.mail_msg !== res.data.mail_msg)
              ) {
                let report = {
                  byWhom:
                    `${
                      userData && userData.userName ? userData.userName : null
                    } ` +
                    ` (${
                      userData && userData.userRole ? userData.userRole : null
                    })`,
                  pagelink: window.location.href,
                  description: `Edited mail message of Retreat ( Id : ${data.id} , Title : ${data.title} )`,
                };
                adminApi
                  .addLogReport(report)
                  .then((response) => {
                    if (response.success) {
                      console.log("Created new log report.");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            } else {
              toast.error(res.msg);
              setApplyLoader(false);
            }
            setApplyLoader(false);
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        let data1 = new FormData();
        data1.append("file1", data.image1);
        data1.append("file2", data.image2);
        let stateRef = { ...data };
        stateRef.mission_statement = missionStatement;
        stateRef.description = description;
        stateRef.mail_msg = emailMsg;
        delete stateRef.image1;
        delete stateRef.image2;
        data1.append("fromData", JSON.stringify(stateRef));
        retreatApi
          .createRetreat(data1)
          .then((res) => {
            if (res.success) {
              toast.success(res.msg);
              clearfield();
              // setShowREgModal(false);
              // editRetreat(res.data.id);
              navigate(`/create-retreat/${res.data.id}`);
              const { schedule, ...rest } = res.data;
              const parsedSchedule = schedule ? JSON.parse(schedule) : [];
              const parsedData = {
                ...rest,
                schedule: parsedSchedule.map((entry) => ({
                  date: new Date(entry.date),
                  from: entry.from,
                  to: entry.to,
                })),
              };
              let myparish = perishDataList.find(
                (i) => i.id === parsedData.parishId
              );
              if (myparish) {
                parsedData.myparish = myparish;
              }
              setData(parsedData);
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Created New  Retreat ( Title : ${data.title} )`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              setApplyLoader(false);
            } else {
              setApplyLoader(false);
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            setApplyLoader(false);
            toast.error(error);
          });
      }
    } else {
      if (
        data &&
        data.schedule &&
        (!data.schedule[0] ||
          !data.schedule[0].from ||
          data.schedule[0].from === "00 : 00")
      ) {
        setFromErrorMsg(true);
        setValidated(true);
      }
      if (
        data &&
        data.schedule &&
        (!data.schedule[0] ||
          !data.schedule[0].to ||
          data.schedule[0].to === "00 : 00")
      ) {
        setToErrorMsg(true);
      }
    }
    setValidated(true);
  };

  const editSchedule = (item, i) => {
    setTimeDate(item);
    setEditIndex(i);
    setTimeout(() => {
      const element = document.getElementById("section-5");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 500);
  };

  const handleTimeChange = (name, value, mode, i) => {
    // if (mode === "editMode") {
    //   const newData = { ...data };
    //   const updatedSchedule = newData.schedule.map((item, j) => {
    //     if (j === editIndex) {
    //       return { ...item, [name]: value };
    //     }
    //     return item;
    //   });
    //   updatedSchedule.forEach((item) => {
    //     item.dayName = getDayName(item.date);
    //   });
    //   newData.schedule = updatedSchedule;
    //   setData(newData);
    // } else {
    if (name === "from") {
      setFromErrorMsg(false);
      if (timeDate.to === value) {
        setCheck("'Time From' and 'Time To' should not be same.");
      } else {
        setCheck("");
      }
    } else if (name === "to") {
      setToErrorMsg(false);
      if (timeDate.from === value) {
        setCheck("'Time From' and 'Time To' should not be same.");
      }
      // else if (timeDate.from > value) {
      //   setCheck("The 'Time From' should not be greater than the 'Time To'.");
      // }
      else {
        setCheck("");
      }
    } else if (name === "date") {
      const adjustedDate = moment(value)
        .tz("America/New_York", true) // Keep the same time, just change the timezone part
        .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
      value = adjustedDate;
    }
    setTimeDate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // }
  };

  const clearfield = () => {
    setValidated(false);
  };

  function getDayName(dateString, language) {
    const dateInUSTimezone = moment(dateString).tz("America/New_York");
    let days = [];
    if (language === "spanish") {
      days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
    } else {
      days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
    }
    return days[dateInUSTimezone.day()];
  }

  const handleTimeSubmit = () => {
    if (
      (check === "" || check === undefined) &&
      timeDate &&
      timeDate.date &&
      timeDate.date !== "" &&
      timeDate.from !== "00 : 00" &&
      timeDate.to !== "00 : 00"
    ) {
      setGreenTick(true);
      const formattedTimeDate = { ...timeDate };
      const newData = { ...data };
      if (editIndex !== null) {
        newData.schedule[editIndex] = formattedTimeDate;
        newData.schedule.sort((a, b) => new Date(a.date) - new Date(b.date));
        newData.schedule.forEach((item) => {
          item.dayName = getDayName(item.date, newData.language);
        });
        if (newData && newData.schedule && newData.schedule.length > 0) {
          newData.dateFrom = new Date(newData.schedule[0].date);
          newData.dateTo = new Date(
            newData.schedule[newData.schedule.length - 1].date
          );
        } else {
          newData.dateFrom = null;
          newData.dateTo = null;
        }
      } else {
        if (!newData.schedule) {
          newData.schedule = [];
        }
        newData.schedule.push(formattedTimeDate);
        newData.schedule.sort((a, b) => new Date(a.date) - new Date(b.date));
        newData.schedule.forEach((item) => {
          item.dayName = getDayName(item.date, newData.language);
        });
        if (newData && newData.schedule && newData.schedule.length > 0) {
          newData.dateFrom = new Date(newData.schedule[0].date);
          newData.dateTo = new Date(
            newData.schedule[newData.schedule.length - 1].date
          );
        } else {
          newData.dateFrom = null;
          newData.dateTo = null;
        }
      }
      if (
        (newData.parishId || newData.parish) &&
        newData.language &&
        newData.location
      ) {
        autoSaveFunction(newData);
      } else {
        setFromErrorMsg(true);
        setValidated(true);
      }
      // setData(newData);
      setEditIndex(null);
      setTimeout(() => {
        setGreenTick(false);
        setTimeDate({
          date: timeDate.date, //Need selected to be there
          from: "00 : 00",
          to: "00 : 00",
        });
        setPass(true);
      }, 2000);
    }
  };

  const autoSaveFunction = (newData, missionData) => {
    setIsTempUpload1(false);
    setIsTempUpload2(false);
    setApplyLoader(true);
    if (
      ediUserIdClicked &&
      ediUserIdClicked !== "" &&
      ediUserIdClicked !== null
    ) {
      let data1 = new FormData();
      if (newData.image1 !== "") {
        data1.append("file1", newData.image1);
      }
      if (newData.image2 !== "") {
        data1.append("file2", newData.image2);
      }
      let stateRef = { ...newData };
      stateRef.mission_statement =
        missionData !== "" ? missionData : missionStatement;
      stateRef.description = description;
      stateRef.mail_msg = emailMsg;
      // delete stateRef.imageUrl
      data1.append("fromData", JSON.stringify(stateRef));
      retreatApi
        .editRetreat(data1)
        .then((res) => {
          if (res.success) {
            toast.success(res.msg);
            clearfield();
            const { schedule, ...rest } = res.data;
            const parsedSchedule = schedule ? JSON.parse(schedule) : [];
            const parsedData = {
              ...rest,
              schedule: parsedSchedule.map((entry) => ({
                date: new Date(entry.date),
                from: entry.from,
                to: entry.to,
              })),
            };
            let myparish = perishDataList.find(
              (i) => i.id === parsedData.parishId
            );
            if (myparish) {
              parsedData.myparish = myparish;
            }
            parsedData.schedule.forEach((item) => {
              item.dayName = getDayName(item.date, parsedData.language);
            });
            setData(parsedData);
            if (oldData.title) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Edited Title of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                  ` From "${oldData.title ? oldData.title : "Nil"}" to "${
                    res.data.title ? res.data.title : "Nil"
                  }"`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.location ||
              (oldData.location === "" &&
                oldData.location !== res.data.location)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Edited Location of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                  ` From "${oldData.location ? oldData.location : "Nil"}" to "${
                    res.data.location ? res.data.location : "Nil"
                  }"`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.language ||
              (oldData.language === "" &&
                oldData.language !== res.data.language)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Edited Language of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                  ` From "${oldData.language ? oldData.language : "Nil"}" to "${
                    res.data.language ? res.data.language : "Nil"
                  }"`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            let oldparish = perishDataList.find((i) => i.id === oldData.parish);
            let newparish = perishDataList.find(
              (i) => i.id === res.data.parishId
            );
            if (oldData.parish || oldData.parish === "") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Edited Parish of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                  ` From "${oldparish.parish ? oldparish.parish : "Nil"}" to "${
                    newparish.parish ? newparish.parish : "Nil"
                  }"`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.image1 ||
              (oldData.image1 === "" && oldData.image1 !== res.data.image1)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Updated Image1 of Retreat ( Id : ${data.id} , Title : ${data.title} )`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.image2 ||
              (oldData.image2 === "" && oldData.image2 !== res.data.image2)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Edited Image2 of Retreat ( Id : ${data.id} , Title : ${data.title} )`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.heading ||
              (oldData.heading === "" && oldData.heading !== res.data.heading)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Edited Theme of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                  ` From "${oldData.heading ? oldData.heading : "Nil"}" to "${
                    res.data.heading ? res.data.heading : "Nil"
                  }"`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.mission_statement ||
              (oldData.mission_statement === "" &&
                oldData.mission_statement !== res.data.mission_statement)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Edited Mission Statement of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                  ` From "${
                    oldData.mission_statement
                      ? oldData.mission_statement
                      : "Nil"
                  }" to "${
                    res.data.mission_statement
                      ? res.data.mission_statement
                      : "Nil"
                  }"`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.description ||
              (oldData.description === "" &&
                oldData.description !== res.data.description)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Edited Description of Retreat ( Id : ${data.id} , Title : ${data.title} )` +
                  ` From "${
                    oldData.description ? oldData.description : "Nil"
                  }" to "${
                    res.data.description ? res.data.description : "Nil"
                  }"`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldValues.mail_msg ||
              (oldValues.mail_msg === "" &&
                oldValues.mail_msg !== res.data.mail_msg)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Edited mail message of Retreat ( Id : ${data.id} , Title : ${data.title} )`,
              };
              adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            setApplyLoader(false);
            toast.error(res.msg);
          }
          setApplyLoader(false);
        })
        .catch((error) => {
          setApplyLoader(false);
          toast.error(error);
        });
    } else {
      let data1 = new FormData();
      data1.append("file1", newData.image1);
      data1.append("file2", newData.image2);
      let stateRef = { ...newData };
      stateRef.mission_statement = missionStatement;
      stateRef.description = description;
      stateRef.mail_msg = emailMsg;
      delete stateRef.image1;
      delete stateRef.image2;
      data1.append("fromData", JSON.stringify(stateRef));
      retreatApi
        .createRetreat(data1)
        .then((res) => {
          if (res.success) {
            toast.success(res.msg);
            clearfield();
            // setShowREgModal(false);
            navigate(`/create-retreat/${res.data.id}`);
            const { schedule, ...rest } = res.data;
            const parsedSchedule = schedule ? JSON.parse(schedule) : [];
            const parsedData = {
              ...rest,
              schedule: parsedSchedule.map((entry) => ({
                date: new Date(entry.date),
                from: entry.from,
                to: entry.to,
              })),
            };
            setData(parsedData);
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: `Created New  Retreat ( Title : ${data.title} )`,
            };
            adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Added new log report");
                }
              })
              .catch((error) => {
                console.log(error);
              });
            setApplyLoader(false);
          } else {
            setApplyLoader(false);
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          setApplyLoader(false);
          toast.error(error);
        });
    }
  };

  const upload = (selectUpload) => {
    if (selectUpload === "ForFirst") {
      document.getElementById("retreatImageUpload1").click();
      setIsTempUpload1(true);
    } else if (selectUpload === "ForSecond") {
      document.getElementById("retreatImageUpload2").click();
      setIsTempUpload2(true);
    } else {
      document
        .getElementById(`${selectUpload}` + "-" + "CoupleProfileUpload")
        .click();
      setIsTempUpload(true);
    }
  };

  const remove = (removeSelect) => {
    let d = { ...data };
    if (removeSelect === "ForFirst") {
      d.image1 = "";
      setIsTempUpload1(false);
      if (
        (d.parishId || d.parish) &&
        d.language &&
        d.location &&
        d.schedule.length
      ) {
        autoSaveFunction(d);
      }
    } else {
      d.image2 = "";
      setIsTempUpload2(false);
      if (
        (d.parishId || d.parish) &&
        d.language &&
        d.location &&
        d.schedule.length
      ) {
        autoSaveFunction(d);
      }
    }
    setData(d);
  };

  const deleteSchedule = () => {
    let d = { ...data };
    if (d.schedule.length > 0) {
      d.schedule.splice(editIndex, 1);
    }
    if (
      (d.parishId || d.parish) &&
      d.language &&
      d.location &&
      d.schedule.length
    ) {
      autoSaveFunction(d);
    }
    setShow(false);
  };

  const editRetreat = async (id) => {
    setEdiUserIdClicked(id);
    let d = { ...data };
    await retreatApi
      .getRetreatDetail({ id: id })
      .then((res) => {
        if (res.success) {
          const { schedule, ...rest } = res.data[0];
          const parsedSchedule = schedule ? JSON.parse(schedule) : [];
          let parsedData = {
            ...rest,
            schedule: parsedSchedule.map((entry) => ({
              date: new Date(entry.date),
              from: entry.from,
              to: entry.to,
            })),
            parish: res.data[0].parishId,
          };
          let myparish = perishDataList.find(
            (i) => i.id === parsedData.parishId
          );
          if (myparish) {
            parsedData.myparish = myparish;
          }
          parsedData.schedule.forEach((item) => {
            item.dayName = getDayName(item.date, parsedData.language);
          });
          //Set Title data values
          let titleData = { ...newTitle };
          titleData.language = parsedData.language;
          titleData.location = parsedData.location;
          titleData.parish =
            parsedData.myparish && parsedData.myparish.parish
              ? parsedData.myparish.parish
              : "";
          setNewTitle(titleData); //
          i18n.changeLanguage(parsedData.language);
          setData(parsedData);
          setOldValues(parsedData);
          setEmailMsg(parsedData.mail_msg);
          setMissionStatement(parsedData.mission_statement);
          setDescription(parsedData.description);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleShow = (i) => {
    setEditIndex(i);
    setShow(true);
  };

  const showModal = (i) => {
    setEditIndex(i.coupleId);
    setShowDeleteModal(true);
  };

  const handlerRemoveShow = (i) => {
    setEditIndex(i.coupleId);
    setRemoveCoupleModal(true);
  };
  const handleRemoveClose = () => {
    setRemoveCoupleModal(false);
  };

  const handlerAccessRmvShow = (item) => {
    setEditIndex(item);
    setRemoveAccessModal(true);
  };

  const handleAccessRmvClose = () => {
    setRemoveAccessModal(false);
  };

  const removeCoupleFromRetreat = () => {
    retreatRoasterApi
      .removeCoupleFromRetreat({
        coupleId: editIndex,
        retreatId: ediUserIdClicked,
      })
      .then((res) => {
        toast.success(res.msg);
        getRolesOfRetreats(ediUserIdClicked);
        handleRemoveClose();
        setEditIndex("");
        setRemoveCoupleModal(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while uploading the image.");
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  const removeCoupleImage = () => {
    setIsTempUpload(false);
    adminApi
      .removeCoupleImage({ id: editIndex })
      .then((res) => {
        getRolesOfRetreats(ediUserIdClicked);
        closeModel();
        setEditIndex("");
        toast.success(res.msg);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while uploading the image.");
      });
  };

  const closeModel = () => {
    setShowDeleteModal(false);
  };

  const handleSelectOption = (val, selection) => {
    if (selection === "forRole") {
      setRoleSelect(val);
    } else if (selection === "coupleForRole") {
      setCoupleSelectForRole(val);
    } else if (selection === "coupleForGiveAccess") {
      setCoupleSelectForAccess(val);
    }
  };

  const handleSelectSubmit = async () => {
    if (ediUserIdClicked) {
      let submitData = {
        roleId: roleSelect.value,
        coupleId: coupleSelectForRole.value,
        retreatId: ediUserIdClicked,
      };
      setRoleGreenTick(true);
      await retreatRoasterApi
        .createLeads(submitData)
        .then((res) => {
          if (res.success) {
            getRolesOfRetreats(ediUserIdClicked);
            toast.success(res.msg);
            setRoleSelect([]);
            setCoupleSelectForRole([]);
            setTimeout(() => {
              setRoleGreenTick(false);
            }, 2000);
          } else {
            toast.error(res.msg);
            setRoleGreenTick(false);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      setRoleSelect([]);
      setCoupleSelectForRole([]);
      toast.error("Please enter the required fields.");
    }
  };

  const removeAccessFromRetreat = () => {
    retreatApi
      .removeAccessFromRetreat({
        remove_access_mail: editIndex,
        retreatId: ediUserIdClicked,
      })
      .then((res) => {
        toast.success(res.msg);
        editRetreat(id);
        handleRemoveClose();
        setEditIndex("");
        setRemoveAccessModal(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while uploading the image.");
      });
  };

  const handleAccessSubmit = async () => {
    coupleSelectForAccess.forEach((item) => {
      item.label = item.label.match(/^[^()\s]+/)[0]; // Extract email
    });
    if (ediUserIdClicked) {
      let submitData = {
        coupleIds: coupleSelectForAccess.map((option) => {
          return {
            label: option.label,
            value: option.value,
            isNew: option.__isNew__ || false,
          };
        }),
        retreatId: ediUserIdClicked,
      };
      setAccessGreenTick(true);
      await retreatApi
        .giveAccesssOfApprove(submitData)
        .then((res) => {
          if (res.success) {
            toast.success(res.msg);
            setCoupleSelectForAccess([]);
            editRetreat(id);
            getAllCouples();
            setTimeout(() => {
              setAccessGreenTick(false);
            }, 2000);
          } else {
            toast.error(res.msg);
            setAccessGreenTick(false);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      setCoupleSelectForAccess([]);
      toast.error("Please enter the required fields.");
    }
  };

  const handleImageChanges = (e, coupleDta) => {
    let { name, value } = e.target;
    if (coupleDta) {
      switch (coupleDta.role) {
        case "Lead Couple":
          setLeadCoupleLoader(true);
          break;
        case "Co-Lead Couple":
          setCoLeadCoupleLoader(true);
          break;
        case "Spiritual Director":
          setSpiritualDirectorLoader(true);
          break;
        case "Spiritual Mentor":
          setSpiritualMentorLoader(true);
          break;
        case "Mentor Couple":
          setMentorLoader(true);
          break;
        default:
          // Handle any other case if necessary
          break;
      }
    } else {
      // Handle the case when coupleDta is null or undefined
      setMentorLoader(true);
    }
    if (name === "imageUrl6" && e.target.files && e.target.files.length > 0) {
      let allData = new FormData();
      allData.append("file", e.target.files[0]);
      allData.append("coupleId", coupleDta.coupleId);
      adminApi
        .saveCoupleImage(allData)
        .then((res) => {
          getRolesOfRetreats(ediUserIdClicked);
          setLeadCoupleLoader(false);
          setCoLeadCoupleLoader(false);
          setSpiritualDirectorLoader(false);
          setSpiritualMentorLoader(false);
          setMentorLoader(false);
          toast.success("Couple image uploaded successfully.");
        })
        .catch((error) => {
          console.error(error);
          setLeadCoupleLoader(false);
          setCoLeadCoupleLoader(false);
          setSpiritualDirectorLoader(false);
          setSpiritualMentorLoader(false);
          setMentorLoader(false);
          toast.error("An error occurred while uploading the image.");
        });
    } else {
      setLeadCoupleLoader(false);
      setCoLeadCoupleLoader(false);
      setSpiritualDirectorLoader(false);
      setSpiritualMentorLoader(false);
      setMentorLoader(false);
      toast.error("Please select an image.");
    }
  };

  return (
    <>
      <Link to="/manageRetreat" className="back-link">
        &lt;Back
      </Link>
      <div className="row ext-space">
        <div className="col-lg-12 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col-12 grid-margin stretch-card">
              <div className="card custom-card">
                <div className="card-body ">
                  <Form
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    className="forms-sample"
                  >
                    <Container fluid className="create-retreat-header">
                      <Row>
                        <Col xs={12} sm={8} md={8} lg={8}>
                          {" "}
                          {ediUserIdClicked && ediUserIdClicked !== "" ? (
                            <h2 className="addRetHeading">Edit Retreat</h2>
                          ) : (
                            <h2 className="addRetHeading">Add New Retreat</h2>
                          )}
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4}>
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-sm submitRet"
                          >
                            {ediUserIdClicked && ediUserIdClicked !== ""
                              ? "Update"
                              : "Save"}
                          </Button>
                          {ediUserIdClicked ? (
                            <a
                              href={`/registration-retreat/${ediUserIdClicked}`}
                              className="font-size"
                              target="_blank"
                            >
                              <Button
                                variant="primary"
                                className="btn-sm submitRet"
                              >
                                Preview
                              </Button>
                            </a>
                          ) : null}
                        </Col>
                        <hr className="mb-0" />
                        <Col md={4} className="left-retreat-panel">
                          <div className="scrollable-content">
                            <Modal.Body>
                              <div className="row">
                                <Form.Group className="form-group col-12">
                                  <Form.Label>
                                    Select Parish
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    required
                                    size="sm"
                                    name="parish"
                                    id="parish"
                                    placeholder="Type"
                                    value={data && data.parish}
                                    onChange={(e) => handleChanges("", e)}
                                  >
                                    <option value="">Select</option>
                                    {perishDataList.map((item) => {
                                      return (
                                        <option
                                          key={`parish-${item.id}`}
                                          value={item.id}
                                        >
                                          {item.parish}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Parish is required!
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group col-12">
                                  <Form.Label>
                                    Language{" "}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    required
                                    size="sm"
                                    type="text"
                                    name="language"
                                    id="language"
                                    placeholder="Language"
                                    value={data.language}
                                    onChange={(e) => handleChanges("", e)}
                                  >
                                    <option value="">Select</option>
                                    <option value="english">English</option>
                                    <option value="spanish">Spanish</option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Language is required!
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group col-12">
                                  <Form.Label>
                                    Enter Location{" "}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    size="sm"
                                    type="text"
                                    name="location"
                                    id="location"
                                    placeholder="Location"
                                    value={data.location}
                                    onBlur={(e) => {
                                      handleSubmitOnBlur(e, "location");
                                    }}
                                    onChange={(e) => handleChanges("", e)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Location is required!
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group col-12">
                                  <Form.Label>Retreat Title</Form.Label>
                                  <Form.Control
                                    // required
                                    readOnly
                                    size="sm"
                                    type="text"
                                    name="title"
                                    id="title"
                                    placeholder="Retreat Title"
                                    value={data.title}
                                    className="form-control"
                                    onBlur={(e) => {
                                      handleSubmitOnBlur(e, "title");
                                    }}
                                    onChange={(e) => handleChanges("", e)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Retreat Title is required!
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <div className="col-12 text-center mt-3">
                                  {data.image1 == "" ||
                                  data.image1 == undefined ? (
                                    <>
                                      <input
                                        name="image1"
                                        className="pt-1"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="retreatImageUpload1"
                                        onChange={(e) => handleChanges("", e)}
                                      />
                                      <Button
                                        variant="primary"
                                        className="mb-1 me-3 text-center btn-sm upload-retreat-image"
                                        onClick={(e) => {
                                          upload("ForFirst");
                                        }}
                                      >
                                        Upload 1st image
                                      </Button>
                                      {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                                    </>
                                  ) : (
                                    <Button
                                      variant="danger"
                                      className="mb-1 mt-2 btn-sm upload-retreat-image"
                                      onClick={(e) => {
                                        remove("ForFirst");
                                      }}
                                    >
                                      Remove 1st image
                                    </Button>
                                  )}
                                </div>

                                <div className="col-12 text-center mt-3 mb-3">
                                  {data.image2 == "" ||
                                  data.image2 == undefined ? (
                                    <>
                                      <input
                                        name="image2"
                                        className="pt-1"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="retreatImageUpload2"
                                        onChange={(e) => handleChanges("", e)}
                                      />

                                      <Button
                                        variant="primary"
                                        className="mb-1 me-3 text-center btn-sm upload-retreat-image"
                                        onClick={(e) => {
                                          upload("ForSecond");
                                        }}
                                      >
                                        Upload 2nd Image
                                      </Button>
                                      {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                                    </>
                                  ) : (
                                    <Button
                                      variant="danger"
                                      className="mb-1 mt-2 btn-sm upload-retreat-image"
                                      onClick={(e) => {
                                        remove("ForSecond");
                                      }}
                                    >
                                      Remove 2nd image
                                    </Button>
                                  )}
                                </div>

                                <Form.Group className="form-group col-12">
                                  <Form.Label>Retreat Theme</Form.Label>
                                  <Form.Control
                                    // required
                                    size="sm"
                                    type="text"
                                    name="heading"
                                    id="heading"
                                    placeholder="Retreat Theme"
                                    value={data.heading}
                                    onBlur={(e) => {
                                      handleSubmitOnBlur(e, "heading");
                                    }}
                                    onChange={(e) => handleChanges("", e)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Retreat Theme is required!
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <div className="col-12">
                                  {data &&
                                  data.schedule &&
                                  data.schedule.length ? (
                                    <hr />
                                  ) : null}
                                  {data &&
                                    data.schedule &&
                                    data.schedule.map((item, i) => {
                                      return (
                                        <div
                                          key={`timeKey` + `${i}`}
                                          className="col-12 mt-2"
                                        >
                                          <div className="row">
                                            <Col
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              lg={12}
                                              className="mb-3"
                                            >
                                              <DatePicker
                                                readOnly
                                                autoComplete="off"
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="mm-dd-yyyy"
                                                className="form-select"
                                                selected={moment(
                                                  formattedDate(item.date)
                                                ).toDate()}
                                                name="date"
                                                onChange={(date) =>
                                                  handleTimeChange(
                                                    "date",
                                                    date,
                                                    "editMode",
                                                    i
                                                  )
                                                }
                                                // minDate={
                                                //   new Date(
                                                //     Date.now() + 86400000
                                                //   )
                                                // }
                                              />
                                            </Col>

                                            <Col
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              lg={12}
                                              className="mb-3 align-date-fields"
                                            >
                                              <div className="timeFields">
                                                {" "}
                                                <input
                                                  readOnly
                                                  type="text"
                                                  value={item && item.from}
                                                  className="form-control dateShowField inputDate"
                                                />
                                                <input
                                                  readOnly
                                                  type="text"
                                                  value={item && item.to}
                                                  className="form-control dateShowField inputDate1"
                                                />
                                              </div>

                                              <div className="timeIcons">
                                                {" "}
                                                <i
                                                  className="mdi mdi-pencil-box text-lg font-size deleteButton"
                                                  onClick={() =>
                                                    editSchedule(item, i)
                                                  }
                                                  title="Edit"
                                                ></i>
                                                <i
                                                  onClick={() => handleShow(i)}
                                                  className="mdi mdi-delete-circle text-md font-size deleteButton delete-icon8"
                                                  title="Delete"
                                                ></i>
                                              </div>
                                            </Col>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>

                                <div className="col-12">
                                  <hr />
                                  <div>
                                    <div className="row">
                                      <div className="col-12">
                                        <Form.Label>
                                          Date
                                          <span className="text-danger">*</span>
                                        </Form.Label>
                                        <DatePicker
                                          required={
                                            data.schedule &&
                                            data.schedule.length
                                              ? false
                                              : true
                                          }
                                          autoComplete="off"
                                          id="dateId"
                                          dateFormat="MM-dd-yyyy"
                                          placeholderText="mm-dd-yyyy"
                                          className="form-select"
                                          selected={
                                            timeDate.date && timeDate.date != ""
                                              ? moment(
                                                  formattedDate(timeDate.date)
                                                ).toDate()
                                              : moment(
                                                  formattedDate(timeDate.date)
                                                ).toDate()
                                          }
                                          name="date"
                                          onChange={(date) =>
                                            handleTimeChange(
                                              "date",
                                              date,
                                              `${
                                                editIndex !== null
                                                  ? "editMode"
                                                  : "createMode"
                                              }`
                                            )
                                          }
                                          // minDate={
                                          //   new Date(Date.now() + 86400000)
                                          // }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Atleast one date is required!
                                        </Form.Control.Feedback>
                                      </div>

                                      <Col
                                        md={12}
                                        sm={8}
                                        xs={8}
                                        lg={8}
                                        className="mt-1"
                                      >
                                        <Form.Label>From</Form.Label>
                                        <TimePicker
                                          chekPicker={Pass}
                                          useTwelveHourFormat={true}
                                          showClockIcon={false}
                                          showCloseIcon={true}
                                          allowBackdrop={true}
                                          onTimeChange={(time) =>
                                            handleTimeChange(
                                              "from",
                                              time,
                                              `${
                                                editIndex !== null
                                                  ? "editMode"
                                                  : "createMode"
                                              }`
                                            )
                                          }
                                          placeholder="00 : 00 am"
                                          defaulValue="00 : 00"
                                          value={timeDate && timeDate.from}
                                          className="timePicker"
                                        />
                                        {fromErrorMsg ? (
                                          <p className="text-danger mt-2">
                                            Time From is required!
                                          </p>
                                        ) : null}
                                        <Form.Label className="mt-2">
                                          To
                                        </Form.Label>
                                        <TimePicker
                                          chekPicker={Pass}
                                          useTwelveHourFormat={true}
                                          showClockIcon={false}
                                          showCloseIcon={true}
                                          allowBackdrop={true}
                                          onTimeChange={(time) =>
                                            handleTimeChange(
                                              "to",
                                              time,
                                              `${
                                                editIndex !== null
                                                  ? "editMode"
                                                  : "createMode"
                                              }`
                                            )
                                          }
                                          placeholder="00 : 00 am"
                                          defaulValue="00 : 00"
                                          value={timeDate && timeDate.to}
                                          className="timePicker indexVlues"
                                        />{" "}
                                        {toErrorMsg ? (
                                          <p className="text-danger mt-2">
                                            Time To is required!
                                          </p>
                                        ) : null}
                                      </Col>

                                      {greenTick ? (
                                        <Col md={12} lg={4} sm={4} xs={4}>
                                          <i
                                            type="button"
                                            className="mdi mdi-check btn-icon-prepend plus-icon-role greenTick"
                                          ></i>
                                        </Col>
                                      ) : (
                                        <Col
                                          md={12}
                                          sm={4}
                                          xs={4}
                                          lg={4}
                                          className="justify-content-center"
                                        >
                                          {editIndex !== null ? (
                                            <button
                                              className="font-size editDate"
                                              onClick={handleTimeSubmit}
                                            >
                                              <i
                                                className="mdi mdi-refresh text-lg font-size  plus-icon-role"
                                                title="Edit"
                                              ></i>
                                            </button>
                                          ) : (
                                            <i
                                              type="button"
                                              onClick={handleTimeSubmit}
                                              className="mdi mdi-plus-circle btn-icon-prepend  plus-icon-role addDate"
                                            ></i>
                                          )}
                                        </Col>
                                      )}

                                      {check && check !== "" ? (
                                        <h5 className="text-danger mt-2">
                                          {check}
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                  <hr className="p-0" />
                                </div>
                                <div id="section-5"> </div>
                                <Form.Group
                                  className="form-group col-12"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Mission Statement</Form.Label>
                                  <SunEditor
                                    id="outlined-adornment-quote"
                                    // autoFocus={false}
                                    type="text"
                                    onBlur={(e) => {
                                      if (
                                        missionStatement != e.target.innerHTML
                                      ) {
                                        handleSubmitOnBlur(
                                          e,
                                          "mission_statement"
                                        );
                                      }
                                    }}
                                    onChange={(e) => setMissionStatement(e)}
                                    setContents={missionStatement}
                                    name="mission_statement"
                                    inputProps={{}}
                                    setOptions={{
                                      buttonList: [
                                        [
                                          "undo",
                                          "redo",
                                          "font",
                                          "fontSize",
                                          "formatBlock",
                                        ],
                                        [
                                          "bold",
                                          "underline",
                                          "italic",
                                          "removeFormat",
                                        ],
                                        [
                                          "fontColor",
                                          "hiliteColor",
                                          "outdent",
                                          "indent",
                                          "align",
                                          "horizontalRule",
                                          "list",
                                        ],
                                        ["fullScreen", "link", "save"],
                                      ],
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Mission Statement is required!
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  className="form-group col-12"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Description</Form.Label>

                                  <SunEditor
                                    id="outlined-adornment-quote"
                                    type="text"
                                    onBlur={(e) => {
                                      handleSubmitOnBlur(e, "description");
                                    }}
                                    onChange={(e) => setDescription(e)}
                                    setContents={description}
                                    name="description"
                                    inputProps={{}}
                                    setOptions={{
                                      buttonList: [
                                        [
                                          "undo",
                                          "redo",
                                          "font",
                                          "fontSize",
                                          "formatBlock",
                                        ],
                                        [
                                          "bold",
                                          "underline",
                                          "italic",
                                          "removeFormat",
                                        ],
                                        [
                                          "fontColor",
                                          "hiliteColor",
                                          "outdent",
                                          "indent",
                                          "align",
                                          "horizontalRule",
                                          "list",
                                        ],
                                        ["fullScreen", "link", "save"],
                                      ],
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Description is required!
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                  className="form-group col-12"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Email Message</Form.Label>
                                  <SunEditor
                                    id="outlined-adornment-quote"
                                    type="text"
                                    onBlur={(e) => {
                                      handleSubmitOnBlur(e, "mail_msg");
                                    }}
                                    onChange={(e) => setEmailMsg(e)}
                                    setContents={emailMsg}
                                    name="mail_msg"
                                    inputProps={{}}
                                    setOptions={{
                                      buttonList: [
                                        [
                                          "undo",
                                          "redo",
                                          "font",
                                          "fontSize",
                                          "formatBlock",
                                        ],
                                        [
                                          "bold",
                                          "underline",
                                          "italic",
                                          "removeFormat",
                                        ],
                                        [
                                          "fontColor",
                                          "hiliteColor",
                                          "outdent",
                                          "indent",
                                          "align",
                                          "horizontalRule",
                                          "list",
                                        ],
                                        ["fullScreen", "link", "save"],
                                      ],
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Email message is required!
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <hr className="mb-0" />
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="col-12 text-center add-role-in-program"
                                >
                                  <h5>Retreat Team</h5>
                                  <Row className="">
                                    <Col
                                      md={8}
                                      lg={8}
                                      sm={8}
                                      xs={8}
                                      className="p-0"
                                    >
                                      <Select
                                        className="mt-3 actions-couple add-role-couple"
                                        id="couple"
                                        name="couple"
                                        options={allCoupleData}
                                        value={coupleSelectForRole}
                                        onChange={(val) => {
                                          handleSelectOption(
                                            val,
                                            "coupleForRole"
                                          );
                                        }}
                                        labelledBy="Select"
                                        isSearchable={true}
                                        isMulti={false}
                                        placeholder="Select Couple"
                                        menuPlacement="top"
                                      />
                                      <Select
                                        className="mt-2 actions-couple add-role-couple"
                                        id="roles"
                                        name="roles"
                                        options={rolesDataList}
                                        value={roleSelect}
                                        onChange={(val) => {
                                          handleSelectOption(val, "forRole");
                                        }}
                                        labelledBy="Roles"
                                        isSearchable={true}
                                        isMulti={false}
                                        placeholder="Select Role"
                                        menuPlacement="top"
                                      />
                                    </Col>
                                    {roleGreenTick ? (
                                      <Col
                                        md={4}
                                        lg={4}
                                        sm={4}
                                        xs={4}
                                        className="mb-2"
                                      >
                                        <div className="selct-role-Submit">
                                          <i
                                            type="button"
                                            className="mdi mdi-check btn-icon-prepend mt-0 mb-3 plus-icon-role greenTick"
                                          ></i>
                                        </div>
                                      </Col>
                                    ) : (
                                      <Col
                                        md={4}
                                        lg={4}
                                        sm={4}
                                        xs={4}
                                        className="mb-2"
                                      >
                                        <div className="selct-role-Submit">
                                          {" "}
                                          <i
                                            type="button"
                                            onClick={() => {
                                              handleSelectSubmit();
                                            }}
                                            className="mdi mdi-plus-circle btn-icon-prepend mt-0 mb-3 plus-icon-role"
                                          ></i>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                                <hr className="mb-0" />
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="col-12 text-center add-role-in-program"
                                >
                                  <h5>Retreat Sub-admins</h5>
                                  {data &&
                                    data.userEmails &&
                                    data.userEmails.map((item, index) => (
                                      <div key={index}>
                                        <p>{item}</p>
                                        <i
                                          className="mdi mdi-delete-forever text-md mx-2 font-size remove_couple"
                                          onClick={() =>
                                            handlerAccessRmvShow(item)
                                          }
                                          title="Remove Couple"
                                        ></i>
                                      </div>
                                    ))}

                                  <Row>
                                    <Col
                                      md={8}
                                      lg={8}
                                      sm={8}
                                      xs={8}
                                      className="p-0"
                                    >
                                      <CreatableSelect
                                        className="mt-3 actions-couple add-role-couple"
                                        id="couple"
                                        name="couple"
                                        options={allCoupleMails}
                                        value={coupleSelectForAccess}
                                        onChange={(val) => {
                                          handleSelectOption(
                                            val,
                                            "coupleForGiveAccess"
                                          );
                                        }}
                                        isSearchable={true}
                                        isMulti={true}
                                        placeholder="Select Couple"
                                        menuPlacement="top"
                                      />
                                    </Col>
                                    {accessGreenTick ? (
                                      <Col
                                        md={4}
                                        lg={4}
                                        sm={4}
                                        xs={4}
                                        className="mb-2"
                                      >
                                        <div className="selct-access-Submit">
                                          <i
                                            type="button"
                                            className="mdi mdi-check btn-icon-prepend mt-0 mb-3 plus-icon-role greenTick"
                                          ></i>
                                        </div>
                                      </Col>
                                    ) : (
                                      <Col
                                        md={4}
                                        lg={4}
                                        sm={4}
                                        xs={4}
                                        className="mb-2"
                                      >
                                        <div className="selct-access-Submit">
                                          <i
                                            type="button"
                                            onClick={() => handleAccessSubmit()}
                                            className="mdi mdi-plus-circle btn-icon-prepend mt-0 mb-3 plus-icon-role"
                                          ></i>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              </div>
                            </Modal.Body>
                          </div>
                        </Col>
                        <Col md={8} className="right-retreat-panel">
                          <div className="scrollable-content">
                            <div className="row w-100">
                              <div className="auth-form-light text-center main-register mt-3">
                                <Row className="justify-content-center">
                                  <Col
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="text-center mb-3"
                                  >
                                    <div className="retreat-Title-Regis">
                                      <h3>
                                        {data?.myparish?.parish}
                                        {data.language
                                          ? ` (${data.language[0].toUpperCase()}${data.language.slice(
                                              1
                                            )}) `
                                          : null}
                                      </h3>
                                      <h3>
                                        {data.location
                                          ? `${data.location[0].toUpperCase()}${data.location.slice(
                                              1
                                            )}`
                                          : ""}
                                      </h3>
                                      {data.dateFrom && data.dateTo ? (
                                        <p>
                                          (
                                          {moment(data.dateFrom).isSame(
                                            data.dateTo,
                                            "day"
                                          )
                                            ? ` ${moment(data.dateFrom)
                                                .tz("America/New_York")
                                                .format("MMM-DD-YYYY")}`
                                            : ` ${moment(data.dateFrom)
                                                .tz("America/New_York")
                                                .format("MMM DD")} - ${moment(
                                                data.dateTo
                                              )
                                                .tz("America/New_York")
                                                .format("MMM DD")}, ${moment(
                                                data.dateFrom
                                              )
                                                .tz("America/New_York")
                                                .format("YYYY")}`}
                                          )
                                        </p>
                                      ) : null}
                                    </div>
                                  </Col>

                                  {applyLoader ? (
                                    <Col
                                      lg={12}
                                      md={12}
                                      xs={12}
                                      className="text-center image-set-retreat"
                                    >
                                      <Loader></Loader>
                                    </Col>
                                  ) : data.image1 || data.image2 ? (
                                    <Col
                                      lg={12}
                                      md={12}
                                      xs={12}
                                      className="text-center image-set-retreat"
                                    >
                                      <Row className="justify-content-center images_aligns">
                                        {data.image1 == "" ||
                                        data.image1 == undefined ? (
                                          <div className="col-md-12 col-xs-12 col-sm-12 col-lg-6 edit-profile-img retreat_img mb-2 text-center">
                                            <img src={uploadRetreat} />
                                          </div>
                                        ) : (
                                          <div className="col-md-12 col-xs-12 col-sm-12 col-lg-6 edit-profile-img  retreat_img mb-2 text-center">
                                            {isTempUpload1 ? (
                                              <>
                                                <img
                                                  src={URL.createObjectURL(
                                                    data?.image1
                                                  )}
                                                />
                                              </>
                                            ) : (
                                              <img src={data?.image1} />
                                            )}
                                          </div>
                                        )}

                                        {data.image2 ? (
                                          <div className="col-md-12 col-xs-12 col-sm-12 col-lg-6 edit-profile-img retreat_img mb-2">
                                            {isTempUpload2 ? (
                                              <>
                                                <img
                                                  src={URL.createObjectURL(
                                                    data?.image2
                                                  )}
                                                />
                                              </>
                                            ) : (
                                              <img src={data?.image2} />
                                            )}
                                          </div>
                                        ) : null}
                                      </Row>
                                    </Col>
                                  ) : null}

                                  {data.heading ? (
                                    <Col
                                      lg={9}
                                      md={9}
                                      xs={9}
                                      className="text-center mt-2 headingDec"
                                    >
                                      <h2>
                                        <i> {data.heading}</i>
                                      </h2>
                                    </Col>
                                  ) : null}
                                  {missionStatement ? (
                                    <Col
                                      lg={9}
                                      md={9}
                                      xs={9}
                                      className={`${
                                        data.image2 || data.image1
                                          ? "text-center mt-2 mb-1 missionState"
                                          : "text-center mb-1 missionState"
                                      }`}
                                    >
                                      <h4 className="mt-4 mb-4">
                                        {t("mission_statement")}
                                      </h4>

                                      <p>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: missionStatement
                                              ? missionStatement
                                              : "",
                                          }}
                                        />
                                      </p>
                                    </Col>
                                  ) : null}
                                  {roles &&
                                  !roles.lead &&
                                  !roles.leadCouple &&
                                  !roles.coLead &&
                                  !roles.coLeadCouple &&
                                  !roles.spiritualDirector &&
                                  !roles.spiritualMentor &&
                                  !roles.mentor ? (
                                    ""
                                  ) : (
                                    <Col
                                      lg={12}
                                      md={12}
                                      xs={12}
                                      className="text-center mt-4 mb-5"
                                    >
                                      <Row className="justify-content-center">
                                        {/* {roles && roles.lead ? (
                                          <Col
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            className="retreatCoupleImg mt-2"
                                          >
                                            <Card className="cardOfLead">
                                              {leadLoader ? (
                                                <div
                                                  lg={12}
                                                  md={12}
                                                  xs={12}
                                                  className="text-center image-set-retreat"
                                                >
                                                  <Loader></Loader>
                                                </div>
                                              ) : (
                                                <Card.Img
                                                  variant="top"
                                                  src={
                                                    roles &&
                                                    roles.lead &&
                                                    roles.lead.imageUrl
                                                      ? roles.lead.imageUrl
                                                      : Profile
                                                  }
                                                />
                                              )}
                                              <Card.Body className="leadBody">
                                                <div className="text-center ">
                                                  {roles &&
                                                  roles.lead &&
                                                  (roles.lead.imageUrl == "" ||
                                                    roles.lead.imageUrl ==
                                                      undefined) ? (
                                                    <>
                                                      <input
                                                        name="imageUrl6"
                                                        className="pt-1"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        id={
                                                          `${
                                                            roles &&
                                                            roles.lead.coupleId
                                                          }` +
                                                          "-" +
                                                          "CoupleProfileUpload"
                                                        }
                                                        onChange={(e) =>
                                                          handleImageChanges(
                                                            e,
                                                            roles.lead
                                                          )
                                                        }
                                                      />
                                                      <i
                                                        onClick={(e) => {
                                                          upload(
                                                            roles &&
                                                              roles.lead
                                                                .coupleId
                                                          );
                                                        }}
                                                        className="mdi mdi-upload text-lg text-primary font-size retreatCouples"
                                                        title="Couple Image Upload"
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <i
                                                      className="mdi mdi-delete-circle text-md text-danger mx-2 font-size retreatCouples"
                                                      onClick={(e) => {
                                                        showModal(
                                                          roles && roles.lead
                                                        );
                                                      }}
                                                      title="Remove Couple Image"
                                                    ></i>
                                                  )}
                                                </div>

                                                <Card.Text className="rolesDesign">
                                                  {`${
                                                    roles &&
                                                    roles.lead &&
                                                    roles.lead.hisFirstName
                                                      ? roles.lead.hisFirstName
                                                      : ""
                                                  }` +
                                                    `${
                                                      roles &&
                                                      roles.lead &&
                                                      roles.lead.herFirstName
                                                        ? " & "
                                                        : ""
                                                    }` +
                                                    `${
                                                      roles &&
                                                      roles.lead &&
                                                      roles.lead.herFirstName
                                                        ? roles.lead
                                                            .herFirstName
                                                        : ""
                                                    }` +
                                                    ` ${
                                                      roles &&
                                                      roles.lead &&
                                                      roles.lead.hisLastName
                                                        ? roles.lead.hisLastName
                                                        : ""
                                                    }`}
                                                </Card.Text>
                                                <Card.Title>Lead</Card.Title>
                                              </Card.Body>
                                            </Card>
                                            <i
                                              className="mdi mdi-delete-forever text-md mx-2 font-size remove_couple"
                                              onClick={() =>
                                                handlerRemoveShow(
                                                  roles && roles.lead
                                                )
                                              }
                                              title="Remove Couple"
                                            ></i>
                                          </Col>
                                        ) : null} */}

                                        {roles && roles.leadCouple ? (
                                          <Col
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            className="retreatCoupleImg mt-2"
                                          >
                                            <Card className="cardOfLead">
                                              {leadCoupleLoader ? (
                                                <div
                                                  lg={12}
                                                  md={12}
                                                  xs={12}
                                                  className="text-center image-set-retreat"
                                                >
                                                  <Loader></Loader>
                                                </div>
                                              ) : (
                                                <Card.Img
                                                  variant="top"
                                                  src={
                                                    roles &&
                                                    roles.leadCouple &&
                                                    roles.leadCouple.imageUrl
                                                      ? roles.leadCouple
                                                          .imageUrl
                                                      : Profile
                                                  }
                                                />
                                              )}
                                              <Card.Footer className="leadBody">
                                                <div className="text-center ">
                                                  {roles &&
                                                  roles.leadCouple &&
                                                  (roles.leadCouple.imageUrl ==
                                                    "" ||
                                                    roles.leadCouple.imageUrl ==
                                                      undefined) ? (
                                                    <>
                                                      <input
                                                        name="imageUrl6"
                                                        className="pt-1"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        id={
                                                          `${
                                                            roles &&
                                                            roles.leadCouple
                                                              .coupleId
                                                          }` +
                                                          "-" +
                                                          "CoupleProfileUpload"
                                                        }
                                                        onChange={(e) =>
                                                          handleImageChanges(
                                                            e,
                                                            roles.leadCouple
                                                          )
                                                        }
                                                      />
                                                      <i
                                                        onClick={(e) => {
                                                          upload(
                                                            roles &&
                                                              roles.leadCouple
                                                                .coupleId
                                                          );
                                                        }}
                                                        className="mdi mdi-upload text-lg text-primary font-size retreatCouples"
                                                        title="Couple Image Upload"
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <i
                                                      className="mdi mdi-delete-circle text-md text-danger mx-2 font-size retreatCouples"
                                                      onClick={(e) => {
                                                        showModal(
                                                          roles &&
                                                            roles.leadCouple
                                                        );
                                                      }}
                                                      title="Remove Couple Image"
                                                    ></i>
                                                  )}
                                                </div>
                                                <Card.Text className="rolesDesign">
                                                  {`${
                                                    roles &&
                                                    roles.leadCouple &&
                                                    roles.leadCouple
                                                      .hisFirstName
                                                      ? roles.leadCouple
                                                          .hisFirstName
                                                      : ""
                                                  }` +
                                                    `${
                                                      roles &&
                                                      roles.leadCouple &&
                                                      roles.leadCouple
                                                        .herFirstName
                                                        ? " & "
                                                        : ""
                                                    }` +
                                                    `${
                                                      roles &&
                                                      roles.leadCouple &&
                                                      roles.leadCouple
                                                        .herFirstName
                                                        ? roles.leadCouple
                                                            .herFirstName
                                                        : ""
                                                    }` +
                                                    ` ${
                                                      roles &&
                                                      roles.leadCouple &&
                                                      roles.leadCouple
                                                        .hisLastName
                                                        ? roles.leadCouple
                                                            .hisLastName
                                                        : ""
                                                    }`}
                                                </Card.Text>
                                                <Card.Title>
                                                  {t("lead_couple")}
                                                </Card.Title>
                                              </Card.Footer>
                                            </Card>
                                            <i
                                              className="mdi mdi-delete-forever text-md mx-2 font-size remove_couple"
                                              onClick={() =>
                                                handlerRemoveShow(
                                                  roles && roles.leadCouple
                                                )
                                              }
                                              title="Remove Couple"
                                            ></i>
                                          </Col>
                                        ) : null}

                                        {/* {roles && roles.coLead ? (
                                          <Col
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            className="leadCoCouples retreatCoupleImg mt-2"
                                          >
                                            <Card className="cardOfLead">
                                              {coLeadLoader ? (
                                                <div
                                                  lg={12}
                                                  md={12}
                                                  xs={12}
                                                  className="text-center image-set-retreat"
                                                >
                                                  <Loader></Loader>
                                                </div>
                                              ) : (
                                                <Card.Img
                                                  variant="top"
                                                  src={
                                                    roles &&
                                                    roles.coLead &&
                                                    roles.coLead.imageUrl
                                                      ? roles.coLead.imageUrl
                                                      : Profile
                                                  }
                                                />
                                              )}
                                              <Card.Body className="leadBody">
                                                <div className="text-center ">
                                                  {roles &&
                                                  roles.coLead &&
                                                  (roles.coLead.imageUrl ==
                                                    "" ||
                                                    roles.coLead.imageUrl ==
                                                      undefined) ? (
                                                    <>
                                                      <input
                                                        name="imageUrl6"
                                                        className="pt-1"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        id={
                                                          `${
                                                            roles &&
                                                            roles.coLead
                                                              .coupleId
                                                          }` +
                                                          "-" +
                                                          "CoupleProfileUpload"
                                                        }
                                                        onChange={(e) =>
                                                          handleImageChanges(
                                                            e,
                                                            roles.coLead
                                                          )
                                                        }
                                                      />
                                                      <i
                                                        onClick={(e) => {
                                                          upload(
                                                            roles &&
                                                              roles.coLead
                                                                .coupleId
                                                          );
                                                        }}
                                                        className="mdi mdi-upload text-lg text-primary font-size retreatCouples"
                                                        title="Image Upload"
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <i
                                                      className="mdi mdi-delete-circle text-md text-danger mx-2 font-size retreatCouples"
                                                      onClick={(e) => {
                                                        showModal(
                                                          roles && roles.coLead
                                                        );
                                                      }}
                                                      title="Remove"
                                                    ></i>
                                                  )}
                                                </div>

                                                <Card.Text className="rolesDesign">
                                                  {`${
                                                    roles &&
                                                    roles.coLead &&
                                                    roles.coLead.hisFirstName
                                                      ? roles.coLead
                                                          .hisFirstName
                                                      : ""
                                                  }` +
                                                    `${
                                                      roles &&
                                                      roles.coLead &&
                                                      roles.coLead.herFirstName
                                                        ? " & "
                                                        : ""
                                                    }` +
                                                    `${
                                                      roles &&
                                                      roles.coLead &&
                                                      roles.coLead.herFirstName
                                                        ? roles.coLead
                                                            .herFirstName
                                                        : ""
                                                    }` +
                                                    " " +
                                                    `${
                                                      roles &&
                                                      roles.coLead &&
                                                      roles.coLead.hisLastName
                                                        ? roles.coLead
                                                            .hisLastName
                                                        : ""
                                                    }`}
                                                </Card.Text>
                                                <Card.Title>Co-Lead</Card.Title>
                                              </Card.Body>
                                            </Card>
                                            <i
                                              className="mdi mdi-delete-forever text-md mx-2 font-size remove_couple"
                                              onClick={() =>
                                                handlerRemoveShow(
                                                  roles && roles.coLead
                                                )
                                              }
                                              title="Remove Couple"
                                            ></i>
                                          </Col>
                                        ) : (
                                          ""
                                        )} */}

                                        {roles && roles.coLeadCouple ? (
                                          <Col
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            className="leadCoCouples retreatCoupleImg mt-2"
                                          >
                                            <Card className="cardOfLead">
                                              {coLeadCoupleLoader ? (
                                                <div
                                                  lg={12}
                                                  md={12}
                                                  xs={12}
                                                  className="text-center image-set-retreat"
                                                >
                                                  <Loader></Loader>
                                                </div>
                                              ) : (
                                                <Card.Img
                                                  variant="top"
                                                  src={
                                                    roles &&
                                                    roles.coLeadCouple &&
                                                    roles.coLeadCouple.imageUrl
                                                      ? roles.coLeadCouple
                                                          .imageUrl
                                                      : Profile
                                                  }
                                                />
                                              )}
                                              <Card.Footer className="leadBody">
                                                <div className="text-center ">
                                                  {roles &&
                                                  roles.coLeadCouple &&
                                                  (roles.coLeadCouple
                                                    .imageUrl == "" ||
                                                    roles.coLeadCouple
                                                      .imageUrl ==
                                                      undefined) ? (
                                                    <>
                                                      <input
                                                        name="imageUrl6"
                                                        className="pt-1"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        id={
                                                          `${
                                                            roles &&
                                                            roles.coLeadCouple
                                                              .coupleId
                                                          }` +
                                                          "-" +
                                                          "CoupleProfileUpload"
                                                        }
                                                        onChange={(e) =>
                                                          handleImageChanges(
                                                            e,
                                                            roles.coLeadCouple
                                                          )
                                                        }
                                                      />
                                                      <i
                                                        onClick={(e) => {
                                                          upload(
                                                            roles &&
                                                              roles.coLeadCouple
                                                                .coupleId
                                                          );
                                                        }}
                                                        className="mdi mdi-upload text-lg text-primary font-size retreatCouples"
                                                        title="Couple Image Upload"
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <i
                                                      className="mdi mdi-delete-circle text-md text-danger mx-2 font-size retreatCouples"
                                                      onClick={(e) => {
                                                        showModal(
                                                          roles &&
                                                            roles.coLeadCouple
                                                        );
                                                      }}
                                                      title="Remove Couple Image"
                                                    ></i>
                                                  )}
                                                </div>

                                                <Card.Text className="rolesDesign">
                                                  {`${
                                                    roles &&
                                                    roles.coLeadCouple &&
                                                    roles.coLeadCouple
                                                      .hisFirstName
                                                      ? roles.coLeadCouple
                                                          .hisFirstName
                                                      : ""
                                                  }` +
                                                    `${
                                                      roles &&
                                                      roles.coLeadCouple &&
                                                      roles.coLeadCouple
                                                        .herFirstName
                                                        ? " & "
                                                        : ""
                                                    }` +
                                                    `${
                                                      roles &&
                                                      roles.coLeadCouple &&
                                                      roles.coLeadCouple
                                                        .herFirstName
                                                        ? roles.coLeadCouple
                                                            .herFirstName
                                                        : ""
                                                    }` +
                                                    " " +
                                                    `${
                                                      roles &&
                                                      roles.coLeadCouple &&
                                                      roles.coLeadCouple
                                                        .hisLastName
                                                        ? roles.coLeadCouple
                                                            .hisLastName
                                                        : ""
                                                    }`}
                                                </Card.Text>
                                                <Card.Title>
                                                  {t("co_lead_couple")}
                                                </Card.Title>
                                              </Card.Footer>
                                            </Card>
                                            <i
                                              className="mdi mdi-delete-forever text-md mx-2 font-size remove_couple"
                                              onClick={() =>
                                                handlerRemoveShow(
                                                  roles && roles.coLeadCouple
                                                )
                                              }
                                              title="Remove Couple"
                                            ></i>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {roles && roles.spiritualDirector ? (
                                          <Col
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            className="retreatCoupleImg mt-2"
                                          >
                                            <Card className="cardOfLead">
                                              {spiritualDirectorLoader ? (
                                                <div
                                                  lg={12}
                                                  md={12}
                                                  xs={12}
                                                  className="text-center image-set-retreat"
                                                >
                                                  <Loader></Loader>
                                                </div>
                                              ) : (
                                                <Card.Img
                                                  variant="top"
                                                  src={
                                                    roles &&
                                                    roles.spiritualDirector &&
                                                    roles.spiritualDirector
                                                      .imageUrl
                                                      ? roles.spiritualDirector
                                                          .imageUrl
                                                      : Profile
                                                  }
                                                />
                                              )}
                                              <Card.Footer className="leadBody">
                                                <div className="text-center ">
                                                  {roles &&
                                                  roles.spiritualDirector &&
                                                  (roles.spiritualDirector
                                                    .imageUrl == "" ||
                                                    roles.spiritualDirector
                                                      .imageUrl ==
                                                      undefined) ? (
                                                    <>
                                                      <input
                                                        name="imageUrl6"
                                                        className="pt-1"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        id={
                                                          `${
                                                            roles &&
                                                            roles
                                                              .spiritualDirector
                                                              .coupleId
                                                          }` +
                                                          "-" +
                                                          "CoupleProfileUpload"
                                                        }
                                                        onChange={(e) =>
                                                          handleImageChanges(
                                                            e,
                                                            roles.spiritualDirector
                                                          )
                                                        }
                                                      />
                                                      <i
                                                        onClick={(e) => {
                                                          upload(
                                                            roles &&
                                                              roles
                                                                .spiritualDirector
                                                                .coupleId
                                                          );
                                                        }}
                                                        className="mdi mdi-upload text-lg text-primary font-size retreatCouples"
                                                        title="Couple Image Upload"
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <i
                                                      className="mdi mdi-delete-circle text-md text-danger mx-2 font-size retreatCouples"
                                                      onClick={(e) => {
                                                        showModal(
                                                          roles &&
                                                            roles.spiritualDirector
                                                        );
                                                      }}
                                                      title="Remove Couple Image"
                                                    ></i>
                                                  )}
                                                </div>

                                                <Card.Text className="rolesDesign">
                                                  {`${
                                                    roles &&
                                                    roles.spiritualDirector &&
                                                    roles.spiritualDirector
                                                      .hisFirstName
                                                      ? roles.spiritualDirector
                                                          .hisFirstName
                                                      : ""
                                                  }` +
                                                    `${
                                                      roles &&
                                                      roles.spiritualDirector &&
                                                      roles.spiritualDirector
                                                        .herFirstName
                                                        ? " & "
                                                        : ""
                                                    }` +
                                                    `${
                                                      roles &&
                                                      roles.spiritualDirector &&
                                                      roles.spiritualDirector
                                                        .herFirstName
                                                        ? roles
                                                            .spiritualDirector
                                                            .herFirstName
                                                        : ""
                                                    }` +
                                                    " " +
                                                    `${
                                                      roles &&
                                                      roles.spiritualDirector &&
                                                      roles.spiritualDirector
                                                        .hisLastName
                                                        ? roles
                                                            .spiritualDirector
                                                            .hisLastName
                                                        : ""
                                                    }`}
                                                </Card.Text>
                                                <Card.Title>
                                                  {t("spiritual_director")}
                                                </Card.Title>
                                              </Card.Footer>
                                            </Card>
                                            <i
                                              className="mdi mdi-delete-forever text-md mx-2 font-size remove_couple"
                                              onClick={() =>
                                                handlerRemoveShow(
                                                  roles &&
                                                    roles.spiritualDirector
                                                )
                                              }
                                              title="Remove Couple"
                                            ></i>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        {roles && roles.spiritualMentor ? (
                                          <Col
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            className="retreatCoupleImg mt-2"
                                          >
                                            <Card className="cardOfLead">
                                              {spiritualMentorLoader ? (
                                                <div
                                                  lg={12}
                                                  md={12}
                                                  xs={12}
                                                  className="text-center image-set-retreat"
                                                >
                                                  <Loader></Loader>
                                                </div>
                                              ) : (
                                                <Card.Img
                                                  variant="top"
                                                  src={
                                                    roles &&
                                                    roles.spiritualMentor &&
                                                    roles.spiritualMentor
                                                      .imageUrl
                                                      ? roles.spiritualMentor
                                                          .imageUrl
                                                      : Profile
                                                  }
                                                />
                                              )}
                                              <Card.Footer className="leadBody">
                                                <div className="text-center ">
                                                  {roles &&
                                                  roles.spiritualMentor &&
                                                  (roles.spiritualMentor
                                                    .imageUrl == "" ||
                                                    roles.spiritualMentor
                                                      .imageUrl ==
                                                      undefined) ? (
                                                    <>
                                                      <input
                                                        name="imageUrl6"
                                                        className="pt-1"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        id={
                                                          `${
                                                            roles &&
                                                            roles
                                                              .spiritualMentor
                                                              .coupleId
                                                          }` +
                                                          "-" +
                                                          "CoupleProfileUpload"
                                                        }
                                                        onChange={(e) =>
                                                          handleImageChanges(
                                                            e,
                                                            roles.spiritualMentor
                                                          )
                                                        }
                                                      />
                                                      <i
                                                        onClick={(e) => {
                                                          upload(
                                                            roles &&
                                                              roles
                                                                .spiritualMentor
                                                                .coupleId
                                                          );
                                                        }}
                                                        className="mdi mdi-upload text-lg text-primary font-size retreatCouples"
                                                        title="Couple Image Upload"
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <i
                                                      className="mdi mdi-delete-circle text-md text-danger mx-2 font-size retreatCouples"
                                                      onClick={(e) => {
                                                        showModal(
                                                          roles &&
                                                            roles.spiritualMentor
                                                        );
                                                      }}
                                                      title="Remove Couple Image"
                                                    ></i>
                                                  )}
                                                </div>

                                                <Card.Text className="rolesDesign">
                                                  {`${
                                                    roles &&
                                                    roles.spiritualMentor &&
                                                    roles.spiritualMentor
                                                      .hisFirstName
                                                      ? roles.spiritualMentor
                                                          .hisFirstName
                                                      : ""
                                                  }` +
                                                    `${
                                                      roles &&
                                                      roles.spiritualMentor &&
                                                      roles.spiritualMentor
                                                        .herFirstName
                                                        ? " & "
                                                        : ""
                                                    }` +
                                                    `${
                                                      roles &&
                                                      roles.spiritualMentor &&
                                                      roles.spiritualMentor
                                                        .herFirstName
                                                        ? roles.spiritualMentor
                                                            .herFirstName
                                                        : ""
                                                    }` +
                                                    " " +
                                                    `${
                                                      roles &&
                                                      roles.spiritualMentor &&
                                                      roles.spiritualMentor
                                                        .hisLastName
                                                        ? roles.spiritualMentor
                                                            .hisLastName
                                                        : ""
                                                    }`}
                                                </Card.Text>
                                                <Card.Title>
                                                  {t("spiritual_mentor")}
                                                </Card.Title>
                                              </Card.Footer>
                                            </Card>
                                            <i
                                              className="mdi mdi-delete-forever text-md mx-2 font-size remove_couple"
                                              onClick={() =>
                                                handlerRemoveShow(
                                                  roles && roles.spiritualMentor
                                                )
                                              }
                                              title="Remove Couple"
                                            ></i>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {roles && roles.mentor ? (
                                          <Col
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            className="retreatCoupleImg mt-2"
                                          >
                                            <Card className="cardOfLead">
                                              {mentorLoader ? (
                                                <div
                                                  lg={12}
                                                  md={12}
                                                  xs={12}
                                                  className="text-center image-set-retreat"
                                                >
                                                  <Loader></Loader>
                                                </div>
                                              ) : (
                                                <Card.Img
                                                  variant="top"
                                                  src={
                                                    roles &&
                                                    roles.mentor &&
                                                    roles.mentor.imageUrl
                                                      ? roles.mentor.imageUrl
                                                      : Profile
                                                  }
                                                />
                                              )}
                                              <Card.Footer className="leadBody">
                                                <div className="text-center ">
                                                  {roles &&
                                                  roles.mentor &&
                                                  (roles.mentor.imageUrl ==
                                                    "" ||
                                                    roles.mentor.imageUrl ==
                                                      undefined) ? (
                                                    <>
                                                      <input
                                                        name="imageUrl6"
                                                        className="pt-1"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        id={
                                                          `${
                                                            roles &&
                                                            roles.mentor
                                                              .coupleId
                                                          }` +
                                                          "-" +
                                                          "CoupleProfileUpload"
                                                        }
                                                        onChange={(e) =>
                                                          handleImageChanges(
                                                            e,
                                                            roles.mentor
                                                          )
                                                        }
                                                      />
                                                      <i
                                                        onClick={(e) => {
                                                          upload(
                                                            roles &&
                                                              roles.mentor
                                                                .coupleId
                                                          );
                                                        }}
                                                        className="mdi mdi-upload text-lg text-primary font-size retreatCouples"
                                                        title="Couple Image Upload"
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <i
                                                      className="mdi mdi-delete-circle text-md text-danger mx-2 font-size retreatCouples"
                                                      onClick={(e) => {
                                                        showModal(
                                                          roles && roles.mentor
                                                        );
                                                      }}
                                                      title="Remove Couple Image"
                                                    ></i>
                                                  )}
                                                </div>

                                                <Card.Text className="rolesDesign">
                                                  {`${
                                                    roles &&
                                                    roles.mentor &&
                                                    roles.mentor.hisFirstName
                                                      ? roles.mentor
                                                          .hisFirstName
                                                      : ""
                                                  }` +
                                                    `${
                                                      roles &&
                                                      roles.mentor &&
                                                      roles.mentor.herFirstName
                                                        ? " & "
                                                        : ""
                                                    }` +
                                                    `${
                                                      roles &&
                                                      roles.mentor &&
                                                      roles.mentor.herFirstName
                                                        ? roles.mentor
                                                            .herFirstName
                                                        : ""
                                                    }` +
                                                    " " +
                                                    `${
                                                      roles &&
                                                      roles.mentor &&
                                                      roles.mentor.hisLastName
                                                        ? roles.mentor
                                                            .hisLastName
                                                        : ""
                                                    }`}
                                                </Card.Text>
                                                <Card.Title>
                                                  {t("mentor_couple")}
                                                </Card.Title>
                                              </Card.Footer>
                                            </Card>
                                            <i
                                              className="mdi mdi-delete-forever text-md mx-2 font-size remove_couple"
                                              onClick={() =>
                                                handlerRemoveShow(
                                                  roles && roles.mentor
                                                )
                                              }
                                              title="Remove Couple"
                                            ></i>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                      </Row>
                                    </Col>
                                  )}

                                  {data && data.schedule.length ? (
                                    <Col
                                      lg={10}
                                      md={10}
                                      xs={10}
                                      className={`${
                                        description
                                          ? "text-center mt-3 mb-5"
                                          : "text-center mt-3 mb-2"
                                      }`}
                                    >
                                      <Table className="tableBorder" responsive>
                                        <thead className="table-headings">
                                          <tr>
                                            <th>{t("day")}</th>
                                            <th>{t("date")}</th>
                                            <th>{t("start")}</th>
                                            <th>{t("end")}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {data &&
                                            data.schedule &&
                                            data.schedule.map((item, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td>
                                                    {getDayName(
                                                      item.date,
                                                      data.language
                                                    )}
                                                  </td>
                                                  <td>
                                                    {Helpers.convertInWordTimming(
                                                      item.date,
                                                      "MM-DD-YYYY",
                                                      data.language ===
                                                        "spanish"
                                                        ? "es"
                                                        : "en"
                                                    )}
                                                  </td>
                                                  <td>{item.from}</td>
                                                  <td>{item.to}</td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  ) : null}
                                  {description ? (
                                    <Col
                                      lg={10}
                                      md={10}
                                      xs={10}
                                      className="text-center  descOfRetreat"
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: description
                                            ? description
                                            : "",
                                        }}
                                      />
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                              </div>
                            </div>

                            <Row className="w-100 mx-0 justify-content-center mt-5">
                              <Col
                                lg={12}
                                md={12}
                                xs={12}
                                className="text-center"
                              >
                                <h3 className="RegisTitle">
                                  {t("registration_form")}
                                </h3>
                                {/* <h4 className="retreatParish mt-3">
                                  {data && data.myparish && data.myparish.parish
                                    ? data.myparish.parish
                                    : ""}
                                </h4>
                                <h5 className="parishAddress mt-3">
                                  {data &&
                                  data.myparish &&
                                  data.myparish.address
                                    ? data.myparish.address
                                    : null}
                                </h5>
                                <h5 className="parishAddress mt-3">
                                  {`${
                                    data && data.myparish && data.myparish.city
                                      ? data.myparish.city
                                      : ""
                                  }` +
                                    `${
                                      data && data.state && data.myparish.state
                                        ? ", " + data.myparish.state
                                        : ""
                                    }` +
                                    `${
                                      data && data.zip && data.myparish.zip
                                        ? " " + data.myparish.zip
                                        : ""
                                    }`}
                                </h5> */}
                              </Col>
                            </Row>
                            <div className="row w-100 mx-0">
                              <div className="col-lg-10 mx-auto">
                                <div className="auth-form-light text-left view-register">
                                  {/* <h4 className="text-danger mb-5">
                                    Please enter at least one Name and Email for
                                    registration.
                                  </h4> */}

                                  <Form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    validated={validated}
                                    className="forms-sample formStyle"
                                  >
                                    <Row>
                                      <Col lg={12} md={12} xs={12}>
                                        <div>
                                          {" "}
                                          <label
                                            className="bold mt-2"
                                            htmlFor="exampleInputEmail1"
                                          >
                                            {t("retreat_team")}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Form.Group className="d-flex">
                                            <div>
                                              <Form.Check
                                                required
                                                disabled
                                                type={"radio"}
                                                id={`retreadRad`}
                                                onChange={(e) => {
                                                  handleChanges(e);
                                                }}
                                                value={"Retreatant"}
                                                label={`${t("retreatant")}`}
                                                name="attendeeType"
                                                className="space_lef1"
                                              />
                                            </div>
                                            <div>
                                              <Form.Check
                                                required
                                                disabled
                                                type={"radio"}
                                                id={`coupleRad`}
                                                onChange={(e) => {
                                                  handleChanges(e);
                                                }}
                                                value={`Team`}
                                                label={`${t("team")}`}
                                                className="space_lef1"
                                                name="attendeeType"
                                              />
                                            </div>
                                          </Form.Group>
                                        </div>
                                        <div>
                                          {" "}
                                          <label
                                            className="bold mt-2"
                                            htmlFor="exampleInputEmail1"
                                          >
                                            {" "}
                                            {t("check_year")}
                                          </label>
                                          <label className="form-check-label mt-2">
                                            <input
                                              type="checkbox"
                                              disabled
                                              className="form-check-input ageLabel"
                                              name="under_age_35"
                                              checked={
                                                data.under_age_35 === "YES"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {t("yes")}
                                            <i className="input-helper"></i>
                                          </label>
                                        </div>
                                      </Col>
                                      {/* <Col lg={6} md={6} xs={12}>
                                        <div className="edit-profile-img mb-2 retreat_img text-center">
                                          <img src={Profile} />
                                        </div>
                                        <div className="text-center mt-3">
                                          <Button
                                            disabled
                                            variant="primary"
                                            className="mb-1 me-3 text-center"
                                            onClick={(e) => {
                                              upload();
                                            }}
                                          >
                                            Upload Profile Image
                                          </Button>
                                        </div>
                                      </Col> */}
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                      <Col lg={6} md={6} xs={12}>
                                        <label className="badge badge-outline-primary rounded mb-3 mt-2 hisLabel">
                                          {t("his_info")}
                                        </label>
                                        <div id="his-info">
                                          <Col lg={12} md={12} xs={12}>
                                            <Form.Group className="form-group">
                                              <Form.Label className="his-info">
                                                {t("his_first_name")}
                                              </Form.Label>
                                              <Form.Control
                                                // required
                                                size="sm"
                                                type="text"
                                                name="hisFirstName"
                                                id="hisFirstName"
                                                placeholder={`${t(
                                                  "his_first_name"
                                                )}`}
                                                // value={data.hisFirstName}
                                                className="form-control his-info-border"
                                                disabled
                                                // onChange={(e) => handleChanges(e)}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                FirstName is required!
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={12} md={12} xs={12}>
                                            <Form.Group className="form-group">
                                              <Form.Label>
                                                {t("his_last_name")}
                                              </Form.Label>
                                              <Form.Control
                                                // required
                                                size="sm"
                                                type="text"
                                                name="hisLastName"
                                                id="hisLastName"
                                                placeholder={`${t(
                                                  "his_last_name"
                                                )}`}
                                                disabled
                                                // value={data.hisLastName}
                                                className="form-control"
                                                // onChange={(e) => handleChanges(e)}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                LastName is required!
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={12} md={12} xs={12}>
                                            <Form.Group className="form-group col">
                                              <Form.Label>
                                                {t("his_email")}
                                              </Form.Label>
                                              <Form.Control
                                                // required
                                                disabled
                                                size="sm"
                                                type="text"
                                                name="hisEmail"
                                                id="hisEmail"
                                                placeholder={`${t(
                                                  "his_email"
                                                )}`}
                                                // value={data.hisEmail}
                                                // onChange={(e) => handleChanges(e)}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Email is required!
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={12} md={12} xs={12}>
                                            <Form.Group className="form-group col">
                                              <Form.Label>
                                                {t("his_mobile")}
                                              </Form.Label>
                                              <Form.Control
                                                // required
                                                disabled
                                                size="sm"
                                                type="text"
                                                name="hisMobile"
                                                id="hisMobile"
                                                placeholder={`${t(
                                                  "his_mobile"
                                                )}`}
                                                // value={data.hisMobile}
                                                // onChange={(e) => handleChanges(e)}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Mobile is required!
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6} xs={12}>
                                        <label className="badge badge-outline-primary rounded mb-3 mt-2 herLabel">
                                          {t("her_info")}
                                        </label>
                                        <div id="her-info">
                                          <Col lg={12} md={12} xs={12}>
                                            <Form.Group className="form-group">
                                              <Form.Label className="her-info">
                                                {t("her_first_name")}
                                              </Form.Label>
                                              <Form.Control
                                                // required
                                                disabled
                                                size="sm"
                                                type="text"
                                                name="herFirstName"
                                                id="herFirstName"
                                                placeholder={`${t(
                                                  "her_first_name"
                                                )}`}
                                                // value={data.herFirstName}
                                                // onChange={(e) => handleChanges(e)}
                                                className="her-info-border"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                FirstName is required!
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={12} md={12} xs={12}>
                                            <Form.Group className="form-group">
                                              <Form.Label>
                                                {t("her_last_name")}
                                              </Form.Label>
                                              <Form.Control
                                                // required
                                                disabled
                                                size="sm"
                                                type="text"
                                                name="herLastName"
                                                id="herLastName"
                                                placeholder={`${t(
                                                  "her_last_name"
                                                )}`}
                                                // value={data.herLastName}
                                                // onChange={(e) => handleChanges(e)}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                LastName is required!
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={12} md={12} xs={12}>
                                            <Form.Group className="form-group">
                                              <Form.Label>
                                                {t("her_email")}
                                              </Form.Label>
                                              <Form.Control
                                                // required
                                                disabled
                                                size="sm"
                                                type="text"
                                                name="herEmail"
                                                id="herEmail"
                                                placeholder={`${t(
                                                  "her_email"
                                                )}`}
                                                // value={data.herEmail}
                                                // onChange={(e) => handleChanges(e)}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Email is required!
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={12} md={12} xs={12}>
                                            <Form.Group className="form-group">
                                              <Form.Label>
                                                {t("her_mobile")}
                                              </Form.Label>
                                              <Form.Control
                                                // required
                                                disabled
                                                size="sm"
                                                type="text"
                                                name="herMobile"
                                                id="herMobile"
                                                placeholder={`${t(
                                                  "her_mobile"
                                                )}`}
                                                // value={data.herMobile}
                                                // onChange={(e) => handleChanges(e)}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                Mobile is required!
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                        </div>
                                      </Col>
                                    </Row>
                                    <hr></hr>
                                    <div className="row locationFields">
                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <Form.Label>{t("address")}</Form.Label>
                                        <Form.Control
                                          // required
                                          disabled
                                          size="sm"
                                          type="text"
                                          name="address"
                                          id="address"
                                          placeholder={`${t("address")}`}
                                          //   value={data.address}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Address is required!
                                        </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <Form.Label>{t("state")}</Form.Label>
                                        <Form.Select
                                          className="minimal locationDropdown"
                                          size="sm"
                                          type="text"
                                          name="state"
                                          id="state"
                                          placeholder="State"
                                          disabled
                                          //   value={data.state}
                                          //   onChange={(e) => handleChanges(e)}
                                        >
                                          <option value="">
                                            {t("select_state")}
                                          </option>
                                          <option value="Alaska">Alaska</option>
                                          <option value="Alabama">
                                            Alabama
                                          </option>
                                          <option value="Arkansas">
                                            Arkansas
                                          </option>
                                          <option value="Arizona">
                                            Arizona
                                          </option>
                                          <option value="California">
                                            California
                                          </option>
                                          <option value="Colorado">
                                            Colorado
                                          </option>
                                          <option value="Connecticut">
                                            Connecticut
                                          </option>
                                          <option value="District of Columbia">
                                            District of Columbia
                                          </option>
                                          <option value="Delaware">
                                            Delaware
                                          </option>
                                          <option value="Florida">
                                            Florida
                                          </option>
                                          <option value="Georgia">
                                            Georgia
                                          </option>
                                          <option value="Hawaii">Hawaii</option>
                                          <option value="Iowa">Iowa</option>
                                          <option value="Idaho">Idaho</option>
                                          <option value="Illinois">
                                            Illinois
                                          </option>
                                          <option value="Indiana">
                                            Indiana
                                          </option>
                                          <option value="Kansas">Kansas</option>
                                          <option value="Kentucky">
                                            Kentucky
                                          </option>
                                          <option value="Louisiana">
                                            Louisiana
                                          </option>
                                          <option value="Massachusetts">
                                            Massachusetts
                                          </option>
                                          <option value="Maryland">
                                            Maryland
                                          </option>
                                          <option value="Maine">Maine</option>
                                          <option value="Michigan">
                                            Michigan
                                          </option>
                                          <option value="Minnesota">
                                            Minnesota
                                          </option>
                                          <option value="Missouri">
                                            Missouri
                                          </option>
                                          <option value="Mississippi">
                                            Mississippi
                                          </option>
                                          <option value="Montana">
                                            Montana
                                          </option>
                                          <option value="North Carolina<">
                                            North Carolina
                                          </option>
                                          <option value="North Dakota">
                                            North Dakota
                                          </option>
                                          <option value="Nebraska">
                                            Nebraska
                                          </option>
                                          <option value="New Hampshire">
                                            New Hampshire
                                          </option>
                                          <option value="New Jersey">
                                            New Jersey
                                          </option>
                                          <option value="New Mexico">
                                            New Mexico
                                          </option>
                                          <option value="Nevada">Nevada</option>
                                          <option value="New York">
                                            New York
                                          </option>
                                          <option value="Ohio">Ohio</option>
                                          <option value="Oklahoma">
                                            Oklahoma
                                          </option>
                                          <option value="Oregon">Oregon</option>
                                          <option value="Pennsylvania">
                                            Pennsylvania
                                          </option>
                                          <option value="Puerto Rico">
                                            Puerto Rico
                                          </option>
                                          <option value="Rhode Island">
                                            Rhode Island
                                          </option>
                                          <option value="South Carolina">
                                            South Carolina
                                          </option>
                                          <option value="South Dakota">
                                            South Dakota
                                          </option>
                                          <option value="Tennessee">
                                            Tennessee
                                          </option>
                                          <option value="Texas">Texas</option>
                                          <option value="Utah">Utah</option>
                                          <option value="Virginia">
                                            Virginia
                                          </option>
                                          <option value="Vermont">
                                            Vermont
                                          </option>
                                          <option value="Washington">
                                            Washington
                                          </option>
                                          <option value="Wisconsin">
                                            Wisconsin
                                          </option>
                                          <option value="West Virginia">
                                            West Virginia
                                          </option>
                                          <option value="Wyoming">
                                            Wyoming
                                          </option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                          State is required!
                                        </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <Form.Label>{t("city")}</Form.Label>
                                        <Form.Control
                                          // required
                                          disabled
                                          size="sm"
                                          type="text"
                                          name="city"
                                          id="city"
                                          placeholder={`${t("city")}`}
                                          //   value={data.city}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          City is required!
                                        </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <Form.Label>{t("zip")}</Form.Label>
                                        <Form.Control
                                          disabled
                                          size="sm"
                                          type="text"
                                          name="zip"
                                          id="zip"
                                          placeholder={`${t("zip")}`}
                                          value={data.zip}
                                          onChange={(e) => handleChanges(e)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Zip is required!
                                        </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <Form.Label>{t("parish")}</Form.Label>
                                        <Select
                                          disabled
                                          name="parish"
                                          labelledBy="Select"
                                          placeholder={`${t("select_parish")}`}
                                          isSearchable={true}
                                          isMulti={false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Parsih is required!
                                        </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <Form.Label>
                                          {t("anniversary_date")}
                                        </Form.Label>

                                        <DatePicker
                                          // required

                                          autoComplete="off"
                                          dateFormat="MM-dd-yyyy"
                                          disabled
                                          placeholderText="mm-dd-yyyy"
                                          className="form-select setdate datepicker"
                                          name="anniversary_date"
                                          id="anniversary_date"
                                          maxDate={new Date()}
                                          showDisabledMonthNavigation
                                        />
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <Form.Label>{t("notes")}</Form.Label>
                                        <Form.Control
                                          // required
                                          size="sm"
                                          type="text"
                                          name="notes"
                                          id="notes"
                                          placeholder={`${t("notes")}`}
                                          disabled
                                          //   value={data.notes}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Notes is required!
                                        </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <Form.Label>
                                          {t("dietary_allergies")}
                                        </Form.Label>
                                        <Form.Control
                                          // required
                                          size="sm"
                                          type="text"
                                          name="allergies"
                                          id="allergies"
                                          disabled
                                          //   value={data.allergies}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                      </Form.Group>
                                    </div>

                                    <div className="row mt-3 locationFields">
                                      <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <h4>{t("one_emergency_contact")}</h4>
                                      </div>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                                        <Form.Label>{t("name")}</Form.Label>

                                        <Form.Control
                                          // required
                                          disabled
                                          size="sm"
                                          type="text"
                                          name="emergency_name1"
                                          id="emergency_name1"
                                          placeholder={`${t("name")}`}
                                          //   value={data.emergency_name1}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <Form.Label>
                                          {t("relationship")}
                                        </Form.Label>

                                        <Form.Control
                                          // required
                                          disabled
                                          size="sm"
                                          type="text"
                                          name="emergency_relationship1"
                                          id="emergency_relationship1"
                                          placeholder={`${t("relationship")}`}
                                          //   value={data.emergency_relationship1}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <Form.Label>{t("phone")}</Form.Label>

                                        <Form.Control
                                          // required
                                          size="sm"
                                          type="text"
                                          disabled
                                          name="emergency_phone1"
                                          id="emergency_phone1"
                                          placeholder={`${t("phone")}`}
                                          //   value={data.emergency_phone1}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                                        <Form.Label>{t("name")}</Form.Label>

                                        <Form.Control
                                          // required
                                          size="sm"
                                          type="text"
                                          disabled
                                          name="emergency_name2"
                                          id="emergency_name2"
                                          placeholder={`${t("name")}`}
                                          //   value={data.emergency_name2}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <Form.Label>
                                          {t("relationship")}
                                        </Form.Label>
                                        <Form.Control
                                          // required
                                          size="sm"
                                          disabled
                                          type="text"
                                          name="emergency_relationship2"
                                          id="emergency_relationship2"
                                          placeholder={`${t("relationship")}`}
                                          //   value={data.emergency_relationship2}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Relationship is required!
                                        </Form.Control.Feedback>
                                      </Form.Group>

                                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                        <Form.Label>{t("phone")}</Form.Label>

                                        <Form.Control
                                          // required
                                          disabled
                                          size="sm"
                                          type="text"
                                          name="emergency_phone2"
                                          id="emergency_phone2"
                                          placeholder={`${t("phone")}`}
                                          //   value={data.emergency_phone2}
                                          //   onChange={(e) => handleChanges(e)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Phone is required!
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>

                                    <div className="row mt-3 locationFields">
                                      <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <h4> {t("hear_us")}</h4>
                                      </div>
                                      <div className="d-flex flex-wrap justify-content-between hear-options">
                                        <label className="form-check-label ml-sm-2">
                                          <input
                                            type="checkbox"
                                            className="form-check-input ageLabel"
                                            name="Website"
                                            value="Website"
                                            disabled
                                          />
                                          {t("website")}
                                          <i className="input-helper"></i>
                                        </label>

                                        <label className="form-check-label ml-sm-2">
                                          <input
                                            type="checkbox"
                                            className="form-check-input ageLabel"
                                            name="Church Bulletin"
                                            value="Church Bulletin"
                                            disabled
                                          />
                                          {t("church_bulletin")}
                                          <i className="input-helper"></i>
                                        </label>

                                        <label className="form-check-label ml-sm-2">
                                          <input
                                            type="checkbox"
                                            className="form-check-input ageLabel"
                                            name="Facebook"
                                            value="Facebook"
                                            disabled
                                          />
                                          {t("facebook")}
                                          <i className="input-helper"></i>
                                        </label>

                                        <label className="form-check-label ml-sm-2">
                                          <input
                                            type="checkbox"
                                            className="form-check-input ageLabel"
                                            name="School Marquee"
                                            value="School Marquee"
                                            disabled
                                          />
                                          {t("school_marquee")}
                                          <i className="input-helper"></i>
                                        </label>

                                        <label className="form-check-label ml-sm-2">
                                          <input
                                            type="checkbox"
                                            className="form-check-input ageLabel"
                                            name="Pulpit Talk/Table in Church"
                                            value="Pulpit Talk/Table in Church"
                                            disabled
                                          />
                                          {t("Pulpit_church")}
                                          <i className="input-helper"></i>
                                        </label>
                                      </div>
                                    </div>

                                    <Col
                                      lg={12}
                                      md={12}
                                      xs={12}
                                      sm={12}
                                      className=" mt-5 locationFields"
                                    >
                                      <h4>
                                        {" "}
                                        <h4> {t("referral")}</h4>
                                      </h4>
                                      <Form.Group className="form-group mt-3">
                                        <Form.Control
                                          // required
                                          size="sm"
                                          type="text"
                                          disabled
                                          name="referral_from_a_friend_or_family_member"
                                          placeholder={`${t("referral")}`}
                                          id="referral_from_a_friend_or_family_member"
                                          //   value={data.referral_from_a_friend_or_family_member}
                                          className="form-control"
                                          //   onChange={(e) => {
                                          //     handleChanges(e);
                                          //   }}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col
                                      lg={12}
                                      md={12}
                                      xs={12}
                                      className="text-center mb-5 mt-5"
                                    >
                                      <Button
                                        variant="primary"
                                        type="submit"
                                        disabled
                                      >
                                        {t("register")}
                                      </Button>
                                    </Col>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {show ? (
          <DeleteModel
            show={show}
            handleClose={handleClose}
            DeleteData={deleteSchedule}
          ></DeleteModel>
        ) : null}
        {showDeleteModal ? (
          <DeleteModel
            show={showDeleteModal}
            handleClose={closeModel}
            DeleteData={removeCoupleImage}
          ></DeleteModel>
        ) : null}

        {removeCoupleModal ? (
          <DeleteModel
            show={removeCoupleModal}
            handleClose={handleRemoveClose}
            DeleteData={removeCoupleFromRetreat}
          ></DeleteModel>
        ) : null}

        {removeAccessModal ? (
          <DeleteModel
            show={removeAccessModal}
            handleClose={handleAccessRmvClose}
            DeleteData={removeAccessFromRetreat}
          ></DeleteModel>
        ) : null}
      </div>
    </>
  );
};
