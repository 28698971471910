import BaseApi from "./BaseApi";
class TalksApi extends BaseApi {
  async addTalk(data) {
    return await this.API(
      {
        url: "/talks/addtalk",
        data: data,
        method: "post"
      }
    )
  }
  async getAllTalkList(data) {
    return await this.API(
        {
            url:"/talks/getalltalklist",
            method: "get"
        }
    )
}

async getAllTalkSearch(data) {
  return await this.API(
      {
          url:"/talks/getAllTalkSearch",
          method: "get"
      }
  )
}

async getTalk(data , talk_id) {
  return await this.API(
      {
          url:`/talks/gettalk/${talk_id}`,
          method: "get"
      }
  )
}
async deleteTalk(data,talk_id) {
  return await this.API({
    url: `/talks/deletetalk/${talk_id}`,
    data: data,
    method: 'delete'
  })
}
async searchTalk (data,key) {
  return await this.API(
      {
          url:`/talks/searchtalk/${key}`,
          method: "get"
      }
  )
}
async updateTalk(data , talk_id) {
  return await this.API({
    url: `/talks/updatetalk/${talk_id}`,
    data: data,
    method: 'put'
  })
}

}

export default TalksApi;