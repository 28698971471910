import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "./assets/css/style1.css";
import "react-toastify/dist/ReactToastify.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/vendors/feather/feather.css";
import "./assets/vendors/mdi/css/materialdesignicons.min.css";
import "./assets/vendors/ti-icons/css/themify-icons.css";
import "./assets/vendors/typicons/typicons.css";
import "./assets/vendors/simple-line-icons/css/simple-line-icons.css";
import "./assets/vendors/css/vendor.bundle.base.css";
// import "./assets/vendors/datatables.net-bs4/dataTables.bootstrap4.css";
import "./assets/js/select.dataTables.min.css";
import "./assets/css/vertical-layout-light/style.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
const Index = () => {
  return (
    <Router>
      <CookiesProvider>
        <App />
        <ToastContainer position="top-right" autoClose={2000} />
      </CookiesProvider>
    </Router>
  );
};
root.render(
  // <React.StrictMode>
    <Index />
  // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
