import axios from "axios";
import { Cookies } from 'react-cookie'
const API_URL = process.env.REACT_APP_API_URL;

class BaseApi {
	API = async (options) => {
		let cookies = new Cookies()
		let token = cookies.get('LoveStrongToken') || null
		let authHeader = ''
		if (token) {
			authHeader = `Bearer ${token}`
		}
		const client = axios.create({
			baseURL: API_URL,
			headers: { authorization: authHeader },
		})

		return client(options)
		.then((response) => {
				return response.data;
		})
		.catch((error) => console.log("Network Error", error));
	}

  async uploadFile(data) {
    return await this.API({ 
			url: "/upload", 
			data, 
			method: "post" 
		});
  }


}

export default BaseApi