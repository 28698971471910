import React from 'react'
const Help = () => {
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="row flex-grow">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card card-rounded">
                            <div className="card-body">
                                <div className="d-sm-flex justify-content-between align-items-start">
                                    <div>
                                        <h4 className="card-title card-title-dash">Help Content Awaited.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Help;
