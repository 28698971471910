import React from "react";
import companyLogo from "../../assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const ThankYou = () => {
  const location1 = useLocation();
  return (
    <div className="container-scroller mainpannel">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-10 mx-auto">
              <div className="brand-logo ">
                <Link to="/dashboard">
                  {" "}
                  <img src={companyLogo} alt="logo" className="logo center" />
                </Link>
              </div>
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="text-center">
                  {location1 && location1.state === "Profile" ? (
                    <>
                      {" "}
                      <h3>Thank You!</h3>
                      <p>Thank you for updating the profile information.</p>
                    </>
                  ) : location1 &&
                    location1.state &&
                    location1.state.value === "New Register Couple" ? (
                    <>
                      {location1 &&
                      location1.state &&
                      (location1.state.data.mail_msg === "" ||
                        location1.state.data.mail_msg === null) ? (
                        <>
                          {" "}
                          <h3>Thank you for Retreat Registration!</h3>
                          <p>
                            Please check your email and click the link to verify
                            your email id.
                          </p>
                        </>
                      ) : (
                        <div
                          className="mb-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              location1 &&
                              location1.state &&
                              location1.state.data.mail_msg,
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {location1 &&
                      location1.state &&
                      (location1.state.data.mail_msg === "" ||
                        location1.state.data.mail_msg === null) ? (
                        <>
                          {" "}
                          <h3>Thank you for Retreat Registration!</h3>
                          <h5 className="mt-2">
                            Please wait for confirmation email from the Admin.
                          </h5>
                          <br />
                          <h4>
                            You can login your account with below registered
                            Email ids:
                          </h4>
                          <h5 className="mt-3">
                            {`${
                              location1 &&
                              location1.state &&
                              location1.state.hisEmail
                            }` +
                              `${
                                location1 &&
                                location1.state &&
                                location1.state.herEmail &&
                                location1.state.hisEmail
                                  ? " Or "
                                  : ""
                              }`}

                            {location1 &&
                              location1.state &&
                              location1.state.herEmail}
                          </h5>
                          <h4 className="d-flex justify-content-center mt-3">
                            <Link className="loginLinkInThanku" to="/login">
                              Login{" "}
                            </Link>
                            <Link to="/forgetPassword"> Forget Password </Link>
                          </h4>
                        </>
                      ) : (
                        <div
                          className="mb-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              location1 &&
                              location1.state &&
                              location1.state.data.mail_msg,
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
