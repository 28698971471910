import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import AuthApi from "../../api/authApi";
import { toast } from "react-toastify";
import AdminApi from "../../api/admin";
import { Cookies } from "react-cookie";

const ChangePassword = ({ userProfileData = {}, getProfileData = null }) => {
  const api = new AuthApi();
  let adminApi = new AdminApi();
  let cookies = new Cookies();
  const [pass, setPass] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPass: "",
  });
  let userData = cookies.get("LoveStrongUser") || null;
  const [cp, setCp] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChanges = (e) => {
    let { name, value } = e.target;
    const d = { ...pass };
    d[name] = value;
    if (name == "newPassword") {
      let regex = /[!@#\$%\^&\*,_ -. +, =,!, %, *, &, ”, :, .,]/g;
      const foundSpecialChar = value.match(regex);
      regex = /[0-9]/g;
      const foundNum = value.match(regex);
      regex = /[A-Z]/g;
      const foundUppercase = value.match(regex);
      regex = /[a-z]/g;
      const lowerCase = value.match(regex);
      regex = /[~,\,<, ,]/g;
      const isSpace = value.match(regex);
      if (
        value?.length > 8 &&
        foundUppercase?.length &&
        foundSpecialChar?.length &&
        foundNum?.length &&
        lowerCase?.length &&
        !isSpace?.length
      ) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
    if (name == "confirmPass") {
      if (d.confirmPass != d.newPassword) {
        setCp(true);
      } else {
        setCp(false);
      }
    } else if (d.confirmPass) {
      if (d.confirmPass != d.newPassword) {
        setCp(true);
      } else {
        setCp(false);
      }
    }
    setPass(d);
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity()) {
      api
        .passwordChangeAfterLogin({ userData: userProfileData, pass: pass })
        .then((res) => {
          if (res.success) {
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: "Changed Password",
              action: "Click",
            };
            adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Create new log Report");
                }
              })
              .catch((error) => {
                console.log(error);
              });

            setValidated(false);
            toast.success(res.msg);
            cleanfunction();
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const cleanfunction = () => {
    setPass({
      oldPassword: "",
      newPassword: "",
      confirmPass: "",
    });
    setValidated(false);
    setCp(false);
  };

  return (
    <div className="media1">
      <div className="media-body">
        <h4 className="card-title">Change Password</h4>
        <Form
          className="forms-sample"
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
        >
          <Form.Group className="form-group">
            <Form.Label>Old password</Form.Label>
            <Form.Group className="input-group">
              <Form.Control
                required
                type="password"
                name="oldPassword"
                className="form-control form-control-sm border-left-0"
                placeholder="Old Password"
                value={pass.oldPassword}
                onChange={(e) => handleChanges(e)}
              />
              <Form.Control.Feedback type="invalid">
                Old password is required!
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>New password</Form.Label>
            <Form.Group className="input-group">
              <Form.Control
                required
                type="password"
                name="newPassword"
                className="form-control form-control-sm border-left-0"
                placeholder="New Password"
                value={pass.newPassword}
                isInvalid={isError}
                onChange={(e) => handleChanges(e)}
              />
              <Form.Control.Feedback type="invalid">
                {" "}
                {pass.newPassword == "" ? (
                  "New password is required!."
                ) : (
                  <>
                    Your password isn’t strong enough It should be minimum 6
                    characters <br></br> with at least one special and one
                    capital letter.
                  </>
                )}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Confirm password</Form.Label>
            <Form.Group className="input-group">
              <Form.Control
                required
                type="password"
                name="confirmPass"
                className="form-control form-control-sm border-left-0"
                placeholder="Confirm Password"
                value={pass.confirmPass}
                onChange={(e) => handleChanges(e)}
                isInvalid={cp}
              />
              <Form.Control.Feedback type="invalid">
                Password is not matching !
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Group>
          <Button
            type="submit"
            className="btn btn-primary me-2 mt-1 "
            disabled={cp || isError}
          >
            {" "}
            Update Password{" "}
          </Button>
          <Button
            type="button"
            className="btn btn-secondry me-2 mt-1"
            onClick={() => cleanfunction()}
          >
            {" "}
            Clear{" "}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
