import BaseApi from "./BaseApi";
class ProgramApi extends BaseApi {
  async getAllRoastersInfo(data) {
    return await this.API({
      url: "/program/getAllRoastersInfo",
      data: data,
      method: "post",
    });
  }

  async getProgramDetail(data) {
    return await this.API({
      url: "/program/getProgramDetail",
      data: data,
      method: "post",
    });
  }

  async removeProgramImage(data) {
    return await this.API({
      url: "/program/removeProgramImage",
      data: data,
      method: "post",
    });
  }

  async createProgram(data) {
    return await this.API({
      url: "/program/createProgram",
      data: data,
      method: "post",
    });
  }

  async removeProgramFooterImage(data) {
    return await this.API({
      url: "/program/removeProgramFooterImage",
      data: data,
      method: "post",
    });
  }

  async changePublishStatus(data) {
    return await this.API({
      url: "/program/changePublishStatus",
      data: data,
      method: "post",
    });
  }

  async createRetreatTeam(data) {
    return await this.API({
      url: "/program/createRetreatTeam",
      data: data,
      method: "post",
    });
  }

  async deleteRoasterCouple(data) {
    return await this.API({
      url: "/program/deleteRoasterCouple",
      data: data,
      method: "post",
    });
  }
}

export default ProgramApi;
