import React, { useState, useEffect } from "react";
import Profile from "../assets/images/couple-default.png";
import companyLogo from "./../assets/images/logo.png";
import companyMiniLogo from "./../assets/images/logo-mini.png";
import { Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";

const Header = ({ logoutUser = null, RightNav = null, LeftNav = null }) => {
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const [cookie, setCookie] = useCookies(["LoveStrongUser"]);
  useEffect(() => {}, [cookie]);
  // console.log('cookie:', cookie);

  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <div className="me-3">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-bs-toggle="minimize"
            onClick={LeftNav}
          >
            <span className="icon-menu"></span>
          </button>
        </div>
        <div>
          <div>
            <Link className="navbar-brand brand-logo" to="/">
              <img src={companyLogo} alt="logo" />
            </Link>

            <Link className="navbar-brand brand-logo-mini" to="/">
              <img src={companyMiniLogo} alt="logo" />
            </Link>
          </div>
        </div>
      </div>
      <div className="navbar-menu-wrapper p-2">
        <div className="d-flex align-items-top px-3 py-2 bg-white border-radius setNav">
          <ul className="navbar-nav">
            <li className="nav-item font-weight-semibold  d-lg-block ms-0">
              <h3 className="welcome-text">
                Welcome,&nbsp;
                <span className="text-brown fw-bold">
                  {userData.firstName ? userData.firstName : ""}{" "}
                  {userData.lastName ? userData.lastName : ""}!
                </span>
              </h3>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto margn-lef">
            <li className="nav-item">
              <Link className="nav-link" target="_blank" to="/help">
                {" "}
                <i className="menu-icon mdi mdi-help-circle-outline me-1 icon-md"></i>{" "}
              </Link>
            </li>

            <li className="nav-item dropdown  d-lg-block user-dropdown">
              <DropdownButton
                as={ButtonGroup}
                key={"start"}
                id={`dropdown-button-drop-start`}
                drop={"start"}
                variant="none"
                title={
                  userData.imageUpload ? (
                    <img
                      className="column-rowImage"
                      src={userData.imageUpload}
                      alt="Profile image"
                    />
                  ) : (
                    <img
                      className="column-rowImage"
                      src={Profile}
                      alt="Profile image"
                    />
                  )
                  // <img className="img-xs rounded-circle" src={Profile} alt="Profile image" sizes='32px'/>
                }
              >
                <a className="nav-link" href="#">
                  <Dropdown.Toggle id="UserDropdown" drop="start">
                    <img
                      className="img-xs rounded-circle"
                      src={Profile}
                      alt="Profile image"
                    />
                  </Dropdown.Toggle>
                </a>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    {userData.imageUpload ? (
                      <img
                        className="img-md profile"
                        src={userData.imageUpload}
                        alt="Profile image"
                      />
                    ) : (
                      <img
                        className="img-md profile"
                        src={Profile}
                        alt="Profile image"
                      />
                    )}
                    <p className="mb-1 mt-3 font-weight-semibold">
                      {userData.firstName
                        ? userData.firstName
                        : userData.hisFirstName
                        ? userData.hisFirstName
                        : ""}
                    </p>
                    <p className="fw-light text-muted mb-0">
                      {userData.email
                        ? userData.email
                        : userData.hisEmail
                        ? userData.hisEmail
                        : ""}
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/my-profile">
                    <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i>{" "}
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => logoutUser()}>
                    <i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>
                    Sign Out
                  </Dropdown.Item>

                  {userData.userRole === "ADMIN" &&
                  userData.isDisabled === 0 ? (
                    <Dropdown.Item as={Link} to="/Access-SuperAdmin">
                      <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i>
                      Access SuperAdmin
                    </Dropdown.Item>
                  ) : null}

                  {userData.userRole === "ADMIN" &&
                  userData.isDisabled === 0 ? (
                    <Dropdown.Item as={Link} to="/admin-dashboard">
                      <i className="dropdown-item-icon mdi mdi-view-dashboard text-primary me-2"></i>
                      Admin Dashboard
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </DropdownButton>
            </li>
          </ul>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-bs-toggle="offcanvas"
            onClick={RightNav}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Header;
