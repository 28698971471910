import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomDataTable from "../../components/CustomDataTable";
import Helpers from "../../components/Helpers";
import AuthApi from "../../api/authApi";
import moment from "moment-timezone";
import Profile from "../../assets/images/couple-default.png";
import AdminApi from "../../api/admin";
import RetreatRoasterApi from "../../api/retreatRoasterApi";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Cookies, useCookies } from "react-cookie";
import { utils, writeFile } from "xlsx";
import {
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Popover,
} from "react-bootstrap";
import RetreatApi from "../../api/retreatApi";
import AlertModal from "../../components/AlertModel";

const RetreatRegistrations = () => {
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  let authApi = new AuthApi();
  let adminApi = new AdminApi();
  let retreatRoasterApi = new RetreatRoasterApi();
  let retreatApi = new RetreatApi();
  const [data, setData] = useState({
    id: "",
    hisFirstName: "",
    hisLastName: "",
    hisEmail: "",
    hisMobile: "",
    herFirstName: "",
    herLastName: "",
    herEmail: "",
    herMobile: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    imageUrl: "",
    // marriageImageUrl: "",
    parishId: null,
    address: "",
    allergies: "",
    anniversary_date: "",
    under_age_35: "NO",
    emergency_name1: "",
    emergency_relationship1: "",
    emergency_phone1: "",
    emergency_name2: "",
    emergency_relationship2: "",
    emergency_phone2: "",
    hear_us: [],
    referral_from_a_friend_or_family_member: "",
    attendeeType: "",
    retreatId: "",
  });
  const [cookie, setCookie] = useCookies(["LoveStrongUser"]);
  const [validated, setValidated] = useState(false);
  const [LoadData, setLoadData] = useState(false);
  const [total, setTotal] = useState(20);
  const [showRegModal, setShowREgModal] = useState(false);
  const [isTempUpload, setIsTempUpload] = useState(false);
  // const [isTempUpload2, setIsTempUpload2] = useState(false);
  const [searchKey, setSearchKey] = useState(""); //for searching
  const [notApprovedCouplesData, setNotApprovedCouplesData] = useState([]);
  const [incommingRetreats, setIncommingRetreats] = useState([]);
  const [selectedRetreat, setSelectedRetreat] = useState("");
  const [retreatHeading, setRetreatHeading] = useState("");
  const [ediUserIdClicked, setEdiUserIdClicked] = useState("");
  const [parishList, setParishList] = useState([]);
  const [selectedParish, setSelectedParish] = useState([]);
  const [selectedCouples, setSelectedCouples] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [loadButton, setLoadButton] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [coupleDeleteData, setCoupleDeleteData] = useState([]);

  useEffect(() => {
    getParishList();
    getAllIncommingRetreats();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Retreat Registrations page.",
      action: "view",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const retreatRegisteredCouples = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    retreatId = "",
    loadCount = false
  ) => {
    setLoadData(true);
    let d = {};
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = searchKey;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = false;
    d.retreatId = retreatId;
    setLoadData(true);
    try {
      setIsTempUpload(false);
      // setIsTempUpload2(false);
      const res = await retreatRoasterApi.getRetreatRoastData(d);
      if (res.success) {
        let totalRecords = res.totalRecords ? res.totalRecords : 0;
        setTotal(totalRecords);
        const r = await adminApi.couplesForCheck();
        if (res && res.data) {
          setNotApprovedCouplesData(res.data);
          setLoadData(false);
        } else {
          setLoadData(false);
        }
      } else {
        setLoadData(false);
        toast.error(res.msg);
      }
    } catch (error) {
      setLoadData(false);
      toast.error(error.toString());
    }
  };

  const getParishList = async () => {
    setLoadData(true);
    await authApi
      .getPerishList()
      .then((response) => {
        if (response.success) {
          let allParishes = [];
          response.data &&
            response.data.forEach((item) => {
              if (item.parish) {
                allParishes.push({
                  label: item.parish,
                  value: item.id,
                });
              }
            });
          const sortedList = allParishes.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          setParishList(sortedList);
        }
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error.toString());
      });
  };

  const getAllIncommingRetreats = async () => {
    setLoadData(true);
    if (
      userData &&
      userData.access === true &&
      (userData.userRole === "COUPLE" || userData.userRole === "SUBADMIN")
    ) {
      await retreatApi
        .getAllAccessedRetreats({ userId: userData.id })
        .then(async (response) => {
          if (response.success) {
            await authApi
              .getPerishList()
              .then((r) => {
                response.data.forEach((event) => {
                  let myparish = r.data.find((i) => i.id === event.parishId);
                  if (myparish) {
                    event.myparish = myparish;
                  }
                });
              })
              .catch((error) => {
                toast.error(error.toString());
              });
            if (response && response.data[0] && response.data[0].id) {
              retreatRegisteredCouples(1, 20, "", {}, response.data[0].id);
              setSelectedRetreat(response.data[0].id);
              setRetreatHeading(response.data[0]);
            }
            setLoadData(false);
            setIncommingRetreats(response.data);
          } else {
            setLoadData(false);
            toast.error(response.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        });
    } else {
      await authApi
        .getAllIncommingRetreats()
        .then(async (response) => {
          if (response.success) {
            await authApi
              .getPerishList()
              .then((r) => {
                response.data.forEach((event) => {
                  let myparish = r.data.find((i) => i.id === event.parishId);
                  if (myparish) {
                    event.myparish = myparish;
                  }
                });
              })
              .catch((error) => {
                toast.error(error.toString());
              });
            if (response && response.data[0] && response.data[0].id) {
              retreatRegisteredCouples(1, 20, "", {}, response.data[0].id);
              setSelectedRetreat(response.data[0].id);
              setRetreatHeading(response.data[0]);
            }
            setLoadData(false);
            setIncommingRetreats(response.data);
          } else {
            setLoadData(false);
            toast.error(response.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        });
    }
  };

  const handleRetreatChanges = (e) => {
    const selectedValue = e.target.value;
    const selectedRetreat = incommingRetreats.find(
      (item) => item.id == selectedValue
    );
    if (selectedRetreat) {
      setSelectedRetreat(selectedRetreat.id);
      setRetreatHeading(selectedRetreat);
      retreatRegisteredCouples(1, 20, "", {}, selectedRetreat.id);
    } else {
      console.log("Retreat not found for selected value:", selectedValue);
    }
  };

  const editCouple = async (row) => {
    await authApi.getPerishList().then((r) => {
      let myparish = r.data.find((i) => i.id === row.parishId);
      if (myparish) {
        let returnOBj = {
          label: myparish.parish,
          value: myparish.id,
        };
        setSelectedParish(returnOBj);
      }
    });
    setIsTempUpload(false);
    let d = { ...data };
    d.id = row.id;
    d.primaryKey = row.primaryKey;
    d.hisFirstName = row.hisFirstName;
    d.hisLastName = row.hisLastName;
    d.hisEmail = row.hisEmail;
    d.hisMobile = row.hisMobile;
    d.herFirstName = row.herFirstName;
    d.herLastName = row.herLastName;
    d.herEmail = row.herEmail;
    d.herMobile = row.herMobile;
    d.city = row.city;
    d.state = row.state;
    d.zip = row.zip;
    d.notes = row.notes;
    d.parishId = row.parishId;
    d.imageUrl = row.imageUrl;
    // d.marriageImageUrl = row.marriageImageUrl;
    d.address = row.address;
    d.allergies = row.allergies;
    d.anniversary_date = row.anniversary_date
      ? new Date(row.anniversary_date)
      : "";
    d.under_age_35 = row.under_age_35;
    d.emergency_name2 = row.emergency_name1;
    d.emergency_relationship1 = row.emergency_relationship1;
    d.emergency_phone1 = row.emergency_phone1;
    d.emergency_name2 = row.emergency_name2;
    d.emergency_relationship2 = row.emergency_relationship2;
    d.emergency_phone2 = row.emergency_phone2;
    d.hear_us = row.hear_us;
    d.referral_from_a_friend_or_family_member =
      row.referral_from_a_friend_or_family_member;
    d.attendeeType = row.attendeeType;
    d.retreatId = row.retreatId;
    d.coupleId = row.coupleId;
    setData(d);
    setOldValues(row);
  };

  const openDeleteAlert = (coupleData) => {
    setCoupleDeleteData(coupleData);
    setDeleteAlert(true);
  };

  const closeDeleteAlert = () => {
    setDeleteAlert(false);
  };

  const deleteCouples = async () => {
    await adminApi
      .deleteCoupleFromRoaster(coupleDeleteData)
      .then(async (res) => {
        if (res.success) {
          CloseCompose();
          closeDeleteAlert();
          clearfield();
          setEdiUserIdClicked("");
          retreatRegisteredCouples(1, 20, "", {}, selectedRetreat);
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const columns = [
    {
      id: "action",
      name: "Edit",
      button: true,
      cell: (row) => (
        <>
          <button
            className="font-size"
            onClick={() => {
              editCouple(row);
              setShowREgModal(true);
            }}
          >
            <i
              className="mdi mdi-pencil-box text-lg text-primary font-size"
              title="Edit"
            ></i>
          </button>
        </>
      ),
      reorder: true,
    },
    {
      id: "created",
      name: "Date Submitted",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "DD-MM-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "120px",
    },
    {
      id: "Type",
      name: "Type",
      selector: (row) => row.attendeeType,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.attendeeType)}
        >
          <span
            className={`${
              row && row.attendeeTypeTrue ? "underLineText" : null
            }`}
          >
            {row.attendeeType}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.attendeeTypeTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "primaryKey",
      name: "Couple Id",
      selector: (row) => row.primaryKey,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          // delay={{ show: 250, hide: 400 }}
          overlay={popover(row)}
        >
          {row.imageUrl ? (
            <span
              onClick={() => {
                editCouple(row);
                setShowREgModal(true);
              }}
            >
              <img
                src={row.imageUrl}
                className={`${
                  row && row.imageUrlTrue
                    ? "column-rowImage checkImageEdit"
                    : "column-rowImage"
                }`}
              />
              {row.primaryKey && row.primaryKey}
            </span>
          ) : (
            <span
              onClick={() => {
                editCouple(row);
                setShowREgModal(true);
              }}
            >
              <img
                src={Profile}
                className={`${
                  row && row.imageUrlTrue
                    ? "column-rowImage checkImageEdit"
                    : "column-rowImage"
                }`}
              />
              {row.primaryKey && row.primaryKey}
            </span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      id: "hisLastName",
      name: "His Last Name",
      selector: (row) => row.hisLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisLastName)}
        >
          <span
            onClick={() => {
              editCouple(row);
              setShowREgModal(true);
            }}
            className={`${row && row.hisLastNameTrue ? "underLineText" : null}`}
          >
            {row.hisLastName &&
              row.hisLastName.slice(0, 10) +
                (row.hisLastName.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.hisLastNameTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "hisFirstName",
      name: "His First Name",
      selector: (row) => row.hisFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisFirstName)}
        >
          <span
            onClick={() => {
              editCouple(row);
              setShowREgModal(true);
            }}
            className={`${
              row && row.hisFirstNameTrue ? "underLineText" : null
            }`}
          >
            {row.hisFirstName &&
              row.hisFirstName.slice(0, 10) +
                (row.hisFirstName.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.hisFirstNameTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "hisEmail",
      name: "His Email",
      selector: (row) => row.hisEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisEmail)}
        >
          <a href={`mailto:${row.herEmail}`} className="mailDEsign">
            <span
              className={`${row && row.herEmailTrue ? "underLineText" : null}`}
            >
              {row.hisEmail &&
                row.hisEmail.slice(0, 12) +
                  (row.hisEmail.length > 12 ? "..." : "")}
            </span>
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.herEmailTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "hisMobile",
      name: "His Mobile",
      selector: (row) => row.hisMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisMobile)}
        >
          <span
            className={`${row && row.hisMobileTrue ? "underLineText" : null}`}
          >
            {row.hisMobile &&
              row.hisMobile.slice(0, 10) +
                (row.hisMobile.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.hisMobileTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "herLastName",
      name: "Her Last Name",
      selector: (row) => row.herLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herLastName)}
        >
          <span
            onClick={() => {
              editCouple(row);
              setShowREgModal(true);
            }}
            className={`${row && row.herLastNameTrue ? "underLineText" : null}`}
          >
            {row.herLastName &&
              row.herLastName.slice(0, 10) +
                (row.herLastName.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.herLastNameTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "herFirstName",
      name: "Her First Name",
      selector: (row) => row.herFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herFirstName)}
        >
          <span
            onClick={() => {
              editCouple(row);
              setShowREgModal(true);
            }}
            className={`${
              row && row.herFirstNameTrue ? "underLineText" : null
            }`}
          >
            {row.herFirstName &&
              row.herFirstName.slice(0, 10) +
                (row.herFirstName.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.herFirstNameTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "herEmail",
      name: "Her Email",
      selector: (row) => row.herEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herEmail)}
        >
          <a href={`mailto:${row.herEmail}`} className="mailDEsign">
            <span
              className={`${row && row.herEmailTrue ? "underLineText" : null}`}
            >
              {row.herEmail &&
                row.herEmail.slice(0, 12) +
                  (row.herEmail.length > 12 ? "..." : "")}
            </span>
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.herEmailTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "herMobile",
      name: "Her Mobile",
      selector: (row) => row.herMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herMobile)}
        >
          <span
            className={`${row && row.herMobileTrue ? "underLineText" : null}`}
          >
            {row.herMobile &&
              row.herMobile.slice(0, 10) +
                (row.herMobile.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.herMobileTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Address",
      name: "Address",
      selector: (row) => row.address,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.address)}
        >
          <span
            className={`${row && row.addressTrue ? "underLineText" : null}`}
          >
            {row.address &&
              row.address.slice(0, 10) + (row.address.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.addressTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.city)}
        >
          <span className={`${row && row.cityTrue ? "underLineText" : null}`}>
            {row.city &&
              row.city.slice(0, 10) + (row.city.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.cityTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.state)}
        >
          <span className={`${row && row.stateTrue ? "underLineText" : null}`}>
            {row.state &&
              row.state.slice(0, 10) + (row.state.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.stateTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "zip",
      name: "Zip",
      selector: (row) => row.zip,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.zip)}
        >
          <span className={`${row && row.zipTrue ? "underLineText" : null}`}>
            {row.zip}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.zipTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Parish",
      name: "Parish",
      selector: (row) => row.parish,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.parish)}
        >
          <span className={`${row && row.parishTrue ? "underLineText" : null}`}>
            {row.parish}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.parishTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Anniversary Date",
      name: "Anniversary Date",
      selector: (row) =>
        row.anniversary_date
          ? Helpers.convertUtcToTimezone(row.anniversary_date, "M/D/YYYY")
          : null,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(
              row && row.anniversary_date,
              "M/D/YYYY"
            )
          )}
        >
          <span
            className={`${
              row && row.anniversary_dateTrue ? "underLineText" : null
            }`}
          >
            {row.anniversary_date
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "M/D/YYYY")
              : "--"}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.anniversary_dateTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    /*{
      id: "Dietary restrictions or physical limitations",
      name: "Dietary restrictions or physical limitations",
      selector: (row) => row.allergies,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.allergies)}
        >
          <span
            className={`${row && row.allergiesTrue ? "underLineText" : null}`}
          >
            {row.allergies &&
              row.allergies.slice(0, 10) +
                (row.allergies.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.allergiesTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Emergency Name1",
      name: "Emergency Name1",
      selector: (row) => row.emergency_name1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name1)}
        >
          <span
            className={`${
              row && row.emergency_name1True ? "underLineText" : null
            }`}
          >
            {row.emergency_name1 &&
              row.emergency_name1.slice(0, 12) +
                (row.emergency_name1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.emergency_name1True,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Emergency Relationship1",
      name: "Emergency Relationship1",
      selector: (row) => row.emergency_relationship1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship1)}
        >
          <span
            className={`${
              row && row.emergency_relationship1True ? "underLineText" : null
            }`}
          >
            {row.emergency_relationship1 &&
              row.emergency_relationship1.slice(0, 12) +
                (row.emergency_relationship1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.emergency_relationship1True,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Emergency Phone1",
      name: "Emergency Phone1",
      selector: (row) => row.emergency_phone1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone1)}
        >
          <span
            className={`${
              row && row.emergency_phone1True ? "underLineText" : null
            }`}
          >
            {row.emergency_phone1 &&
              row.emergency_phone1.slice(0, 12) +
                (row.emergency_phone1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.emergency_phone1True,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Emergency Name2",
      name: "Emergency Name2",
      selector: (row) => row.emergency_name2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name2)}
        >
          <span
            className={`${
              row && row.emergency_name2True ? "underLineText" : null
            }`}
          >
            {row.emergency_name2 &&
              row.emergency_name2.slice(0, 12) +
                (row.emergency_name2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.emergency_name2True,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Emergency Relationship2",
      name: "Emergency Relationship2",
      selector: (row) => row.emergency_relationship2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship2)}
        >
          <span
            className={`${
              row && row.emergency_relationship2True ? "underLineText" : null
            }`}
          >
            {row.emergency_relationship2 &&
              row.emergency_relationship2.slice(0, 12) +
                (row.emergency_relationship2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.emergency_relationship2True,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Emergency Phone2",
      name: "Emergency Phone2",
      selector: (row) => row.emergency_phone2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone2)}
        >
          <span
            className={`${
              row && row.emergency_phone2True ? "underLineText" : null
            }`}
          >
            {row.emergency_phone2 &&
              row.emergency_phone2.slice(0, 12) +
                (row.emergency_phone2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.emergency_phone2True,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Hear about us",
      name: "Hear about us",
      selector: (row) => row.hear_us,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
          )}
        >
          <span
            className={`${row && row.hear_usTrue ? "underLineText" : null}`}
          >
            {row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
                .slice(0, 12) + (row.hear_us.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.hear_usTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Referral from a friend/Family member",
      name: "Referral from a friend/Family member",
      selector: (row) => row.referral_from_a_friend_or_family_member,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.referral_from_a_friend_or_family_member)}
        >
          <span
            className={`${
              row && row.referral_from_a_friend_or_family_memberTrue
                ? "underLineText"
                : null
            }`}
          >
            {row.referral_from_a_friend_or_family_member &&
              row.referral_from_a_friend_or_family_member.slice(0, 12) +
                (row.referral_from_a_friend_or_family_member.length > 12
                  ? "..."
                  : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.referral_from_a_friend_or_family_memberTrue,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Under Age 35",
      name: "Under Age 35",
      selector: (row) => row.under_age_35,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.under_age_35)}
        >
          <span
            className={`${
              row && row.under_age_35True ? "underLineText" : null
            }`}
          >
            {row.under_age_35 &&
              row.under_age_35.slice(0, 12) +
                (row.under_age_35.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.under_age_35True,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      id: "Notes",
      name: "Notes",
      selector: (row) => row.notes,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.notes)}
        >
          <span className={`${row && row.notesTrue ? "underLineText" : null}`}>
            {row.notes &&
              row.notes.slice(0, 10) + (row.notes.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row && row.notesTrue,
          style: {
            color: "red",
          },
        },
      ],
    },*/
  ];

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      setSearchKey(event.target.value);
      retreatRegisteredCouples(1, 20, event.target.value, {}, selectedRetreat);
    } else {
      setSearchKey("");
      retreatRegisteredCouples(1, 20, "", {}, selectedRetreat);
    }
  };

  const handleAgeChanges = (e) => {
    const { name, checked } = e.target;
    let o = { ...data };
    o.under_age_35 = checked ? "YES" : "NO";
    setData(o);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let hearUsArray = Array.isArray(data.hear_us)
      ? data.hear_us
      : JSON.parse(data.hear_us);

    let updatedOptions;
    if (checked) {
      updatedOptions = hearUsArray ? [...hearUsArray, name] : [name];
    } else {
      updatedOptions = hearUsArray.filter((option) => option !== name);
    }
    setData({ ...data, hear_us: updatedOptions });
  };

  const handleDatePicker = (e) => {
    let o = { ...data };
    o.anniversary_date = e;
    setData(o);
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    let o = { ...oldData };
    if (name === "hisFirstName") {
      o.hisFirstName = oldValues.hisFirstName;
    } else if (name === "hisLastName") {
      o.hisLastName = oldValues.hisLastName;
    } else if (name === "hisEmail") {
      o.hisEmail = oldValues.hisEmail;
    } else if (name === "hisMobile") {
      o.hisMobile = oldValues.hisMobile;
    } else if (name === "herFirstName") {
      o.herFirstName = oldValues.herFirstName;
    } else if (name === "herLastName") {
      o.herLastName = oldValues.herLastName;
    } else if (name === "herEmail") {
      o.herEmail = oldValues.herEmail;
    } else if (name === "herMobile") {
      o.herMobile = oldValues.herMobile;
    } else if (name === "parishId") {
      o.parishId = oldValues.parishId;
    } else if (name === "notes") {
      o.notes = oldValues.notes;
    } else if (name === "zip") {
      o.zip = oldValues.zip;
    } else if (name === "city") {
      o.city = oldValues.city;
    } else if (name === "address") {
      o.address = oldValues.address;
    } else if (name === "allergies") {
      o.allergies = oldValues.allergies;
    } else if (name === "anniversary_date") {
      o.anniversary_date = oldValues.anniversary_date;
    } else if (name === "state") {
      o.state = oldValues.state;
    } else if (name === "imageUrl") {
      o.imageUrl = oldValues.imageUrl;
    }
    // else if (name === "marriageImageUrl") {
    //   o.marriageImageUrl = oldValues.marriageImageUrl;
    // }
    else if (name === "under_age_35") {
      o.under_age_35 = oldValues.under_age_35;
    } else if (name === "emergency_name1") {
      o.emergency_name1 = oldValues.emergency_name1;
    } else if (name === "emergency_relationship1") {
      o.emergency_relationship1 = oldValues.emergency_relationship1;
    } else if (name === "emergency_phone1") {
      o.emergency_phone1 = oldValues.emergency_phone1;
    } else if (name === "emergency_name2") {
      o.emergency_name1 = oldValues.emergency_name1;
    } else if (name === "emergency_relationship2") {
      o.emergency_relationship1 = oldValues.emergency_relationship1;
    } else if (name === "emergency_phone2") {
      o.emergency_phone1 = oldValues.emergency_phone1;
    } else if (name === "hear_us") {
      o.hear_us = oldValues.hear_us;
    } else if (name === "referral_from_a_friend_or_family_member") {
      o.referral_from_a_friend_or_family_member =
        oldValues.referral_from_a_friend_or_family_member;
    }
    setOldData(o);
    // Check if the input is for an image
    // if (name === "imageUrl" || name === "marriageImageUrl") {
    //   const file = e.target.files[0];
    //   if (file) {
    //     const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB
    //     if (fileSizeInMB > 10) {
    //       alert("File size should be less than 10 MB");
    //       return;
    //     } else {
    //       d[name] = file;
    //       const successMessage =
    //         name === "imageUrl"
    //           ? "The profile image has been successfully added."
    //           : "The Wedding image has been successfully added.";
    //       toast.success(successMessage);
    //     }
    //   }
    // } else {
    //   d[name] = value;
    // }

    // ONLY FOR UPLOAD COUPLE IMAGE
    if (name === "imageUrl") {
      const file = e.target.files[0];
      if (file) {
        const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB
        if (fileSizeInMB > 10) {
          alert("File size should be less than 10 MB");
          return;
        } else {
          d[name] = file;
          const successMessage =
            name === "imageUrl"
              ? "The profile image has been successfully added."
              : "";
          toast.success(successMessage);
        }
      }
    } else {
      d[name] = value;
    }
    setData(d);
  };

  const handleSelectParish = (val) => {
    setSelectedParish(val);
    let d = { ...data };
    d.parishId = val.value;
    setData(d);
  };

  const handleSubmit = (e) => {
    setLoadButton(true);
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      let data1 = new FormData();
      let stateRef = { ...data };
      if (data.imageUrl !== "" && data.imageUrl != null) {
        data1.append("file", data.imageUrl);
        delete stateRef.imageUrl;
      }
      // if (data.marriageImageUrl !== "" && data.marriageImageUrl != null) {
      //   data1.append("marriage_file", data.marriageImageUrl);
      //   delete stateRef.marriageImageUrl;
      // }
      stateRef.retreatId = selectedRetreat;
      data1.append("fromData", JSON.stringify(stateRef));

      adminApi
        .updateNewCoupleRegister(data1)
        .then(async (res) => {
          if (res.success) {
            userData.imageUpload = res.data.imageUrl;
            //userData.marriageImageUpload = res.data[0].marriageImageUrl;
            if (userData.coupleId === res.data.id) {
              setCookie("LoveStrongUser", userData, {
                path: "/",
                maxAge: 60 * 60 * 100,
                expires: new Date(Date.now() + 60 * 60 * 100),
              });
            }
            toast.success(res.msg);
            setLoadButton(false);
            if (
              oldData.hisFirstName ||
              (oldData.hisFirstName === "" &&
                oldData.hisFirstName !== res.data.hisFirstName)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated HisFirstName of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.hisFirstName ? oldData.hisFirstName : "Nil"
                  }" to "${
                    res.data.hisFirstName ? res.data.hisFirstName : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.hisLastName ||
              (oldData.hisLastName === "" &&
                oldData.hisLastName !== res.data.hisLastName)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated HisLastName of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.hisLastName ? oldData.hisLastName : "Nil"
                  }" to "${
                    res.data.hisLastName ? res.data.hisLastName : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.hisEmail ||
              (oldData.hisEmail === "" &&
                oldData.hisEmail !== res.data.hisEmail)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated HisEmail of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${oldData.hisEmail ? oldData.hisEmail : "Nil"}" to "${
                    res.data.hisEmail ? res.data.hisEmail : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.hisMobile ||
              (oldData.hisMobile === "" &&
                oldData.hisMobile !== res.data.hisMobile)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated HisMobile of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.hisMobile ? oldData.hisMobile : "Nil"
                  }" to "${res.data.hisMobile ? res.data.hisMobile : "Nil"}"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.herFirstName ||
              (oldData.herFirstName === "" &&
                oldData.herFirstName !== res.data.herFirstName)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated HerFirstName of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.herFirstName ? oldData.herFirstName : "Nil"
                  }" to "${
                    res.data.herFirstName ? res.data.herFirstName : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.herLastName ||
              (oldData.herLastName === "" &&
                oldData.herLastName !== res.data.herLastName)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated HerLastName of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.herLastName ? oldData.herLastName : "Nil"
                  }" to "${
                    res.data.herLastName ? res.data.herLastName : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.herEmail ||
              (oldData.herEmail === "" &&
                oldData.herEmail !== res.data.herEmail)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated HerEmail of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${oldData.herEmail ? oldData.herEmail : "Nil"}" to "${
                    res.data.herEmail ? res.data.herEmail : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.herMobile ||
              (oldData.herMobile === "" &&
                oldData.herMobile !== res.data.herMobile)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated HerMobile of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.herMobile ? oldData.herMobile : "Nil"
                  }" to "${res.data.herMobile ? res.data.herMobile : "Nil"}"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            let oldparish = parishList.find((i) => i.id === oldData.parishId);
            let newparish = parishList.find((i) => i.id === res.data.parishId);
            if (oldData.parishId || oldData.parishId === null) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Parish of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldparish &&
                    oldparish.parish &&
                    oldparish.parish !== undefined
                      ? oldparish.parish
                      : "Nil"
                  }" to "${
                    newparish &&
                    newparish.parish &&
                    newparish.parish !== undefined
                      ? newparish.parish
                      : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.notes ||
              (oldData.notes === "" && oldData.notes !== res.data.notes)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Notes of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${oldData.notes ? oldData.notes : "Nil"}" to "${
                    res.data.notes ? res.data.notes : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.zip ||
              (oldData.zip === "" && oldData.zip !== res.data.zip)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Zip of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${oldData.zip ? oldData.zip : "Nil"}" to "${
                    res.data.zip ? res.data.zip : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.city ||
              (oldData.city === "" && oldData.city !== res.data.city)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated City of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${oldData.city ? oldData.city : "Nil"}" to "${
                    res.data.city ? res.data.city : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.state ||
              (oldData.state === "" && oldData.state !== res.data.state)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated State of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${oldData.state ? oldData.state : "Nil"}" to "${
                    res.data.state ? res.data.state : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.imageUrl ||
              (oldData.imageUrl === "" &&
                oldData.imageUrl !== res.data.imageUrl)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated ImageUrl of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            // if (
            //   oldData.marriageImageUrl ||
            //   (oldData.marriageImageUrl === "" &&
            //     oldData.marriageImageUrl !== res.data.marriageImageUrl)
            // ) {
            //   let report = {
            //     byWhom:
            //       `${
            //         userData && userData.userName ? userData.userName : null
            //       } ` +
            //       ` (${
            //         userData && userData.userRole ? userData.userRole : null
            //       })`,
            //     pagelink: window.location.href,
            //     description:
            //       `Updated MarriageImageUrl of Couple by Retreat Admin (Retreat - ${
            //         retreatHeading &&
            //         retreatHeading.title &&
            //         retreatHeading.title.charAt(0).toUpperCase() +
            //           retreatHeading.title.slice(1)
            //       }) ` +
            //       `(Couple - ${
            //         res && res.data && res.data.primaryKey
            //           ? `${res.data.primaryKey} - `
            //           : ""
            //       }` +
            //       `${
            //         res && res.data && res.data.hisLastName
            //           ? `${res.data.hisLastName}`
            //           : ""
            //       }` +
            //       `${
            //         res && res.data && res.data.hisFirstName
            //           ? `, ${res.data.hisFirstName}`
            //           : ""
            //       }` +
            //       `${
            //         res && res.data && res.data.herFirstName
            //           ? ` and  ${res.data.herFirstName}`
            //           : ""
            //       })`,
            //     action: "Update",
            //   };
            //   await adminApi
            //     .addLogReport(report)
            //     .then((response) => {
            //       if (response.success) {
            //         console.log("Created new log report.");
            //       }
            //     })
            //     .catch((error) => {
            //       console.log(error);
            //     });
            // }

            if (
              oldData.address ||
              (oldData.address === "" && oldData.address !== res.data.address)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Address of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${oldData.address ? oldData.address : "Nil"}" to "${
                    res.data.address ? res.data.address : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.allergies ||
              (oldData.allergies === "" &&
                oldData.allergies !== res.data.allergies)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "dietary restrictions or physical limitations" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.allergies ? oldData.allergies : "Nil"
                  }" to "${res.data.allergies ? res.data.allergies : "Nil"}"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.anniversary_date ||
              (oldData.anniversary_date === "" &&
                oldData.anniversary_date !== res.data.anniversary_date)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Anniversary Date of Couple by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.anniversary_date ? oldData.anniversary_date : "Nil"
                  }" to "${
                    res.data.anniversary_date
                      ? res.data.anniversary_date
                      : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.under_age_35 ||
              (oldData.under_age_35 === "" &&
                oldData.under_age_35 !== res.data.under_age_35)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Under Age 35" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.under_age_35 ? oldData.under_age_35 : "Nil"
                  }" to "${
                    res.data.under_age_35 ? res.data.under_age_35 : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.emergency_name1 ||
              (oldData.emergency_name1 === "" &&
                oldData.emergency_name1 !== res.data.emergency_name1)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Emergency Name1" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.emergency_name1 ? oldData.emergency_name1 : "Nil"
                  }" to "${
                    res.data.emergency_name1 ? res.data.emergency_name1 : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.emergency_relationship1 ||
              (oldData.emergency_relationship1 === "" &&
                oldData.emergency_relationship1 !==
                  res.data.emergency_relationship1)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Emergency Relationship1" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.emergency_relationship1
                      ? oldData.emergency_relationship1
                      : "Nil"
                  }" to "${
                    res.data.emergency_relationship1
                      ? res.data.emergency_relationship1
                      : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.emergency_phone1 ||
              (oldData.emergency_phone1 === "" &&
                oldData.emergency_phone1 !== res.data.emergency_phone1)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Emergency Phone1" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.emergency_phone1 ? oldData.emergency_phone1 : "Nil"
                  }" to "${
                    res.data.emergency_phone1
                      ? res.data.emergency_phone1
                      : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.emergency_name2 ||
              (oldData.emergency_name2 === "" &&
                oldData.emergency_name2 !== res.data.emergency_name2)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Emergency Name2" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.emergency_name2 ? oldData.emergency_name2 : "Nil"
                  }" to "${
                    res.data.emergency_name2 ? res.data.emergency_name2 : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.emergency_relationship2 ||
              (oldData.emergency_relationship2 === "" &&
                oldData.emergency_relationship2 !==
                  res.data.emergency_relationship2)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Emergency Relationship2" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.emergency_relationship2
                      ? oldData.emergency_relationship2
                      : "Nil"
                  }" to "${
                    res.data.emergency_relationship2
                      ? res.data.emergency_relationship2
                      : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.emergency_phone2 ||
              (oldData.emergency_phone2 === "" &&
                oldData.emergency_phone2 !== res.data.emergency_phone2)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Emergency Phone2" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.emergency_phone2 ? oldData.emergency_phone2 : "Nil"
                  }" to "${
                    res.data.emergency_phone2
                      ? res.data.emergency_phone2
                      : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            if (
              oldData.hear_us ||
              (oldData.hear_us === "" && oldData.hear_us !== res.data.hear_us)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Hear about us" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${oldData.hear_us ? oldData.hear_us : "Nil"}" to "${
                    res.data.hear_us ? res.data.hear_us : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (
              oldData.referral_from_a_friend_or_family_member ||
              (oldData.referral_from_a_friend_or_family_member === "" &&
                oldData.referral_from_a_friend_or_family_member !==
                  res.data.referral_from_a_friend_or_family_member)
            ) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Updated Couple's column on "Referral from a friend/Family member" by Retreat Admin (Retreat - ${
                    retreatHeading &&
                    retreatHeading.title &&
                    retreatHeading.title.charAt(0).toUpperCase() +
                      retreatHeading.title.slice(1)
                  }) ` +
                  `(Couple - ${
                    res && res.data && res.data.primaryKey
                      ? `${res.data.primaryKey} - `
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisLastName
                      ? `${res.data.hisLastName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.hisFirstName
                      ? `, ${res.data.hisFirstName}`
                      : ""
                  }` +
                  `${
                    res && res.data && res.data.herFirstName
                      ? ` and  ${res.data.herFirstName}`
                      : ""
                  })` +
                  ` From "${
                    oldData.referral_from_a_friend_or_family_member
                      ? oldData.referral_from_a_friend_or_family_member
                      : "Nil"
                  }" to "${
                    res.data.referral_from_a_friend_or_family_member
                      ? res.data.referral_from_a_friend_or_family_member
                      : "Nil"
                  }"`,
                action: "Update",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description:
                `Couple approved by Retreat Admin (Retreat - ${
                  retreatHeading &&
                  retreatHeading.title &&
                  retreatHeading.title.charAt(0).toUpperCase() +
                    retreatHeading.title.slice(1)
                }) ` +
                `(Couple - ${
                  res && res.data && res.data.primaryKey
                    ? `${res.data.primaryKey} - `
                    : ""
                }` +
                `${
                  res && res.data && res.data.hisLastName
                    ? `${res.data.hisLastName}`
                    : ""
                }` +
                `${
                  res && res.data && res.data.hisFirstName
                    ? `, ${res.data.hisFirstName}`
                    : ""
                }` +
                `${
                  res && res.data && res.data.herFirstName
                    ? ` and  ${res.data.herFirstName}`
                    : ""
                })"`,
              action: "Approve Couple for retreat",
            };
            await adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Created new log report.");
                }
              })
              .catch((error) => {
                console.log(error);
              });

            setOldData([]);
            clearfield();
            remove();
            setShowREgModal(false);
            // getAllIncommingRetreats();
            retreatRegisteredCouples(1, 20, "", {}, selectedRetreat);
            setData({
              id: "",
              hisFirstName: "",
              hisLastName: "",
              hisEmail: "",
              hisMobile: "",
              herFirstName: "",
              herLastName: "",
              herEmail: "",
              herMobile: "",
              city: "",
              state: "",
              zip: "",
              notes: "",
              imageUrl: "",
              // marriageImageUrl: "",
              address: "",
              allergies: "",
              anniversary_date: "",
              under_age_35: false,
              emergency_name1: "",
              emergency_relationship1: "",
              emergency_phone1: "",
              emergency_name2: "",
              emergency_relationship2: "",
              emergency_phone2: "",
              hear_us: [],
              referral_from_a_friend_or_family_member: "",
              attendeeType: "",
              retreatId: "",
            });
          } else {
            setLoadButton(false);
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          setLoadButton(false);
          console.log(error);
        });
    }
    setValidated(true);
  };

  const popover = (row) => {
    return (
      <Popover id="popover-ImgPk" bsPrefix="popover">
        <Popover.Body>
          <div style={{ textAlign: "center", padding: "10px 0px" }}>
            {row.imageUrl ? (
              <span>
                <img src={row.imageUrl} className="column-rowImagePopover" />
              </span>
            ) : (
              <img src={Profile} className="column-rowImagePopover" />
            )}
            <h6 className="couplename">
              {" "}
              {row &&
                row.hisLastName +
                  ", " +
                  row.hisFirstName +
                  " and " +
                  row.herFirstName}
            </h6>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const upload = () => {
    document.getElementById("CoupleProfileUpload").click();
    setIsTempUpload(true);
  };

  // const uploadMarriagePhoto = () => {
  //   document.getElementById("MarriagePhotoUpload").click();
  //   setIsTempUpload2(true);
  // };

  const remove = () => {
    let d = { ...data };
    d.imageUrl = "";
    setData(d);
    setIsTempUpload(false);
  };

  // const removeMarriagePhoto = () => {
  //   let d = { ...data };
  //   d.marriageImageUrl = "";
  //   setData(d);
  //   setIsTempUpload2(false);
  // };

  const clearfield = () => {
    setData({
      id: "",
      hisFirstName: "",
      hisLastName: "",
      hisEmail: "",
      hisMobile: "",
      herFirstName: "",
      herLastName: "",
      herEmail: "",
      herMobile: "",
      city: "",
      state: "",
      zip: "",
      notes: "",
      imageUrl: "",
      // marriageImageUrl: "",
      parishId: null,
      address: "",
      allergies: "",
      anniversary_date: "",
      under_age_35: false,
      emergency_name1: "",
      emergency_relationship1: "",
      emergency_phone1: "",
      emergency_name2: "",
      emergency_relationship2: "",
      emergency_phone2: "",
      hear_us: [],
      referral_from_a_friend_or_family_member: "",
      attendeeType: "",
      retreatId: "",
    });
    setSelectedParish([]);
    setParishList([]);
    setValidated(false);
  };

  const CloseCompose = () => {
    setValidated(false);
    setShowREgModal(false);
    setEdiUserIdClicked("");
    clearfield();
  };

  const renderModel = () => {
    return (
      <div className="tab_headers">
        <Modal
          show={showRegModal}
          onHide={() => {
            CloseCompose();
            clearfield();
            setEdiUserIdClicked("");
          }}
          size="lg"
        >
          <Modal.Header
            closeButton={() => {
              // checkPKCouple();
              setEdiUserIdClicked("");
              CloseCompose();
            }}
          ></Modal.Header>
          <div className="row w-100 mx-0">
            <div className="col-lg-12 mx-auto">
              <div className="auth-form-light text-left main-register pb-5 px-4 px-sm-5">
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={validated}
                  className="forms-sample"
                >
                  <Row>
                    <Col lg={6} md={6} xs={12}>
                      <div>
                        {" "}
                        <label
                          className="bold mt-2"
                          htmlFor="exampleInputEmail1"
                        >
                          Are you Retreatant Couple or a Team Couple ?{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Group className="form-group">
                          <div className="mb-3">
                            <Form.Check
                              required
                              type={"radio"}
                              id={`retreadRad`}
                              checked={data.attendeeType === "Retreatant"}
                              onChange={(e) => {
                                handleChanges(e);
                              }}
                              value={"Retreatant"}
                              label={`Retreatant`}
                              name="attendeeType"
                              className="space_lef1"
                            />
                          </div>
                          <div className="mb-3">
                            <Form.Check
                              required
                              type={"radio"}
                              id={`coupleRad`}
                              checked={data.attendeeType === "Team"}
                              onChange={(e) => {
                                handleChanges(e);
                              }}
                              value={`Team`}
                              label={`Team`}
                              className="space_lef1"
                              name="attendeeType"
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="mt-4">
                        {" "}
                        <label
                          className="bold mt-2"
                          htmlFor="exampleInputEmail1"
                        >
                          If you or your spouse are 35 years of age or younger,
                          click this box:
                        </label>
                        <label className="form-check-label mt-2">
                          <input
                            type="checkbox"
                            className="form-check-input ageLabel"
                            name="under_age_35"
                            checked={data.under_age_35 === "YES" ? true : false}
                            onChange={handleAgeChanges}
                          />
                          Yes
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                      <Form.Group>
                        {data.imageUrl == "" || data.imageUrl == undefined ? (
                          <div className="edit-profile-img mb-2 text-center">
                            <img src={Profile} />
                          </div>
                        ) : (
                          <div className="edit-profile-img mb-2 text-center">
                            {isTempUpload ? (
                              <>
                                <img
                                  src={URL.createObjectURL(data?.imageUrl)}
                                />
                              </>
                            ) : (
                              <>
                                <img src={data?.imageUrl} />
                              </>
                            )}
                          </div>
                        )}{" "}
                        <div className="text-center mt-3">
                          {data.imageUrl == "" || data.imageUrl == undefined ? (
                            <>
                              <input
                                name="imageUrl"
                                className="pt-1"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id="CoupleProfileUpload"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Button
                                variant="primary"
                                className="mb-1 me-3 text-center"
                                onClick={(e) => {
                                  upload();
                                }}
                              >
                                Upload Profile Image
                              </Button>
                              {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                            </>
                          ) : (
                            <Button
                              variant="danger"
                              className="mb-1 mt-2 "
                              onClick={(e) => {
                                remove();
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    {/* <Col lg={3} md={3} xs={12}>
                      <Form.Group>
                        {data.marriageImageUrl == "" ||
                        data.marriageImageUrl == undefined ? (
                          <div className="edit-profile-img mb-2 text-center">
                            <img src={Profile} />
                          </div>
                        ) : (
                          <div className="edit-profile-img mb-2 text-center">
                            {isTempUpload2 ? (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    data?.marriageImageUrl
                                  )}
                                />
                              </>
                            ) : (
                              <>
                                <img src={data?.marriageImageUrl} />
                              </>
                            )}
                          </div>
                        )}
                        <div className="text-center mt-3">
                          {data.marriageImageUrl == "" ||
                          data.marriageImageUrl == undefined ? (
                            <>
                              <input
                                name="marriageImageUrl"
                                className="pt-1"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id="MarriagePhotoUpload"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Button
                                variant="primary"
                                className="mb-1 text-center"
                                onClick={(e) => {
                                  uploadMarriagePhoto();
                                }}
                              >
                                Upload Wedding Image
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="danger"
                              className="mb-1 mt-2 "
                              onClick={(e) => {
                                removeMarriagePhoto();
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col> */}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col lg={6} md={6} xs={12}>
                      <label className="badge badge-outline-primary rounded hisLabel">
                        His Info
                      </label>
                      <div id="his-info">
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="hisFirstName"
                              id="hisFirstName"
                              placeholder="First Name"
                              value={data.hisFirstName}
                              className="form-control"
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              FirstName is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="hisLastName"
                              id="hisLastName"
                              placeholder="First Name"
                              value={data.hisLastName}
                              className="form-control"
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              LastName is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group col">
                            <Form.Label>His Email</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="hisEmail"
                              id="hisEmail"
                              placeholder="Email"
                              value={data.hisEmail}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Email is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group col">
                            <Form.Label>His Mobile</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="hisMobile"
                              id="hisMobile"
                              placeholder="Mobile"
                              value={data.hisMobile}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Mobile is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </div>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <label className="badge badge-outline-primary rounded herLabel">
                        Her Info
                      </label>
                      <div id="her-info">
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="herFirstName"
                              id="herFirstName"
                              placeholder="First Name"
                              value={data.herFirstName}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              FirstName is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="herLastName"
                              id="herLastName"
                              placeholder="Last Name"
                              value={data.herLastName}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              LastName is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Her Email</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="herEmail"
                              id="herEmail"
                              placeholder="Email"
                              value={data.herEmail}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Email is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="herMobile"
                              id="herMobile"
                              placeholder="Mobile"
                              value={data.herMobile}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Mobile is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                  <hr></hr>
                  <div className="row">
                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                        value={data.address}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Address is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        className="form-control minimal"
                        size="sm"
                        type="text"
                        name="state"
                        id="state"
                        placeholder="State"
                        value={data.state}
                        onChange={(e) => handleChanges(e)}
                      >
                        <option value="">Select State</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="Arizona">Arizona</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="District of Columbia">
                          District of Columbia
                        </option>
                        <option value="Delaware">Delaware</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Maine">Maine</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Montana">Montana</option>
                        <option value="North Carolina<">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New York">New York</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Washington">Washington</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wyoming">Wyoming</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        State is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        value={data.city}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        City is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Zip</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="Zip"
                        value={data.zip}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Zip is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Parish</Form.Label>

                      <Select
                        name="parish"
                        options={parishList}
                        value={selectedParish}
                        onChange={handleSelectParish}
                        labelledBy="Select"
                        isSearchable={true}
                        isMulti={false}
                      />

                      {/* <Form.Select
                        className="form-control minimal"
                        size="sm"
                        type="text"
                        name="parishId"
                        id="parishId"
                        placeholder="Parish"
                        value={data.parishId}
                        onChange={(e) => handleChanges(e)}
                      >
                        <option value="">Select Parish</option>
                        {parishList.map((item, index) => {
                          return <option value={item.id}>{item.parish}</option>;
                        })}
                      </Form.Select> */}

                      <Form.Control.Feedback type="invalid">
                        Parsih is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label className="d-block">
                        Anniversary Date (Month/Day)
                      </Form.Label>

                      <DatePicker
                        // required
                        autoComplete="off"
                        dateFormat="MM-dd-yyyy"
                        placeholderText="mm-dd-yyyy"
                        selected={data && data.anniversary_date}
                        onChange={(e) => handleDatePicker(e)}
                        className="form-select setdate datepicker"
                        name="anniversary_date"
                        id="anniversary_date"
                        maxDate={new Date()}
                        showDisabledMonthNavigation
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="notes"
                        id="notes"
                        placeholder="Notes"
                        value={data.notes}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Notes is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>
                        Do you or your spouse have any dietary allergies,
                        dietary restrictions or physical limitations?
                      </Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="allergies"
                        id="allergies"
                        value={data.allergies}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>
                  </div>

                  <div className="row mt-3">
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h4>Please provide at least one emergency contact:</h4>
                    </div>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_name1"
                        id="emergency_name1"
                        placeholder="Name"
                        value={data.emergency_name1}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <Form.Label>Relationship</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_relationship1"
                        id="emergency_relationship1"
                        placeholder="Relationship"
                        value={data.emergency_relationship1}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_phone1"
                        id="emergency_phone1"
                        placeholder="Phone"
                        value={data.emergency_phone1}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_name2"
                        id="emergency_name2"
                        placeholder="Name"
                        value={data.emergency_name2}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <Form.Label>Relationship</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_relationship2"
                        id="emergency_relationship2"
                        placeholder="Relationship"
                        value={data.emergency_relationship2}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Relationship is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_phone2"
                        id="emergency_phone2"
                        placeholder="Phone"
                        value={data.emergency_phone2}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Phone is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="row mt-3 ">
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h4>How did you hear about us?</h4>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between hear-options">
                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="Website"
                          value="Website"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("Website")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(data.hear_us === "Website")
                          }
                        />
                        Website
                        <i className="input-helper"></i>
                      </label>

                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="Church Bulletin"
                          value="Church Bulletin"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("Church Bulletin")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(
                              data.hear_us === "Church Bulletin"
                            )
                          }
                        />
                        Church Bulletin
                        <i className="input-helper"></i>
                      </label>

                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="Facebook"
                          value="Facebook"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("Facebook")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(data.hear_us === "Facebook")
                          }
                        />
                        Facebook
                        <i className="input-helper"></i>
                      </label>

                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="School Marquee"
                          value="School Marquee"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("School Marquee")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(
                              data.hear_us === "School Marquee"
                            )
                          }
                        />
                        School Marquee
                        <i className="input-helper"></i>
                      </label>

                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="Pulpit Talk/Table in Church"
                          value="Pulpit Talk/Table in Church"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("Pulpit Talk/Table in Church")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(
                              data.hear_us === "Pulpit Talk/Table in Church"
                            )
                          }
                        />
                        Pulpit Talk/Table in Church
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </div>
                  <Col lg={6} md={6} xs={12} sm={12} className=" mt-5">
                    <h4> Referral from a friend/Family member</h4>
                    <Form.Group className="form-group">
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="referral_from_a_friend_or_family_member"
                        placeholder=" Referral from a friend/Family member"
                        id="referral_from_a_friend_or_family_member"
                        value={data.referral_from_a_friend_or_family_member}
                        className="form-control"
                        onChange={(e) => {
                          handleChanges(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12} className="text-center mt-5">
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={loadButton ? true : false}
                      >
                        Update
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          openDeleteAlert(data);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          CloseCompose();
                          clearfield();
                          setEdiUserIdClicked("");
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Col>
                </Form>
              </div>{" "}
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const handleExport = async () => {
    if (notApprovedCouplesData && notApprovedCouplesData.length) {
      let exportData = [];
      notApprovedCouplesData.forEach((element) => {
        exportData.push({
          coupleId: element.coupleId,
          attendeeType: element.attendeeType,
          hisLastName: element.hisLastName,
          hisFirstName: element.hisFirstName,
          hisEmail: element.hisEmail,
          hisMobile: element.hisMobile,
          herLastName: element.herLastName,
          herFirstName: element.herFirstName,
          herEmail: element.herEmail,
          herMobile: element.herMobile,
          address: element.address,
          city: element.city,
          state: element.state,
          zip: element.zip,
          parish: element.parish,
          Anniversary_date:
          element.anniversary_date !== null
            ? Helpers.convertUtcToTimezone(
                element.anniversary_date,
                "MM-DD-YYYY"
              )
            : "",
          Dietary_restrictions_or_physical_limitations: element.allergies,
          Under_age_35: element.under_age_35,
          Emergency_name1: element.emergency_name1,
          Emergency_relationship1: element.emergency_relationship1,
          Emergency_phone1: element.emergency_phone1,
          Emergency_name2: element.emergency_name2,
          Emergency_relationship2: element.emergency_relationship2,
          Emergency_phone2: element.emergency_phone2,
          // How_did_you_hear_about_us: element.hear_us,
          Referral_from_a_friend_or_family_member:
            element.referral_from_a_friend_or_family_member,
          notes: element.notes,
        });
      });
      const headings = [
        [
          "Couple Id",
          "Type",
          "His Last Name",
          "His First Name",
          "His Email",
          "His Mobile",
          "Her Last Name",
          "Her First Name",
          "Her Email",
          "Her Mobile",
          "Address",
          "City",
          "State",
          "Zip",
          "Parish",
          "Anniversary Date",
          "Dietary Restrictions Or Physical Limitations",
          "Under Age 35",
          "Emergency Name1",
          "Emergency Relationship1",
          "Emergency Phone1",
          "Emergency Name2",
          "Emergency Relationship2",
          "Emergency Phone2",
          "How did you hear about us?",
          "Referral from a friend/family member",
          "Notes",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${
          retreatHeading && retreatHeading.title
            ? `${
                retreatHeading &&
                retreatHeading.title &&
                retreatHeading.title.charAt(0).toUpperCase() +
                  retreatHeading.title.slice(1)
              }` +
              " " +
              `${
                retreatHeading.dateFrom == retreatHeading.dateTo
                  ? moment(retreatHeading && retreatHeading.dateFrom)
                      .tz("America/New_York")
                      .format("MMM-DD-YYYY")
                  : moment(retreatHeading && retreatHeading.dateFrom)
                      .tz("America/New_York")
                      .format("MMM DD") +
                    " - " +
                    moment(retreatHeading && retreatHeading.dateTo)
                      .tz("America/New_York")
                      .format("MMM DD") +
                    ", " +
                    moment(retreatHeading && retreatHeading.dateTo)
                      .tz("America/New_York")
                      .format("YYYY")
              }`
            : "Retreat Roaster"
        }.xlsx`
      );
      let report = {
        byWhom:
          `${userData && userData.userName ? userData.userName : null} ` +
          ` (${userData && userData.userRole ? userData.userRole : null})`,
        pagelink: window.location.href,
        description: `Exported Excel of approval couple for Retreat ( Retreat : ${retreatHeading.title} ).`,
        action: "Click",
      };
      await adminApi
        .addLogReport(report)
        .then((response) => {
          if (response.success) {
            console.log("Created new log report.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // Retreat - Date - Language - Location
      toast.error("No couples are present at this retreat for approval.");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 d-flex flex-column">
          <div className="col-lg-12 d-flex flex-column">
            <div className="container  text-center">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-8 col-xs-8">
                  {incommingRetreats && incommingRetreats.length ? (
                    <select
                      className="form-select  retreatSelect form-select-lg mb-0 mt-3"
                      aria-label="Large select example"
                      onChange={(e) => handleRetreatChanges(e)}
                    >
                      {incommingRetreats.map((item, i) => {
                        return (
                          <option
                            key={i}
                            value={item.id}
                            className="retreatOptions"
                          >
                            {`${
                              item.title.slice(0, 30) +
                              (item.title.length > 30 ? "..." : "")
                            }` +
                              ` ${
                                moment(item && item.dateFrom)
                                  .tz("America/New_York")
                                  .format("MMM DD") +
                                " - " +
                                moment(item.dateTo)
                                  .tz("America/New_York")
                                  .format("MMM DD") +
                                ", " +
                                moment(item && item.dateFrom)
                                  .tz("America/New_York")
                                  .format("YYYY")
                              }`}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="card custom-card mt-3">
            <div className="card-body">
              <div className="d-sm-flex justify-content-between align-items-start Couple_heading">
                <h4 className="card-title">Retreat Registrations</h4>
                <div className="d-flex">
                  <div className="search mb-3">
                    <div className="input-group setSearch">
                      <input
                        onChange={SearchData}
                        type="text"
                        className="form-control searchData"
                        placeholder="Search..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-sm btn-primary"
                          type="button"
                        >
                          <i className="icon-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  {(userData && userData.userRole === "ADMIN") ||
                  (userData &&
                    userData.access === true &&
                    (userData.userRole === "COUPLE" ||
                      userData.userRole === "SUBADMIN")) ? (
                    <button
                      onClick={handleExport}
                      className="btn btn-primary btn-sm text-white mb-0 me-0 alignbtn export-approval-couple"
                      type="button"
                    >
                      Export
                    </button>
                  ) : null}
                </div>
              </div>

              {incommingRetreats && incommingRetreats.length ? (
                <>
                  <div className="heading-approval-retreat">
                    {retreatHeading && retreatHeading !== "" ? (
                      <h4 className="card-title">
                        {`${retreatHeading.title} ${
                          moment(retreatHeading.dateFrom)
                            .tz("America/New_York")
                            .format("MMM DD") +
                          " - " +
                          moment(retreatHeading.dateTo)
                            .tz("America/New_York")
                            .format("MMM DD") +
                          ", " +
                          moment(retreatHeading.dateFrom)
                            .tz("America/New_York")
                            .format("YYYY")
                        }`}
                      </h4>
                    ) : null}
                  </div>
                  {/* {selectedCouples && selectedCouples.length ? (
                    <div className="approve-button">
                      <button
                        onClick={openRetApproveAlert}
                        className="btn btn-primary btn-sm text-white mb-0 me-0 alignbtn export-approval-couple"
                        type="button"
                      >
                        Approve
                      </button>
                    </div>
                  ) : null} */}
                  <CustomDataTable
                    loading={LoadData}
                    data={notApprovedCouplesData}
                    columns={columns}
                    total={total}
                    countPerPage={20}
                    isServerSide={true}
                    pagination={true}
                    sortServer={true}
                    dataFetchApi={retreatRegisteredCouples}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    selectedRetreat={selectedRetreat}
                  />
                </>
              ) : (
                <div
                  className="container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4>There are no new registrations yet.</h4>
                </div>
              )}
            </div>
          </div>
          {showRegModal ? renderModel() : null}
          {deleteAlert ? (
            <AlertModal
              show={deleteAlert}
              handleClose={closeDeleteAlert}
              message={`Are you sure you want to delete couple.`}
              actionFunction={deleteCouples}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default RetreatRegistrations;
