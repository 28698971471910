import React from "react";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/forgotPassword";
import EmailVerification from "../pages/auth/emailVerify";
import { Navigate, useRoutes } from "react-router-dom";
import ChangePassword from "../pages/auth/changePassword";
import CoupleUpdate from "../pages/common/coupleUpdate";
import ThankYou from "../pages/common/thankYou";
import { RegistrationRetreat } from "../pages/auth/registrationRetreat";
import VerifiedThankYou from "../pages/auth/verifiedThankYou";
import Directory from "../pages/auth/Directory";
import Program from "../pages/auth/Program";

const AuthRouter = ({ updatedUser }) => {
  const route = useRoutes([
    { path: "/login", element: <Login updatedUser={updatedUser} /> },
    { path: "/forgetPassword", element: <ForgotPassword /> },
    { path: "/changePassword", element: <ChangePassword /> },
    { path: "/email-verification", element: <EmailVerification /> },
    { path: "/couple-update/:coupleId", element: <CoupleUpdate /> },
    { path: "/registration-retreat", element: <RegistrationRetreat /> },
    { path: "/thankyou", element: <ThankYou /> },
    { path: "/verified-thank-You", element: <VerifiedThankYou /> },
    { path: "/directory/:id", element: <Directory /> },
    { path: "/program/:id", element: <Program /> },
    { path: "/*", element: <Navigate to="/login" /> },
    { path: "/registration-retreat/:id", element: <RegistrationRetreat/> },
  ]);
  return <>{route}</>;
};
export default AuthRouter;
