import BaseApi from "./BaseApi";

class QuickReportApi extends BaseApi {

  async searchCounts (query) {
    return await this.API({
      url:`/quickReport/searchCounts`,
      method: "post",
      data:query
    })
  }

}

export default QuickReportApi