import React, { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const Dashboard = () => {
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  return (
    <div className="container overflow-hidden text-center">
      {userData && userData.userRole === "ADMIN" ? (
        <div className="row gy-5">
          <div className="col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/quickReport">
                {" "}
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md dashbtn"
                >
                  Quick Reports
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/search_data">
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md  dashbtn"
                >
                  Advanced Search
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/manageCouples">
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md  dashbtn"
                >
                  Manage Couples Data
                </button>
              </Link>
            </div>
          </div>
          <div className=" col-lg-4 col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/manageRetreat">
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md  dashbtn "
                >
                  Upload Retreats
                </button>
              </Link>{" "}
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/sendEmail">
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md  dashbtn"
                >
                  Broadcast Emails
                </button>
              </Link>{" "}
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <a
                href="https://lovestrongmarriage.com/?page_id=6"
                target="_blank"
              >
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md dashbtn"
                >
                  Help
                </button>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="row gy-5">
          <div className="col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/quickReport">
                {" "}
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md dashbtn"
                >
                  Quick Reports
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/search_data">
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md  dashbtn"
                >
                  Advanced Search
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/manageCouples">
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md  dashbtn"
                >
                  View Couples Data
                </button>
              </Link>
            </div>
          </div>
          <div className=" col-lg-4 col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <Link to="/manageRetreat">
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md  dashbtn "
                >
                  View Retreats
                </button>
              </Link>{" "}
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="p-3">
              {" "}
              <a
                href="https://lovestrongmarriage.com/?page_id=6"
                target="_blank"
              >
                <button
                  type="button"
                  className="btn btn-primary btn-fw btn-rect-md dashbtn"
                  target="_blank"
                >
                  Help
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
