import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthApi from "../../api/authApi";

const EmailVerification = () => {
  let api = new AuthApi();
  let navigate = new useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const id = searchParams.get("code");
    if (id) {
      handleVerification(id);
    } else {
      navigate("/login");
    }
  }, []);

  const handleVerification = async (code) => {
    if (code) {
      await api
        .verifyEmail({ code })
        .then((response) => {
          if (response.success) {
            toast.success(response.msg);
            navigate("/verified-thank-You");
          } else {
            toast.error(response.msg);
            navigate("/login");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div className="row flex-grow">
            `{" "}
            {/* <div className="col-lg-12 login-half-bg d-flex flex-row">
              <p className="text-white font-weight-semibold text-center flex-grow align-self-end"></p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
