import React, { useState, useEffect } from "react";
import {
  Form,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Row,
  Col,
  Popover,
} from "react-bootstrap";
import AdminApi from "../../api/admin";
import { toast } from "react-toastify";
import CustomDataTable from "../../components/CustomDataTable";
import Helpers from "../../components/Helpers";
import DeleteModel from "../../components/DeleteModel";
import { Cookies, useCookies } from "react-cookie";
import { utils, writeFile } from "xlsx";
import AlertModal from "../../components/AlertModel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Profile from "../../assets/images/couple-default.png";
import AuthApi from "../../api/authApi";
import UpdateCouplePopUp from "../common/UpdateCouplePopUp";
const XLSX = require("xlsx");

const ManageCouples = () => {
  const [validated, setValidated] = useState(false);
  const [showRegModal, setShowREgModal] = useState(false);
  const [isTempUpload, setIsTempUpload] = useState(false);
  const [data, setData] = useState({
    id: "",
    hisFirstName: "",
    hisLastName: "",
    hisEmail: "",
    hisMobile: "",
    herFirstName: "",
    herLastName: "",
    herEmail: "",
    herMobile: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    imageUrl: "",
    // marriageImageUrl: "",
    parishId: null,
    address: "",
    allergies: "",
    anniversary_date: "",
    under_age_35: "NO",
    emergency_name1: "",
    emergency_relationship1: "",
    emergency_phone1: "",
    emergency_name2: "",
    emergency_relationship2: "",
    emergency_phone2: "",
    hear_us: [],
    referral_from_a_friend_or_family_member: "",
  });
  const [allCoupleData, setAllCoupleData] = useState([]);
  const [ediUserIdClicked, setEdiUserIdClicked] = useState("");
  const [total, setTotal] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [show, setShow] = useState(false);
  const [icon_id, setIcon_id] = useState("");
  let adminApi = new AdminApi();
  let authApi = new AuthApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const [cookie, setCookie] = useCookies(["LoveStrongUser"]);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [changeText, setChangeText] = useState("");
  const [LoadData, setLoadData] = useState(false);
  const [roastersOfCoupleList, setRoastersOfCoupleList] = useState([]);
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [parishList, setParishList] = useState([]);
  const [roasterCount, setRoasterCount] = useState("");
  const [oldValues, setOldValues] = useState([]);
  const [makeSubAdminValue, setMakeSubAdminValue] = useState({
    value: "",
    coupleId: "",
    adminType: "",
  });
  const [alreadyPk, setAlreadyPk] = useState();
  const [coupleRepeatMsg, setCoupleRepeatMsg] = useState("");

  useEffect(() => {
    getAllCouples();
    getParishList();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Manage Couples page.",
      action: "view",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cookie]);
  useEffect(() => {}, [cookie]);

  const checkPKCouple = async () => {
    await authApi
      .checkPKCouple()
      .then((res) => {
        if (res.success) {
          if (res.data != null) {
            let incPK = res.data + 1;
            setAlreadyPk(incPK);
            let d = { ...data };
            d.primaryKey = incPK;
            setData(d);
          } else {
            let defaultPK = 1001;
            setAlreadyPk(defaultPK);
            let d = { ...data };
            d.primaryKey = defaultPK;
            setData(d);
          }
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getParishList = async () => {
    setLoadData(true);
    await authApi
      .getPerishList()
      .then((response) => {
        const sortedList = response.data.sort((a, b) =>
          a.parish.localeCompare(b.parish)
        );
        setParishList(sortedList);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const clearfield = () => {
    setData({
      id: "",
      hisFirstName: "",
      hisLastName: "",
      hisEmail: "",
      hisMobile: "",
      herFirstName: "",
      herLastName: "",
      herEmail: "",
      herMobile: "",
      city: "",
      state: "",
      zip: "",
      notes: "",
      imageUrl: "",
      // marriageImageUrl: "",
      parishId: null,
      address: "",
      allergies: "",
      anniversary_date: "",
      under_age_35: false,
      emergency_name1: "",
      emergency_relationship1: "",
      emergency_phone1: "",
      emergency_name2: "",
      emergency_relationship2: "",
      emergency_phone2: "",
      hear_us: [],
      referral_from_a_friend_or_family_member: "",
    });
    setValidated(false);
  };

  const getAllCouples = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    loadCount = false
  ) => {
    setLoadData(true);
    let d = { userRole: "COUPLE" };
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = searchKey;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = false;
    await adminApi
      .getAllCouples(d)
      .then((res) => {
        if (res.success) {
          setLoadData(false);
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
          setTotal(totalRecords);
          setAllCoupleData(res.data);
        } else {
          setLoadData(false);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error);
      });
  };

  const allCouples = async () => {
    getAllCouples();
  };

  const missingCouples = async () => {
    await adminApi
      .getMissingData()
      .then((res) => {
        if (res.success) {
          setAllCoupleData(res.data);
          setTotal(res.totalRecords);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const handleSearchChanges = async (e) => {
    let { name, value } = e.target;
    if (e.target.value === "0") {
      allCouples();
      let report = {
        byWhom:
          `${userData && userData.userName ? userData.userName : null} ` +
          ` (${userData && userData.userRole ? userData.userRole : null})`,
        pagelink: window.location.href,
        description: `Viewed all couples data by select the "All Couple" option`,
        action: "Click",
      };
      await adminApi
        .addLogReport(report)
        .then((response) => {
          if (response.success) {
            console.log("Created new log report.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (e.target.value === "1") {
      let report = {
        byWhom:
          `${userData && userData.userName ? userData.userName : null} ` +
          ` (${userData && userData.userRole ? userData.userRole : null})`,
        pagelink: window.location.href,
        description: `Viewed Missing Couple data by select the "Missing Data" option`,
        action: "Click",
      };
      await adminApi
        .addLogReport(report)
        .then((response) => {
          if (response.success) {
            console.log("Created new log report.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      missingCouples();
    }
  };

  const getroastersOfCouple = async (id) => {
    await adminApi
      .participatedInRetreats({ couple_Id: id })
      .then(async (response) => {
        setRoastersOfCoupleList(response.data);
        setRoasterCount(response.totalRecords);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const editCouple = async (id) => {
    setIsTempUpload(false);
    // setIsTempUpload2(false);
    setEdiUserIdClicked(id);
    let d = { ...data };
    await adminApi
      .getCouplesDetail({ coupleId: id })
      .then(async (res) => {
        if (
          res.data[0].hisEmail === "tkolkebeck@me.com" ||
          res.data[0].herEmail === "tkolkebeck@satx.rr.com" ||
          res.data[0].hisEmail === "jimmieflores@gmail.com" ||
          res.data[0].herEmail === "dulceaflores@gmail.com"
        ) {
          setIsEditAdmin(true);
        }
        if (res.success) {
          d.id = res.data[0].id;
          d.primaryKey = res.data[0].primaryKey;
          d.hisFirstName = res.data[0].hisFirstName;
          d.hisLastName = res.data[0].hisLastName;
          d.hisEmail = res.data[0].hisEmail;
          d.hisMobile = res.data[0].hisMobile;
          d.herFirstName = res.data[0].herFirstName;
          d.herLastName = res.data[0].herLastName;
          d.herEmail = res.data[0].herEmail;
          d.herMobile = res.data[0].herMobile;
          d.city = res.data[0].city;
          d.state = res.data[0].state;
          d.zip = res.data[0].zip;
          d.notes = res.data[0].notes;
          d.parishId = res.data[0].parishId;
          d.imageUrl = res.data[0].imageUrl;
          // d.marriageImageUrl = res.data[0].marriageImageUrl;
          d.address = res.data[0].address;
          d.allergies = res.data[0].allergies;
          d.anniversary_date = res.data[0].anniversary_date
            ? new Date(res.data[0].anniversary_date)
            : "";
          d.under_age_35 = res.data[0].under_age_35;
          d.emergency_name1 = res.data[0].emergency_name1;
          d.emergency_relationship1 = res.data[0].emergency_relationship1;
          d.emergency_phone1 = res.data[0].emergency_phone1;
          d.emergency_name2 = res.data[0].emergency_name2;
          d.emergency_relationship2 = res.data[0].emergency_relationship2;
          d.emergency_phone2 = res.data[0].emergency_phone2;
          d.hear_us = res.data[0].hear_us;
          d.referral_from_a_friend_or_family_member =
            res.data[0].referral_from_a_friend_or_family_member;
          setData(d);
          setOldValues(res.data[0]);
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Checked Detail of Couple (${d && d.primaryKey} - ${
              res && res.data && res.data[0] && res.data[0].herFirstName
            } ${res && res.data && res.data[0] && res.data[0].herLastName})`,
            action: "View",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("New log report created.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const deleteCouple = async () => {
    await adminApi
      .deleteCouple({ couple_id: icon_id })
      .then(async (res) => {
        if (res.success) {
          getAllCouples();
          setShow(false);
          setIcon_id("");
          checkPKCouple();
          toast.success(res.msg);
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description:
              `Deleted Couple` +
              ` ( ${data && data.primaryKey ? `${data.primaryKey} - ` : ""}` +
              `${data && data.hisLastName ? `${data.hisLastName}` : ""}` +
              `${data && data.hisFirstName ? `, ${data.hisFirstName}` : ""}` +
              `${
                data && data.herFirstName ? ` and  ${data.herFirstName}` : ""
              } )`,
            action: "Delete",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Created new log report.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          checkPKCouple();
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const popover = (row) => {
    return (
      <Popover id="popover-ImgPk" bsPrefix="popover">
        <Popover.Body>
          <div style={{ textAlign: "center", padding: "10px 0px" }}>
            {row.imageUrl ? (
              <span>
                <img src={row.imageUrl} className="column-rowImagePopover" />
              </span>
            ) : (
              <img src={Profile} className="column-rowImagePopover" />
            )}
            <h6 className="couplename">
              {" "}
              {row &&
                row.hisLastName +
                  ", " +
                  row.hisFirstName +
                  " and " +
                  row.herFirstName}
            </h6>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const columns = [
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: "action",
            name: "Edit",
            button: true,
            cell: (row) => (
              <>
                <button
                  className="font-size"
                  onClick={() => {
                    editCouple(row.id);
                    setShowREgModal(true);
                    getroastersOfCouple(row.id);
                  }}
                >
                  <i
                    className="mdi mdi-pencil-box text-lg text-primary font-size"
                    title="Edit"
                  ></i>
                </button>
                <button className="font-size" onClick={() => handleShow(row)}>
                  <i
                    className="mdi mdi-delete-forever text-md text-danger mx-2 font-size"
                    title="Delete"
                  ></i>
                </button>
              </>
            ),
            reorder: true,
          },
        ]
      : []),
    {
      id: "primaryKey",
      name: "Couple Id",
      selector: (row) => row.primaryKey,
      cell: (row) => (
        <OverlayTrigger placement="right" overlay={popover(row)}>
          {row.imageUrl ? (
            <span
              onClick={() => {
                editCouple(row.id);
                setShowREgModal(true);
                getroastersOfCouple(row.id);
              }}
            >
              <img src={row.imageUrl} className="column-rowImage" />
              {row.primaryKey && row.primaryKey}
            </span>
          ) : (
            <span
              onClick={() => {
                editCouple(row.id);
                setShowREgModal(true);
                getroastersOfCouple(row.id);
              }}
            >
              <img src={Profile} className="column-rowImage" />
              {row.primaryKey && row.primaryKey}
            </span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: "hisLastName",
      name: "His Last Name",
      selector: (row) => row.hisLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisLastName)}
        >
          <span
            onClick={() => {
              editCouple(row.id);
              setShowREgModal(true);
              getroastersOfCouple(row.id);
            }}
          >
            {row.hisLastName &&
              row.hisLastName.slice(0, 10) +
                (row.hisLastName.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "hisFirstName",
      name: "His First Name",
      selector: (row) => row.hisFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisFirstName)}
        >
          <span
            onClick={() => {
              editCouple(row.id);
              setShowREgModal(true);
              getroastersOfCouple(row.id);
            }}
          >
            {row.hisFirstName &&
              row.hisFirstName.slice(0, 10) +
                (row.hisFirstName.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "hisEmail",
      name: "His Email",
      selector: (row) => row.hisEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisEmail)}
        >
          <a href={`mailto:${row.herEmail}`}>
            <span>
              {row.hisEmail &&
                row.hisEmail.slice(0, 12) +
                  (row.hisEmail.length > 12 ? "..." : "")}
            </span>
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      style: {
        backgroundColor: "#E0F8FE",
        cursor: "text",
      },
    },
    {
      id: "hisMobile",
      name: "His Mobile",
      selector: (row) => row.hisMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisMobile)}
        >
          <span>
            {row.hisMobile &&
              row.hisMobile.slice(0, 10) +
                (row.hisMobile.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#E0F8FE",
        cursor: "text",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "herLastName",
      name: "Her Last Name",
      selector: (row) => row.herLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herLastName)}
        >
          <span
            onClick={() => {
              editCouple(row.id);
              setShowREgModal(true);
              getroastersOfCouple(row.id);
            }}
          >
            {row.herLastName &&
              row.herLastName.slice(0, 10) +
                (row.herLastName.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "herFirstName",
      name: "Her First Name",
      selector: (row) => row.herFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herFirstName)}
        >
          <span
            onClick={() => {
              editCouple(row.id);
              setShowREgModal(true);
              getroastersOfCouple(row.id);
            }}
          >
            {row.herFirstName &&
              row.herFirstName.slice(0, 10) +
                (row.herFirstName.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "herEmail",
      name: "Her Email",
      selector: (row) => row.herEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herEmail)}
        >
          <a href={`mailto:${row.herEmail}`}>
            <span>
              {row.herEmail &&
                row.herEmail.slice(0, 12) +
                  (row.herEmail.length > 12 ? "..." : "")}
            </span>
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "herMobile",
      name: "Her Mobile",
      selector: (row) => row.herMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herMobile)}
        >
          <span>
            {row.herMobile &&
              row.herMobile.slice(0, 10) +
                (row.herMobile.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#FFEDFB",
        cursor: "text",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "Address",
      name: "Address",
      selector: (row) => row.address,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.address)}
        >
          <span>
            {row.address &&
              row.address.slice(0, 10) + (row.address.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Parish",
      name: "Parish",
      selector: (row) => row.parish,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.parish)}
        >
          <span>{row.parish}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.city)}
        >
          <span>
            {row.city &&
              row.city.slice(0, 10) + (row.city.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.state)}
        >
          <span>
            {row.state &&
              row.state.slice(0, 10) + (row.state.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "zip",
      name: "Zip",
      selector: (row) => row.zip,
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Anniversary Date",
      name: "Anniversary Date",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.anniversary_date, "M/D/YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.anniversary_date, "M/D/YYYY")
          )}
        >
          <span>
            {row.anniversary_date
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "M/D/YYYY")
              : "--"}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    /*{
      id: "Dietary restrictions or physical limitations",
      name: "Dietary restrictions or physical limitations",
      selector: (row) => row.allergies,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.allergies)}
        >
          <span>
            {row.allergies &&
              row.allergies.slice(0, 10) +
                (row.allergies.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name1",
      name: "Emergency Name1",
      selector: (row) => row.emergency_name1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name1)}
        >
          <span>
            {row.emergency_name1 &&
              row.emergency_name1.slice(0, 12) +
                (row.emergency_name1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship1",
      name: "Emergency Relationship1",
      selector: (row) => row.emergency_relationship1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship1)}
        >
          <span>
            {row.emergency_relationship1 &&
              row.emergency_relationship1.slice(0, 12) +
                (row.emergency_relationship1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone1",
      name: "Emergency Phone1",
      selector: (row) => row.emergency_phone1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone1)}
        >
          <span>
            {row.emergency_phone1 &&
              row.emergency_phone1.slice(0, 12) +
                (row.emergency_phone1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name2",
      name: "Emergency Name2",
      selector: (row) => row.emergency_name2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name2)}
        >
          <span>
            {row.emergency_name2 &&
              row.emergency_name2.slice(0, 12) +
                (row.emergency_name2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship2",
      name: "Emergency Relationship2",
      selector: (row) => row.emergency_relationship2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship2)}
        >
          <span>
            {row.emergency_relationship2 &&
              row.emergency_relationship2.slice(0, 12) +
                (row.emergency_relationship2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone2",
      name: "Emergency Phone2",
      selector: (row) => row.emergency_phone2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone2)}
        >
          <span>
            {row.emergency_phone2 &&
              row.emergency_phone2.slice(0, 12) +
                (row.emergency_phone2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Hear about us",
      name: "Hear about us",
      selector: (row) => row.hear_us,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
          )}
        >
          <span>
            {row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
                .slice(0, 12) + (row.hear_us.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Referral from a friend/Family member",
      name: "Referral from a friend/Family member",
      selector: (row) => row.referral_from_a_friend_or_family_member,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.referral_from_a_friend_or_family_member)}
        >
          <span>
            {row.referral_from_a_friend_or_family_member &&
              row.referral_from_a_friend_or_family_member.slice(0, 12) +
                (row.referral_from_a_friend_or_family_member.length > 12
                  ? "..."
                  : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Under Age 35",
      name: "Under Age 35",
      selector: (row) => row.under_age_35,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.under_age_35)}
        >
          <span>
            {row.under_age_35 &&
              row.under_age_35.slice(0, 12) +
                (row.under_age_35.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Notes",
      name: "Notes",
      selector: (row) => row.notes,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.notes)}
        >
          <span>
            {row.notes &&
              row.notes.slice(0, 10) + (row.notes.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },*/
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "DD-MM-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    ...(userData && userData.userRole === "SUBADMIN"
      ? [
          {
            id: "status",
            name: "Status",
            selector: (row) =>
              row.accountStatus.charAt(0).toUpperCase() +
              row.accountStatus.slice(1),
            reorder: true,
          },
        ]
      : []),
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: "status",
            name: "Status",
            selector: (row) =>
              row.accountStatus.charAt(0).toUpperCase() +
              row.accountStatus.slice(1),
            cell: (row) => (
              <Form.Select
                size="sm"
                value={row.accountStatus}
                onChange={(e) => changeStatus(row.id, e.target.value)}
                className="selectData"
              >
                <option value="ACTIVE">Active</option>
                <option value="DECEASED">Deceased</option>
                <option value="DIVORCED">Divorced</option>
                <option value="INACTIVE">Inactive</option>
              </Form.Select>
            ),
            reorder: true,
          },
          {
            id: "makeSubAdmin",
            name: "Sub Admin?",
            selector: (row) => {
              if (
                row.accountStatus === "ACTIVE" &&
                (row.hisEmail || row.herEmail)
              ) {
                if (
                  row.hisEmail !== "tkolkebeck@me.com" &&
                  row.herEmail !== "tkolkebeck@satx.rr.com" &&
                  row.hisEmail !== "jimmieflores@gmail.com" &&
                  row.herEmail !== "dulceaflores@gmail.com"
                ) {
                  return (
                    <Form.Check
                      className="switchSize"
                      type="switch"
                      name="makeCoupleAsSubAdmin"
                      value={row.isAdminRight}
                      checked={row.isAdminRight}
                      onChange={(e) => {
                        handleShow1(e.target.value, row, "SUBADMIN");
                      }}
                      id="makeCoupleAsSubAdmin"
                    />
                  );
                }
              }
            },
            reorder: true,
          },
          {
            id: "makeAdmin",
            name: "Super Admin?",
            selector: (row) => {
              if (
                row.accountStatus === "ACTIVE" &&
                (row.userRole === "SUBADMIN" || row.userRole === "ADMIN")
              ) {
                if (
                  row.hisEmail !== "tkolkebeck@me.com" &&
                  row.herEmail !== "tkolkebeck@satx.rr.com" &&
                  row.hisEmail !== "jimmieflores@gmail.com" &&
                  row.herEmail !== "dulceaflores@gmail.com"
                ) {
                  return (
                    <Form.Check
                      className="switchSize"
                      type="switch"
                      name="makeCoupleAsAdmin"
                      value={row.userRole === "ADMIN" ? 1 : 0}
                      checked={row.userRole === "ADMIN" ? 1 : 0}
                      onChange={(e) => {
                        handleShow2(e.target.value, row, "ADMIN");
                      }}
                      id="makeCoupleAsAdmin"
                    />
                  );
                }
              }
            },
            reorder: true,
          },
        ]
      : []),
  ];

  const changeStatus = (id, value) => {
    adminApi
      .changeStatus({ id: id, value: value })
      .then((res) => {
        getAllCouples();
        let report = {
          byWhom:
            `${userData && userData.userName ? userData.userName : null} ` +
            ` (${userData && userData.userRole ? userData.userRole : null})`,
          pagelink: window.location.href,
          description:
            `Changed Status of Couple ` +
            `(${
              res && res.data && res.data.primaryKey
                ? `${res.data.primaryKey} - `
                : ""
            }` +
            `${
              res && res.data && res.data.hisLastName
                ? `${res.data.hisLastName}`
                : ""
            }` +
            `${
              res && res.data && res.data.hisFirstName
                ? `, ${res.data.hisFirstName}`
                : ""
            }` +
            `${
              res && res.data && res.data.herFirstName
                ? ` and  ${res.data.herFirstName}`
                : ""
            }), (status - ${value})`,
          action: "Change status",
        };
        adminApi
          .addLogReport(report)
          .then((response) => {
            if (response.success) {
              console.log("Created new log report.");
            }
          })
          .catch((error) => {
            console.log(error);
          });

        toast.success(res.msg);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const makeCoupleAsSubAdmin = () => {
    if (
      makeSubAdminValue &&
      makeSubAdminValue.value !== "" &&
      makeSubAdminValue.coupleId !== ""
    ) {
      let value;
      if (makeSubAdminValue.value === "1") {
        value = 0;
      } else {
        value = 1;
      }
      adminApi
        .makeCoupleAsSubAdmin({
          value: value,
          id: makeSubAdminValue.coupleId,
          adminType: makeSubAdminValue.adminType,
        })
        .then((res) => {
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `${res.msg} ( id : ${makeSubAdminValue.coupleId} )`,
          };
          adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Added new log report");
              }
            })
            .catch((error) => {
              console.log(error);
            });

          getAllCouples();
          toast.success(res.msg);
          handleClose1();
          handleClose2();
        })
        .catch((error) => {
          toast.error(error);
          let d = { ...makeSubAdminValue };
          d.value = "";
          d.coupleId = "";
          d.adminType = "";
          setMakeSubAdminValue(d);
        });
    }
  };

  const handleShow1 = (value, row, adminType) => {
    if (row.isAdminRight) {
      setChangeText("remove");
    } else {
      setChangeText("");
    }
    setShow1(true);
    let d = { ...makeSubAdminValue };
    d.value = value;
    d.coupleId = row.id;
    d.adminType = adminType;
    setMakeSubAdminValue(d);
  };

  const handleShow2 = (value, row, adminType) => {
    if (row.isAdminRight && row.userRole == "ADMIN") {
      setChangeText("remove");
    } else {
      setChangeText("");
    }
    setShow2(true);
    let d = { ...makeSubAdminValue };
    d.value = value;
    d.coupleId = row.id;
    d.adminType = adminType;
    setMakeSubAdminValue(d);
  };

  const handleShow = (item) => {
    setShow(true);
    setIcon_id(item.id);
    let d = { ...data };
    d.primaryKey = item.primaryKey;
    d.hisFirstName = item.hisFirstName;
    d.hisLastName = item.hisLastName;
    d.herFirstName = item.herFirstName;
    setData(d);
  };
  const handleClose = () => setShow(false);

  const handleClose1 = () => {
    setChangeText("");
    setShow1(false);
    let d = { ...makeSubAdminValue };
    d.value = "";
    d.coupleId = "";
    d.adminType = "";
    setMakeSubAdminValue(d);
  };

  const handleClose2 = () => {
    setChangeText("");
    setShow2(false);
    let d = { ...makeSubAdminValue };
    d.value = "";
    d.coupleId = "";
    d.adminType = "";
    setMakeSubAdminValue(d);
  };

  const handleClose3 = () => {
    setShow3(false);
  };

  const CloseCompose = () => {
    setIsEditAdmin(false);
    setValidated(false);
    setShowREgModal(false);
    setEdiUserIdClicked("");
    clearfield();
  };

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      setSearchKey(event.target.value);
      getAllCouples(1, 20, event.target.value);
    } else {
      setSearchKey("");
      getAllCouples(1, 20, "");
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.accountStatus === "DECEASED" ||
        row.accountStatus === "DIVORCED" ||
        row.accountStatus === "INACTIVE",
      style: {
        backgroundColor: "#f8d7da",
        color: "#000",
        borderColor: "#dfc2c4",
      },
    },
  ];

  const handleExport = async () => {
    let d = { userRole: "COUPLE" };
    d.pageNo = 1;
    d.size = 20;
    d.export = true;
    await adminApi
      .getAllCouples(d)
      .then(async (res) => {
        if (res.success) {
          let exportData = [];
          res.data.forEach((element) => {
            exportData.push({
              primaryKey: element.primaryKey,
              hisFirstName: element.hisFirstName,
              hisLastName: element.hisLastName,
              hisEmail: element.hisEmail,
              hisMobile: element.hisMobile,
              herFirstName: element.herFirstName,
              herLastName: element.herLastName,
              herEmail: element.herEmail,
              herMobile: element.herMobile,
              address: element.address,
              city: element.city,
              state: element.state,
              zip: element.zip,
              parish: element.parish,
              Anniversary_date:
                element.anniversary_date !== null
                  ? Helpers.convertUtcToTimezone(
                      element.anniversary_date,
                      "MM-DD-YYYY"
                    )
                  : "",
              Dietary_restrictions_or_physical_limitations: element.allergies,
              Under_age_35: element.under_age_35,
              Emergency_name1: element.emergency_name1,
              Emergency_relationship1: element.emergency_relationship1,
              Emergency_phone1: element.emergency_phone1,
              Emergency_name2: element.emergency_name2,
              Emergency_relationship2: element.emergency_relationship2,
              Emergency_phone2: element.emergency_phone2,
              // How_did_you_hear_about_us: element.hear_us,
              Referral_from_a_friend_or_family_member:
                element.referral_from_a_friend_or_family_member,
              notes: element.notes,
            });
          });
          const headings = [
            [
              "Couple Id",
              "His First Name",
              "His Last Name",
              "His Email",
              "His Mobile",
              "Her First Name",
              "Her Last Name",
              "Her Email",
              "Her Mobile",
              "Address",
              "City",
              "State",
              "Zip",
              "Parish",
              "Anniversary Date",
              "Dietary Restrictions Or Physical Limitations",
              "Under Age 35",
              "Emergency Name1",
              "Emergency Relationship1",
              "Emergency Phone1",
              "Emergency Name2",
              "Emergency Relationship2",
              "Emergency Phone2",
              "How did you hear about us?",
              "Referral from a friend/family member",
              "Notes",
            ],
          ];
          const wb = utils.book_new();
          const ws = utils.json_to_sheet([]);
          utils.sheet_add_aoa(ws, headings);
          utils.sheet_add_json(ws, exportData, {
            origin: "A2",
            skipHeader: true,
          });
          utils.book_append_sheet(wb, ws, "Data");
          writeFile(wb, "Couple Data.xlsx");
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Exported Excel File of Couple data.`,
            action: "Click",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Created new log report.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const exportPdf = async () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "All Couples";
    const headers = [
      [
        "Couple Id",
        "His First Name",
        "His Last Name",
        "His Email",
        "His Mobile",
        "Her First Name",
        "Her Last Name",
        "Her Email",
        "Her Mobile",
        "Address",
        "City",
        "State",
        "Zip",
        "Parish",
        "Anniversary Date",
        "Dietary Restrictions Or Physical Limitations",
        "Under Age 35",
        "Emergency Name1",
        "Emergency Relationship1",
        "Emergency Phone1",
        "Emergency Name2",
        "Emergency Relationship2",
        "Emergency Phone2",
        "Referral from a friend/family member",
        "Notes",
      ],
    ];
    let d = { userRole: "COUPLE" };
    d.pageNo = 1;
    d.size = 20;
    d.export = true;
    await adminApi
      .getAllCouples(d)
      .then(async (res) => {
        if (res.success) {
          const data = res.data.map((element) => [
            element.primaryKey,
            element.hisFirstName,
            element.hisLastName,
            element.hisEmail,
            element.hisMobile,
            element.herFirstName,
            element.herLastName,
            element.herEmail,
            element.herMobile,
            element.address,
            element.city,
            element.state,
            element.zip,
            element.parish,
            element.anniversary_date !== null
              ? Helpers.convertUtcToTimezone(
                  element.anniversary_date,
                  "MM-DD-YYYY"
                )
              : "",
            element.allergies,
            element.under_age_35,
            element.emergency_name1,
            element.emergency_relationship1,
            element.emergency_phone1,
            element.emergency_name2,
            element.emergency_relationship2,
            element.emergency_phone2,
            element.referral_from_a_friend_or_family_member,
            element.notes,
          ]);

          let content = {
            head: headers,
            body: data,
            startY: 70,
            theme: "grid",
            tableLineColor: [189, 195, 199],
            tableLineWidth: 0.75,
            columnWidth: "wrap",
          };
          doc.text(title, marginLeft, 40);
          doc.autoTable(content);
          doc.save("report.pdf");
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Exported PDF file of Couple Data.`,
            action: "Click",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Created new log report.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  function isXLSXFile(file) {
    return file.name.endsWith(".xlsx");
  }

  const fileChangeListener = (event) => {
    const text = [];
    let files = [];
    files = event.target.files;
    if (files && files.length && isXLSXFile(files[0])) {
      const input = event.target;
      const reader = new FileReader();
      reader.readAsBinaryString(input.files[0]);

      reader.onload = (e) => {
        const exportData = [];
        const xlsxData = reader.result;
        processXLSXCoupleRecord(xlsxData);
      };
      reader.onerror = function () {
        alert("Unable to read " + input.files[0]);
      };
    } else {
      toast.error("Please import valid .xlsx file.");
    }
  };

  const processXLSXCoupleRecord = (xlsxRecordsArray) => {
    try {
      const workbook = XLSX.read(xlsxRecordsArray, { type: "binary" });
      const sheet_name_list = workbook.SheetNames;
      const data = [];
      sheet_name_list.forEach(function (y) {
        const worksheet = workbook.Sheets[y];
        const headers = {};
        for (let z in worksheet) {
          if (z[0] === "!") continue;
          // parse out the column, row, and value
          let tt = 0;
          for (let i = 0; i < z.length; i++) {
            if (!isNaN(z[i])) {
              tt = i;
              break;
            }
          }
          const col = z.substring(0, tt);
          const row = parseInt(z.substring(tt));
          const value = worksheet[z].v;
          // store header names
          if (row == 1 && value) {
            headers[col] = value;
            continue;
          }
          if (!data[row]) {
            data[row] = {};
          }
          data[row][headers[col]] = value;
        }
      });

      if (data && data.length) {
        let importedData = [];
        // if (!data[2]["Couple Id"]) {
        //   toast.error("Couple Id Header is missing in Excel");
        //   // setProcessing(false);
        //   return false;
        // }
        data.forEach(function (value, index) {
          const obj = {};
          // if (value["Couple Id"] == '') {
          //   toast.error("Couple Id value missing. Check near line " + index + 1);
          //   // setProcessing(false);
          //   return false;
          // }
          if (value["Couple Id"]) {
            // && value["His Last Name"] && value["His Email"] && value["His Mobile"] && value["Her First Name"] && value["Her Last Name"] && value["Her Email"] && value["Her Mobile"]
            obj.primaryKey = value["Couple Id"];
            obj.hisFirstName = value["His First Name"]
              ? value["His First Name"]
              : "";
            obj.hisLastName = value["His Last Name"]
              ? value["His Last Name"]
              : "";
            obj.hisEmail = value["His Email"] ? value["His Email"] : "";
            obj.hisMobile = value["His Mobile"] ? value["His Mobile"] : "";

            obj.herFirstName = value["Her First Name"]
              ? value["Her First Name"]
              : "";
            obj.herLastName = value["Her Last Name"]
              ? value["Her Last Name"]
              : "";
            obj.herEmail = value["Her Email"] ? value["Her Email"] : "";
            obj.herMobile = value["Her Mobile"] ? value["Her Mobile"] : "";
            obj.address = value["Address"] ? value["Address"] : "";
            obj.city = value["City"] ? value["City"] : "";
            obj.state = value["State"] ? value["State"] : "";
            obj.zip = value["Zip"] ? value["State"] : "";
            obj.anniversary_date = value["Anniversary Date"]
              ? value["Anniversary Date"]
              : "";
            obj.allergies = value[
              "Dietary Restrictions Or Physical Limitations"
            ]
              ? value["Dietary Restrictions Or Physical Limitations"]
              : "";
            obj.under_age_35 = value["Under Age 35"]
              ? value["Under Age 35"]
              : "";
            obj.emergency_name1 = value["Emergency Name1"]
              ? value["Emergency Name1"]
              : "";
            obj.emergency_relationship1 = value["Emergency Relationship1"]
              ? value["Emergency Relationship1"]
              : "";
            obj.emergency_phone1 = value["Emergency Phone1"]
              ? value["Emergency Phone1"]
              : "";
            obj.emergency_name2 = value["Emergency Name2"]
              ? value["Emergency Name2"]
              : "";
            obj.emergency_relationship2 = value["Emergency Relationship2"]
              ? value["Emergency Relationship2"]
              : "";
            obj.emergency_phone2 = value["Emergency Phone2"]
              ? value["Emergency Phone2"]
              : "";

            obj.hear_us = value["How did you hear about us?"]
              ? value["How did you hear about us?"]
              : "";

            obj.referral_from_a_friend_or_family_member = value[
              "Referral from a friend/family member"
            ]
              ? value["referral from a friend/family member"]
              : "";

            obj.notes = value["Notes"] ? value["Notes"] : "";
            if (value["Parish"]) {
              let findParish = parishList.find(
                (i) => i.parish == value["Parish"]
              );
              if (findParish && findParish !== undefined && findParish.id) {
                obj.parishId = findParish.id;
              } else {
                obj.parishId = "";
              }
            }
          } else {
            obj.primaryKey = "";
            obj.hisFirstName = value["His First Name"]
              ? value["His First Name"]
              : "";
            obj.hisLastName = value["His Last Name"]
              ? value["His Last Name"]
              : "";
            obj.hisEmail = value["His Email"] ? value["His Email"] : "";
            obj.hisMobile = value["His Mobile"] ? value["His Mobile"] : "";

            obj.herFirstName = value["Her First Name"]
              ? value["Her First Name"]
              : "";
            obj.herLastName = value["Her Last Name"]
              ? value["Her Last Name"]
              : "";
            obj.herEmail = value["Her Email"] ? value["Her Email"] : "";
            obj.herMobile = value["Her Mobile"] ? value["Her Mobile"] : "";
            obj.address = value["Address"] ? value["Address"] : "";
            obj.city = value["City"] ? value["City"] : "";
            obj.state = value["State"] ? value["State"] : "";
            obj.zip = value["Zip"] ? value["State"] : "";
            obj.anniversary_date = value["Anniversary Date"]
              ? value["Anniversary Date"]
              : "";
            obj.allergies = value[
              "Dietary Restrictions Or Physical Limitations"
            ]
              ? value["Dietary Restrictions Or Physical Limitations"]
              : "";
            obj.under_age_35 = value["Under Age 35"]
              ? value["Under Age 35"]
              : "";
            obj.emergency_name1 = value["Emergency Name1"]
              ? value["Emergency Name1"]
              : "";
            obj.emergency_relationship1 = value["Emergency Relationship1"]
              ? value["Emergency Relationship1"]
              : "";
            obj.emergency_phone1 = value["Emergency Phone1"]
              ? value["Emergency Phone1"]
              : "";
            obj.emergency_name2 = value["Emergency Name2"]
              ? value["Emergency Name2"]
              : "";
            obj.emergency_relationship2 = value["Emergency Relationship2"]
              ? value["Emergency Relationship2"]
              : "";
            obj.emergency_phone2 = value["Emergency Phone2"]
              ? value["Emergency Phone2"]
              : "";
            obj.hear_us = value["How did you hear about us?"]
              ? value["How did you hear about us?"]
              : "";
            obj.referral_from_a_friend_or_family_member = value[
              "Referral from a friend/family member"
            ]
              ? value["referral from a friend/family member"]
              : "";
            obj.notes = value["Notes"] ? value["Notes"] : "";
            if (value["Parish"]) {
              let findParish = parishList.find(
                (i) => i.parish == value["Parish"]
              );
              if (findParish && findParish !== undefined) {
                obj.parishId = findParish.id;
              } else {
                obj.parishId = "";
              }
            }
          }
          importedData.push(obj);
        });
        saveCoupleExcel(importedData);
      }
    } catch (err) {
      toast.error(err.toString());
    }
    // setProcessing(false);
  };

  const saveCoupleExcel = async (importedData) => {
    const chunkSize = 10000;
    for (let i = 0; i < importedData.length; i += chunkSize) {
      const chunk = importedData.slice(i, i + chunkSize);
      await adminApi
        .saveCoupleExcel(chunk)
        .then(async (response) => {
          if (response.success) {
            if (response && response.mismatch.length) {
              setShow3(true);
              let arrayId = response.mismatch.join(",");
              let msg = `These couple have mismatch record.
              - \n\t
              ${arrayId}.
              So, these record are not inserted.`;
              setCoupleRepeatMsg(msg);
            }
            document.getElementById("fileUpload").value = "";
            getAllCouples(1, 20, "");
            checkPKCouple();
            toast.success("File data imported successfully");
            if (response.createdCouple && response.createdCouple.length > 0) {
              await Promise.all(
                response.createdCouple.map(async (item) => {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,

                    description: `New Couple (${item.couple}) added through Excel file import`,
                    action: "Click",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
              );
            }
            if (response.updateCouple && response.updateCouple.length > 0) {
              await Promise.all(
                response.updateCouple.map(async (item) => {
                  if (item.oldherFirstName != item.newherFirstName) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated herFirstName from "${
                        item && item.oldherFirstName
                          ? item.oldherFirstName
                          : "Nil"
                      }" to "${
                        item && item.newherFirstName
                          ? item.newherFirstName
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldherLastName != item.newherLastName) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated herLastName from "${
                        item && item.oldherLastName
                          ? item.oldherLastName
                          : "Nil"
                      }" to "${
                        item && item.newherLastName
                          ? item.newherLastName
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldherEmail != item.newherEmail) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated herEmail from "${
                        item && item.oldherEmail ? item.oldherEmail : "Nil"
                      }" to "${
                        item && item.newherEmail ? item.newherEmail : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldherMobile != item.newherMobile) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated herMobile from "${
                        item && item.oldherMobile ? item.oldherMobile : "Nil"
                      }" to "${
                        item && item.newherMobile ? item.newherMobile : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhisFirstName != item.newhisFirstName) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated hisFirstName from "${
                        item && item.oldhisFirstName
                          ? item.oldhisFirstName
                          : "Nil"
                      }" to "${
                        item && item.newhisFirstName
                          ? item.newhisFirstName
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhisLastName != item.newhisLastName) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated hisLastName from "${
                        item && item.oldhisLastName
                          ? item.oldhisLastName
                          : "Nil"
                      }" to "${
                        item && item.newhisLastName
                          ? item.newhisLastName
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhisEmail != item.newhisEmail) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated hisEmail from "${
                        item && item.oldhisEmail ? item.oldhisEmail : "Nil"
                      }" to "${
                        item && item.newhisEmail ? item.newhisEmail : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhisMobile != item.newhisMobile) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated hisMobile from "${
                        item && item.oldhisMobile ? item.oldhisMobile : "Nil"
                      }" to "${
                        item && item.newhisMobile ? item.newhisMobile : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldcity != item.newcity) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated City from "${
                        item && item.oldcity ? item.oldcity : "Nil"
                      }" to "${
                        item && item.newcity ? item.newcity : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldstate != item.newstate) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated State from "${
                        item && item.oldstate ? item.oldstate : "Nil"
                      }" to "${
                        item && item.newcity ? item.newcity : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldzip != item.newzip) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated Zip from "${
                        item && item.oldzip ? item.oldzip : "Nil"
                      }" to "${
                        item && item.newzip ? item.newzip : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldnotes != item.newnotes) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated Notes from "${
                        item && item.oldnotes ? item.oldnotes : "Nil"
                      }" to "${
                        item && item.newnotes ? item.newnotes : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldparish != item.newparish) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated Parish from "${
                        item && item.oldparish ? item.oldparish : "Nil"
                      }" to "${
                        item && item.newparish ? item.newparish : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldaddress != item.newaddress) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated Address from "${
                        item && item.oldaddress ? item.oldaddress : "Nil"
                      }" to "${
                        item && item.newaddress ? item.newaddress : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldallergies != item.newallergies) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's columns on "dietary restrictions or physical limitations" from "${
                        item && item.oldallergies ? item.oldallergies : "Nil"
                      }" to "${
                        item && item.newallergies ? item.newallergies : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldanniversary_date != item.newanniversary_date) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Anniversary Date from "${
                        item && item.oldanniversary_date
                          ? item.oldanniversary_date
                          : "Nil"
                      }" to "${
                        item && item.newanniversary_date
                          ? item.newanniversary_date
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhear_us != item.newhear_us) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }):Updated Couple's column on "Under Age 35" from "${
                        item && item.oldhear_us ? item.oldhear_us : "Nil"
                      }" to "${
                        item && item.newhear_us ? item.newhear_us : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldemergency_name1 != item.newemergency_name1) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }):Updated Couple's column on "Emergency Name1" from "${
                        item && item.oldemergency_name1
                          ? item.oldemergency_name1
                          : "Nil"
                      }" to "${
                        item && item.newemergency_name1
                          ? item.newemergency_name1
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (
                    item.oldemergency_relationship1 !=
                    item.newemergency_relationship1
                  ) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Relationship1" from  "${
                        item && item.oldemergency_relationship1
                          ? item.oldemergency_relationship1
                          : "Nil"
                      }" to "${
                        item && item.newemergency_relationship1
                          ? item.newemergency_relationship1
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldemergency_phone1 != item.newemergency_phone1) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Phone1"  from "${
                        item && item.oldemergency_phone1
                          ? item.oldemergency_phone1
                          : "Nil"
                      }" to "${
                        item && item.newemergency_phone1
                          ? item.newemergency_phone1
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldemergency_name2 != item.newemergency_name2) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Name2" from "${
                        item && item.oldemergency_name2
                          ? item.oldemergency_name2
                          : "Nil"
                      }" to "${
                        item && item.newemergency_name2
                          ? item.newemergency_name2
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (
                    item.oldemergency_relationship2 !=
                    item.newemergency_relationship2
                  ) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Relationship2"  from "${
                        item && item.oldemergency_relationship2
                          ? item.oldemergency_relationship2
                          : "Nil"
                      }" to "${
                        item && item.newemergency_relationship2
                          ? item.newemergency_relationship2
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldemergency_phone2 != item.newemergency_phone2) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Phone2" from "${
                        item && item.oldemergency_phone2
                          ? item.oldemergency_phone2
                          : "Nil"
                      }" to "${
                        item && item.newemergency_phone2
                          ? item.newemergency_phone2
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldunder_age_35 != item.newunder_age_35) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Hear about us" from "${
                        item && item.oldunder_age_35
                          ? item.oldunder_age_35
                          : "Nil"
                      }" to "${
                        item && item.newunder_age_35
                          ? item.newunder_age_35
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (
                    item.oldreferral_from_a_friend_or_family_member !=
                    item.newreferral_from_a_friend_or_family_member
                  ) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Referral from a friend/Family member" from "${
                        item && item.oldreferral_from_a_friend_or_family_member
                          ? item.oldreferral_from_a_friend_or_family_member
                          : "Nil"
                      }" to "${
                        item && item.newreferral_from_a_friend_or_family_member
                          ? item.newreferral_from_a_friend_or_family_member
                          : "Nil"
                      }" through Excel file import.`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                })
              );
            }
          } else {
            document.getElementById("fileUpload").value = "";
            getAllCouples(1, 20, "");
            checkPKCouple();
            toast.error(response.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There is an error!", error);
        });
    }
  };

  const clickOnInputFile = () => {
    document.getElementById("fileUpload").click();
  };

  const downloadSampleExcel = async () => {
    const headings = [
      [
        "Couple Id",
        "His First Name",
        "His Last Name",
        "His Email",
        "His Mobile",
        "Her First Name",
        "Her Last Name",
        "Her Email",
        "Her Mobile",
        "Address",
        "City",
        "State",
        "Zip",
        "Parish",
        "Anniversary Date",
        "Dietary Restrictions Or Physical Limitations",
        "Under Age 35",
        "Emergency Name1",
        "Emergency Relationship1",
        "Emergency Phone1",
        "Emergency Name2",
        "Emergency Relationship2",
        "Emergency Phone2",
        "How did you hear about us?",
        "Referral from a friend/family member",
        "Notes",
      ],
    ];
    const data = [
      {
        primaryKey: "0001",
        hisLastName: "Paul",
        hisFirstName: "Sam",
        hisEmail: "sampaul@gmail.com",
        hisMobile: "210-323-0384",
        herLastName: "Adams",
        herFirstName: "Charisse",
        herEmail: "charisseAdams@gmail.com",
        herMobile: "210-459-4955",
        address: "101 Balistreri Overpass, Cummerataland, KY 42976",
        city: "Los Angeles",
        state: "California",
        zip_code: "123456",
        parish: "Holy Spirit",
        ainniversary_date: "04-02-2000",
        dietary_restrictions_or_physical_limitations:
          "Dietary restrictions or physical limitations",
        under_age_35: "NO",
        emergency_name_1: "cris",
        emergency_relationship_1: "friend",
        emergency_phone_1: "6445-7645-6786",
        emergency_name_2: "Eric",
        emergency_relationship_2: "Brother",
        emergency_phone_2: "1425-7645-6786",
        hear_about_us: "School Marquee",
        Referral_from_a_friend_or_Family_member: "sister",
        notes: "Your notes here.",
      },
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, "Sample Couple File.xlsx");
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: `Downloaded Sample Excel file of Couples data.`,
      action: "Click",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="dropdata">
        <select
          className="form-control minimal "
          onChange={(e) => handleSearchChanges(e)}
        >
          <option value="0">All Couples</option>
          <option value="1">Missing Data</option>
        </select>
      </div>
      <div className="row ext-space">
        <div className="col-lg-12 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col-12 grid-margin stretch-card">
              <div className="card custom-card">
                <div className="card-body ">
                  <Row className="m-0">
                    <Col md={3} sm={6} xs={4}>
                      <h4 className="card-title card_head">Couples: {total}</h4>
                    </Col>
                    <Col md={4} sm={6} xs={8}>
                      <div className="form-group content1 ">
                        <div className="input-group setSearch">
                          <input
                            onChange={SearchData}
                            type="text"
                            className="form-control searchData"
                            placeholder="Search..."
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                            >
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={5} sm={12} xs={12}>
                      {userData && userData.userRole === "ADMIN" ? (
                        <div className="import_buttons content1">
                          <a
                            onClick={downloadSampleExcel}
                            className=" btn me-3 set_text1"
                          >
                            (Download Sample Excel)
                          </a>
                          <div className="import_drop">
                            <a
                              onClick={clickOnInputFile}
                              className="btn btn-outline-primary btn-sm me-3 impbtn import_button"
                            >
                              <i className="icon-upload "></i> Import
                            </a>
                            <input
                              className="btn btn-secondary"
                              type="file"
                              name="File Upload"
                              id="fileUpload"
                              hidden
                              onChange={fileChangeListener}
                              accept=".xlsx"
                            />
                            <Dropdown className="dropset">
                              <Dropdown.Toggle
                                className="btn btn-outline-secondary btn-sm mt-2"
                                id="dropdown-basic"
                              >
                                <i className="icon-download"></i>
                                Export
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <a onClick={handleExport}>
                                  <Dropdown.Item>Excel</Dropdown.Item>
                                </a>
                                <a onClick={exportPdf}>
                                  <Dropdown.Item>PDF</Dropdown.Item>
                                </a>
                              </Dropdown.Menu>
                            </Dropdown>
                            <button
                              className="text-big add-user"
                              type="button"
                              onClick={() => {
                                setShowREgModal(true);
                                checkPKCouple();
                                clearfield();
                                // setIsDisabled(false)
                              }}
                            >
                              <i
                                className="mdi mdi mdi-account-plus text-white"
                                title="Add New User"
                              ></i>
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <CustomDataTable
                    loading={LoadData}
                    data={allCoupleData}
                    columns={columns}
                    total={total}
                    countPerPage={20}
                    isServerSide={true}
                    pagination={true}
                    sortServer={true}
                    dataFetchApi={getAllCouples}
                    conditionalRowStyles={conditionalRowStyles}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    // fixedHeader={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {show ? (
          <DeleteModel
            show={show}
            handleClose={handleClose}
            DeleteData={deleteCouple}
          ></DeleteModel>
        ) : null}
        {show1 ? (
          <AlertModal
            show={show1}
            handleClose={handleClose1}
            message={`Are you sure you want to ${
              changeText && changeText !== "" ? changeText : "make"
            } this couple as sub-admin?`}
            actionFunction={makeCoupleAsSubAdmin}
          />
        ) : null}
        {show2 ? (
          <AlertModal
            show={show2}
            handleClose={handleClose2}
            message={`Are you sure you want to ${
              changeText && changeText !== "" ? changeText : "make"
            } this couple as super-admin?`}
            actionFunction={makeCoupleAsSubAdmin}
          />
        ) : null}
        {showRegModal ? (
          <UpdateCouplePopUp
            showRegModal={showRegModal}
            setShowREgModal={setShowREgModal}
            setEdiUserIdClicked={setEdiUserIdClicked}
            onHide={() => {
              CloseCompose();
              clearfield();
              setEdiUserIdClicked("");
            }}
            closeButton={() => {
              checkPKCouple();
              setEdiUserIdClicked("");
              CloseCompose();
              getAllCouples();
            }}
            userData={userData}
            isEditAdmin={isEditAdmin}
            parishList={parishList}
            ediUserIdClicked={ediUserIdClicked}
            CloseCompose={CloseCompose}
            roasterCount={roasterCount}
            roastersOfCoupleList={roastersOfCoupleList}
            getAllCouples={getAllCouples}
            data={data}
            setData={setData}
            oldValues={oldValues}
            setOldValues={setOldValues}
            isTempUpload={isTempUpload}
            // isTempUpload2={isTempUpload2}
            setIsTempUpload={setIsTempUpload}
            // setIsTempUpload2={setIsTempUpload2}
          />
        ) : null}
        {show3 ? (
          <AlertModal
            show={show3}
            handleClose={handleClose3}
            message={coupleRepeatMsg}
            actionFunction={handleClose3}
          />
        ) : null}
      </div>
    </>
  );
};

export default ManageCouples;
