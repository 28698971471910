import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import AuthApi from "../../api/authApi";
import AdminApi from "../../api/admin";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import companyLogo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import LoginHeader from "../../layout/loginHeader";

const Login = ({ updatedUser }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
    signedIn: false,
  });
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies([
    "LoveStrongToken",
    "LoveStrongUser",
  ]);

  const handleLoginSubmit = async (event) => {
    // console.log('event: ', event);
    let api = new AuthApi();
    let adminApi = new AdminApi();
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      await api
        .Login(data)
        .then((response) => {
          if (response.success) {
            toast.success(
              `Welcome ${
                response.data.userName && response.data.userName !== ""
                  ? response.data.userName
                  : ""
              }`
            );
            setCookie("LoveStrongToken", response.data.jwtToken, {
              path: "/",
              maxAge: 60 * 60 * 100,
              expires: new Date(Date.now() + 60 * 60 * 100),
            });
            setCookie("LoveStrongUser", response.data, {
              path: "/",
              maxAge: 60 * 60 * 100,
              expires: new Date(Date.now() + 60 * 60 * 100),
            });
            updatedUser(response.data.userRole);
            let report = {
              byWhom:
                `${
                  response.data && response.data.userName
                    ? response.data.userName
                    : null
                } ` +
                ` (${
                  response.data && response.data.userRole
                    ? response.data.userRole
                    : null
                })`,
              pagelink: window.location.href,
              description: "Logged In.",
              action: "CLICK",
            };
            adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Created new log report.");
                }
              })
              .catch((error) => {
                console.log(error);
              });

            navigate("/");
          } else {
            toast.error(response.msg);
            // console.log('error: ', response.msg)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error("User Name and Password is required");
    }
    setValidated(true);
  };

  const handleChanges = async (e) => {
    if (e.target.checked === true) {
      document.getElementById("signedIn").value = JSON.parse(true);
      data.signedIn = true;
    } else {
      data.signedIn = false;
    }
    let { name, value } = e.target;
    let d = { ...data };
    d[name] = value;
    setData(d);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {/* <LoginHeader /> */}
      <div className="container-scroller mainpannel">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-md-12">
                {" "}
                <div className="compLogo">
                  <img src={companyLogo} alt="logo" className="logo center" />
                </div>
              </div>
              <div className="col-lg-4 mx-auto">
                <div className=" auth-form-light text-left py-5 px-4 px-sm-5 text-center">
                  <h4>Login</h4>
                  <h6 className="fw-light">Sign in to continue.</h6>
                  <Form
                    onSubmit={handleLoginSubmit}
                    noValidate
                    validated={validated}
                    className="pt-3"
                  >
                    <Form.Group>
                      <Form.Control
                        required
                        className="form-control form-control-sm border-left-0"
                        type="text"
                        name="username"
                        id=""
                        placeholder="Username or Email"
                        value={data.username}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ textAlign: "left" }}
                      >
                        Username or email is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Form.Group>
                      <Form.Control
                        required
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control form-control-sm border-left-0"
                        id="exampleInputPassword"
                        placeholder="Password"
                        value={data.password}
                        onChange={(e) => handleChanges(e)}
                      />
                      {showPassword ? (
                        <i
                          onClick={togglePasswordVisibility}
                          className={
                            validated === true
                              ? "mdi mdi-eye eyeIcon1"
                              : "mdi mdi-eye eyeIcon"
                          }
                        ></i>
                      ) : (
                        <i
                          onClick={togglePasswordVisibility}
                          className={
                            validated === true
                              ? "mdi mdi-eye-off eyeIcon1"
                              : "mdi mdi-eye-off eyeIcon"
                          }
                        ></i>
                      )}
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ textAlign: "left" }}
                      >
                        Password is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="mt-4 mb-3">
                      <Button
                        type="submit"
                        className="btn btn-block btn-primary btn-md font-weight-medium"
                      >
                        SIGN IN
                      </Button>
                    </div>
                    <div className="my-2 mt-4 login-options justify-content-between align-items-center">
                      <Form.Group className="check">
                        <Form.Check
                          type="checkbox"
                          label=" Keep me signed in"
                          name="signedIn"
                          id="signedIn"
                          feedback="You must agree before submitting."
                          feedbackType="invalid"
                          onChange={(e) => handleChanges(e)}
                        />
                      </Form.Group>
                      <Link
                        to="/forgetPassword"
                        className="auth-link text-black "
                        onKeyPress={(e) =>
                          e.key === "Enter"
                            ? document.getElementById("LoginBtn").click()
                            : null
                        }
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
