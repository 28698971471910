import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Card from "react-bootstrap/Card";
import Profile from "../../assets/images/couple-default.png";
import AuthApi from "../../api/authApi";
import DatePicker from "react-datepicker";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import LoginHeader from "../../layout/loginHeader";
import moment from "moment-timezone";
import Helpers from "../../components/Helpers";
import Loader from "../../components/loader";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import companyLogo from "../../assets/images/logo.png";

export const RegistrationRetreat = () => {
  let authApi = new AuthApi();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [selectedRetreat, setSelectedRetreat] = useState("");
  const [data, setData] = useState({
    hisFirstName: "",
    hisLastName: "",
    hisEmail: "",
    hisMobile: "",
    herFirstName: "",
    herLastName: "",
    herEmail: "",
    herMobile: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    imageUrl: "",
    // marriageImageUrl: "",
    parishId: null,
    address: "",
    allergies: "",
    anniversary_date: "",
    under_age_35: "NO",
    emergency_name1: "",
    emergency_relationship1: "",
    emergency_phone1: "",
    emergency_name2: "",
    emergency_relationship2: "",
    emergency_phone2: "",
    hear_us: [],
    referral_from_a_friend_or_family_member: "",
    attendeeType: "",
  });
  const [parishList, setParishList] = useState([]);
  const [selectedParish, setSelectedParish] = useState([]);
  const [LoadData, setLoadData] = useState(false);
  const [incommingRetreats, setIncommingRetreats] = useState([]);
  const [roles, setRoles] = useState([]);
  const [status, setStatus] = useState("");

  const { id } = useParams();

  useEffect(() => {
    getParishList();
    getAllIncommingRetreats();
  }, []);

  const { t, i18n } = useTranslation();

  // const [isTempUpload, setIsTempUpload] = useState(false);

  const getParishList = async () => {
    setLoadData(true);
    await authApi
      .getPerishList()
      .then((response) => {
        if (response.success) {
          let allParishes = [];
          response.data &&
            response.data.forEach((item) => {
              if (item.parish) {
                allParishes.push({
                  label: item.parish,
                  value: item.id,
                });
              }
            });
          const sortedList = allParishes.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          setParishList(sortedList);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  function getDayName(dateString, language) {
    const dateInUSTimezone = moment(dateString).tz("America/New_York");
    let days = [];
    if (language === "spanish") {
      days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
    } else {
      days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
    }
    return days[dateInUSTimezone.day()];
  }

  const getAllIncommingRetreats = async () => {
    setLoadData(true);
    await authApi
      .getAllIncommingRetreats()
      .then(async (response) => {
        await authApi
          .getPerishList()
          .then((r) => {
            response.data.forEach((event) => {
              let myparish = r.data.find((i) => i.id === event.parishId);
              if (myparish) {
                event.myparish = myparish;
              }
              event.schedule.forEach((item) => {
                item.dayName = getDayName(item.date, event.language);
              });
            });
          })
          .catch((error) => {
            toast.error(error.toString());
          });
        if (id) {
          getRolesOfRetreats(id);
          let findRetreat = response.data.find((item) => item.id == id);
          setIncommingRetreats(response.data);
          if (findRetreat) {
            i18n.changeLanguage(findRetreat.language);
            setSelectedRetreat(findRetreat);
            setStatus({
              directoryStatus: findRetreat.directoryStatus,
              programStatus: findRetreat.programStatus,
            });
          } else {
            alert("The Retreat ID you entered does not exist.");
            navigate("/registration-retreat");
          }
        } else {
          setIncommingRetreats(response.data);
          if (response && response.data[0] && response.data[0].id) {
            // getRolesOfRetreats(response.data[0].id);
            navigate(`/registration-retreat/${response.data[0].id}`);
            i18n.changeLanguage(response.data[0].language);
          }
          setSelectedRetreat(response.data[0]);
          setStatus({
            directoryStatus: response.data[0].directoryStatus,
            programStatus: response.data[0].programStatus,
          });
        }
        setLoadData(false);
      })
      .catch((error) => {
        toast.error(error.toString());
        setLoadData(false);
      });
  };

  const getRolesOfRetreats = async (id) => {
    await authApi.getRolesOfRetreats({ id: id }).then(async (response) => {
      setRoles(response.data);
    });
  };

  const handleAgeChanges = (e) => {
    const { name, checked } = e.target;
    let o = { ...data };
    o.under_age_35 = checked ? "YES" : "NO";
    setData(o);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let hearUsArray = Array.isArray(data.hear_us)
      ? data.hear_us
      : JSON.parse(data.hear_us);

    let updatedOptions;
    if (checked) {
      updatedOptions = hearUsArray ? [...hearUsArray, name] : [name];
    } else {
      updatedOptions = hearUsArray.filter((option) => option !== name);
    }
    setData({ ...data, hear_us: updatedOptions });
  };
  const handleDatePicker = (e) => {
    let o = { ...data };
    o.anniversary_date = e;
    setData(o);
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    // Check if the input is for an image
    // if (name === "imageUrl" || name === "marriageImageUrl") {
    //   const file = e.target.files[0];
    //   if (file) {
    //     const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB
    //     if (fileSizeInMB > 10) {
    //       alert("File size should be less than 10 MB");
    //       return;
    //     } else {
    //       d[name] = file;
    //       const successMessage =
    //         name === "imageUrl"
    //           ? "The profile image has been successfully added."
    //           : "The Wedding image has been successfully added.";
    //       toast.success(successMessage);
    //     }
    //   }
    // } else {
    //   d[name] = value;
    // }
    // ONLY FOR UPLOAD COUPLE IMAGE
    if (name === "imageUrl") {
      const file = e.target.files[0];
      if (file) {
        const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB
        if (fileSizeInMB > 10) {
          alert("File size should be less than 10 MB");
          return;
        } else {
          d[name] = file;
          const successMessage =
            name === "imageUrl"
              ? "The profile image has been successfully added."
              : "";
          toast.success(successMessage);
        }
      }
    } else {
      d[name] = value;
    }

    setData(d);
  };

  const handleSelectParish = (val) => {
    setSelectedParish(val);
    let d = { ...data };
    d.parishId = val.value;
    setData(d);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      let data1 = new FormData();
      data1.append("file", data.imageUrl);
      // data1.append("marriage_file", data.marriageImageUrl);
      let stateRef = { ...data };
      delete stateRef.imageUrl;
      // delete stateRef.marriageImageUrl;
      stateRef.retreatId = selectedRetreat.id;
      data1.append("fromData", JSON.stringify(stateRef));
      authApi
        .registerCoupleForRetrteat(data1)
        .then(async (res) => {
          if (res.success) {
            toast.success(t("couple_register_retreat_msg"));
            clearfield();
            setData({
              id: "",
              hisFirstName: "",
              hisLastName: "",
              hisEmail: "",
              hisMobile: "",
              herFirstName: "",
              herLastName: "",
              herEmail: "",
              herMobile: "",
              city: "",
              state: "",
              zip: "",
              notes: "",
              imageUrl: "",
              // marriageImageUrl: "",
              address: "",
              allergies: "",
              anniversary_date: "",
              under_age_35: false,
              emergency_name1: "",
              emergency_relationship1: "",
              emergency_phone1: "",
              emergency_name2: "",
              emergency_relationship2: "",
              emergency_phone2: "",
              hear_us: [],
              referral_from_a_friend_or_family_member: "",
              attendeeType: "",
            });
            if (res.checkInMainCouple === true) {
              navigate("/thankyou", {
                state: {
                  value: "Old Register Couple",
                  hisEmail: res.data.hisEmail,
                  herEmail: res.data.herEmail,
                  data: selectedRetreat,
                },
              });
            } else {
              navigate("/thankyou", {
                state: {
                  value: "New Register Couple",
                  hisEmail: res.data.hisEmail,
                  herEmail: res.data.herEmail,
                  data: selectedRetreat,
                },
              });
            }
          } else {
            if (res.msg == "Please add at least one name.") {
              toast.error(t("add_one_name"));
            } else if (res.msg == "Please add at least one email ID.") {
              toast.error(t("least_email_id"));
            } else {
              toast.error(res.msg);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setValidated(true);
  };

  const clearfield = () => {
    setData({
      hisFirstName: "",
      hisLastName: "",
      hisEmail: "",
      hisMobile: "",
      herFirstName: "",
      herLastName: "",
      herEmail: "",
      herMobile: "",
      city: "",
      state: "",
      zip: "",
      notes: "",
      imageUrl: "",
      // marriageImageUrl: "",
      parishId: null,
      address: "",
      allergies: "",
      anniversary_date: "",
      under_age_35: false,
      emergency_name1: "",
      emergency_relationship1: "",
      emergency_phone1: "",
      emergency_name2: "",
      emergency_relationship2: "",
      emergency_phone2: "",
      hear_us: [],
      referral_from_a_friend_or_family_member: "",
      attendeeType: "",
    });
    setSelectedParish([]);
    setValidated(false);
  };

  // const upload = () => {
  //   document.getElementById("CoupleProfileUpload").click();
  //   setIsTempUpload(true);
  // };

  // const remove = () => {
  //   let d = { ...data };
  //   d.marriageImageUrl = "";
  //   setData(d);
  //   setIsTempUpload(false);
  // };

  const handleRetreatChanges = (e) => {
    const selectedValue = e.target.value;
    const selectedRetreat = incommingRetreats.find(
      (item) => item.title === selectedValue
    );
    if (selectedRetreat) {
      i18n.changeLanguage(selectedRetreat.language);
      navigate(`/registration-retreat/${selectedRetreat.id}`);
      // getRolesOfRetreats(selectedRetreat.id);
      setSelectedRetreat(selectedRetreat);
      setStatus({
        directoryStatus: selectedRetreat.directoryStatus,
        programStatus: selectedRetreat.programStatus,
      });
    } else {
      console.log("Retreat not found for selected value:", selectedValue);
    }
  };

  return (
    <>
      {/* <LoginHeader /> */}
      <div className="container-scroller mainpannel">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          {incommingRetreats && incommingRetreats.length ? (
            <div className="content-wrapper  align-items-center auth px-0">
              <div className="container  text-center">
                <div className="row justify-content-center">
                  {/* <div className="col-lg-6 col-md-6 col-sm-8 col-xs-8">
                    <select
                      className="form-select retreatSelect form-select-lg mb-0 mt-3"
                      value={selectedRetreat.title}
                      aria-label="Large select example"
                      onChange={(e) => handleRetreatChanges(e)}
                    >
                      {incommingRetreats.map((item, i) => {
                        return (
                          <option
                            key={i}
                            value={item.title}
                            className="retreatOptions"
                          >
                            {item.dateFrom === item.dateTo
                              ? `${
                                  item.title.slice(0, 55) +
                                  (item.title.length > 55 ? "..." : "")
                                }` +
                                ` ${moment(item && item.dateFrom).format(
                                  "MMM-DD-YYYY"
                                )}`
                              : `${
                                  item.title.slice(0, 40) +
                                  (item.title.length > 40 ? "..." : "")
                                }` +
                                ` ${
                                  moment(item && item.dateFrom).format(
                                    "MMM DD"
                                  ) +
                                  " - " +
                                  moment(item.dateTo).format("MMM DD") +
                                  ", " +
                                  moment(item && item.dateFrom).format("YYYY")
                                }`}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}

                  {/* shows the directory and program links */}
                  {/* {status !== "" ? (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 dirPage">
                      {status.directoryStatus === "PUBLISH" ? (
                        <a
                          href={`/directory/${selectedRetreat.id}`}
                          className=""
                          target="_blank"
                        >
                          Directory
                        </a>
                      ) : null}

                      {status.programStatus === "PUBLISH" ? (
                        <a
                          href={`/program/${selectedRetreat.id}`}
                          className="pageButton"
                          target="_blank"
                        >
                          Program
                        </a>
                      ) : null}
                    </div>
                  ) : null} */}
                </div>
              </div>
              <div className="row w-100 mx-0">
                <div className="col-md-12">
                  {" "}
                  <div className="compLogo">
                    <img src={companyLogo} alt="logo" className="logo center" />
                  </div>
                </div>
                <div className="col-lg-10 mx-auto">
                  <div className="auth-form-light text-center main-register py-5 px-4 px-sm-5 mt-3">
                    {!LoadData ? (
                      <Row className="justify-content-center">
                        <Col
                          lg={12}
                          md={12}
                          xs={12}
                          className="text-center mb-3"
                        >
                          <div className="retreat-Title-Regis">
                            {/* <h3>{selectedRetreat.title}</h3> */}
                            <h3>
                              {selectedRetreat?.myparish?.parish}
                              {selectedRetreat.language
                                ? ` (${selectedRetreat.language[0].toUpperCase()}${selectedRetreat.language.slice(
                                    1
                                  )}) `
                                : null}
                            </h3>
                            <h3>
                              {selectedRetreat.location
                                ? `${selectedRetreat.location[0].toUpperCase()}${selectedRetreat.location.slice(
                                    1
                                  )}`
                                : ""}
                            </h3>
                            <p>
                              (
                              {selectedRetreat.dateFrom ===
                              selectedRetreat.dateTo
                                ? ` ${moment(
                                    selectedRetreat && selectedRetreat.dateFrom
                                  )
                                    .tz("America/New_York")
                                    .format("MMM-DD-YYYY")}`
                                : ` ${
                                    moment(
                                      selectedRetreat &&
                                        selectedRetreat.dateFrom
                                    )
                                      .tz("America/New_York")
                                      .format("MMM DD") +
                                    " - " +
                                    moment(selectedRetreat.dateTo)
                                      .tz("America/New_York")
                                      .format("MMM DD") +
                                    ", " +
                                    moment(
                                      selectedRetreat &&
                                        selectedRetreat.dateFrom
                                    )
                                      .tz("America/New_York")
                                      .format("YYYY")
                                  }`}
                              )
                            </p>
                          </div>
                        </Col>
                        {
                          selectedRetreat.image1 || selectedRetreat.image2 ? (
                            <Col
                              lg={8}
                              md={8}
                              xs={8}
                              className="text-center  mt-4 mb-5"
                            >
                              <div
                                className={`${
                                  (selectedRetreat.image1 &&
                                    !selectedRetreat.image2) ||
                                  (!selectedRetreat.image1 &&
                                    selectedRetreat.image2)
                                    ? "church_img"
                                    : "churchImage"
                                }`}
                              >
                                {selectedRetreat && selectedRetreat.image1 ? (
                                  <img
                                    className="churchImg1"
                                    src={
                                      selectedRetreat && selectedRetreat.image1
                                    }
                                    alt=""
                                    srcset=""
                                  />
                                ) : null}
                                {selectedRetreat && selectedRetreat.image2 ? (
                                  <img
                                    src={
                                      selectedRetreat && selectedRetreat.image2
                                    }
                                    alt=""
                                    srcset=""
                                    className="secondRetreatImage"
                                  />
                                ) : null}
                              </div>
                            </Col>
                          ) : null
                          // <Col
                          //   lg={8}
                          //   md={8}
                          //   xs={8}
                          //   className="text-center  mt-4 mb-5"
                          // >
                          //   <div className="create_retreat_image">
                          //     <img
                          //       src="https://lovestrong.koolderbyacademy.com/logo.png"
                          //       alt=""
                          //       srcset=""
                          //     />
                          //   </div>
                          // </Col>
                        }
                        {selectedRetreat.heading ? (
                          <Col
                            lg={9}
                            md={9}
                            xs={9}
                            className="text-center mt-1 headingDec"
                          >
                            <h2>
                              <i> {selectedRetreat.heading}</i>
                            </h2>
                          </Col>
                        ) : null}
                        {selectedRetreat.mission_statement ? (
                          <Col
                            lg={9}
                            md={9}
                            xs={9}
                            className={`${
                              selectedRetreat.image2 || selectedRetreat.image1
                                ? "text-center mt-2 mb-1 missionState"
                                : "text-center mb-1 missionState"
                            }`}
                          >
                            <h4 className="mt-4 mb-4">
                              {t("mission_statement")}
                            </h4>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  selectedRetreat &&
                                  selectedRetreat.mission_statement,
                              }}
                            />{" "}
                          </Col>
                        ) : null}

                        {roles &&
                        !roles.lead &&
                        !roles.leadCouple &&
                        !roles.coLead &&
                        !roles.coLeadCouple &&
                        !roles.spiritualDirector &&
                        !roles.spiritualMentor &&
                        !roles.mentor ? (
                          ""
                        ) : (
                          <Col
                            lg={12}
                            md={12}
                            xs={12}
                            className="text-center mt-4 mb-5"
                          >
                            <Row className="justify-content-center">
                              {/* {roles && roles.lead ? (
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  className="mt-3"
                                >
                                  <Card className="cardStyle">
                                    <Card.Img
                                      variant="top"
                                      className="coupleImage"
                                      src={
                                        roles &&
                                        roles.lead &&
                                        roles.lead.imageUrl
                                          ? roles.lead.imageUrl
                                          : Profile
                                      }
                                    />
                                    <Card.Footer className="leadBody">
                                      <Card.Text className="rolesDesign mt-2">
                                        {`${
                                          roles &&
                                          roles.lead &&
                                          roles.lead.hisFirstName
                                            ? roles.lead.hisFirstName
                                            : ""
                                        }` +
                                          `${
                                            roles &&
                                            roles.lead &&
                                            roles.lead.herFirstName
                                              ? " & "
                                              : ""
                                          }` +
                                          `${
                                            roles &&
                                            roles.lead &&
                                            roles.lead.herFirstName
                                              ? roles.lead.herFirstName
                                              : ""
                                          }` +
                                          ` ${
                                            roles &&
                                            roles.lead &&
                                            roles.lead.hisLastName
                                              ? roles.lead.hisLastName
                                              : ""
                                          }`}
                                      </Card.Text>
                                      <Card.Title>Lead</Card.Title>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              ) : null} */}

                              {roles && roles.leadCouple ? (
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  className="mt-3"
                                >
                                  <Card className="cardStyle">
                                    <Card.Img
                                      variant="top"
                                      className="coupleImage"
                                      src={
                                        roles &&
                                        roles.leadCouple &&
                                        roles.leadCouple.imageUrl
                                          ? roles.leadCouple.imageUrl
                                          : Profile
                                      }
                                    />
                                    <Card.Footer className="leadBody">
                                      <Card.Text className="rolesDesign mt-2">
                                        {`${
                                          roles &&
                                          roles.leadCouple &&
                                          roles.leadCouple.hisFirstName
                                            ? roles.leadCouple.hisFirstName
                                            : ""
                                        }` +
                                          `${
                                            roles &&
                                            roles.leadCouple &&
                                            roles.leadCouple.herFirstName
                                              ? " & "
                                              : ""
                                          }` +
                                          `${
                                            roles &&
                                            roles.leadCouple &&
                                            roles.leadCouple.herFirstName
                                              ? roles.leadCouple.herFirstName
                                              : ""
                                          }` +
                                          ` ${
                                            roles &&
                                            roles.leadCouple &&
                                            roles.leadCouple.hisLastName
                                              ? roles.leadCouple.hisLastName
                                              : ""
                                          }`}
                                      </Card.Text>
                                      <Card.Title>
                                        {t("lead_couple")}
                                      </Card.Title>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              ) : null}

                              {/* {roles && roles.coLead ? (
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  className="mt-3"
                                >
                                  <Card className="cardStyle">
                                    <Card.Img
                                      variant="top"
                                      className="coupleImage"
                                      src={
                                        roles &&
                                        roles.coLead &&
                                        roles.coLead.imageUrl
                                          ? roles.coLead.imageUrl
                                          : Profile
                                      }
                                    />
                                    <Card.Footer className="leadBody">
                                      <Card.Text className="rolesDesign mt-2">
                                        {`${
                                          roles &&
                                          roles.coLead &&
                                          roles.coLead.hisFirstName
                                            ? roles.coLead.hisFirstName
                                            : ""
                                        }` +
                                          `${
                                            roles &&
                                            roles.coLead &&
                                            roles.coLead.herFirstName
                                              ? " & "
                                              : ""
                                          }` +
                                          `${
                                            roles &&
                                            roles.coLead &&
                                            roles.coLead.herFirstName
                                              ? roles.coLead.herFirstName
                                              : ""
                                          }` +
                                          ` ${
                                            roles &&
                                            roles.coLead &&
                                            roles.coLead.hisLastName
                                              ? roles.coLead.hisLastName
                                              : ""
                                          }`}
                                      </Card.Text>
                                      <Card.Title>Co-Lead</Card.Title>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              ) : null} */}

                              {roles && roles.coLeadCouple ? (
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  className="leadCoCouples mt-3"
                                >
                                  <Card className="cardStyle">
                                    <Card.Img
                                      variant="top"
                                      src={
                                        roles &&
                                        roles.coLeadCouple &&
                                        roles.coLeadCouple.imageUrl
                                          ? roles.coLeadCouple.imageUrl
                                          : Profile
                                      }
                                    />
                                    <Card.Footer className="leadBody">
                                      <Card.Text className="rolesDesign mt-2">
                                        {`${
                                          roles &&
                                          roles.coLeadCouple &&
                                          roles.coLeadCouple.hisFirstName
                                            ? roles.coLeadCouple.hisFirstName
                                            : ""
                                        }` +
                                          `${
                                            roles &&
                                            roles.coLeadCouple &&
                                            roles.coLeadCouple.herFirstName
                                              ? " & "
                                              : ""
                                          }` +
                                          `${
                                            roles &&
                                            roles.coLeadCouple &&
                                            roles.coLeadCouple.herFirstName
                                              ? roles.coLeadCouple.herFirstName
                                              : ""
                                          }` +
                                          " " +
                                          `${
                                            roles &&
                                            roles.coLeadCouple &&
                                            roles.coLeadCouple.hisLastName
                                              ? roles.coLeadCouple.hisLastName
                                              : ""
                                          }`}
                                      </Card.Text>
                                      <Card.Title>
                                        {t("co_lead_couple")}
                                      </Card.Title>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              ) : (
                                ""
                              )}

                              {roles && roles.spiritualDirector ? (
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  className="leadCoCouples mt-3"
                                >
                                  <Card className="cardStyle">
                                    <Card.Img
                                      variant="top"
                                      src={
                                        roles &&
                                        roles.spiritualDirector &&
                                        roles.spiritualDirector.imageUrl
                                          ? roles.spiritualDirector.imageUrl
                                          : Profile
                                      }
                                    />
                                    <Card.Footer className="leadBody">
                                      <Card.Text className="rolesDesign mt-2">
                                        {`${
                                          roles &&
                                          roles.spiritualDirector &&
                                          roles.spiritualDirector.hisFirstName
                                            ? roles.spiritualDirector
                                                .hisFirstName
                                            : ""
                                        }` +
                                          `${
                                            roles &&
                                            roles.spiritualDirector &&
                                            roles.spiritualDirector.herFirstName
                                              ? " & "
                                              : ""
                                          }` +
                                          `${
                                            roles &&
                                            roles.spiritualDirector &&
                                            roles.spiritualDirector.herFirstName
                                              ? roles.spiritualDirector
                                                  .herFirstName
                                              : ""
                                          }` +
                                          " " +
                                          `${
                                            roles &&
                                            roles.spiritualDirector &&
                                            roles.spiritualDirector.hisLastName
                                              ? roles.spiritualDirector
                                                  .hisLastName
                                              : ""
                                          }`}
                                      </Card.Text>
                                      <Card.Title>
                                        {t("spiritual_director")}
                                      </Card.Title>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              ) : (
                                ""
                              )}

                              {roles && roles.spiritualMentor ? (
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  className="leadCoCouples mt-3"
                                >
                                  <Card className="cardStyle">
                                    <Card.Img
                                      variant="top"
                                      src={
                                        roles &&
                                        roles.spiritualMentor &&
                                        roles.spiritualMentor.imageUrl
                                          ? roles.spiritualMentor.imageUrl
                                          : Profile
                                      }
                                    />
                                    <Card.Footer className="leadBody">
                                      <Card.Text className="rolesDesign mt-2">
                                        {`${
                                          roles &&
                                          roles.spiritualMentor &&
                                          roles.spiritualMentor.hisFirstName
                                            ? roles.spiritualMentor.hisFirstName
                                            : ""
                                        }` +
                                          `${
                                            roles &&
                                            roles.spiritualMentor &&
                                            roles.spiritualMentor.herFirstName
                                              ? " & "
                                              : ""
                                          }` +
                                          `${
                                            roles &&
                                            roles.spiritualMentor &&
                                            roles.spiritualMentor.herFirstName
                                              ? roles.spiritualMentor
                                                  .herFirstName
                                              : ""
                                          }` +
                                          " " +
                                          `${
                                            roles &&
                                            roles.spiritualMentor &&
                                            roles.spiritualMentor.hisLastName
                                              ? roles.spiritualMentor
                                                  .hisLastName
                                              : ""
                                          }`}
                                      </Card.Text>
                                      <Card.Title>
                                        {t("spiritual_mentor")}
                                      </Card.Title>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              ) : (
                                ""
                              )}

                              {roles && roles.mentor ? (
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  className="mentorCouples mt-3"
                                >
                                  <Card className="cardStyle">
                                    <Card.Img
                                      variant="top"
                                      src={
                                        roles &&
                                        roles.mentor &&
                                        roles.mentor.imageUrl
                                          ? roles.mentor.imageUrl
                                          : Profile
                                      }
                                    />
                                    <Card.Footer className="leadBody">
                                      <Card.Text className="rolesDesign mt-2">
                                        {`${
                                          roles &&
                                          roles.mentor &&
                                          roles.mentor.hisFirstName
                                            ? roles.mentor.hisFirstName
                                            : ""
                                        }` +
                                          `${
                                            roles &&
                                            roles.mentor &&
                                            roles.mentor.herFirstName
                                              ? " & "
                                              : ""
                                          }` +
                                          `${
                                            roles &&
                                            roles.mentor &&
                                            roles.mentor.herFirstName
                                              ? roles.mentor.herFirstName
                                              : ""
                                          }` +
                                          " " +
                                          `${
                                            roles &&
                                            roles.mentor &&
                                            roles.mentor.hisLastName
                                              ? roles.mentor.hisLastName
                                              : ""
                                          }`}
                                      </Card.Text>
                                      <Card.Title>
                                        {" "}
                                        {t("mentor_couple")}
                                      </Card.Title>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Col>
                        )}
                        {selectedRetreat && selectedRetreat.schedule.length ? (
                          <Col
                            lg={10}
                            md={10}
                            xs={10}
                            className="text-center mt-4 mb-5"
                          >
                            <Table className="tableBorder" responsive>
                              <thead>
                                <tr>
                                  <th>{t("day")}</th>
                                  <th>{t("date")}</th>
                                  <th>{t("start")}</th>
                                  <th>{t("end")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedRetreat &&
                                  selectedRetreat.schedule &&
                                  selectedRetreat.schedule.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          {getDayName(
                                            item.date,
                                            selectedRetreat.language
                                          )}
                                        </td>
                                        <td>
                                          {Helpers.convertInWordTimming(
                                            item.date,
                                            "MM-DD-YYYY",
                                            selectedRetreat.language ===
                                              "spanish"
                                              ? "es"
                                              : "en"
                                          )}
                                        </td>
                                        <td>{item.from}</td>
                                        <td>{item.to}</td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </Col>
                        ) : null}
                        <Col
                          lg={10}
                          md={10}
                          xs={10}
                          className="text-center  descOfRetreat mb-2"
                        >
                          {selectedRetreat.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  selectedRetreat &&
                                  selectedRetreat.description,
                              }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
              <Row className="w-100 mx-0 justify-content-center mt-5">
                <Col lg={12} md={12} xs={12} className="text-center">
                  <h3 className="RegisTitle">{t("registration_form")}</h3>
                  {/* <h4 className="retreatParish mt-3">
                    {selectedRetreat &&
                    selectedRetreat.myparish &&
                    selectedRetreat.myparish.parish
                      ? selectedRetreat.myparish.parish
                      : ""}
                  </h4>
                  <h5 className="parishAddress mt-3">
                    {selectedRetreat &&
                    selectedRetreat.myparish &&
                    selectedRetreat.myparish.address
                      ? selectedRetreat.myparish.address
                      : null}
                  </h5>
                  <h5 className="parishAddress mt-3">
                    {`${
                      selectedRetreat &&
                      selectedRetreat.myparish &&
                      selectedRetreat.myparish.city
                        ? selectedRetreat.myparish.city
                        : ""
                    }` +
                      `${
                        selectedRetreat &&
                        selectedRetreat.state &&
                        selectedRetreat.myparish.state
                          ? ", " + selectedRetreat.myparish.state
                          : ""
                      }` +
                      `${
                        selectedRetreat &&
                        selectedRetreat.zip &&
                        selectedRetreat.myparish.zip
                          ? " " + selectedRetreat.myparish.zip
                          : ""
                      }`}
                  </h5>

                  <h5 className="retreatParish mt-3">
                    {`${
                      roles && roles.leadCouple && roles.leadCouple.hisFirstName
                        ? roles.leadCouple.hisFirstName
                        : ""
                    }` +
                      `${
                        roles &&
                        roles.leadCouple &&
                        roles.leadCouple.hisLastName
                          ? roles.leadCouple.hisLastName
                          : ""
                      }` +
                      " " +
                      `${
                        roles && roles.leadCouple && roles.leadCouple.hisMobile
                          ? "(" + roles.leadCouple.hisMobile + ")"
                          : ""
                      }`}
                  </h5> */}
                </Col>
              </Row>
              <div className="row w-100 mx-0">
                <div className="col-lg-10 mx-auto">
                  <div className="auth-form-light text-left main-register py-5 px-4 px-sm-5">
                    {/* <h4 className="text-danger mb-5">
                      Please enter at least one Name and Email for registration.
                    </h4> */}

                    <Form
                      onSubmit={handleSubmit}
                      noValidate
                      validated={validated}
                      className="forms-sample formStyle"
                    >
                      <Row>
                        <Col lg={6} md={6} xs={12}>
                          <div>
                            {" "}
                            <label
                              className="bold mt-2"
                              htmlFor="exampleInputEmail1"
                            >
                              {t("retreat_team")}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Group className="form-group d-flex">
                              <div>
                                <Form.Check
                                  required
                                  type={"radio"}
                                  id={`retreadRad`}
                                  onChange={(e) => {
                                    handleChanges(e);
                                  }}
                                  value={"Retreatant"}
                                  label={`${t("retreatant")}`}
                                  name="attendeeType"
                                  className="space_lef1"
                                />
                              </div>
                              <div>
                                <Form.Check
                                  required
                                  type={"radio"}
                                  id={`coupleRad`}
                                  onChange={(e) => {
                                    handleChanges(e);
                                  }}
                                  value={`Team`}
                                  label={`${t("team")}`}
                                  className="space_lef1"
                                  name="attendeeType"
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div>
                            {" "}
                            <label
                              className="bold"
                              htmlFor="exampleInputEmail1"
                            >
                              {t("check_year")}
                            </label>
                            <div></div>
                            <label className="form-check-label mt-2">
                              <input
                                type="checkbox"
                                className="form-check-input ageLabel"
                                name="under_age_35"
                                checked={
                                  data.under_age_35 === "YES" ? true : false
                                }
                                onChange={handleAgeChanges}
                              />
                              {t("yes")}
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        </Col>

                        {/* <Col lg={6} md={6} xs={12}>
                            <Form.Group>
                              {data.marriageImageUrl == "" ||
                              data.marriageImageUrl == undefined ? (
                                <div className="edit-profile-img mb-2 text-center">
                                  <img src={Profile} />
                                </div>
                              ) : (
                                <div className="edit-profile-img mb-2 text-center">
                                  {isTempUpload ? (
                                    <>
                                      <img
                                        src={URL.createObjectURL(
                                          data?.marriageImageUrl
                                        )}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img src={data?.marriageImageUrl} />
                                    </>
                                  )}
                                </div>
                              )}{" "}
                              <div className="text-center mt-3">
                                {data.marriageImageUrl == "" ||
                                data.marriageImageUrl == undefined ? (
                                  <>
                                    <input
                                      name="marriageImageUrl"
                                      className="pt-1"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      id="CoupleProfileUpload"
                                      onChange={(e) => handleChanges(e)}
                                    />
                                    <Button
                                      variant="primary"
                                      className="mb-1 me-3 text-center"
                                      onClick={(e) => {
                                        upload();
                                      }}
                                    >
                                      Upload Wedding Image
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    variant="danger"
                                    className="mb-1 mt-2 "
                                    onClick={(e) => {
                                      remove();
                                    }}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </div>
                            </Form.Group>
                          </Col> */}
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={6} md={6} xs={12}>
                          <label className="badge badge-outline-primary rounded mb-3 mt-2 hisLabel">
                            {t("his_info")}
                          </label>
                          <div id="his-info">
                            <Col lg={12} md={12} xs={12}>
                              <Form.Group className="form-group">
                                <Form.Label className="his-info">
                                  {t("his_first_name")}
                                </Form.Label>
                                <Form.Control
                                  // required
                                  size="sm"
                                  type="text"
                                  name="hisFirstName"
                                  id="hisFirstName"
                                  placeholder={`${t("his_first_name")}`}
                                  value={data.hisFirstName}
                                  className="form-control his-info-border"
                                  onChange={(e) => handleChanges(e)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  FirstName is required!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                              <Form.Group className="form-group">
                                <Form.Label> {t("his_last_name")}</Form.Label>
                                <Form.Control
                                  // required
                                  size="sm"
                                  type="text"
                                  name="hisLastName"
                                  id="hisLastName"
                                  placeholder={`${t("his_last_name")}`}
                                  value={data.hisLastName}
                                  className="form-control"
                                  onChange={(e) => handleChanges(e)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  LastName is required!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                              <Form.Group className="form-group col">
                                <Form.Label>{t("his_email")}</Form.Label>
                                <Form.Control
                                  // required
                                  size="sm"
                                  type="text"
                                  name="hisEmail"
                                  id="hisEmail"
                                  placeholder={`${t("his_email")}`}
                                  value={data.hisEmail}
                                  onChange={(e) => handleChanges(e)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Email is required!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                              <Form.Group className="form-group col">
                                <Form.Label>{t("his_mobile")}</Form.Label>
                                <Form.Control
                                  // required
                                  size="sm"
                                  type="text"
                                  name="hisMobile"
                                  id="hisMobile"
                                  placeholder={`${t("his_mobile")}`}
                                  value={data.hisMobile}
                                  onChange={(e) => handleChanges(e)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Mobile is required!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                          <label className="badge badge-outline-primary rounded mb-3 mt-2 herLabel">
                            {t("her_info")}
                          </label>
                          <div id="her-info">
                            <Col lg={12} md={12} xs={12}>
                              <Form.Group className="form-group">
                                <Form.Label className="her-info">
                                  {t("her_first_name")}
                                </Form.Label>
                                <Form.Control
                                  // required
                                  size="sm"
                                  type="text"
                                  name="herFirstName"
                                  id="herFirstName"
                                  placeholder={`${t("her_first_name")}`}
                                  value={data.herFirstName}
                                  onChange={(e) => handleChanges(e)}
                                  className="her-info-border"
                                />
                                <Form.Control.Feedback type="invalid">
                                  FirstName is required!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                              <Form.Group className="form-group">
                                <Form.Label> {t("her_last_name")}</Form.Label>
                                <Form.Control
                                  // required
                                  size="sm"
                                  type="text"
                                  name="herLastName"
                                  id="herLastName"
                                  placeholder={`${t("her_last_name")}`}
                                  value={data.herLastName}
                                  onChange={(e) => handleChanges(e)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  LastName is required!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                              <Form.Group className="form-group">
                                <Form.Label>{t("her_email")}</Form.Label>
                                <Form.Control
                                  // required
                                  size="sm"
                                  type="text"
                                  name="herEmail"
                                  id="herEmail"
                                  placeholder={`${t("her_email")}`}
                                  value={data.herEmail}
                                  onChange={(e) => handleChanges(e)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Email is required!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                              <Form.Group className="form-group">
                                <Form.Label>{t("her_mobile")}</Form.Label>
                                <Form.Control
                                  // required
                                  size="sm"
                                  type="text"
                                  name="herMobile"
                                  id="herMobile"
                                  placeholder={`${t("her_mobile")}`}
                                  value={data.herMobile}
                                  onChange={(e) => handleChanges(e)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Mobile is required!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="row locationFields">
                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Form.Label>{t("address")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="address"
                            id="address"
                            placeholder={`${t("address")}`}
                            value={data.address}
                            onChange={(e) => handleChanges(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Address is required!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Form.Label>{t("state")}</Form.Label>
                          <Form.Select
                            className="minimal locationDropdown"
                            size="sm"
                            type="text"
                            name="state"
                            id="state"
                            placeholder={`${t("state")}`}
                            value={data.state}
                            onChange={(e) => handleChanges(e)}
                          >
                            <option value="">{t("select_state")}</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Alabama">Alabama</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="Arizona">Arizona</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="District of Columbia">
                              District of Columbia
                            </option>
                            <option value="Delaware">Delaware</option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Maine">Maine</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Montana">Montana</option>
                            <option value="North Carolina<">
                              North Carolina
                            </option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New York">New York</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennsylvania">Pennsylvania</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">
                              South Carolina
                            </option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Washington">Washington</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wyoming">Wyoming</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            State is required!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Form.Label>{t("city")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="city"
                            id="city"
                            placeholder={`${t("city")}`}
                            value={data.city}
                            onChange={(e) => handleChanges(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            City is required!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Form.Label>{t("zip")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="zip"
                            id="zip"
                            placeholder={`${t("zip")}`}
                            value={data.zip}
                            onChange={(e) => handleChanges(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Zip is required!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Form.Label>{t("parish")}</Form.Label>
                          <Select
                            name="parish"
                            options={parishList}
                            value={selectedParish}
                            onChange={handleSelectParish}
                            labelledBy="Select"
                            placeholder={`${t("select_parish")}`}
                            isSearchable={true}
                            isMulti={false}
                          />
                          <Form.Control.Feedback type="invalid">
                            Parsih is required!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Form.Label className="d-block">
                            {t("anniversary_date")}
                          </Form.Label>

                          <DatePicker
                            // required
                            autoComplete="off"
                            dateFormat="MM-dd-yyyy"
                            placeholderText="mm-dd-yyyy"
                            selected={data && data.anniversary_date}
                            onChange={(e) => handleDatePicker(e)}
                            className="form-select setdate datepicker"
                            name="anniversary_date"
                            id="anniversary_date"
                            maxDate={new Date()}
                            showDisabledMonthNavigation
                          />
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Form.Label>{t("notes")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="notes"
                            id="notes"
                            placeholder={`${t("notes")}`}
                            value={data.notes}
                            onChange={(e) => handleChanges(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Notes is required!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <Form.Label>{t("dietary_allergies")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="allergies"
                            id="allergies"
                            value={data.allergies}
                            onChange={(e) => handleChanges(e)}
                          />
                        </Form.Group>
                      </div>

                      <div className="row mt-3 locationFields">
                        <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h4>{t("one_emergency_contact")}</h4>
                        </div>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                          <Form.Label>{t("name")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="emergency_name1"
                            id="emergency_name1"
                            placeholder={`${t("name")}`}
                            value={data.emergency_name1}
                            onChange={(e) => handleChanges(e)}
                          />
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                          <Form.Label>{t("relationship")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="emergency_relationship1"
                            id="emergency_relationship1"
                            placeholder={`${t("relationship")}`}
                            value={data.emergency_relationship1}
                            onChange={(e) => handleChanges(e)}
                          />
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                          <Form.Label>{t("phone")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="emergency_phone1"
                            id="emergency_phone1"
                            placeholder={`${t("phone")}`}
                            value={data.emergency_phone1}
                            onChange={(e) => handleChanges(e)}
                          />
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                          <Form.Label>{t("name")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="emergency_name2"
                            id="emergency_name2"
                            placeholder={`${t("name")}`}
                            value={data.emergency_name2}
                            onChange={(e) => handleChanges(e)}
                          />
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                          <Form.Label>{t("relationship")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="emergency_relationship2"
                            id="emergency_relationship2"
                            placeholder={`${t("relationship")}`}
                            value={data.emergency_relationship2}
                            onChange={(e) => handleChanges(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Relationship is required!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                          <Form.Label>{t("phone")}</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="emergency_phone2"
                            id="emergency_phone2"
                            placeholder={`${t("phone")}`}
                            value={data.emergency_phone2}
                            onChange={(e) => handleChanges(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Phone is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="row mt-3 locationFields">
                        <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h4> {t("hear_us")}</h4>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between hear-options">
                          <label className="form-check-label ml-sm-2">
                            <input
                              type="checkbox"
                              className="form-check-input ageLabel"
                              name="Website"
                              value="Website"
                              onChange={handleCheckboxChange}
                              checked={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes("Website")
                              }
                              disabled={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes(
                                  data.hear_us === "Website"
                                )
                              }
                            />
                            {t("website")}
                            <i className="input-helper"></i>
                          </label>

                          <label className="form-check-label ml-sm-2">
                            <input
                              type="checkbox"
                              className="form-check-input ageLabel"
                              name="Church Bulletin"
                              value="Church Bulletin"
                              onChange={handleCheckboxChange}
                              checked={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes("Church Bulletin")
                              }
                              disabled={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes(
                                  data.hear_us === "Church Bulletin"
                                )
                              }
                            />{" "}
                            {t("church_bulletin")}
                            <i className="input-helper"></i>
                          </label>

                          <label className="form-check-label ml-sm-2">
                            <input
                              type="checkbox"
                              className="form-check-input ageLabel"
                              name="Facebook"
                              value="Facebook"
                              onChange={handleCheckboxChange}
                              checked={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes("Facebook")
                              }
                              disabled={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes(
                                  data.hear_us === "Facebook"
                                )
                              }
                            />
                            {t("facebook")}
                            <i className="input-helper"></i>
                          </label>

                          <label className="form-check-label ml-sm-2">
                            <input
                              type="checkbox"
                              className="form-check-input ageLabel"
                              name="School Marquee"
                              value="School Marquee"
                              onChange={handleCheckboxChange}
                              checked={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes("School Marquee")
                              }
                              disabled={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes(
                                  data.hear_us === "School Marquee"
                                )
                              }
                            />
                            {t("school_marquee")}
                            <i className="input-helper"></i>
                          </label>

                          <label className="form-check-label ml-sm-2">
                            <input
                              type="checkbox"
                              className="form-check-input ageLabel"
                              name="Pulpit Talk/Table in Church"
                              value="Pulpit Talk/Table in Church"
                              onChange={handleCheckboxChange}
                              checked={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes(
                                  "Pulpit Talk/Table in Church"
                                )
                              }
                              disabled={
                                data &&
                                data.hear_us &&
                                data.hear_us.includes(
                                  data.hear_us === "Pulpit Talk/Table in Church"
                                )
                              }
                            />
                            {t("Pulpit_church")}
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </div>

                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                        sm={12}
                        className=" mt-5 locationFields"
                      >
                        <h4> {t("referral")}</h4>
                        <Form.Group className="form-group mt-3">
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="referral_from_a_friend_or_family_member"
                            placeholder={`${t("referral")}`}
                            id="referral_from_a_friend_or_family_member"
                            value={data.referral_from_a_friend_or_family_member}
                            className="form-control"
                            onChange={(e) => {
                              handleChanges(e);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12} className="text-center mt-5">
                        <Button variant="primary" type="submit">
                          {t("register")}
                        </Button>
                      </Col>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          ) : !LoadData ? (
            <div className="content-wrapper  align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-10 mx-auto">
                  <div className="auth-form-light text-center main-register py-5 px-4 px-sm-5">
                    <h3>No Retreat</h3>
                  </div>{" "}
                </div>
              </div>
            </div>
          ) : (
            <div className="content-wrapper  align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-10 mx-auto">
                  <div className="auth-form-light text-center main-register py-5 px-4 px-sm-5">
                    <Loader />
                  </div>{" "}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
