import BaseApi from "./BaseApi";
class AdminApi extends BaseApi {
  async createCouple(data) {
    return this.API({
      url: "/admin/createCouple",
      data: data,
      method: "post",
    });
  }
  async deleteCouple(data) {
    return this.API({
      url: "/admin/deleteCouple",
      data: data,
      method: "post",
    });
  }

  async editCouple(data) {
    return this.API({
      url: "/admin/editCouple",
      data: data,
      method: "post",
    });
  }

  async getAllCouples(data) {
    return this.API({
      url: "/admin/getAllCouplesList",
      data: data,
      method: "post",
    });
  }

  async getAllCouplesSearch(data) {
    return this.API({
      url: "/admin/getAllCouplesSearch",
      data: data,
      method: "post",
    });
  }

  async getMissingData(data) {
    return this.API({
      url: "/admin/getMissingData",
      data: data,
      method: "get",
    });
  }

  async getCouplesDetail(data) {
    return this.API({
      url: "/admin/getCouplesDetail",
      data: data,
      method: "post",
    });
  }

  async searchKey(key) {
    return await this.API({
      url: `/admin/search/${key}`,
      method: "get",
    });
  }

  async makeCoupleAsSubAdmin(data) {
    return this.API({
      url: "/admin/makeCoupleAsSubAdmin",
      data: data,
      method: "post",
    });
  }

  async changeStatus(data) {
    return this.API({
      url: "/admin/changeStatus",
      data: data,
      method: "post",
    });
  }

  async saveCoupleExcel(data) {
    return await this.API({
      url: "/admin/saveCoupleExcel",
      data: data,
      method: "post",
    });
  }

  async getStateCity() {
    return await this.API({
      url: "/admin/getStateCity",
      method: "post",
    });
  }

  async participatedInRetreats(data) {
    return this.API({
      url: "/admin/participatedInRetreats",
      data: data,
      method: "post",
    });
  }

  async addLogReport(data) {
    return this.API({
      url: "/admin/addlogreport",
      data: data,
      method: "post",
    });
  }

  async getAllLogReport(data) {
    return this.API({
      url: "/admin/getalllogreport",
      data: data,
      method: "post",
    });
  }

  async getAllAdmins() {
    return this.API({
      url: "/admin/getAllAdmins",
      method: "get",
    });
  }

  async updateAdmins(data) {
    return this.API({
      url: "/admin/updateAdmins",
      data: data,
      method: "post",
    });
  }

  async retreatRegisteredCouples(data) {
    return await this.API({
      url: "/admin/retreatRegisteredCouples",
      data: data,
      method: "post",
    });
  }

  async updateNewCoupleRegister(data) {
    return await this.API({
      url: "/admin/updateNewCoupleRegister",
      data: data,
      method: "post",
    });
  }

  async couplesForCheck() {
    return await this.API({
      url: "/admin/couplesForCheck",
      method: "get",
    });
  }

  async allCouples() {
    return await this.API({
      url: "/admin/allCouples",
      method: "get",
    });
  }

  async saveCoupleImage(data) {
    return await this.API({
      url: "/admin/saveCoupleImage",
      data: data,
      method: "post",
    });
  }

  async removeCoupleImage(data) {
    return await this.API({
      url: "/admin/removeCoupleImage",
      data: data,
      method: "post",
    });
  }

  async outerUsers() {
    return await this.API({
      url: "/admin/outerUsers",
      method: "get",
    });
  }

  async geAdminsAndSubAdmins() {
    return await this.API({
      url: "/admin/geAdminsAndSubAdmins",
      method: "get",
    });
  }

  async updateUserRole(data) {
    return await this.API({
      url: "/admin/updateUserRole",
      data: data,
      method: "post",
    });
  }

  async removeUserRole(data) {
    return await this.API({
      url: "/admin/removeUserRole",
      data: data,
      method: "post",
    });
  }

  async changeAdminAccess(data) {
    return await this.API({
      url: "/admin/changeAdminAccess",
      data: data,
      method: "post",
    });
  }

  async deleteCoupleFromRoaster(data) {
    return await this.API({
      url: "/admin/deleteCoupleFromRoaster",
      data: data,
      method: "post",
    });
  }
}

export default AdminApi;
