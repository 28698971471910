import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import Profile from "../../assets/images/couple-default.png";
import ImagePlaceHolder from "../../assets/images/image-placeholder.png";
import AuthApi from "../../api/authApi";
import Helpers from "../../components/Helpers";

const Directory = () => {
  let authApi = new AuthApi();
  const { id } = useParams();
  const [directory, setDirectory] = useState([]);
  const [data, setData] = useState([]);
  const [allRetreatRoasters, setAllRetreatRoasters] = useState([]);

  useEffect(() => {
    getDirectoryDetail(id);
    getRetreatDetail(id);
    getAllRoastersOfRetreat(id);
  }, [id]);

  const getRetreatDetail = async (id) => {
    await authApi
      .getRetreatDetail({ id: id })
      .then((res) => {
        if (res.success) {
          const { schedule, ...rest } = res.data[0];
          const parsedSchedule = schedule ? JSON.parse(schedule) : [];
          const parsedData = {
            ...rest,
            schedule: parsedSchedule.map((entry) => ({
              date: new Date(entry.date),
              from: entry.from,
              to: entry.to,
            })),
            parish: res.data[0].parishId,
          };
          setData(parsedData);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getDirectoryDetail = async (id) => {
    await authApi
      .getDirectoryDetail({ rereatId: id })
      .then((response) => {
        setDirectory(response.data[0]);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getAllRoastersOfRetreat = async (id) => {
    await authApi
      .getAllRoastersOfRetreat({ rereatId: id })
      .then((response) => {
        setAllRetreatRoasters(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  return (
    <div className="container-scroller mainpannel">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper  align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-10 mx-auto">
              <div className="auth-form-light text-center main-register py-5 px-4 px-sm-5">
                <Row className="text-center">
                  {directory && directory.heading ? (
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="directoryHeader howerOption"
                    >
                      <div>
                        <h1>
                          {" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: directory && directory.heading,
                            }}
                          />{" "}
                        </h1>
                      </div>
                    </Col>
                  ) : null}

                  {directory && directory.headerImage ? (
                    <Col
                      className="directoryImgMain howerOption p-0"
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <div className="text-center">
                        <img
                          src={directory && directory.headerImage}
                          className="imageHeader"
                        />
                      </div>
                    </Col>
                  ) : null}
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="directoryHeaderInMain "
                  >
                    <div>
                      <h1>
                        {" "}
                        <em>Retreat Directory</em>
                      </h1>
                      <h2 className="mt-4">
                        <em>
                          {" "}
                          {data.dateFrom === data.dateTo ? (
                            <span>
                              {moment(data && data.dateFrom).format(
                                "MMM DD, YYYY"
                              )}
                            </span>
                          ) : (
                            <span>
                              {moment(data && data.dateFrom).format("MMM") +
                                " " +
                                moment(data.dateFrom).format("DD") +
                                "-" +
                                moment(data.dateTo).format("DD") +
                                ", " +
                                moment(data && data.dateFrom).format("YYYY")}
                            </span>
                          )}
                        </em>
                      </h2>
                      <img
                        src="https://lovestrong.koolderbyacademy.com/logo.png"
                        alt=""
                        srcset=""
                        className="mt-3"
                      />
                      <h2>
                        <em>Live. Love. strong.</em>
                      </h2>
                    </div>
                  </Col>
                  {(allRetreatRoasters &&
                    allRetreatRoasters[0] &&
                    allRetreatRoasters[0].lead &&
                    allRetreatRoasters[0].lead.length) ||
                  (allRetreatRoasters &&
                    allRetreatRoasters[1] &&
                    allRetreatRoasters[1].coLead &&
                    allRetreatRoasters[1].coLead.length) ||
                  (allRetreatRoasters &&
                    allRetreatRoasters[2] &&
                    allRetreatRoasters[2].mentor &&
                    allRetreatRoasters[2].mentor.length) ? (
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="colorTalks mt-3 p-5 leaderShip"
                    >
                      <h2 className="LeadHeading">Leadership</h2>
                      {allRetreatRoasters &&
                        allRetreatRoasters[0] &&
                        allRetreatRoasters[0].lead &&
                        allRetreatRoasters[0].lead.map((item, i) => {
                          return (
                            <Row key={i} className="mt-5">
                              <Col
                                md={5}
                                className="coupleImages coupleImageHower"
                              >
                                {(item && item.roasterImage == "") ||
                                item.roasterImage == undefined ? (
                                  <div className="edit-profile-img mb-2  imageHeader">
                                    <img
                                      src={Profile}
                                      className="shadowImage"
                                    />
                                  </div>
                                ) : (
                                  <div className="edit-profile-img mb-2  imageHeader">
                                    <>
                                      <img src={item && item.roasterImage} />
                                    </>{" "}
                                  </div>
                                )}{" "}
                              </Col>
                              <Col md={7} className="coupl_detail">
                                {" "}
                                <h2 className="mt-3 mb-3">
                                  {`${
                                    item && item.hisFirstName
                                      ? item.hisFirstName
                                      : ""
                                  } ${item && item.hisFirstName ? "&" : ""} ${
                                    item && item.herFirstName
                                      ? item.herFirstName
                                      : ""
                                  } ${
                                    item && item.hisLastName
                                      ? item.hisLastName
                                      : ""
                                  }`}
                                </h2>
                                <h4>
                                  {" "}
                                  {"His : " +
                                    `${
                                      item && item.hisMobile
                                        ? item.hisMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.hisEmail ? item.hisEmail : ""
                                }`}</h4>
                                <h4>
                                  {" "}
                                  {"Her : " +
                                    `${
                                      item && item.herMobile
                                        ? item.herMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.herEmail ? item.herEmail : ""
                                }`}</h4>{" "}
                                {item && item.anniversary_date ? (
                                  <h4>
                                    {"Anniversary Date : " +
                                      `${
                                        item && item.anniversary_date
                                          ? Helpers.convertUtcToTimezone(
                                              item.anniversary_date,
                                              "MM/DD"
                                            )
                                          : ""
                                      }`}
                                  </h4>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          );
                        })}

                      {allRetreatRoasters &&
                        allRetreatRoasters[1] &&
                        allRetreatRoasters[1].coLead &&
                        allRetreatRoasters[1].coLead.map((item, i) => {
                          return (
                            <Row key={i} className="mt-5">
                              <Col
                                md={5}
                                className="coupleImages coupleImageHower"
                              >
                                {(item && item.roasterImage == "") ||
                                item.roasterImage == undefined ? (
                                  <div className="edit-profile-img mb-2  imageHeader">
                                    <img
                                      src={Profile}
                                      className="shadowImage"
                                    />
                                  </div>
                                ) : (
                                  <div className="edit-profile-img mb-2  imageHeader">
                                    <>
                                      <img
                                        src={item && item.roasterImage}
                                        className="shadowImage"
                                      />
                                    </>{" "}
                                  </div>
                                )}{" "}
                              </Col>
                              <Col md={7} className="coupl_detail">
                                {" "}
                                <h2 className="mt-3 mb-3">
                                  {`${
                                    item && item.hisFirstName
                                      ? item.hisFirstName
                                      : ""
                                  } ${item && item.hisFirstName ? "&" : ""} ${
                                    item && item.herFirstName
                                      ? item.herFirstName
                                      : ""
                                  } ${
                                    item && item.hisLastName
                                      ? item.hisLastName
                                      : ""
                                  }`}
                                </h2>
                                <h4>
                                  {" "}
                                  {"His : " +
                                    `${
                                      item && item.hisMobile
                                        ? item.hisMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.hisEmail ? item.hisEmail : ""
                                }`}</h4>
                                <h4>
                                  {" "}
                                  {"Her : " +
                                    `${
                                      item && item.herMobile
                                        ? item.herMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.herEmail ? item.herEmail : ""
                                }`}</h4>{" "}
                                {item && item.anniversary_date ? (
                                  <h4>
                                    {"Anniversary Date : " +
                                      `${
                                        item && item.anniversary_date
                                          ? Helpers.convertUtcToTimezone(
                                              item.anniversary_date,
                                              "MM/DD"
                                            )
                                          : ""
                                      }`}
                                  </h4>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          );
                        })}

                      {allRetreatRoasters &&
                        allRetreatRoasters[2] &&
                        allRetreatRoasters[2].mentor &&
                        allRetreatRoasters[2].mentor.map((item, i) => {
                          return (
                            <Row key={i} className="my-5">
                              <Col
                                md={5}
                                className="coupleImages coupleImageHower"
                              >
                                {(item && item.roasterImage == "") ||
                                item.roasterImage == undefined ? (
                                  <div className="edit-profile-img mb-2  imageHeader">
                                    <img
                                      src={Profile}
                                      className="shadowImage"
                                    />
                                  </div>
                                ) : (
                                  <div className="edit-profile-img mb-2  imageHeader">
                                    <>
                                      <img
                                        src={item && item.roasterImage}
                                        className="shadowImage"
                                      />
                                    </>{" "}
                                  </div>
                                )}{" "}
                              </Col>
                              <Col md={7} className="coupl_detail">
                                {" "}
                                <h2 className="mt-3 mb-3">
                                  {`${
                                    item && item.hisFirstName
                                      ? item.hisFirstName
                                      : ""
                                  } ${item && item.hisFirstName ? "&" : ""} ${
                                    item && item.herFirstName
                                      ? item.herFirstName
                                      : ""
                                  } ${
                                    item && item.hisLastName
                                      ? item.hisLastName
                                      : ""
                                  }`}
                                </h2>
                                <h4>
                                  {" "}
                                  {"His : " +
                                    `${
                                      item && item.hisMobile
                                        ? item.hisMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.hisEmail ? item.hisEmail : ""
                                }`}</h4>
                                <h4>
                                  {" "}
                                  {"Her : " +
                                    `${
                                      item && item.herMobile
                                        ? item.herMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.herEmail ? item.herEmail : ""
                                }`}</h4>{" "}
                                {item && item.anniversary_date ? (
                                  <h4>
                                    {"Anniversary Date : " +
                                      `${
                                        item && item.anniversary_date
                                          ? Helpers.convertUtcToTimezone(
                                              item.anniversary_date,
                                              "MM/DD"
                                            )
                                          : ""
                                      }`}
                                  </h4>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                    </Col>
                  ) : null}
                  {allRetreatRoasters &&
                  allRetreatRoasters[4] &&
                  allRetreatRoasters[4].retreatant.length &&
                  allRetreatRoasters[4].retreatant ? (
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="colorTalks mt-3 p-5 leaderShip"
                    >
                      <h2 className="LeadHeading">Retreatants</h2>
                      {allRetreatRoasters &&
                        allRetreatRoasters[4] &&
                        allRetreatRoasters[4].retreatant &&
                        allRetreatRoasters[4].retreatant.map((item, i) => {
                          return (
                            <Row className="mt-5" key={i}>
                              <Col
                                md={5}
                                className="coupleImages coupleImageHower"
                              >
                                {(item && item.roasterImage == "") ||
                                item.roasterImage == undefined ? (
                                  <div className="edit-profile-img mb-2 imageHeader ">
                                    <img
                                      src={Profile}
                                      className="shadowImage"
                                    />
                                  </div>
                                ) : (
                                  <div className="edit-profile-img mb-2  imageHeader ">
                                    <>
                                      <img
                                        src={item && item.roasterImage}
                                        className="shadowImage"
                                      />
                                    </>{" "}
                                  </div>
                                )}{" "}
                              </Col>
                              <Col md={7} className="coupl_detail">
                                {" "}
                                <h2 className="mt-3 mb-3">
                                  {`${
                                    item && item.hisFirstName
                                      ? item.hisFirstName
                                      : ""
                                  } ${item && item.hisFirstName ? "&" : ""} ${
                                    item && item.herFirstName
                                      ? item.herFirstName
                                      : ""
                                  } ${
                                    item && item.hisLastName
                                      ? item.hisLastName
                                      : ""
                                  }`}
                                </h2>
                                <h4>
                                  {" "}
                                  {"His : " +
                                    `${
                                      item && item.hisMobile
                                        ? item.hisMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.hisEmail ? item.hisEmail : ""
                                }`}</h4>
                                <h4>
                                  {" "}
                                  {"Her : " +
                                    `${
                                      item && item.herMobile
                                        ? item.herMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.herEmail ? item.herEmail : ""
                                }`}</h4>{" "}
                                {item && item.anniversary_date ? (
                                  <h4>
                                    {"Anniversary Date : " +
                                      `${
                                        item && item.anniversary_date
                                          ? Helpers.convertUtcToTimezone(
                                              item.anniversary_date,
                                              "MM/DD"
                                            )
                                          : ""
                                      }`}
                                  </h4>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                    </Col>
                  ) : null}
                  {allRetreatRoasters &&
                  allRetreatRoasters[3] &&
                  allRetreatRoasters[3].team &&
                  allRetreatRoasters[3].team.length ? (
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="colorTalks mt-3 p-5 leaderShip"
                    >
                      <img
                        src="https://lovestrong.koolderbyacademy.com/logo.png"
                        alt=""
                        srcset=""
                        className="footer-lovestrong-logo"
                      />
                      <h2 className="mt-3 mb-3">
                        <em style={{ color: "#d2b74c", fontWeight: "700" }}>
                          Live. Love. strong.
                        </em>
                      </h2>
                      <h2 className="LeadHeading">Team</h2>
                      {allRetreatRoasters &&
                        allRetreatRoasters[3] &&
                        allRetreatRoasters[3].team &&
                        allRetreatRoasters[3].team.map((item, i) => {
                          return (
                            <Row className="mt-5">
                              <Col
                                md={5}
                                className="coupleImages coupleImageHower"
                              >
                                {(item && item.roasterImage == "") ||
                                item.roasterImage == undefined ? (
                                  <div className="edit-profile-img mb-2 imageHeader">
                                    <img
                                      src={Profile}
                                      className="shadowImage"
                                    />
                                  </div>
                                ) : (
                                  <div className="edit-profile-img mb-2 imageHeader">
                                    <>
                                      <img
                                        src={item && item.roasterImage}
                                        className="shadowImage"
                                      />
                                    </>{" "}
                                  </div>
                                )}{" "}
                              </Col>
                              <Col md={7} className="coupl_detail">
                                {" "}
                                <h2 className="mt-3 mb-3">
                                  {`${
                                    item && item.hisFirstName
                                      ? item.hisFirstName
                                      : ""
                                  } ${item && item.hisFirstName ? "&" : ""} ${
                                    item && item.herFirstName
                                      ? item.herFirstName
                                      : ""
                                  } ${
                                    item && item.hisLastName
                                      ? item.hisLastName
                                      : ""
                                  }`}
                                </h2>
                                <h4>
                                  {" "}
                                  {"His : " +
                                    `${
                                      item && item.hisMobile
                                        ? item.hisMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.hisEmail ? item.hisEmail : ""
                                }`}</h4>
                                <h4>
                                  {" "}
                                  {"Her : " +
                                    `${
                                      item && item.herMobile
                                        ? item.herMobile
                                        : ""
                                    }`}
                                </h4>
                                <h4>{`${
                                  item && item.herEmail ? item.herEmail : ""
                                }`}</h4>{" "}
                                {item && item.anniversary_date ? (
                                  <h4>
                                    {"Anniversary Date : " +
                                      `${
                                        item && item.anniversary_date
                                          ? Helpers.convertUtcToTimezone(
                                              item.anniversary_date,
                                              "MM/DD"
                                            )
                                          : ""
                                      }`}
                                  </h4>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                    </Col>
                  ) : null}
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="colorTalks mt-3 leaderShip directory_footer "
                  >
                    {directory &&
                    (directory.footerImage1 || directory.footerImage2) ? (
                      <div>
                        {directory.footerImage1 && (
                          <div className="mb-2 text-center footer_images">
                            <img
                              src={directory.footerImage1}
                              className="imageHeader"
                              alt="Footer Image 1"
                            />
                          </div>
                        )}
                        <div className="retdet1">
                          <h2>Holy Spirit Lovestrong Team</h2>
                          <h2>
                            <em>
                              {data.dateFrom === data.dateTo ? (
                                <span>
                                  {moment(data.dateFrom).format("MMM DD, YYYY")}
                                </span>
                              ) : (
                                <span>
                                  {moment(data.dateFrom).format("MMM")}{" "}
                                  {moment(data.dateFrom).format("DD")}-
                                  {moment(data.dateTo).format("DD")},{" "}
                                  {moment(data.dateFrom).format("YYYY")}
                                </span>
                              )}
                            </em>
                          </h2>
                        </div>
                        {directory.footerImage2 && (
                          <div className="mb-2 text-center footer_images">
                            <img
                              src={directory.footerImage2}
                              height={"286px"}
                              width={"824px"}
                              className="imageHeader"
                              alt="Footer Image 2"
                            />
                          </div>
                        )}
                      </div>
                    ) : null}

                    <h2 className="mt-5 mb-2 dirTitle">
                      <em
                        className="mt-5 mb-2 dirTitle"
                        dangerouslySetInnerHTML={{
                          __html: directory && directory.heading,
                        }}
                      />{" "}
                    </h2>
                    <dir className="howerOption">
                      <h2 className="mb-4">
                        {directory && directory.footerText ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: directory && directory.footerText,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </h2>
                    </dir>
                    <img
                      src="https://lovestrong.koolderbyacademy.com/logo.png"
                      alt=""
                      srcset=""
                      className="logo_image"
                    />
                    <h2 className="mt-3 mb-3">
                      <em style={{ color: "#d2b74c", fontWeight: "700" }}>
                        Live. Love. strong.
                      </em>
                    </h2>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directory;
