import React from "react";
import remove from "./../assets/images/remove.png";
import { Button, Modal } from "react-bootstrap";

function DeleteModel(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>DELETE!</Modal.Title>
        </Modal.Header>
        <div className="Delete_img">
          <img className="icon-box" src={remove} alt="logo" />
        </div>
        <Modal.Body>
          {props.selectionDelete === "temporary" ? (
            <>
              <h5 className="mt-2 deleteMessage">
                You have selected to temporarily DELETE the following Retreat:
                <h4 className="mt-1">
                  <b>{props.title}</b>
                </h4>
              </h5>
              <h5 className="deleteMessage mt-4">
                This action will also DELETE the Roaster data, Program, and
                Directory associated with this Retreat.
              </h5>
            </>
          ) : props.selectionDelete === "permanent" ? (
            <>
              <h5 className="mt-2 deleteMessage">
                You have selected to permanent DELETE the following Retreat:
                <h4 className="mt-1">
                  <b>{props.title}</b>
                </h4>
              </h5>
              <h5 className="deleteMessage mt-4">
                This action will also DELETE the Roaster data, Program, and
                Directory associated with this Retreat.
              </h5>
            </>
          ) : (
            <h5 className="mt-3">Are you sure you want to Delete?</h5>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={props.DeleteData}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteModel;
