import BaseApi from "./BaseApi";
class DirectoryApi extends BaseApi {
  async getAllRoastersOfRetreat(data) {
    return await this.API({
      url: "/directory/getAllRoastersOfRetreat",
      data: data,
      method: "post",
    });
  }

  async getDirectoryDetail(data) {
    return await this.API({
      url: "/directory/getDirectoryDetail",
      data: data,
      method: "post",
    });
  }

  async createDirectory(data) {
    return await this.API({
      url: "/directory/createDirectory",
      data: data,
      method: "post",
    });
  }

  async saveCoupleImage(data) {
    return await this.API({
      url: "/directory/saveCoupleImage",
      data: data,
      method: "post",
    });
  }

  async removeImage(data) {
    return await this.API({
      url: "/directory/removeImage",
      data: data,
      method: "post",
    });
  }

  async removeDirectoryImage(data) {
    return await this.API({
      url: "/directory/removeDirectoryImage",
      data: data,
      method: "post",
    });
  }

}

export default DirectoryApi;
