import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import fbIcon from "../../assets/images/facebook-logo.webp";
import AuthApi from "../../api/authApi";

const Program = () => {
  const [roasterData, setRoasterData] = useState([]);
  const [program, setProgram] = useState([]);
  const [retreatDetail, setRetreatDetail] = useState([]);
  let authApi = new AuthApi();
  const { id } = useParams();

  useEffect(() => {
    getAllRoastersInfo(id);
    getProgramDetail(id);
  }, [id]);

  const getAllRoastersInfo = async (id) => {
    await authApi
      .getAllRoastersInfo({ rereatId: id })
      .then((response) => {
        setRoasterData(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getProgramDetail = async (id) => {
    await authApi
      .getProgramDetail({ rereatId: id })
      .then((response) => {
        const modifiedData = { ...response.data[0] };
        if (modifiedData.footerImages) {
          modifiedData.footerImages = JSON.parse(modifiedData.footerImages);
        }
        setProgram(modifiedData);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  return (
    <div className="container-scroller mainpannel">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper  align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-10 mx-auto">
              <div className="auth-form-light text-center main-register py-5 px-4 px-sm-5">
                <Row className="text-center">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="colorTalks mt-3 p-5"
                  >
                    <img
                      src="https://lovestrong.koolderbyacademy.com/logo.png"
                      alt=""
                      srcset=""
                      className="logo_image"
                    />
                    <h2 style={{ color: "#d2b74c", fontWeight: "700" }}>
                      MINISTRY
                    </h2>
                    <h2 className="mt-3 mb-3">
                      <em style={{ color: "#d2b74c", fontWeight: "700" }}>
                        Live. Love. strong.
                      </em>
                    </h2>
                    {program && program.headerImage ? (
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="mb-5 mt-5">
                          <>
                            <img
                              src={program && program.headerImage}
                              className="imageHeader"
                            />
                          </>{" "}
                        </div>
                      </Col>
                    ) : null}
                  </Col>
                  {(roasterData &&
                    roasterData[0] &&
                    roasterData[0].role &&
                    roasterData[0].role.length) ||
                  (roasterData &&
                    roasterData[1] &&
                    roasterData[1].speakers &&
                    roasterData[1].speakers.length) ||
                  (roasterData &&
                    roasterData[2] &&
                    roasterData[2].services.length) ? (
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="colorTalks mt-3 program_couple"
                      style={{
                        backgroundColor: "rgb(234 232 232)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <Container className="table_Content_Data">
                        {roasterData &&
                        roasterData[0] &&
                        roasterData[0].role &&
                        roasterData[0].role.length ? (
                          <h2 className="mt-5 mb-5">RETREAT TEAM</h2>
                        ) : null}
                        {roasterData &&
                          roasterData[0] &&
                          roasterData[0].role &&
                          roasterData[0].role.map((item, i) => {
                            return (
                              <Row key={i} className="table_content mt-1">
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="mt-3"
                                >
                                  {" "}
                                  <h3>{item.role} Couple</h3>{" "}
                                </Col>
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="mt-3"
                                >
                                  {" "}
                                  <h3 className="">
                                    {`${
                                      item && item.hisFirstName
                                        ? item.hisFirstName
                                        : ""
                                    } ${item && item.hisFirstName ? "&" : ""} ${
                                      item && item.herFirstName
                                        ? item.herFirstName
                                        : ""
                                    } ${
                                      item && item.hisLastName
                                        ? item.hisLastName
                                        : ""
                                    }`}
                                  </h3>
                                </Col>
                              </Row>
                            );
                          })}
                        {roasterData &&
                        roasterData[1] &&
                        roasterData[1].speakers &&
                        roasterData[1].speakers.length ? (
                          <>
                            {" "}
                            <hr /> <h2 className="mt-5 mb-5">SPEAKERS</h2>
                          </>
                        ) : null}
                        {roasterData &&
                          roasterData[1] &&
                          roasterData[1].speakers &&
                          roasterData[1].speakers.map((item, i) => {
                            return (
                              <Row key={i} className="table_content mt-1">
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="mt-3"
                                >
                                  {" "}
                                  <h3>{item.talk}</h3>{" "}
                                </Col>
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="mt-3"
                                >
                                  {" "}
                                  <h3 className="">
                                    {`${
                                      item && item.hisFirstName
                                        ? item.hisFirstName
                                        : ""
                                    } ${item && item.hisFirstName ? "&" : ""} ${
                                      item && item.herFirstName
                                        ? item.herFirstName
                                        : ""
                                    } ${
                                      item && item.hisLastName
                                        ? item.hisLastName
                                        : ""
                                    }`}
                                  </h3>
                                </Col>
                              </Row>
                            );
                          })}

                        {roasterData &&
                        roasterData[2] &&
                        roasterData[2].services.length ? (
                          <>
                            {" "}
                            <hr />
                            <h2 className="mt-5 mb-5">SUPPORT TEAMS</h2>
                          </>
                        ) : null}

                        {roasterData &&
                          roasterData[2] &&
                          roasterData[2].services &&
                          (() => {
                            let previousService = "";
                            return roasterData[2].services.map((item, i) => {
                              const showServiceHeader =
                                item.service !== previousService;
                              previousService = item.service;
                              return (
                                <>
                                  {showServiceHeader && (
                                    <h3
                                      key={`service-${i}`}
                                      className="mt-2 mb-3 serviceTerms"
                                    >
                                      {item.service}{" "}
                                    </h3>
                                  )}
                                  <Row key={i} className="table_content mt-1">
                                    <Col
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={6}
                                      className="mt-3"
                                    >
                                      {" "}
                                      <h3 className="">
                                        {`${
                                          item && item.hisFirstName
                                            ? item.hisFirstName
                                            : ""
                                        } ${
                                          item && item.hisFirstName ? "&" : ""
                                        } ${
                                          item && item.herFirstName
                                            ? item.herFirstName
                                            : ""
                                        } ${
                                          item && item.hisLastName
                                            ? item.hisLastName
                                            : ""
                                        }`}
                                      </h3>
                                    </Col>
                                    <Col
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={6}
                                      className="mt-3"
                                    >
                                      {" "}
                                      <h3>{item.role}</h3>
                                    </Col>
                                  </Row>
                                </>
                              );
                            });
                          })()}
                      </Container>
                    </Col>
                  ) : null}
                  {program && program.wisdom_from_mt ? (
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="colorTalks mt-3 program_couple"
                    >
                      <div className="howerOption">
                        <h3 className="mb-4 roaster_list">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: program && program.wisdom_from_mt,
                            }}
                          />
                        </h3>
                      </div>
                    </Col>
                  ) : null}
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="colorTalks mt-3 p-5"
                  >
                    <img
                      src="https://lovestrong.koolderbyacademy.com/logo.png"
                      alt=""
                      srcset=""
                      className="logo_image1"
                    />
                    <h3 style={{ color: "#d2b74c", fontWeight: "700" }}>
                      MINISTRY
                    </h3>
                    <h5 className="mt-2 mb-3">
                      <em style={{ color: "#d2b74c", fontWeight: "700" }}>
                        LIVE. LOVE. STRONG.
                      </em>
                    </h5>
                    <div className="howerOption">
                      <h3
                        className="mb-4 roaster_list"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {program && program.guidelines ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: program && program.guidelines,
                            }}
                          />
                        ) : null}
                      </h3>
                    </div>
                  </Col>
                  {roasterData &&
                    roasterData[2] &&
                    roasterData[1].speakers &&
                    roasterData[1].speakers.map((item, i) => {
                      return item && item.talk_question ? (
                        <Row key={i} className="mt-1">
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="colorTalks mt-3 p-5"
                          >
                            <div className="speakersQuestions">
                              <h3>{item.talk}</h3>
                              <h4>
                                {`${
                                  item && item.hisFirstName
                                    ? item.hisFirstName
                                    : ""
                                } ${item && item.hisFirstName ? "&" : ""} ${
                                  item && item.herFirstName
                                    ? item.herFirstName
                                    : ""
                                } ${
                                  item && item.hisLastName
                                    ? item.hisLastName
                                    : ""
                                }`}
                              </h4>
                            </div>

                            <div className="howerOption talk-question">
                              <h2
                                className="mb-4 roaster_list"
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item && item.talk_question,
                                  }}
                                />
                              </h2>
                            </div>
                          </Col>
                        </Row>
                      ) : null;
                    })}
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="colorTalks mt-3 p-5 "
                  >
                    <div className="howerOption">
                      <h2
                        className="mb-4 roaster_list"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {program && program.parishMission ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: program && program.parishMission,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </h2>
                    </div>
                    <Row className="parishes-logos">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="mt-5 mb-5"
                      >
                        <img
                          src="https://lovestrong.koolderbyacademy.com/logo.png"
                          alt=""
                          srcset=""
                          className="logo_image5"
                        />
                        <h5 className="mt-2 mb-3">
                          <em style={{ color: "#d2b74c", fontWeight: "700" }}>
                            LIVE. LOVE. STRONG.
                          </em>
                        </h5>
                        <a
                          href="https://lovestrong.koolderbyacademy.com"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          {" "}
                          <h2
                            style={{
                              color: "black",
                              fontWeight: "700",
                              float: "left",
                              float: "inherit",
                              textDecoration: "none",
                            }}
                            className="lovestrongLink"
                          >
                            www.LOVESTRONGMarriage.com
                          </h2>
                        </a>
                      </Col>
                      {program && program.qrCodeImage ? (
                        <Col
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className="mt-3 qrCode coupleImages5 coupleImageHower"
                        >
                          <div className="edit-profile-img">
                            <img
                              src={program && program.qrCodeImage}
                              className="shadowImage"
                            />
                          </div>
                        </Col>
                      ) : null}
                      {program && program.fbLink ? (
                        <Col lg={2} md={6} sm={6} xs={12}>
                          <a href={program && program.fbLink}>
                            <img
                              src={fbIcon}
                              alt=""
                              srcset=""
                              className="logo_image1"
                            />
                          </a>
                          {/* <div
                            dangerouslySetInnerHTML={{
                              __html: program && program.fbLink,
                            }}
                          /> */}
                        </Col>
                      ) : null}
                      {program && program.parishImageUrl ? (
                        <Col lg={3} md={6} sm={6} xs={12} className="mt-3">
                          <img
                            src={program && program.parishImageUrl}
                            alt=""
                            srcset=""
                            className="parishLogo"
                          />
                        </Col>
                      ) : null}
                      {program && program.parishName ? (
                        <Col
                          lg={4}
                          md={6}
                          sm={6}
                          xs={12}
                          className="parishDetail mt-0"
                        >
                          <h3>{program && program.parishName}</h3>

                          <h5> {program && program.parishAddress}</h5>
                          <h5>
                            {" "}
                            {program && program.parishCity}{" "}
                            {program && program.parishState ? ", " : ""}
                            {program && program.parishState}
                          </h5>
                          <h5> {program && program.parishzip}</h5>
                          <h5> {program && program.parishLink}</h5>
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className=" colorTalks mt-3 p-5  howerOption"
                  >
                    {program &&
                      program.footerImages &&
                      program.footerImages.length > 0 &&
                      program.footerImages.map((item, index) => {
                        return (
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mt-3 howerOption "
                          >
                            <div className="text-center">
                              <img src={item} className="imageHeader" />
                            </div>
                          </Col>
                        );
                      })}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
