import BaseApi from "./BaseApi";
class PerishApi extends BaseApi {
  async addPerish(data) {
    return await this.API({
      url: "/perish/addperish",
      data: data,
      method: "post",
    });
  }
  // async getPerishList(data) {
  //   return await this.API({
  //     url: "/perish/getallperishlist",
  //     method: "get",
  //   });
  // }

  async getAllPerishSearch(data) {
    return await this.API({
      url: "/perish/getAllPerishSearch",
      data: data,
      method: "post",
    });
  }

  async deletePerish(data, service_id) {
    return await this.API({
      url: `/perish/deleteperish/${service_id}`,
      data: data,
      method: "delete",
    });
  }

  async searchKey(data, key) {
    return await this.API({
      url: `/perish/search/${key}`,
      method: "get",
    });
  }

  async updatePerish(data, service_id) {
    return await this.API({
      url: `/perish/updateperish/${service_id}`,
      data: data,
      method: "put",
    });
  }
}

export default PerishApi;
