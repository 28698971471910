import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import AdminApi from "../../api/admin";
import DeleteModel from "../../components/DeleteModel";
import AlertModal from "../../components/AlertModel";
import RetreatApi from "../../api/retreatApi";
import { Cookies, useCookies } from "react-cookie";

const AdminDashboard = () => {
  const [show, setShow] = useState(false);
  const [mailId, setMailId] = useState([]);
  const [removeId, setRemoveId] = useState([]);
  const [allcouple, setAllcouple] = useState([]);
  const [allAdminList, setAllAdminList] = useState([]);
  const [allSubAdminList, setAllSubAdminList] = useState([]);
  const [showAccessAlert, setShowAccessAlert] = useState(false);
  const [coupleSelectAdmin, setCoupleSelectAdmin] = useState([]);
  const [removeAccessShow, setRemoveAccessShow] = useState(false);
  const [retAdminAccessAlert, setRetAdminAccessAlert] = useState(false);
  const [coupleSelectSubAdmin, setCoupleSelectSubAdmin] = useState([]);
  const [allRetreatSubAdminList, setAllRetreatSubAdminList] = useState([]);

  let adminApi = new AdminApi();
  let retreatApi = new RetreatApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;

  useEffect(() => {
    getAllCouples();
    adminsAndSubAdmins();
    getAllretreatSubAdmins();
  }, []);

  const adminsAndSubAdmins = async () => {
    await adminApi
      .geAdminsAndSubAdmins()
      .then(async (res) => {
        if (res.success) {
          if (res.admins) {
            setAllAdminList(res.admins);
          }
          if (res.subAdmins) {
            setAllSubAdminList(res.subAdmins);
          }
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getAllretreatSubAdmins = async () => {
    await retreatApi
      .allretreatSubAdmins()
      .then(async (res) => {
        if (res.success) {
          setAllRetreatSubAdminList(res.data);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getAllCouples = async () => {
    await adminApi
      .allCouples()
      .then(async (res) => {
        if (res.success) {
          let Allcouple = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.hisEmail) {
                Allcouple.push({
                  label: `${item.hisFirstName} ${item.hisLastName} (${item.hisEmail})`,
                  value: item.hisEmail,
                });
              }
              if (item.herEmail) {
                Allcouple.push({
                  label: `${item.herFirstName} ${item.herLastName} (${item.herEmail})`,
                  value: item.herEmail,
                });
              }
            });
          setAllcouple(Allcouple);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleSelectOption = (val, selection) => {
    if (selection === "forAdmins") {
      setCoupleSelectAdmin(val);
    } else {
      setCoupleSelectSubAdmin(val);
    }
  };

  const handleSelectSubmit = async () => {
    let submitData = null;
    if (coupleSelectAdmin && Object.keys(coupleSelectAdmin).length > 0) {
      submitData = {
        role: "ADMIN",
        email: coupleSelectAdmin.value,
      };
    }
    if (coupleSelectSubAdmin && Object.keys(coupleSelectSubAdmin).length > 0) {
      submitData = {
        role: "SUBADMIN",
        email: coupleSelectSubAdmin.value,
      };
    }
    if (submitData) {
      try {
        const res = await adminApi.updateUserRole(submitData);
        if (res.success) {
          setCoupleSelectSubAdmin([]);
          setCoupleSelectAdmin([]);
          toast.success(res.msg);
          adminsAndSubAdmins();
        } else {
          toast.error(res.msg);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.error("No valid data to submit.");
    }
  };

  const handleShow = (i) => {
    setRemoveId(i.id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShowOfAccess = (retreatData, removed_mail) => {
    setRemoveId(retreatData.id);
    setMailId(removed_mail);
    setRemoveAccessShow(true);
  };

  const handleCloseOfAccess = () => {
    setRemoveAccessShow(false);
  };

  const removeRole = () => {
    adminApi
      .removeUserRole({
        removeId: removeId,
      })
      .then((res) => {
        adminsAndSubAdmins();
        toast.success(res.msg);
        setRemoveId([]);
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error);
      });
  };

  const removeAccessFromRetreat = () => {
    // Combine array elements into a single object
    retreatApi
      .removeAccessFromRetreat({
        remove_access_mail: mailId,
        retreatId: removeId,
      })
      .then((res) => {
        // Check if response has a message
        if (res.msg) {
          toast.success(res.msg);
        } else {
          toast.success("Access removed successfully");
        }
        getAllretreatSubAdmins();
        setMailId([]);
        handleCloseOfAccess();
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while removing access");
      });
  };

  const handleShowAlert = (event, item) => {
    let { name, value } = event.target;
    setRemoveId(item);
    setShowAccessAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAccessAlert(false);
  };

  const adminAccess = () => {
    adminApi
      .changeAdminAccess({
        id: removeId.id,
        isDisabled: removeId.isDisabled,
      })
      .then((res) => {
        adminsAndSubAdmins();
        toast.success(res.msg);
        setRemoveId([]);
        handleCloseAlert();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error);
      });
  };

  const showRetreatAdminAlert = (retreat, UserData) => {
    setRemoveId(UserData);
    setMailId(retreat.id);
    setRetAdminAccessAlert(true);
  };

  const closeretAdminAlert = () => {
    setRetAdminAccessAlert(false);
  };

  const retreatAdminAccess = () => {
    retreatApi
      .retreatAdminAccess({
        userId: removeId.id,
        retreatId: mailId,
        checkAccess: removeId.isDisabled,
      })
      .then((res) => {
        getAllretreatSubAdmins();
        toast.success(res.msg);
        setRemoveId([]);
        setMailId([]);
        closeretAdminAlert();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error);
      });
  };

  return (
    <div className="settings">
      <div className="card ext-space">
        <div className="card-body">
          <h3 className="card-title">Admin Dashboard</h3>
          <p className="card-description">
            <div className="media1">
              <div className="media-body">
                <Row className="m-0">
                  <Col lg={4} md={6} sm={6} className="col-border-admin-access">
                    <h4 className="card-title">Super Admins</h4>
                    {allAdminList && allAdminList.length
                      ? allAdminList.map((item) => {
                          return (
                            <div
                              className="form-check roleBasedAccess"
                              key={item.id}
                            >
                              <label className="form-check-label newLabel">
                                <input
                                  disabled={
                                    item.email === "tkolkebeck@me.com" ||
                                    item.email === "jimmieflores@gmail.com" ||
                                    item.email === "dulceaflores@gmail.com" ||
                                    item.email === "kolkebeckd@gmail.com" ||
                                    item.email === userData.email
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                  className="form-check-input"
                                  name="deleteAccess"
                                  onChange={(e) => handleShowAlert(e, item)}
                                  value={item.id}
                                  checked={item.isDisabled === 1 ? false : true}
                                />
                                {item && item.firstName ? item.firstName : ""}{" "}
                                {item && item.lastName ? item.lastName : ""} (
                                {item && item.email ? item.email : ""}) (
                                {item && item.password ? item.password : ""})
                                <i className="input-helper"></i>
                              </label>

                              {item.email === "tkolkebeck@me.com" ||
                              item.email === "jimmieflores@gmail.com" ||
                              item.email === "dulceaflores@gmail.com" ||
                              item.email === "kolkebeckd@gmail.com" ||
                              item.email === userData.email ? null : (
                                <i
                                  className="mdi mdi-delete-circle text-md font-size deleteButton delete-icon8"
                                  onClick={() => handleShow(item)}
                                  title="Delete"
                                ></i>
                              )}
                            </div>
                          );
                        })
                      : " "}
                    <div className="form-check d-flex">
                      <Select
                        className="mt-3 actions-couple add-role-couple"
                        id="couple"
                        name="couple"
                        labelledBy="Select"
                        isSearchable={true}
                        isMulti={false}
                        placeholder="Select Couple"
                        menuPlacement="top"
                        options={allcouple}
                        onChange={(val) => {
                          handleSelectOption(val, "forAdmins");
                        }}
                        value={coupleSelectAdmin}
                      />
                      <div className="addAdmin">
                        <i
                          type="button"
                          onClick={() => {
                            handleSelectSubmit();
                          }}
                          className="mdi mdi-plus-circle btn-icon-prepend mt-0 mb-3 plus-icon-role"
                        ></i>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={6} sm={6} className="col-border-admin-access">
                    <h4 className="card-title">Sub Admins</h4>
                    {allSubAdminList && allSubAdminList.length
                      ? allSubAdminList.map((item) => {
                          return (
                            <div
                              className="form-check roleBasedAccess"
                              key={item.id}
                            >
                              <label className="form-check-label newLabel">
                                <input
                                  disabled={
                                    item.email === userData.email ? true : false
                                  }
                                  type="checkbox"
                                  className="form-check-input"
                                  name="deleteAccess"
                                  onChange={(e) => handleShowAlert(e, item)}
                                  value={item.id}
                                  checked={item.isDisabled === 1 ? false : true}
                                />
                                {item && item.firstName ? item.firstName : ""}{" "}
                                {item && item.lastName ? item.lastName : ""} (
                                {item && item.email ? item.email : ""}) (
                                {item && item.password ? item.password : ""})
                                <i className="input-helper"></i>
                              </label>
                              {item.email === userData.email ? null : (
                                <i
                                  onClick={() => handleShow(item)}
                                  className="mdi mdi-delete-circle text-md font-size deleteButton delete-icon8"
                                  title="Delete"
                                ></i>
                              )}
                            </div>
                          );
                        })
                      : " "}
                    <div className="form-check d-flex">
                      <Select
                        className="mt-3 actions-couple add-role-couple"
                        id="couple"
                        name="couple"
                        labelledBy="Select"
                        isSearchable={true}
                        isMulti={false}
                        menuPlacement="top"
                        placeholder="Select Couple"
                        options={allcouple}
                        value={coupleSelectSubAdmin}
                        onChange={(val) => {
                          handleSelectOption(val, "forSubAdmin");
                        }}
                      />
                      <div className="addAdmin">
                        <i
                          type="button"
                          onClick={() => {
                            handleSelectSubmit();
                          }}
                          className="mdi mdi-plus-circle btn-icon-prepend mt-0 mb-3 plus-icon-role"
                        ></i>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <h4 className="card-title">Retreat Sub Admins</h4>
                    {allRetreatSubAdminList &&
                      allRetreatSubAdminList.map((item, index) => (
                        <div key={index}>
                          <h5 className="mb-3 mt-4 retreatAdminTitle">
                            {item.title}
                          </h5>
                          {item.subAdmins &&
                            item.subAdmins.map((subAdmin, subIndex) => (
                              <div
                                className="form-check roleBasedAccess"
                                key={subIndex}
                              >
                                <label className="form-check-label newLabel">
                                  <input
                                    // disabled={
                                    //   item.email === userData.email
                                    //     ? true
                                    //     : false
                                    // }
                                    type="checkbox"
                                    className="form-check-input"
                                    name="deleteAccess"
                                    onChange={(e) =>
                                      showRetreatAdminAlert(item, subAdmin)
                                    }
                                    value={subAdmin.id}
                                    checked={subAdmin.isDisabled}
                                  />
                                  {subAdmin.name} ({subAdmin.password})
                                  <i className="input-helper"></i>
                                </label>
                                <i
                                  className="mdi mdi-delete-circle text-md font-size deleteButton delete-icon8"
                                  onClick={() =>
                                    handleShowOfAccess(item, subAdmin.email)
                                  }
                                  title="Delete"
                                ></i>
                              </div>
                            ))}
                        </div>
                      ))}
                  </Col>
                </Row>
              </div>
            </div>
          </p>
        </div>
      </div>
      {show ? (
        <DeleteModel
          show={show}
          handleClose={handleClose}
          DeleteData={removeRole}
        ></DeleteModel>
      ) : null}

      {removeAccessShow ? (
        <DeleteModel
          show={removeAccessShow}
          handleClose={handleCloseOfAccess}
          DeleteData={removeAccessFromRetreat}
        ></DeleteModel>
      ) : null}

      {showAccessAlert ? (
        <AlertModal
          show={showAccessAlert}
          handleClose={handleCloseAlert}
          message={`Are you sure you want to ${
            removeId && removeId.isDisabled === 1 ? "enable" : "disable"
          } this admin?`}
          actionFunction={adminAccess}
        />
      ) : null}

      {retAdminAccessAlert ? (
        <AlertModal
          show={retAdminAccessAlert}
          handleClose={closeretAdminAlert}
          message={`Are you sure you want to ${
            removeId && removeId.isDisabled === 1 ? "enable" : "disable"
          } this admin?`}
          actionFunction={retreatAdminAccess}
        />
      ) : null}
    </div>
  );
};

export default AdminDashboard;
