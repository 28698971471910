import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Container,
  Table,
  Button,
} from "react-bootstrap";
import moment from "moment";
import Helpers from "../../../components/Helpers";
import uploadRetreat from "../../../assets/images/uploadRetreat.png";
import Profile from "../../../assets/images/couple-default.png";
import Select from "react-select";
import { Link } from "react-router-dom";
import fbIcon from "../../../assets/images/facebook-logo.webp";
import ProgramApi from "../../../api/programApi";
import AdminApi from "../../../api/admin";
import RolesApi from "../../../api/RolesApi";
import TalksApi from "../../../api/TalksApi";
import ServiceApi from "../../../api/ServiceApi";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import ProgramModel from "./ProgramModel";
import Loader from "../../../components/loader";
import DeleteModel from "../../../components/DeleteModel";
import { Cookies } from "react-cookie";
import RetreatApi from "../../../api/retreatApi";

export const CreateProgram = () => {
  let programApi = new ProgramApi();
  let adminApi = new AdminApi();
  let rolesApi = new RolesApi();
  let talksApi = new TalksApi();
  let serviceApi = new ServiceApi();
  let retreatApi = new RetreatApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;

  const [program, setProgram] = useState([]);
  const [roasterData, setRoasterData] = useState([]);
  const [isTempUpload, setIsTempUpload] = useState(false);
  const [modalProramShow, setModalProramShow] = useState(false);
  const [nameModel, setNameModel] = useState("");
  const [coupleTalkData, setCoupleTalkData] = useState([]);
  const [allCoupleData, setAllCoupleData] = useState([]);
  const [rolesDataList, setRolesDataList] = useState([]);
  const [talksDataList, setTalksDataList] = useState([]);
  const [serviceDataList, setServiceDataList] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [coupleSelectForRole, setCoupleSelectForRole] = useState([]);
  const [talkSelect, setTalkSelect] = useState([]);
  const [coupleSelectForTalk, setCoupleSelectForTalk] = useState([]);
  const [serviceSelect, setServiceSelect] = useState([]);
  const [coupleSelectForService, setCoupleSelectForService] = useState([]);
  const [LoadData, setLoadData] = useState(false);
  const [roleGreenTick, setRoleGreenTick] = useState(false);
  const [talkGreenTick, setTalkGreenTick] = useState(false);
  const [serviceGreenTick, setServiceGreenTick] = useState(false);
  const [deleteItm, setDeleteItm] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [data, setData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    getProgramDetail(id);
    getAllCouples();
    getrolesList();
    getAllRoastersInfo(id);
    getTalksList();
    getServiceList();
    getRetreatDetail(id);
  }, [id]);

  const getRetreatDetail = async (id) => {
    await retreatApi
      .getRetreatDetail({ id: id })
      .then((res) => {
        if (res.success) {
          const { schedule, ...rest } = res.data[0];
          const parsedSchedule = schedule ? JSON.parse(schedule) : [];
          const parsedData = {
            ...rest,
            schedule: parsedSchedule.map((entry) => ({
              date: new Date(entry.date),
              from: entry.from,
              to: entry.to,
            })),
            parish: res.data[0].parishId,
          };
          setData(parsedData);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getAllCouples = async () => {
    await adminApi
      .allCouples()
      .then((res) => {
        if (res.success) {
          let allcoupleList = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.hisFirstName) {
                allcoupleList.push({
                  label: `${item.hisLastName}, ${item.hisFirstName} and ${item.herFirstName}`,
                  value: item.id,
                });
              }
            });
          const sort = allcoupleList.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          setAllCoupleData(sort);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getrolesList = async () => {
    await rolesApi
      .getAllRoleList()
      .then(async (res) => {
        let roles = [];
        res.data &&
          res.data.forEach((item) => {
            if (item.role) {
              roles.push({
                label: item.role,
                value: item.id,
              });
            }
          });
        const sort = roles.sort((a, b) => a.label.localeCompare(b.label));
        setRolesDataList(sort);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getTalksList = async () => {
    await talksApi
      .getAllTalkList()
      .then(async (res) => {
        let talks = [];
        res.data &&
          res.data.forEach((item) => {
            if (item.talk) {
              talks.push({
                label: item.talk,
                value: item.talkId,
              });
            }
          });
        const sort = talks.sort((a, b) => a.label.localeCompare(b.label));
        setTalksDataList(sort);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getServiceList = async () => {
    await serviceApi
      .getAllServiceList()
      .then(async (res) => {
        let services = [];
        res.data &&
          res.data.forEach((item) => {
            if (item.service) {
              services.push({
                label: item.service,
                value: item.id,
              });
            }
          });
        const sort = services.sort((a, b) => a.label.localeCompare(b.label));
        setServiceDataList(sort);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getAllRoastersInfo = async (id) => {
    await programApi
      .getAllRoastersInfo({ rereatId: id })
      .then((response) => {
        setRoasterData(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getProgramDetail = async (id) => {
    await programApi
      .getProgramDetail({ rereatId: id })
      .then((response) => {
        const modifiedData = { ...response.data[0] };
        if (modifiedData.footerImages) {
          modifiedData.footerImages = JSON.parse(modifiedData.footerImages);
        }
        setProgram(modifiedData);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const handleallProgramImages = (e, prog, imageSelection) => {
    setLoadData(true);
    let { name, value } = e.target;
    if (name === "imageUrl" && e.target.files && e.target.files.length > 0) {
      let allData = new FormData();
      allData.append("file", e.target.files[0]);
      allData.append("programId", prog && prog.id);
      allData.append("retreatId", id);
      allData.append("imageSelection", imageSelection);
      programApi
        .createProgram(allData)
        .then(async (res) => {
          if (res.mode === "create") {
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: `Created new retreat program (Retreat - ${data.title})`,
            };
            await adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Added new log report");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            if (imageSelection === "mainImage") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Header image changed in retreat program (Retreat - ${data.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (imageSelection === "footerImages") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Footer image uploaded in retreat program (Retreat - ${data.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `QR-Code image changed in retreat program (Retreat - ${data.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
          toast.success(res.msg);
          getProgramDetail(id);
          setLoadData(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error("An error occurred while uploading the image.");
        });
    } else {
      toast.error("Please select an image.");
    }
  };

  const hadleProgramModel = (a, item) => {
    setModalProramShow(true);
    setNameModel(a);
    setCoupleTalkData(item);
  };

  const closeProgramModel = () => {
    setModalProramShow(false);
    getAllRoastersInfo(id);
    getProgramDetail(id);
  };

  const upload = (i) => {
    document.getElementById(`${i}` + "-" + "CoupleProfileUpload").click();
    setIsTempUpload(true);
  };

  const remove = (coupleDta, selection, checkImage) => {
    setIsTempUpload(false);
    programApi
      .removeProgramImage({ checkImage: checkImage, id: coupleDta.id })
      .then((res) => {
        toast.success(res.msg);
        getProgramDetail(coupleDta.retreatId);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while uploading the image.");
      });
  };

  const changePublishStatus = (directory, select) => {
    programApi
      .changePublishStatus({ id: directory.id, select: select })
      .then((res) => {
        getProgramDetail(directory.retreatId);
        toast.success(res.msg);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleSelectOption = (val, selection) => {
    if (selection === "forRole") {
      setRoleSelect(val);
    } else if (selection === "forTalk") {
      setTalkSelect(val);
    } else if (selection === "coupleForRole") {
      setCoupleSelectForRole(val);
    } else if (selection === "coupleForTalk") {
      setCoupleSelectForTalk(val);
    } else if (selection === "forService") {
      setServiceSelect(val);
    } else if (selection === "coupleForService") {
      setCoupleSelectForService(val);
    }
  };

  const handleSelectSubmit = async (selection) => {
    let submitData;
    if (selection === "forRole") {
      submitData = {
        roleId: roleSelect.value,
        coupleId: coupleSelectForRole.value,
        retreatId: id,
        selection: selection,
      };
      setRoleGreenTick(true);
    } else if (selection === "forTalk") {
      submitData = {
        talkId: talkSelect.value,
        coupleId: coupleSelectForTalk.value,
        retreatId: id,
        selection: selection,
      };
      setTalkGreenTick(true);
    } else if (selection === "forService") {
      submitData = {
        serviceId: serviceSelect.value,
        coupleId: coupleSelectForService.value,
        retreatId: id,
        selection: selection,
      };
      setServiceGreenTick(true);
    }
    await programApi
      .createRetreatTeam(submitData)
      .then((res) => {
        if (res.success) {
          toast.success(res.msg);
          getAllRoastersInfo(id);
          getProgramDetail(id);
          setRoleSelect([]);
          setCoupleSelectForRole([]);
          setTalkSelect([]);
          setCoupleSelectForTalk([]);
          setServiceSelect([]);
          setCoupleSelectForService([]);
          setTimeout(() => {
            setRoleGreenTick(false);
            setTalkGreenTick(false);
            setServiceGreenTick(false);
          }, 2000);
        } else {
          toast.error(res.msg);
          setRoleGreenTick(false);
          setTalkGreenTick(false);
          setServiceGreenTick(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  function deleteFooterFile(program, item) {
    programApi
      .removeProgramFooterImage({ id: program.id, item: item })
      .then((res) => {
        toast.success(res.msg);
        getProgramDetail(program.retreatId);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while uploading the image.");
      });
  }

  function deleteRoasterCouple() {
    programApi
      .deleteRoasterCouple({ id: deleteItm.id })
      .then((res) => {
        getAllRoastersInfo(id);
        setShowDelete(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while uploading the image.");
      });
  }

  const handleShow = (item) => {
    setDeleteItm(item);
    setShowDelete(true);
  };

  const handleClose = () => {
    setShowDelete(false);
  };

  return (
    <>
      <Link to="/manageRetreat" className="back-link">
        &lt;Back
      </Link>
      <div className="row ext-space">
        <div className="col-lg-12 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col-12 grid-margin stretch-card">
              <div className="card custom-card">
                <div className="card-body ">
                  <Container fluid className="create-retreat-header ">
                    <Row className="p-0">
                      <Col
                        xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        className="directorydata"
                      >
                        {" "}
                        <h1 className="addRetHeading">Program -</h1>
                        <div className="mt-2 program-upper-title">
                          <h6>
                            {data && data.title
                              ? `${
                                  data &&
                                  data.title &&
                                  data.title.charAt(0).toUpperCase() +
                                    data.title.slice(1)
                                }, `
                              : null}

                            {data.dateFrom == data.dateTo
                              ? moment(data && data.dateFrom).format(
                                  "MMM-DD-YYYY"
                                )
                              : moment(data && data.dateFrom).format("MMM DD") +
                                " - " +
                                moment(data && data.dateTo).format("MMM DD") +
                                ", " +
                                moment(data && data.dateTo).format("YYYY")}
                          </h6>
                        </div>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4}>
                        {program && program.id ? (
                          <>
                            {" "}
                            <Button
                              onClick={() =>
                                changePublishStatus(program, "program")
                              }
                              className="btn-sm submitRet"
                            >
                              {program && program.status === "PUBLISH"
                                ? "Unpublish"
                                : "Publish"}
                            </Button>
                            <a
                              href={`/program/${id}`}
                              className="font-size"
                              target="_blank"
                            >
                              <Button
                                variant="primary"
                                className="btn-sm submitRet"
                              >
                                Preview
                              </Button>
                            </a>
                          </>
                        ) : null}
                      </Col>
                      <hr className="mb-0" />
                      <Col md={3} className="left-retreat-panel">
                        <div className="scrollable-content">
                          <div className="row">
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-12 text-center sideProgram "
                            >
                              {!program ||
                              program.headerImage == "" ||
                              program.headerImage == undefined ? (
                                <Row>
                                  <Col
                                    lg={9}
                                    md={9}
                                    sm={9}
                                    xs={9}
                                    className="set-text-heading"
                                  >
                                    {" "}
                                    <input
                                      name="imageUrl"
                                      className="pt-1"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      id={
                                        `${program && program.id}` +
                                        "-" +
                                        "CoupleProfileUpload"
                                      }
                                      onChange={(e) =>
                                        handleallProgramImages(
                                          e,
                                          program,
                                          "mainImage"
                                        )
                                      }
                                    />{" "}
                                    <h5>Header Image</h5>
                                  </Col>
                                  <Col lg={3} md={3} sm={3} xs={3}>
                                    {" "}
                                    <i
                                      onClick={(e) => {
                                        upload(program && program.id);
                                      }}
                                      className="mdi mdi-upload text-lg font-size uploadMdi1"
                                      title="Image Upload"
                                    ></i>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col
                                    lg={9}
                                    md={9}
                                    sm={9}
                                    xs={9}
                                    className="set-text-heading"
                                  >
                                    {" "}
                                    <h5>Header Image</h5>
                                  </Col>

                                  <Col lg={3} md={3} sm={3} xs={3}>
                                    {" "}
                                    <i
                                      className="mdi mdi-delete-circle text-md font-size uploadMdi-delete"
                                      onClick={(e) => {
                                        remove(program, "program", "mainImage");
                                      }}
                                      title="Remove"
                                    ></i>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-12 text-center add-role-in-program"
                            >
                              <h5>Retreat Team</h5>
                              <Row className="">
                                <Col
                                  md={8}
                                  lg={8}
                                  sm={8}
                                  xs={8}
                                  className="p-0"
                                >
                                  <Select
                                    className="mt-2 actions-couple add-service-buttons"
                                    id="roles"
                                    name="roles"
                                    options={rolesDataList}
                                    value={roleSelect}
                                    onChange={(val) => {
                                      handleSelectOption(val, "forRole");
                                    }}
                                    labelledBy="Roles"
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder="Select Role"
                                  />
                                  <Select
                                    className="mt-3 actions-couple add-service-buttons"
                                    id="couple"
                                    name="couple"
                                    options={allCoupleData}
                                    value={coupleSelectForRole}
                                    onChange={(val) => {
                                      handleSelectOption(val, "coupleForRole");
                                    }}
                                    labelledBy="Select"
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder="Select Couple"
                                  />
                                </Col>
                                {roleGreenTick ? (
                                  <Col
                                    md={4}
                                    lg={4}
                                    sm={4}
                                    xs={4}
                                    className="mb-2"
                                  >
                                    <div className="selctSubmit">
                                      <i
                                        type="button"
                                        className="mdi mdi-check btn-icon-prepend mt-0 mb-3 plus-icon-role greenTick"
                                      ></i>
                                    </div>
                                  </Col>
                                ) : (
                                  <Col
                                    md={4}
                                    lg={4}
                                    sm={4}
                                    xs={4}
                                    className="mb-2"
                                  >
                                    <div className="selctSubmit">
                                      {" "}
                                      <i
                                        type="button"
                                        onClick={() => {
                                          handleSelectSubmit("forRole");
                                        }}
                                        className="mdi mdi-plus-circle btn-icon-prepend mt-0 mb-3 plus-icon-role"
                                      ></i>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-12 text-center add-role-in-program"
                            >
                              <h5>Speakers</h5>
                              <Row>
                                <Col
                                  md={8}
                                  lg={8}
                                  sm={8}
                                  xs={8}
                                  className="p-0 mb-2"
                                >
                                  <Select
                                    className="mt-2 actions-couple add-service-buttons"
                                    id="roles"
                                    name="roles"
                                    options={talksDataList}
                                    value={talkSelect}
                                    onChange={(val) => {
                                      handleSelectOption(val, "forTalk");
                                    }}
                                    labelledBy="Roles"
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder="Select Talk"
                                  />
                                  <Select
                                    className="mt-3 actions-couple add-service-buttons"
                                    id="couple"
                                    name="couple"
                                    options={allCoupleData}
                                    value={coupleSelectForTalk}
                                    onChange={(val) => {
                                      handleSelectOption(val, "coupleForTalk");
                                    }}
                                    labelledBy="Select"
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder="Select Couple"
                                  />
                                </Col>

                                {talkGreenTick ? (
                                  <Col
                                    md={4}
                                    lg={4}
                                    sm={4}
                                    xs={4}
                                    className="mb-2"
                                  >
                                    <div className="selctSubmit">
                                      {" "}
                                      <i
                                        type="button"
                                        className="mdi mdi-check btn-icon-prepend mt-0 mb-3 plus-icon-role greenTick"
                                      ></i>
                                    </div>
                                  </Col>
                                ) : (
                                  <Col md={4} lg={4} sm={4} xs={4}>
                                    {" "}
                                    <div className="selctSubmit">
                                      {" "}
                                      <i
                                        type="button"
                                        onClick={() => {
                                          handleSelectSubmit("forTalk");
                                        }}
                                        className="mdi mdi-plus-circle btn-icon-prepend mt-0 mb-3 plus-icon-role"
                                      ></i>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-12 text-center add-role-in-program"
                            >
                              <h5>Support Teams</h5>

                              <Row>
                                <Col
                                  md={8}
                                  lg={8}
                                  sm={8}
                                  xs={8}
                                  className="p-0 mb-2"
                                >
                                  <Select
                                    className="mt-2 actions-couple add-service-buttons"
                                    id="service"
                                    name="service"
                                    options={serviceDataList}
                                    value={serviceSelect}
                                    onChange={(val) => {
                                      handleSelectOption(val, "forService");
                                    }}
                                    labelledBy="Service"
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder="Select Service"
                                  />
                                  <Select
                                    className="mt-3 actions-couple add-service-buttons"
                                    id="couple"
                                    name="couple"
                                    options={allCoupleData}
                                    value={coupleSelectForService}
                                    onChange={(val) => {
                                      handleSelectOption(
                                        val,
                                        "coupleForService"
                                      );
                                    }}
                                    labelledBy="Select"
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder="Select Couple"
                                  />
                                </Col>
                                {serviceGreenTick ? (
                                  <Col
                                    md={4}
                                    lg={4}
                                    sm={4}
                                    xs={4}
                                    className="mb-2"
                                  >
                                    <div className="selctSubmit">
                                      {" "}
                                      <i
                                        type="button"
                                        className="mdi mdi-check btn-icon-prepend mt-0 mb-3 plus-icon-role greenTick"
                                      ></i>
                                    </div>
                                  </Col>
                                ) : (
                                  <Col md={4} lg={4} sm={4} xs={4}>
                                    <div className="selctSubmit">
                                      {" "}
                                      <i
                                        type="button"
                                        onClick={() => {
                                          handleSelectSubmit("forService");
                                        }}
                                        className="mdi mdi-plus-circle btn-icon-prepend mt-0 mb-3 plus-icon-role"
                                      ></i>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="text-center sideProgram"
                            >
                              <Row>
                                <Col
                                  lg={9}
                                  md={9}
                                  sm={9}
                                  xs={9}
                                  className="set-text-heading"
                                >
                                  {" "}
                                  <h5>Wisdom from Mother Theresa</h5>
                                </Col>
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="sideFields"
                                >
                                  {" "}
                                  <button
                                    className="font-size"
                                    onClick={() =>
                                      hadleProgramModel("WisdomDescription")
                                    }
                                  >
                                    <i
                                      className="mdi mdi-pencil-box text-lg font-size uploadMdi"
                                      title="Edit"
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="text-center sideProgram"
                            >
                              <Row>
                                <Col
                                  lg={9}
                                  md={9}
                                  sm={9}
                                  xs={9}
                                  className="set-text-heading"
                                >
                                  {" "}
                                  <h5>Add Guidelines</h5>
                                </Col>
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="sideFields"
                                >
                                  {" "}
                                  <button
                                    className="font-size"
                                    onClick={() =>
                                      hadleProgramModel(
                                        "about_program_guidelines"
                                      )
                                    }
                                  >
                                    <i
                                      className="mdi mdi-pencil-box text-lg font-size uploadMdi"
                                      title="Edit"
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-12 text-center mt-3 "
                            >
                              {roasterData &&
                              roasterData[1] &&
                              roasterData[1].speakers.length ? (
                                <h4 className="talk-question">
                                  Talks Questions
                                </h4>
                              ) : null}
                              {roasterData &&
                                roasterData[1] &&
                                roasterData[1].speakers &&
                                roasterData[1].speakers.map((item, i) => {
                                  return (
                                    <Row>
                                      {" "}
                                      <Col
                                        lg={10}
                                        md={10}
                                        sm={10}
                                        xs={10}
                                        className="text-center"
                                      >
                                        <div
                                          className="speakersQuestions item-talk mt-4"
                                          onClick={() =>
                                            hadleProgramModel(
                                              "talk_question",
                                              item
                                            )
                                          }
                                        >
                                          <h5>{item.talk}</h5>
                                          <h5>
                                            {`${
                                              item && item.hisFirstName
                                                ? item.hisFirstName
                                                : ""
                                            } ${
                                              item && item.hisFirstName
                                                ? "&"
                                                : ""
                                            } ${
                                              item && item.herFirstName
                                                ? item.herFirstName
                                                : ""
                                            } ${
                                              item && item.hisLastName
                                                ? item.hisLastName
                                                : ""
                                            }`}
                                          </h5>
                                        </div>
                                      </Col>
                                      <Col
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        xs={2}
                                        className="text-center mt-5 p-0 sideFields"
                                      >
                                        <button
                                          className="font-size"
                                          onClick={() =>
                                            hadleProgramModel(
                                              "talk_question",
                                              item
                                            )
                                          }
                                        >
                                          <i
                                            className="mdi mdi-pencil-box text-lg font-size"
                                            title="Edit"
                                          ></i>
                                        </button>
                                      </Col>
                                    </Row>
                                  );
                                })}
                              {roasterData &&
                              roasterData[1] &&
                              roasterData[1].speakers.length ? (
                                <div className="mb-3"></div>
                              ) : null}
                            </Col>
                            {roasterData &&
                            roasterData[1] &&
                            roasterData[1].speakers.length ? (
                              <hr className="mb-0" />
                            ) : (
                              <div className="mb-0" />
                            )}
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-12 text-center sideProgram"
                            >
                              <Row>
                                <Col
                                  lg={9}
                                  md={9}
                                  sm={9}
                                  xs={9}
                                  className="set-text-heading"
                                >
                                  {" "}
                                  <h5>Our Parish Mission</h5>
                                </Col>
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="sideFields"
                                >
                                  {" "}
                                  <button
                                    className="font-size"
                                    onClick={() =>
                                      hadleProgramModel("ParishMission")
                                    }
                                  >
                                    <i
                                      className="mdi mdi-pencil-box text-lg font-size uploadMdi"
                                      title="Edit"
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-12 text-center  sideProgram"
                            >
                              <Row>
                                <Col
                                  lg={9}
                                  md={9}
                                  sm={9}
                                  xs={9}
                                  className="set-text-heading"
                                >
                                  <h5>Facebook Link</h5>
                                </Col>
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="sideFields"
                                >
                                  {" "}
                                  <button
                                    className="font-size"
                                    onClick={() => hadleProgramModel("fbLink")}
                                  >
                                    <i
                                      className="mdi mdi-pencil-box text-lg font-size uploadMdi"
                                      title="Edit"
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-12 text-center sideProgram"
                            >
                              {!program ||
                              program.qrCodeImage == "" ||
                              program.qrCodeImage == undefined ? (
                                <Row>
                                  <Col
                                    lg={9}
                                    md={9}
                                    sm={9}
                                    xs={9}
                                    className="set-text-heading"
                                  >
                                    {" "}
                                    <input
                                      name="imageUrl"
                                      className="pt-1"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      id={
                                        `${program.id}` +
                                        90 +
                                        "-" +
                                        "CoupleProfileUpload"
                                      }
                                      onChange={(e) =>
                                        handleallProgramImages(
                                          e,
                                          program,
                                          "qrCodeImage"
                                        )
                                      }
                                    />
                                    <h5>QR Code</h5>
                                  </Col>
                                  <Col lg={3} md={3} sm={3} xs={3}>
                                    {" "}
                                    <i
                                      onClick={(e) => {
                                        upload(`${program.id}` + 90);
                                      }}
                                      className="mdi mdi-upload text-lg font-size uploadMdi1"
                                      title="Image Upload"
                                    ></i>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  {" "}
                                  <Col
                                    lg={9}
                                    md={9}
                                    sm={9}
                                    xs={9}
                                    className="set-text-heading"
                                  >
                                    <h5>QR Code</h5>{" "}
                                  </Col>
                                  <Col lg={3} md={3} sm={3} xs={3}>
                                    {" "}
                                    <i
                                      className="mdi mdi-delete-circle text-md font-size uploadMdi"
                                      onClick={(e) => {
                                        remove(
                                          program,
                                          "program",
                                          "qrCodeImage"
                                        );
                                      }}
                                      title="Remove"
                                    ></i>
                                  </Col>{" "}
                                </Row>
                              )}
                            </Col>
                            <hr className="mb-0" />
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              onChange={(e) =>
                                handleallProgramImages(
                                  e,
                                  program,
                                  "footerImages"
                                )
                              }
                              className="col-12 text-center sideProgram "
                            >
                              <Row>
                                <Col
                                  lg={9}
                                  md={9}
                                  sm={9}
                                  xs={9}
                                  className="set-text-heading"
                                >
                                  {" "}
                                  <input
                                    name="imageUrl"
                                    className="pt-1"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id={
                                      `${program.id}` +
                                      101 +
                                      "-" +
                                      "CoupleProfileUpload"
                                    }
                                  />
                                  <h5>Upload Footer images</h5>
                                </Col>
                                <Col lg={3} md={3} sm={3} xs={3}>
                                  {" "}
                                  <i
                                    onClick={(e) => {
                                      upload(`${program.id}` + 101);
                                    }}
                                    className="mdi mdi-upload text-lg font-size uploadMdi1"
                                    title="Image Upload"
                                  ></i>
                                </Col>
                              </Row>
                            </Col>
                          </div>
                        </div>
                      </Col>
                      <Col md={9} className="right-retreat-panel p-0">
                        <div className="scrollable-content">
                          <div className="container-scroller mainpannel">
                            <div className="container-fluid page-body-wrapper full-page-wrapper">
                              <div className="row w-100 mx-0">
                                {LoadData ? (
                                  <div className="auth-form-light text-center main-register py-5 pt-0 px-4 px-sm-5">
                                    <Row className="text-center">
                                      <Col
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="colorTalks mt-3 p-5"
                                      >
                                        <Loader />
                                      </Col>
                                    </Row>
                                  </div>
                                ) : program && program.id ? (
                                  <div className="auth-form-light text-center main-register py-5 pt-0 px-4 px-sm-4">
                                    <Row className="text-center">
                                      <Col
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="colorTalks mt-3"
                                      >
                                        <img
                                          src="https://lovestrong.koolderbyacademy.com/logo.png"
                                          alt=""
                                          srcset=""
                                          className="logo_image"
                                        />
                                        <h2
                                          style={{
                                            color: "#d2b74c",
                                            fontWeight: "700",
                                          }}
                                        >
                                          MINISTRY
                                        </h2>
                                        <h2 className="mt-3 mb-3">
                                          <em
                                            style={{
                                              color: "#d2b74c",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Live. Love. strong.
                                          </em>
                                        </h2>
                                        {program && program.headerImage ? (
                                          <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className="mb-5 mt-5 howerOption mainHeaderImage">
                                              <div>
                                                <img
                                                  src={
                                                    program &&
                                                    program.headerImage
                                                  }
                                                  className="imageHeader"
                                                />

                                                <div className="text-center setUploadField">
                                                  <button
                                                    className="middle_Icon-Header-img button-18"
                                                    onClick={(e) => {
                                                      remove(
                                                        program,
                                                        "program",
                                                        "mainImage"
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        ) : null}
                                      </Col>
                                      {(roasterData &&
                                        roasterData[0] &&
                                        roasterData[0].role &&
                                        roasterData[0].role.length) ||
                                      (roasterData &&
                                        roasterData[1] &&
                                        roasterData[1].speakers &&
                                        roasterData[1].speakers.length) ||
                                      (roasterData &&
                                        roasterData[2] &&
                                        roasterData[2].services.length) ? (
                                        <Col
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          className="colorTalks roaster_list pt-0 mt-3"
                                          style={{
                                            backgroundColor: "rgb(234 232 232)",
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                          }}
                                        >
                                          <Container className="table_Content_Data">
                                            {roasterData &&
                                            roasterData[0] &&
                                            roasterData[0].role &&
                                            roasterData[0].role.length ? (
                                              <h2 className="mt-5 mb-4">
                                                RETREAT TEAM
                                              </h2>
                                            ) : null}
                                            {roasterData &&
                                              roasterData[0] &&
                                              roasterData[0].role &&
                                              roasterData[0].role.map(
                                                (item, i) => {
                                                  return (
                                                    <Row
                                                      key={i}
                                                      className="table_content mt-1"
                                                    >
                                                      <Col
                                                        lg={5}
                                                        md={5}
                                                        sm={5}
                                                        xs={5}
                                                        className="mt-3 master-couple"
                                                      >
                                                        {" "}
                                                        <h3>
                                                          {item.role} Couple
                                                        </h3>{" "}
                                                      </Col>
                                                      <Col
                                                        lg={5}
                                                        md={5}
                                                        sm={5}
                                                        xs={5}
                                                        className="mt-3 master-couple"
                                                      >
                                                        {" "}
                                                        <h3 className="">
                                                          {`${
                                                            item &&
                                                            item.hisFirstName
                                                              ? item.hisFirstName
                                                              : ""
                                                          } ${
                                                            item &&
                                                            item.hisFirstName
                                                              ? "&"
                                                              : ""
                                                          } ${
                                                            item &&
                                                            item.herFirstName
                                                              ? item.herFirstName
                                                              : ""
                                                          } ${
                                                            item &&
                                                            item.hisLastName
                                                              ? item.hisLastName
                                                              : ""
                                                          }`}
                                                        </h3>
                                                      </Col>
                                                      <Col
                                                        lg={2}
                                                        md={2}
                                                        sm={2}
                                                        xs={2}
                                                        className="mt-2"
                                                      >
                                                        <button
                                                          onClick={() =>
                                                            handleShow(item)
                                                          }
                                                          className="font-size delete-icon"
                                                        >
                                                          <i
                                                            className="mdi mdi-delete-forever text-md mx-2 font-size"
                                                            title="Delete"
                                                          ></i>
                                                        </button>
                                                      </Col>
                                                    </Row>
                                                  );
                                                }
                                              )}

                                            {roasterData &&
                                            roasterData[1] &&
                                            roasterData[1].speakers &&
                                            roasterData[1].speakers.length ? (
                                              <>
                                                {" "}
                                                <hr />{" "}
                                                <h2 className="mt-5 mb-3">
                                                  SPEAKERS
                                                </h2>
                                              </>
                                            ) : null}
                                            {roasterData &&
                                              roasterData[1] &&
                                              roasterData[1].speakers &&
                                              roasterData[1].speakers.map(
                                                (item, i) => {
                                                  return (
                                                    <Row
                                                      key={i}
                                                      className="table_content mt-1"
                                                    >
                                                      <Col
                                                        lg={5}
                                                        md={5}
                                                        sm={5}
                                                        xs={5}
                                                        className="mt-3 master-couple"
                                                      >
                                                        {" "}
                                                        <h3>
                                                          {item.talk}
                                                        </h3>{" "}
                                                      </Col>
                                                      <Col
                                                        lg={5}
                                                        md={5}
                                                        sm={5}
                                                        xs={5}
                                                        className="mt-3 master-couple"
                                                      >
                                                        {" "}
                                                        <h3 className="">
                                                          {`${
                                                            item &&
                                                            item.hisFirstName
                                                              ? item.hisFirstName
                                                              : ""
                                                          } ${
                                                            item &&
                                                            item.hisFirstName
                                                              ? "&"
                                                              : ""
                                                          } ${
                                                            item &&
                                                            item.herFirstName
                                                              ? item.herFirstName
                                                              : ""
                                                          } ${
                                                            item &&
                                                            item.hisLastName
                                                              ? item.hisLastName
                                                              : ""
                                                          }`}
                                                        </h3>
                                                      </Col>
                                                      <Col
                                                        lg={2}
                                                        md={2}
                                                        sm={2}
                                                        xs={2}
                                                        className="mt-2"
                                                      >
                                                        <button
                                                          onClick={() =>
                                                            handleShow(item)
                                                          }
                                                          className="font-size delete-icon"
                                                        >
                                                          <i
                                                            className="mdi mdi-delete-forever text-md mx-2 font-size"
                                                            title="Delete"
                                                          ></i>
                                                        </button>
                                                      </Col>
                                                    </Row>
                                                  );
                                                }
                                              )}
                                            {roasterData &&
                                            roasterData[2] &&
                                            roasterData[2].services.length ? (
                                              <>
                                                {" "}
                                                <hr />
                                                <h2 className="mt-5 mb-5">
                                                  SUPPORT TEAMS
                                                </h2>
                                              </>
                                            ) : null}
                                            {roasterData &&
                                              roasterData[2] &&
                                              roasterData[2].services &&
                                              (() => {
                                                let previousService = "";
                                                return roasterData[2].services.map(
                                                  (item, i) => {
                                                    const showServiceHeader =
                                                      item.service !==
                                                      previousService;
                                                    previousService =
                                                      item.service;

                                                    return (
                                                      <>
                                                        {showServiceHeader && (
                                                          <h3
                                                            key={`service-${i}`}
                                                            className="mt-2 mb-3 serviceTerms"
                                                          >
                                                            {item.service}{" "}
                                                          </h3>
                                                        )}
                                                        <Row
                                                          key={i}
                                                          className="table_content mt-1 "
                                                        >
                                                          <Col
                                                            lg={5}
                                                            md={5}
                                                            sm={5}
                                                            xs={5}
                                                            className="mt-3 master-couple"
                                                          >
                                                            <h3 className="">
                                                              {`${
                                                                item?.hisFirstName
                                                                  ? item.hisFirstName
                                                                  : ""
                                                              } ${
                                                                item?.hisFirstName
                                                                  ? "&"
                                                                  : ""
                                                              } ${
                                                                item?.herFirstName
                                                                  ? item.herFirstName
                                                                  : ""
                                                              } ${
                                                                item?.hisLastName
                                                                  ? item.hisLastName
                                                                  : ""
                                                              }`}
                                                            </h3>
                                                          </Col>
                                                          <Col
                                                            lg={5}
                                                            md={5}
                                                            sm={5}
                                                            xs={5}
                                                            className="mt-3 master-couple"
                                                          >
                                                            <h3>
                                                              {item.role}{" "}
                                                              {item.role
                                                                ? " Couple"
                                                                : null}
                                                            </h3>
                                                          </Col>
                                                          <Col
                                                            lg={2}
                                                            md={2}
                                                            sm={2}
                                                            xs={2}
                                                            className="mt-2"
                                                          >
                                                            <button
                                                              onClick={() =>
                                                                handleShow(item)
                                                              }
                                                              className="font-size delete-icon"
                                                            >
                                                              <i
                                                                className="mdi mdi-delete-forever text-md mx-2 font-size"
                                                                title="Delete"
                                                              ></i>
                                                            </button>
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    );
                                                  }
                                                );
                                              })()}
                                          </Container>
                                        </Col>
                                      ) : null}
                                      {program && program.wisdom_from_mt ? (
                                        <Col
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          className="colorTalks mt-3"
                                        >
                                          <div className="howerOption">
                                            <h3 className="mb-0 model_data">
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    program &&
                                                    program.wisdom_from_mt,
                                                }}
                                              />
                                            </h3>
                                          </div>
                                        </Col>
                                      ) : null}
                                      <Col
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="colorTalks mt-3 "
                                      >
                                        <img
                                          src="https://lovestrong.koolderbyacademy.com/logo.png"
                                          alt=""
                                          srcset=""
                                          className="logo_image1 mt-4"
                                        />
                                        <h3
                                          style={{
                                            color: "#d2b74c",
                                            fontWeight: "700",
                                          }}
                                        >
                                          MINISTRY
                                        </h3>
                                        <h5 className="mt-2 mb-3">
                                          <em
                                            style={{
                                              color: "#d2b74c",
                                              fontWeight: "700",
                                            }}
                                          >
                                            LIVE. LOVE. STRONG.
                                          </em>
                                        </h5>
                                        <div className="howerOption">
                                          <h3
                                            className="mb-4 roaster_list"
                                            style={{
                                              textAlign: "left",
                                            }}
                                          >
                                            {program && program.guidelines ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    program &&
                                                    program.guidelines,
                                                }}
                                              />
                                            ) : null}
                                          </h3>
                                        </div>
                                      </Col>
                                      {roasterData &&
                                        roasterData[1] &&
                                        roasterData[1].speakers &&
                                        roasterData[1].speakers.map(
                                          (item, i) => {
                                            return item &&
                                              item.talk_question ? (
                                              <Col
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className="colorTalks mt-3 pt-5"
                                                key={i}
                                              >
                                                <div className="speakersQuestions">
                                                  <h3>{item.talk}</h3>
                                                  <h4>
                                                    {`${
                                                      item && item.hisFirstName
                                                        ? item.hisFirstName
                                                        : ""
                                                    } ${
                                                      item && item.hisFirstName
                                                        ? "&"
                                                        : ""
                                                    } ${
                                                      item && item.herFirstName
                                                        ? item.herFirstName
                                                        : ""
                                                    } ${
                                                      item && item.hisLastName
                                                        ? item.hisLastName
                                                        : ""
                                                    }`}
                                                  </h4>
                                                </div>

                                                <div className="howerOption talk-question">
                                                  <h2
                                                    className="mb-4"
                                                    style={{
                                                      color: "black",
                                                      fontWeight: "500",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item &&
                                                          item.talk_question,
                                                      }}
                                                    />
                                                  </h2>
                                                </div>
                                              </Col>
                                            ) : null;
                                          }
                                        )}
                                      <Col
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="colorTalks mt-3"
                                      >
                                        <div className="howerOption">
                                          <h2
                                            className="mb-4 model_data"
                                            style={{
                                              textAlign: "left",
                                            }}
                                          >
                                            {program &&
                                            program.parishMission ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    program &&
                                                    program.parishMission,
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </h2>
                                        </div>
                                        <Row className="parishes-logos">
                                          <Col
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="mt-5 mb-5"
                                          >
                                            <img
                                              src="https://lovestrong.koolderbyacademy.com/logo.png"
                                              alt=""
                                              srcset=""
                                              className="logo_image5"
                                            />
                                            <h5 className="mt-2 mb-3">
                                              <em
                                                style={{
                                                  color: "#d2b74c",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                LIVE. LOVE. STRONG.
                                              </em>
                                            </h5>
                                            <a
                                              href="https://lovestrong.koolderbyacademy.com"
                                              style={{
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              <h2
                                                style={{
                                                  color: "black",
                                                  fontWeight: "700",
                                                  float: "left",
                                                  float: "inherit",
                                                  textDecoration: "none",
                                                }}
                                              >
                                                www.LOVESTRONGMarriage.com
                                              </h2>
                                            </a>
                                          </Col>
                                          {program && program.qrCodeImage ? (
                                            <Col
                                              lg={3}
                                              md={6}
                                              sm={6}
                                              xs={12}
                                              className="mt-4 qrCode coupleImages5 coupleImageHower"
                                            >
                                              <div className="edit-profile-img mb-2   ">
                                                <img
                                                  src={
                                                    program &&
                                                    program.qrCodeImage
                                                  }
                                                  className="shadowImage"
                                                />
                                              </div>
                                              <i
                                                className="mdi mdi-delete-circle text-md font-size qrUpload0"
                                                onClick={(e) => {
                                                  remove(
                                                    program,
                                                    "program",
                                                    "qrCodeImage"
                                                  );
                                                }}
                                                title="Remove"
                                              ></i>
                                            </Col>
                                          ) : null}
                                          {program && program.fbLink ? (
                                            <Col lg={2} md={6} sm={6} xs={12}>
                                              <a
                                                href={program && program.fbLink}
                                                target="_blank"
                                              >
                                                <img
                                                  src={fbIcon}
                                                  alt=""
                                                  srcset=""
                                                  className="logo_image1"
                                                />
                                              </a>
                                              {/* <div
                            dangerouslySetInnerHTML={{
                              __html: program && program.fbLink,
                            }}
                          /> */}
                                            </Col>
                                          ) : null}
                                          {program && program.parishImageUrl ? (
                                            <Col
                                              lg={3}
                                              md={6}
                                              sm={6}
                                              xs={12}
                                              className="mt-4"
                                            >
                                              <img
                                                src={
                                                  program &&
                                                  program.parishImageUrl
                                                }
                                                alt=""
                                                srcset=""
                                                className="parishLogo1"
                                              />
                                            </Col>
                                          ) : null}
                                          {program && program.parishName ? (
                                            <Col
                                              lg={4}
                                              md={6}
                                              sm={6}
                                              xs={12}
                                              className="parishDetail mt-2"
                                            >
                                              <h3>
                                                {program && program.parishName}
                                              </h3>
                                              <h5>
                                                {" "}
                                                {program &&
                                                  program.parishAddress}
                                              </h5>
                                              <h5>
                                                {" "}
                                                {program &&
                                                  program.parishCity}{" "}
                                                {program && program.parishState
                                                  ? ", "
                                                  : ""}
                                                {program && program.parishState}
                                              </h5>
                                              <h5>
                                                {" "}
                                                {program && program.parishzip}
                                              </h5>
                                              <h5>
                                                {" "}
                                                {program && program.parishLink}
                                              </h5>
                                            </Col>
                                          ) : null}
                                        </Row>
                                      </Col>

                                      {program &&
                                      program.footerImages &&
                                      program.footerImages.length > 0 ? (
                                        <Col
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          className=" colorTalks mt-3 howerOption"
                                        >
                                          {program &&
                                            program.footerImages &&
                                            program.footerImages.length > 0 &&
                                            program.footerImages.map(
                                              (item, index) => {
                                                return (
                                                  <Col
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    className="mb-5 howerOption mainHeaderImage"
                                                  >
                                                    <>
                                                      {" "}
                                                      {/* <div className="text-center setUploadField">
                                <h2>Upload more images</h2>
                                <input
                                  name="imageUrl"
                                  className="pt-1"
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id={`programId` + "-" + "CoupleProfileUpload"}
                                  onChange={(e) =>
                                    handleallProgramImages(
                                      e,
                                      program,
                                      "footerImages"
                                    )
                                  }
                                />
                                <button
                                  onClick={(e) => {
                                    upload("programId");
                                  }}
                                  className="middle_Icon button-18"
                                >
                                  Upload
                                </button>
                              </div> */}
                                                      <div className="text-center">
                                                        <img
                                                          src={item}
                                                          className="imageHeader"
                                                        />
                                                      </div>
                                                      <div className="text-center setUploadField">
                                                        <button
                                                          className="middle_Icon3 button-18"
                                                          onClick={() =>
                                                            deleteFooterFile(
                                                              program,
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Remove
                                                        </button>
                                                      </div>
                                                    </>
                                                  </Col>
                                                );
                                              }
                                            )}
                                        </Col>
                                      ) : null}
                                    </Row>
                                  </div>
                                ) : (
                                  <div className="auth-form-light text-center main-register py-5 pt-0 px-4 px-sm-5">
                                    <Row className="text-center">
                                      <Col
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="colorTalks mt-3 p-5"
                                      >
                                        <h2>Please add data</h2>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modalProramShow ? (
          <ProgramModel
            show={modalProramShow}
            programField={nameModel}
            program={program}
            retreatData={data}
            coupleTalkData={coupleTalkData}
            onHide={() => closeProgramModel()}
          />
        ) : null}{" "}
        {showDelete ? (
          <DeleteModel
            show={showDelete}
            handleClose={handleClose}
            DeleteData={deleteRoasterCouple}
          ></DeleteModel>
        ) : null}
      </div>
    </>
  );
};
