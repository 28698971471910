import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Profile from "../../assets/images/couple-default.png";
import AuthApi from "../../api/authApi";
import AdminApi from "../../api/admin";
import DatePicker from "react-datepicker";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Cookies, useCookies } from "react-cookie";

export const UpdateCouple = () => {
  const [validated, setValidated] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  let authApi = new AuthApi();
  let adminApi = new AdminApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const [cookie, setCookie] = useCookies(["LoveStrongUser"]);
  const [data, setData] = useState({
    id: "",
    hisFirstName: "",
    hisLastName: "",
    hisEmail: "",
    hisMobile: "",
    herFirstName: "",
    herLastName: "",
    herEmail: "",
    herMobile: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    imageUrl: "",
    // marriageImageUrl: "",
    parishId: null,
    address: "",
    allergies: "",
    anniversary_date: "",
    under_age_35: "NO",
    emergency_name1: "",
    emergency_relationship1: "",
    emergency_phone1: "",
    emergency_name2: "",
    emergency_relationship2: "",
    emergency_phone2: "",
    hear_us: [],
    referral_from_a_friend_or_family_member: "",
  });
  const [parishList, setParishList] = useState([]);
  const [LoadData, setLoadData] = useState(false);
  const [alreadyPk, setAlreadyPk] = useState();

  useEffect(() => {
    getParishList();
    editCouple(userData.coupleId);
  }, [userData.coupleId]);

  const [isTempUpload, setIsTempUpload] = useState(false);
  // const [isTempUpload2, setIsTempUpload2] = useState(false);

  const editCouple = async (coupleId) => {
    setIsTempUpload(false);
    // setIsTempUpload2(false);
    let d = { ...data };
    await adminApi
      .getCouplesDetail({ coupleId: coupleId })
      .then(async (res) => {
        if (res.success) {
          d.id = res.data[0].id;
          d.primaryKey = res.data[0].primaryKey;
          d.hisFirstName = res.data[0].hisFirstName;
          d.hisLastName = res.data[0].hisLastName;
          d.hisEmail = res.data[0].hisEmail;
          d.hisMobile = res.data[0].hisMobile;
          d.herFirstName = res.data[0].herFirstName;
          d.herLastName = res.data[0].herLastName;
          d.herEmail = res.data[0].herEmail;
          d.herMobile = res.data[0].herMobile;
          d.city = res.data[0].city;
          d.state = res.data[0].state;
          d.zip = res.data[0].zip;
          d.notes = res.data[0].notes;
          d.parishId = res.data[0].parishId;
          d.imageUrl = res.data[0].imageUrl;
          // d.marriageImageUrl = res.data[0].marriageImageUrl;
          d.address = res.data[0].address;
          d.allergies = res.data[0].allergies;
          d.anniversary_date = res.data[0].anniversary_date
            ? new Date(res.data[0].anniversary_date)
            : "";
          d.under_age_35 = res.data[0].under_age_35;
          d.emergency_name1 = res.data[0].emergency_name1;
          d.emergency_relationship1 = res.data[0].emergency_relationship1;
          d.emergency_phone1 = res.data[0].emergency_phone1;
          d.emergency_name2 = res.data[0].emergency_name2;
          d.emergency_relationship2 = res.data[0].emergency_relationship2;
          d.emergency_phone2 = res.data[0].emergency_phone2;
          d.hear_us = JSON.parse(res.data[0].hear_us || "[]");
          d.referral_from_a_friend_or_family_member =
            res.data[0].referral_from_a_friend_or_family_member;
          setData(d);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getParishList = async () => {
    setLoadData(true);
    await authApi
      .getPerishList()
      .then((response) => {
        const sortedList = response.data.sort((a, b) =>
          a.parish.localeCompare(b.parish)
        );
        setParishList(sortedList);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const handleAgeChanges = (e) => {
    const { name, checked } = e.target;
    let o = { ...data };
    o.under_age_35 = checked ? "YES" : "NO";
    setData(o);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let hearUsArray = Array.isArray(data.hear_us)
      ? data.hear_us
      : JSON.parse(data.hear_us);
    let updatedOptions;
    if (checked) {
      updatedOptions = hearUsArray ? [...hearUsArray, name] : [name];
    } else {
      updatedOptions = hearUsArray.filter((option) => option !== name);
    }
    setData({ ...data, hear_us: updatedOptions });
  };

  const handleDatePicker = (e) => {
    let o = { ...data };
    o.anniversary_date = e;
    setData(o);
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    // Check if the input is for an image
    // if (name === "imageUrl" || name === "marriageImageUrl") {
    //   const file = e.target.files[0];
    //   if (file) {
    //     const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB
    //     if (fileSizeInMB > 10) {
    //       alert("File size should be less than 10 MB");
    //       return;
    //     } else {
    //       d[name] = file;
    //       const successMessage =
    //         name === "imageUrl"
    //           ? "The profile image has been successfully added."
    //           : "The Wedding image has been successfully added.";
    //       toast.success(successMessage);
    //     }
    //   }
    // } else {
    //   d[name] = value;
    // }
    // ONLY FOR UPLOAD COUPLE IMAGE
    if (name === "imageUrl") {
      const file = e.target.files[0];
      if (file) {
        const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB
        if (fileSizeInMB > 10) {
          alert("File size should be less than 10 MB");
          return;
        } else {
          d[name] = file;
          const successMessage =
            name === "imageUrl"
              ? "The profile image has been successfully added."
              : "";
          toast.success(successMessage);
        }
      }
    } else {
      d[name] = value;
    }
    setData(d);
  };

  const handleSubmit = (e) => {
    setLoadButton(true);
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      let data1 = new FormData();
      data1.append("file", data.imageUrl);
      // data1.append("marriage_file", data.marriageImageUrl);
      let stateRef = { ...data };
      delete stateRef.imageUrl;
      // delete stateRef.marriageImageUrl;
      data1.append("fromData", JSON.stringify(stateRef));
      adminApi
        .editCouple(data1)
        .then(async (res) => {
          if (res.success) {
            console.log("ressss-->", res.data);
            userData.imageUpload = res.data.imageUrl;
            // userData.marriageImageUpload = res.data.marriageImageUrl;
            setCookie("LoveStrongUser", userData, {
              path: "/",
              maxAge: 60 * 60 * 100,
              expires: new Date(Date.now() + 60 * 60 * 100),
            });
            toast.success(res.msg);
            clearfield();
            remove();
            editCouple(userData.coupleId);
            setLoadButton(false);
          } else {
            setLoadButton(false);
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setValidated(true);
  };

  const clearfield = () => {
    setData({
      id: "",
      hisFirstName: "",
      hisLastName: "",
      hisEmail: "",
      hisMobile: "",
      herFirstName: "",
      herLastName: "",
      herEmail: "",
      herMobile: "",
      city: "",
      state: "",
      zip: "",
      notes: "",
      imageUrl: "",
      // marriageImageUrl: "",
      parishId: null,
      address: "",
      allergies: "",
      anniversary_date: "",
      under_age_35: false,
      emergency_name1: "",
      emergency_relationship1: "",
      emergency_phone1: "",
      emergency_name2: "",
      emergency_relationship2: "",
      emergency_phone2: "",
      hear_us: [],
      referral_from_a_friend_or_family_member: "",
    });
    setValidated(false);
  };

  const upload = () => {
    document.getElementById("CoupleProfileUpload").click();
    setIsTempUpload(true);
  };

  // const uploadMarriagePhoto = () => {
  //   document.getElementById("MarriagePhotoUpload").click();
  //   setIsTempUpload2(true);
  // };

  const remove = () => {
    let d = { ...data };
    d.imageUrl = "";
    setData(d);
    setIsTempUpload(false);
  };

  // const removeMarriagePhoto = () => {
  //   let d = { ...data };
  //   d.marriageImageUrl = "";
  //   setData(d);
  //   setIsTempUpload2(false);
  // };

  return (
    <div className="row ext-space">
      <div className="col-lg-12 d-flex flex-column">
        <div className="row flex-grow">
          <div className="col-12 grid-margin stretch-card">
            <div className="card custom-card">
              <div className="card-body m-3">
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={validated}
                  className="forms-sample"
                >
                  <Row>
                    <Col lg={12} md={12} xs={12} className="text-center mb-3">
                      <h2 className="card-description">Update Your Profile</h2>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Couple Id</Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="text"
                          name="primaryKey"
                          id="primaryKey"
                          placeholder="Couple Id"
                          value={data.primaryKey}
                          className="form-control"
                          onChange={(e) => handleChanges(e)}
                          readOnly
                        />
                        <Form.Control.Feedback type="invalid">
                          Couple Id is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="mt-4">
                        {" "}
                        <label
                          className="bold mt-2"
                          htmlFor="exampleInputEmail1"
                        >
                          If you or your spouse are 35 years of age or younger,
                          click this box:
                        </label>
                        <label className="form-check-label mt-2">
                          <input
                            type="checkbox"
                            className="form-check-input ageLabel"
                            name="under_age_35"
                            checked={data.under_age_35 === "YES" ? true : false}
                            onChange={handleAgeChanges}
                          />
                          Yes
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                      <Form.Group>
                        {data.imageUrl == "" ||
                        data.imageUrl == undefined ||
                        data.imageUrl == "null" ? (
                          <div className="edit-profile-img mb-2 text-center">
                            <img src={Profile} />
                          </div>
                        ) : (
                          <div className="edit-profile-img mb-2 text-center">
                            {isTempUpload ? (
                              <>
                                <img
                                  src={URL.createObjectURL(data?.imageUrl)}
                                />
                              </>
                            ) : (
                              <>
                                <img src={data?.imageUrl} />
                              </>
                            )}
                          </div>
                        )}{" "}
                        <div className="text-center mt-3">
                          {data.imageUrl == "" ||
                          data.imageUrl == undefined ||
                          data.imageUrl == "null" ? (
                            <>
                              <input
                                name="imageUrl"
                                className="pt-1"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id="CoupleProfileUpload"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Button
                                variant="primary"
                                className="mb-1 me-3 text-center"
                                onClick={(e) => {
                                  upload();
                                }}
                              >
                                Upload Profile Image
                              </Button>
                              {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                            </>
                          ) : (
                            <Button
                              variant="danger"
                              className="mb-1 mt-2 "
                              onClick={(e) => {
                                remove();
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    {/* <Col lg={3} md={3} xs={12}>
                      <Form.Group>
                        {data.marriageImageUrl == "" ||
                        data.marriageImageUrl == undefined || data.marriageImageUrl == 'null' ? (
                          <div className="edit-profile-img mb-2 text-center">
                            <img src={Profile} />
                          </div>
                        ) : (
                          <div className="edit-profile-img mb-2 text-center">
                            {isTempUpload2 ? (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    data?.marriageImageUrl
                                  )}
                                />
                              </>
                            ) : (
                              <>
                                <img src={data?.marriageImageUrl} />
                              </>
                            )}
                          </div>
                        )}
                        <div className="text-center mt-3">
                          {data.marriageImageUrl == "" ||
                          data.marriageImageUrl == undefined || data.marriageImageUrl == 'null' ? (
                            <>
                              <input
                                name="marriageImageUrl"
                                className="pt-1"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id="MarriagePhotoUpload"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Button
                                variant="primary"
                                className="mb-1 text-center"
                                onClick={(e) => {
                                  uploadMarriagePhoto();
                                }}
                              >
                                Upload Wedding Image
                              </Button>
                            </>
                          ) : userData &&
                            userData.userRole === "SUBADMIN" ? null : (
                            <Button
                              variant="danger"
                              className="mb-1 mt-2 "
                              onClick={(e) => {
                                removeMarriagePhoto();
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col> */}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col lg={6} md={6} xs={12}>
                      <label className="badge badge-outline-primary rounded hisLabel">
                        His Info
                      </label>
                      <div id="his-info">
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="hisFirstName"
                              id="hisFirstName"
                              placeholder="First Name"
                              value={data.hisFirstName}
                              className="form-control"
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              FirstName is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="hisLastName"
                              id="hisLastName"
                              placeholder="First Name"
                              value={data.hisLastName}
                              className="form-control"
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              LastName is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group col">
                            <Form.Label>His Email</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="hisEmail"
                              id="hisEmail"
                              placeholder="Email"
                              value={data.hisEmail}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Email is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group col">
                            <Form.Label>His Mobile</Form.Label>
                            <Form.Control
                              size="sm"
                              type="text"
                              name="hisMobile"
                              id="hisMobile"
                              placeholder="Mobile"
                              value={data.hisMobile}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Mobile is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </div>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <label className="badge badge-outline-primary rounded herLabel">
                        Her Info
                      </label>
                      <div id="her-info">
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="herFirstName"
                              id="herFirstName"
                              placeholder="First Name"
                              value={data.herFirstName}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              FirstName is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="herLastName"
                              id="herLastName"
                              placeholder="Last Name"
                              value={data.herLastName}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              LastName is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Her Email</Form.Label>
                            <Form.Control
                              size="sm"
                              type="text"
                              name="herEmail"
                              id="herEmail"
                              placeholder="Email"
                              value={data.herEmail}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Email is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <Form.Group className="form-group">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                              // required
                              size="sm"
                              type="text"
                              name="herMobile"
                              id="herMobile"
                              placeholder="Mobile"
                              value={data.herMobile}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Mobile is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                  <hr></hr>
                  <div className="row">
                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                        value={data.address}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Address is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        className="form-control minimal"
                        size="sm"
                        type="text"
                        name="state"
                        id="state"
                        placeholder="State"
                        value={data.state}
                        onChange={(e) => handleChanges(e)}
                      >
                        <option value="">Select State</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="Arizona">Arizona</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="District of Columbia">
                          District of Columbia
                        </option>
                        <option value="Delaware">Delaware</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Maine">Maine</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Montana">Montana</option>
                        <option value="North Carolina<">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New York">New York</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Washington">Washington</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wyoming">Wyoming</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        State is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        value={data.city}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        City is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Zip</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="Zip"
                        value={data.zip}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Zip is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Parish</Form.Label>
                      <Form.Select
                        className="form-control minimal"
                        size="sm"
                        type="text"
                        name="parishId"
                        id="parishId"
                        placeholder="Parish"
                        value={data.parishId}
                        onChange={(e) => handleChanges(e)}
                      >
                        <option value="">Select Parish</option>
                        {parishList.map((item, index) => {
                          return <option value={item.id}>{item.parish}</option>;
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Parsih is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label className="d-block">
                        Anniversary Date (Month/Day)
                      </Form.Label>
                      <DatePicker
                        autoComplete="off"
                        dateFormat="MM-dd-yyyy"
                        placeholderText="mm-dd-yyyy"
                        selected={
                          data.anniversary_date ? data.anniversary_date : null
                        }
                        onChange={(e) => handleDatePicker(e)}
                        className="form-control setdate datepicker"
                        name="anniversary_date"
                        id="anniversary_date"
                        maxDate={new Date()}
                        showDisabledMonthNavigation
                      />
                    </Form.Group>

                    {/* <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Anniversary Date (Month/Day)</Form.Label>
                      <DatePicker
                        // required
                        autoComplete="off"
                        dateFormat="MM-dd"
                        placeholderText="mm-dd"
                        selected={
                          data.anniversary_date ? data.anniversary_date : null
                        }
                        onChange={(e) => handleDatePicker(e)}
                        className="form-select setdate datepicker"
                        name="anniversary_date"
                        id="anniversary_date"
                        // maxDate={new Date()}
                        showDisabledMonthNavigation
                      />
                    </Form.Group> */}

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="notes"
                        id="notes"
                        placeholder="Notes"
                        value={data.notes}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Notes is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>
                        Do you or your spouse have any dietary allergies,
                        dietary restrictions or physical limitations?
                      </Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="allergies"
                        id="allergies"
                        value={data.allergies}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>
                  </div>

                  <div className="row mt-3">
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h4>Please provide at least one emergency contact:</h4>
                    </div>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_name1"
                        id="emergency_name1"
                        placeholder="Name"
                        value={data.emergency_name1}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <Form.Label>Relationship</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_relationship1"
                        id="emergency_relationship1"
                        placeholder="Relationship"
                        value={data.emergency_relationship1}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_phone1"
                        id="emergency_phone1"
                        placeholder="Phone"
                        value={data.emergency_phone1}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_name2"
                        id="emergency_name2"
                        placeholder="Name"
                        value={data.emergency_name2}
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <Form.Label>Relationship</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_relationship2"
                        id="emergency_relationship2"
                        placeholder="Relationship"
                        value={data.emergency_relationship2}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Relationship is required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="emergency_phone2"
                        id="emergency_phone2"
                        placeholder="Phone"
                        value={data.emergency_phone2}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Phone is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="row mt-3 ">
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h4>How did you hear about us?</h4>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between hear-options">
                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="Website"
                          value="Website"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("Website")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(data.hear_us === "Website")
                          }
                        />
                        Website
                        <i className="input-helper"></i>
                      </label>

                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="Church Bulletin"
                          value="Church Bulletin"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("Church Bulletin")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(
                              data.hear_us === "Church Bulletin"
                            )
                          }
                        />
                        Church Bulletin
                        <i className="input-helper"></i>
                      </label>

                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="Facebook"
                          value="Facebook"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("Facebook")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(data.hear_us === "Facebook")
                          }
                        />
                        Facebook
                        <i className="input-helper"></i>
                      </label>

                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="School Marquee"
                          value="School Marquee"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("School Marquee")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(
                              data.hear_us === "School Marquee"
                            )
                          }
                        />
                        School Marquee
                        <i className="input-helper"></i>
                      </label>

                      <label className="form-check-label ml-sm-2">
                        <input
                          type="checkbox"
                          className="form-check-input ageLabel"
                          name="Pulpit Talk/Table in Church"
                          value="Pulpit Talk/Table in Church"
                          onChange={handleCheckboxChange}
                          checked={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes("Pulpit Talk/Table in Church")
                          }
                          disabled={
                            data &&
                            data.hear_us &&
                            data.hear_us.includes(
                              data.hear_us === "Pulpit Talk/Table in Church"
                            )
                          }
                        />
                        Pulpit Talk/Table in Church
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </div>
                  <Col lg={6} md={6} xs={12} sm={12} className=" mt-5">
                    <h4> Referral from a friend/Family member</h4>
                    <Form.Group className="form-group">
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="referral_from_a_friend_or_family_member"
                        placeholder=" Referral from a friend/Family member"
                        id="referral_from_a_friend_or_family_member"
                        value={data.referral_from_a_friend_or_family_member}
                        className="form-control"
                        onChange={(e) => {
                          handleChanges(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12} className="text-center mt-5">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loadButton ? true : false}
                    >
                      Update
                    </Button>
                  </Col>
                </Form>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};
