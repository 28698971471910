import BaseApi from "./BaseApi";

class RetreatRoasterApi extends BaseApi {
  async getRetreatRoastData(data) {
    return this.API({
      url: "/retreatRoast/getRetreatRoastData",
      data: data,
      method: "post",
    });
  }

  async getSpritualRoastData(data) {
    return this.API({
      url: "/retreatRoast/getSpritualRoastData",
      data: data,
      method: "post",
    });
  }

  async getTeamRoastData(data) {
    return this.API({
      url: "/retreatRoast/getTeamRoastData",
      data: data,
      method: "post",
    });
  }

  async getLeadCoupleRoastData(data) {
    return this.API({
      url: "/retreatRoast/getLeadCoupleRoastData",
      data: data,
      method: "post",
    });
  }

  async gettalkRoastData(data) {
    return this.API({
      url: "/retreatRoast/gettalkRoastData",
      data: data,
      method: "post",
    });
  }

  async getServiceRoastData(data) {
    return this.API({
      url: "/retreatRoast/getServiceRoastData",
      data: data,
      method: "post",
    });
  }

  async getRetreatantRoastData(data) {
    return this.API({
      url: "/retreatRoast/getRetreatantRoastData",
      data: data,
      method: "post",
    });
  }

  async saveRetreatRoasterExcel(data) {
    return await this.API({
      url: "/retreatRoast/saveRetreatRoasterExcel",
      data: data,
      method: "post",
    });
  }

  async deleteRetreatRoast(data) {
    return this.API({
      url: "/retreatRoast/deleteRetreatRoast",
      data: data,
      method: "put",
    });
  }

  async getRetreatLead(data) {
    return this.API({
      url: "/retreatRoast/getRetreatLead",
      data: data,
      method: "post",
    });
  }

  async updateRoaster(data) {
    return this.API({
      url: "/retreatRoast/updateRoaster",
      data: data,
      method: "post",
    });
  }

  async CreateRoaster(data) {
    return this.API({
      url: "/retreatRoast/CreateRoaster",
      data: data,
      method: "post",
    });
  }

  async getCouplesInRetreat(data) {
    return this.API({
      url: "/retreatRoast/getCouplesInRetreat",
      data: data,
      method: "post",
    });
  }

  async createLeads(data) {
    return this.API({
      url: "/retreatRoast/createLeads",
      data: data,
      method: "post",
    });
  }

  async removeCoupleFromRetreat(data) {
    return await this.API({
      url: `/retreatRoast/removeCoupleFromRetreat`,
      method: "post",
      data: data,
    });
  }
}
export default RetreatRoasterApi;
