import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import companyLogo from '../../assets/images/logo.png';
import AuthApi from '../../api/authApi';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  let api = new AuthApi();
  let history = new useNavigate();
  const [validated, setValidated] = useState(false);
  const [data,setData]=useState({
    email:""
  })

  const handleChanges = async (e) => {
    let {name, value} = e.target;
    // console.log(name,value)
    let d = {...data}
    d[name] = value;
    setData(d);
  }

  const handleSubmit = (event) => {
    // console.log('data to forget password:', data);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      api.TakeEmail(data)
      .then((res)=>{
        if(res.success){
          toast.success(res.msg);
          history(`/changePassword`, {
            state: {
              emailaddress: data.email
            }
          })
        }else{
          toast.error(res.msg);
        }
      }).catch((error)=>{
        console.log(error);
        toast.error(error);
      })
    }
    setValidated(true);
  };

  return (
    <div className="container-scroller mainpannel">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo ">
                  <img src={companyLogo} alt="logo" className="logo center" />
                </div>
                <h4>Forgot Password? 🔒</h4>
                <h6 className="fw-light">Enter your email and we'll send you OTP.</h6>

                <Form noValidate validated={validated} onSubmit={handleSubmit} className="pt-3">
                  <Form.Group style={{ marginBottom: "1rem" }}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                      required type="email" id='EmailinPut' className="form-control  form-control-sm border-left-0" placeholder="Email" name="email" value={data.email} onChange={(e)=>handleChanges(e)}/>
                    <Form.Control.Feedback type="invalid">
                      Please provide valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button type="submit" variant="primary" >SEND OTP</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;