import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RetreatBackUpApi from "../../../api/retreatBackUpApi";
import AuthApi from "../../../api/authApi";
import { toast } from "react-toastify";
import CustomDataTable from "../../../components/CustomDataTable";
import Helpers from "../../../components/Helpers";
import DeleteModel from "../../../components/DeleteModel";
import { Cookies } from "react-cookie";
import "react-datepicker/dist/react-datepicker.css";
import RetreatApi from "../../../api/retreatApi";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import moment from "moment";
import AlertModal from "../../../components/AlertModel";
import AdminApi from "../../../api/admin";

const RetreatBackUp = () => {
  const [total, setTotal] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const [LoadData, setLoadData] = useState(false);
  const [retreatDetail, setRetreatDetail] = useState([]);
  const [deleteList, setDeleteList] = useState(null);
  let retreatBackUpApi = new RetreatBackUpApi();
  let retreatApi = new RetreatApi();

  let adminApi = new AdminApi();
  let navigate = useNavigate();

  useEffect(() => {
    getBackUpRetreats();
  }, []);

  const getBackUpRetreats = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    loadCount = false,
    coupleId
  ) => {
    setDeleteList([]);
    setLoadData(true);
    let d = {};
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = searchKey;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = false;
    d.coupleId = coupleId;
    await retreatBackUpApi
      .getBackUpRetreats(d)
      .then((res) => {
        if (res.success) {
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
          setTotal(totalRecords);
          const parsedData = res.data.map((item) => {
            const { schedule, ...rest } = item;
            const parsedSchedule = schedule ? JSON.parse(schedule) : [];
            return {
              ...rest,
              schedule: parsedSchedule.map((entry) => ({
                date: new Date(entry.date),
                from: entry.from,
                to: entry.to,
              })),
            };
          });
          setLoadData(false);
          setDeleteList(parsedData);
        } else {
          setLoadData(false);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error);
      });
  };

  const restoreRetreat = async () => {
    await retreatBackUpApi
      .restoreRetreat({ id: retreatDetail.id })
      .then(async (res) => {
        if (res.success) {
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Retreat activated again ( ${retreatDetail.id} - ${retreatDetail.title} )`,
            action: "Delete",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Added new log report");
              }
            })
            .catch((error) => {
              console.log(error);
            });
          toast.success(res.msg);
          getBackUpRetreats();
          setRetreatDetail([]);
          setShow1(false);
        } else {
          toast.error(res.msg);
          getBackUpRetreats();
          setRetreatDetail([]);
          setShow1(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const permanentDeleteRetreat = async () => {
    await retreatBackUpApi
      .permanentDeleteRetreat({ id: retreatDetail.id })
      .then(async (res) => {
        if (res.success) {
          toast.success(res.msg);
          getBackUpRetreats();
          setShow(false);
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Retreat deleted permanently ( ${retreatDetail.id} - ${retreatDetail.title} )`,
            action: "Delete",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Added new log report");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
          getBackUpRetreats();
          setShow(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      setSearchKey(event.target.value);
      getBackUpRetreats(1, 20, event.target.value);
    } else {
      setSearchKey("");
      getBackUpRetreats(1, 20, event.target.value);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    {
      id: "title",
      name: "Retreat Title",
      selector: (row) => (
        <>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              navigate(`/retreatRoaster?retreatId=${row.id}`);
            }}
          >
            <div>{row.title}</div>
          </a>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(
              row.dateFrom === row.dateTo
                ? moment(row && row.dateFrom).format("MMM-DD-YYYY")
                : moment(row && row.dateFrom).format("MMM DD") +
                    " - " +
                    moment(row && row.dateTo).format("MMM DD") +
                    ", " +
                    moment(row && row.dateFrom).format("YYYY")
            )}
          >
            {row.dateFrom === row.dateTo ? (
              <span>{moment(row && row.dateFrom).format("MMM-DD-YYYY")}</span>
            ) : (
              <span>
                {moment(row && row.dateFrom).format("MMM-DD") +
                  " - " +
                  moment(row.dateTo).format("MMM DD") +
                  ", " +
                  moment(row && row.dateFrom).format("YYYY")}
              </span>
            )}
          </OverlayTrigger>
        </>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "language",
      name: "Language",
      selector: (row) =>
        row.language.charAt(0).toUpperCase() + row.language.slice(1),
      sortable: true,
      reorder: true,
      width: "120px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "location",
      name: "Location",
      selector: (row) =>
        row.location.charAt(0).toUpperCase() + row.location.slice(1),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "fromToDate",
      name: "Retreat Dates",
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.dateFrom === row.dateTo
              ? moment(row && row.dateFrom).format("MMM-DD-YYYY")
              : moment(row && row.dateFrom).format("MMM DD") +
                  " - " +
                  moment(row && row.dateTo).format("MMM DD") +
                  ", " +
                  moment(row && row.dateFrom).format("YYYY")
          )}
        >
          {row.dateFrom === row.dateTo ? (
            <span>{moment(row && row.dateFrom).format("MMM-DD-YYYY")}</span>
          ) : (
            <span>
              {moment(row && row.dateFrom).format("MMM-DD") +
                " - " +
                moment(row.dateTo).format("MMM DD") +
                ", " +
                moment(row && row.dateFrom).format("YYYY")}
            </span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "140px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "team",
      name: "# Team Couples",
      selector: (row) => row && row.countTeam,
      cell: (row) => (
        <div className="tooltip-static-demo">
          <div
            className="bs-tooltip-bottom bs-tooltip-bottom-demo tooltip-info"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title=""
            data-bs-original-title="Decorations, Facilities, Go Team, Kitchen"
          >
            <div className="arrow"></div>
            <a
              href={`/retreatRoaster?retreatId=${row.id}&flag=2`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="tooltip-inner">{row && row.countTeam}</div>
            </a>
          </div>
        </div>
      ),
      reorder: true,
      sortable: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Retreatants",
      name: "# Retreatant Couples",
      selector: (row) => row && row.countRetreatant,
      cell: (row) => (
        <div className="tooltip-static-demo">
          <div
            className="bs-tooltip-bottom bs-tooltip-bottom-demo tooltip-info"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title=""
            data-bs-original-title="Decorations, Facilities, Go Team, Kitchen"
          >
            <div className="arrow"></div>
            <a
              href={`/retreatRoaster?retreatId=${row.id}&flag=1`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="tooltip-inner">{row && row.countRetreatant}</div>
            </a>
          </div>
        </div>
      ),
      reorder: true,
      sortable: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Directory",
      name: "Directory",
      button: true,
      cell: (row) => (
        <a
          href={`/create-directory/${row.id}`}
          className="font-size ml-3"
          target="_blank"
        >
          <i className="mdi mdi-file-document-box" title="View"></i>
        </a>
      ),
      reorder: true,
      width: "100px",
    },
    {
      id: "Program",
      name: "Program",
      button: true,
      cell: (row) => (
        <a
          href={`/create-program/${row.id}`}
          className="font-size ml-2"
          target="_blank"
        >
          <i className="mdi mdi-format-list-bulleted" title="View"></i>
        </a>
      ),
      reorder: true,
      width: "100px",
    },
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(row && row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Deleted Date",
      name: "Deleted Date",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.updatedAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.updatedAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(row && row.updatedAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: "action",
            name: "Action",
            button: true,
            cell: (row) => (
              <>
                <Link to={`/create-retreat/${row.id}`} className="font-size">
                  <i
                    className="mdi mdi-pencil-box text-lg text-primary font-size"
                    title="Edit"
                  ></i>
                </Link>

                <button className="font-size" onClick={() => handleShow(row)}>
                  <i
                    className="mdi mdi-delete-forever text-md text-danger mx-2 font-size"
                    title="Permanent Delete"
                  ></i>
                </button>

                <button className="font-size" onClick={() => handleShow1(row)}>
                  <i
                    className="mdi mdi-backup-restore text-md text-danger mx-2 font-size"
                    title="Restore"
                  ></i>
                </button>

                <a
                  href={`/registration-retreat/${row.id}`}
                  className="font-size"
                  target="_blank"
                >
                  <i className="mdi mdi-eye" title="View"></i>
                </a>
              </>
            ),
            reorder: true,
            width: "200px",
          },
        ]
      : []),
  ];

  const handleShow = (item) => {
    setRetreatDetail(item);
    setShow(true);
  };

  const handleClose = () => {
    getBackUpRetreats();
    setShow(false);
    setRetreatDetail([]);
  };

  const handleShow1 = (item) => {
    setRetreatDetail(item);
    setShow1(true);
  };

  const handleClose1 = () => {
    getBackUpRetreats();
    setRetreatDetail([]);
    setShow1(false);
  };

  return (
    <>
      <Link to="/manageRetreat" className="back-link">
        &lt;Back
      </Link>
      <div className="row mt-3">
        <div className="col-lg-12 d-flex flex-column">
          <div className="card  custom-card">
            <div className="card-body">
              <div className="d-sm-flex justify-content-between align-items-start Couple_heading">
                <h4 className="card-title">Retreats-Backup</h4>
                {userData && userData.userRole === "ADMIN" ? (
                  <div className="d-flex">
                    <div className="search">
                      <div className="input-group setSearch">
                        <input
                          onChange={SearchData}
                          type="text"
                          className="form-control searchData"
                          placeholder="Search..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-primary"
                            type="button"
                          >
                            <i className="icon-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <Link to="/create-retreat" className="addNewRet">
                      <button
                        className="btn btn-primary btn-sm text-white mb-0 me-0 alignbtn"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i className="mdi mdi-account-multiple"></i> Add New
                        Retreat
                      </button>
                    </Link>
                  </div>
                ) : null}
              </div>
              {deleteList ? (
                <CustomDataTable
                  data={deleteList}
                  countPerPage={20}
                  isServerSide={true}
                  pagination={true}
                  sortServer={true}
                  total={total}
                  columns={columns}
                  dataFetchApi={getBackUpRetreats}
                  pointerOnHover={true}
                  highlightOnHover={true}
                />
              ) : null}
            </div>
          </div>
        </div>
        {show ? (
          <DeleteModel
            show={show}
            handleClose={handleClose}
            DeleteData={permanentDeleteRetreat}
            title={retreatDetail.title}
            selectionDelete="permanent"
          ></DeleteModel>
        ) : null}
        {show1 ? (
          <AlertModal
            show={show1}
            handleClose={handleClose1}
            message={
              <h5 className="mt-2 deleteMessage">
                Are you sure to restore this Retreat?
                <h4 className="mt-3">
                  <b>{retreatDetail ? retreatDetail.title : ""}</b>
                </h4>
              </h5>
            }
            actionFunction={restoreRetreat}
          />
        ) : null}
      </div>
    </>
  );
};
export default RetreatBackUp;
