import BaseApi from "./BaseApi";
class AuthApi extends BaseApi {
  async Login(data) {
    return await this.API({
      url: "/auth/login",
      data: data,
      method: "post",
    });
  }
  async TakeEmail(data) {
    return await this.API({
      url: "auth/forgetPassword",
      data: data,
      method: "post",
    });
  }
  async ResetPassWord(data) {
    return await this.API({
      url: "auth/changePassword",
      data: data,
      method: "post",
    });
  }
  async verifyEmail(data) {
    return await this.API({
      url: "/auth/verify-email",
      data: data,
      method: "post",
    });
  }
  async getProfile(data) {
    return await this.API({
      url: "auth/get-profile",
      data: data,
      method: "post",
    });
  }
  async EditProfile(data) {
    return await this.API({
      url: "auth/update-profile",
      data: data,
      method: "post",
    });
  }

  async passwordChangeAfterLogin(data) {
    return await this.API({
      url: "auth/update-password",
      data: data,
      method: "post",
    });
  }

  async getCouplesDetail(data) {
    return this.API({
      url: "/auth/getCouplesDetail",
      data: data,
      method: "post",
    });
  }

  async editCouple(data) {
    return this.API({
      url: "/auth/editCouple",
      data: data,
      method: "post",
    });
  }

  async getPerishList() {
    return await this.API({
      url: "/auth/getallperishlist",
      method: "get",
    });
  }

  async checkPKCouple() {
    return await this.API({
      url: "/auth/checkPKCouple",
      method: "post",
    });
  }

  async getAllIncommingRetreats() {
    return await this.API({
      url: "/auth/getAllIncommingRetreats",
      method: "get",
    });
  }

  async getRolesOfRetreats(data) {
    return await this.API({
      url: "/auth/getRolesOfRetreats",
      data: data,
      method: "post",
    });
  }

  async registerCoupleForRetrteat(data) {
    return await this.API({
      url: "/auth/registerCoupleForRetrteat",
      data: data,
      method: "post",
    });
  }

  async retreatRegisteredCouples(data) {
    return await this.API({
      url: "/auth/retreatRegisteredCouples",
      data: data,
      method: "post",
    });
  }

  async getDirectoryDetail(data) {
    return await this.API({
      url: "/auth/getDirectoryDetail",
      data: data,
      method: "post",
    });
  }

  async getAllRoastersOfRetreat(data) {
    return await this.API({
      url: "/auth/getAllRoastersOfRetreat",
      data: data,
      method: "post",
    });
  }

  async getRetreatDetail(data) {
    return this.API({
      url: "/auth/getRetreatDetail",
      data: data,
      method: "post",
    });
  }

  async getProgramDetail(data) {
    return await this.API({
      url: "/auth/getProgramDetail",
      data: data,
      method: "post",
    });
  }

  async getAllRoastersInfo(data) {
    return await this.API({
      url: "/auth/getAllRoastersInfo",
      data: data,
      method: "post",
    });
  }
}

export default AuthApi;
