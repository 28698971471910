import BaseApi from "./BaseApi";

class retreatBackUpApi extends BaseApi {
    
  async getBackUpRetreats(data) {
    return this.API({
      url: "/retreat-backup/getBackUpRetreats",
      data: data,
      method: "post",
    });
  }

    async permanentDeleteRetreat(data) {
      return this.API({
        url: "/retreat-backup/permanentDeleteRetreat",
        data: data,
        method: "post",
      });
    }


    async restoreRetreat(data) {
      return this.API({
        url: "/retreat-backup/restoreRetreat",
        data: data,
        method: "post",
      });
    }
}

export default retreatBackUpApi;
