import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import AuthApi from "../../api/authApi";
import { toast } from "react-toastify";
import { Cookies, useCookies } from "react-cookie";
import "jspdf-autotable";
import Profile from "../../assets/images/couple-default.png";
import companyLogo from "../../assets/images/logo.png";
import DatePicker from "react-datepicker";

function CoupleUpdate() {
  const [data, setData] = useState({
    id: "",
    primaryKey: "",
    hisFirstName: "",
    hisLastName: "",
    hisEmail: "",
    hisMobile: "",
    herFirstName: "",
    herLastName: "",
    herEmail: "",
    herMobile: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    imageUrl: "",
    parishId: null,
    address: "",
    allergies: "",
    anniversary_date: "",
    under_age_35: "NO",
    emergency_name1: "",
    emergency_relationship1: "",
    emergency_phone1: "",
    emergency_name2: "",
    emergency_relationship2: "",
    emergency_phone2: "",
    hear_us: [],
    referral_from_a_friend_or_family_member: "",
    linkverify: "0",
  });

  const [validated, setValidated] = useState(false);
  const [isTempUpload, setIsTempUpload] = useState(false);
  const [Flag, setFlag] = useState("");
  const [parishList, setParishList] = useState([]);
  const [LoadData, setLoadData] = useState(false);
  const navigate = useNavigate();
  let cookies = new Cookies();
  let authApi = new AuthApi();
  let { coupleId } = useParams();
  let couple_id = coupleId.split("-")[0];
  let linkverify = coupleId.split("-")[1];
  useEffect(() => {
    getParishList();
    if (couple_id) {
      getCouplesDetail(couple_id);
    }
  }, [couple_id]);

  const getCouplesDetail = async (couple_id) => {
    let d = { ...data };
    await authApi
      .getCouplesDetail({ coupleId: couple_id })
      .then(async (res) => {
        if (res.success) {
          d.id = res.data[0].id;
          d.primaryKey = res.data[0].primaryKey;
          d.hisFirstName = res.data[0].hisFirstName;
          d.hisLastName = res.data[0].hisLastName;
          d.hisEmail = res.data[0].hisEmail;
          d.hisMobile = res.data[0].hisMobile;
          d.herFirstName = res.data[0].herFirstName;
          d.herLastName = res.data[0].herLastName;
          d.herEmail = res.data[0].herEmail;
          d.herMobile = res.data[0].herMobile;
          d.city = res.data[0].city;
          d.state = res.data[0].state;
          d.zip = res.data[0].zip;
          d.notes = res.data[0].notes;
          d.parishId = res.data[0].parishId;
          d.imageUrl = res.data[0].imageUrl;
          d.address = res.data[0].address;
          d.allergies = res.data[0].allergies;
          d.anniversary_date = new Date(res.data[0].anniversary_date);
          d.under_age_35 = res.data[0].under_age_35;
          d.emergency_name1 = res.data[0].emergency_name1;
          d.emergency_relationship1 = res.data[0].emergency_relationship1;
          d.emergency_phone1 = res.data[0].emergency_phone1;
          d.emergency_name2 = res.data[0].emergency_name2;
          d.emergency_relationship2 = res.data[0].emergency_relationship2;
          d.emergency_phone2 = res.data[0].emergency_phone2;
          d.hear_us = res.data[0].hear_us;
          d.referral_from_a_friend_or_family_member =
            res.data[0].referral_from_a_friend_or_family_member;
          setData(d);
          setFlag(res.data[0].linkverify);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getParishList = async () => {
    setLoadData(true);
    await authApi
      .getPerishList()
      .then((response) => {
        const sortedList = response.data.sort((a, b) =>
          a.parish.localeCompare(b.parish)
        );
        setParishList(sortedList);
        // setLoadData(false)
      })
      .catch((error) => {
        // setLoadData(false)
        toast.error(error.toString());
      });
  };

  let userData = cookies.get("LoveStrongUser") || null;
  const [cookie, setCookie] = useCookies(["LoveStrongUser"]);

  const clearfield = () => {
    setData({
      id: "",
      hisFirstName: "",
      hisLastName: "",
      hisEmail: "",
      hisMobile: "",
      herFirstName: "",
      herLastName: "",
      herEmail: "",
      herMobile: "",
      ciyt: "",
      state: "",
      zip: "",
      notes: "",
      userRole: "COUPLE",
      address: "",
      allergies: "",
      anniversary_date: "",
      under_age_35: "NO",
      emergency_name1: "",
      emergency_relationship1: "",
      emergency_phone1: "",
      emergency_name2: "",
      emergency_relationship2: "",
      emergency_phone2: "",
      hear_us: [],
      referral_from_a_friend_or_family_member: "",
      linkverify: "0",
    });
    setValidated(false);
  };

  const handleAgeChanges = (e) => {
    const { name, checked } = e.target;
    let o = { ...data };
    o.under_age_35 = checked ? "YES" : "NO";
    setData(o);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let hearUsArray = Array.isArray(data.hear_us)
      ? data.hear_us
      : JSON.parse(data.hear_us);

    let updatedOptions;
    if (checked) {
      updatedOptions = hearUsArray ? [...hearUsArray, name] : [name];
    } else {
      updatedOptions = hearUsArray.filter((option) => option !== name);
    }
    setData({ ...data, hear_us: updatedOptions });
  };

  const handleDatePicker = (e) => {
    let o = { ...data };
    o.anniversary_date = e;
    setData(o);
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    if (name == "imageUrl") {
      d.imageUrl = e.target.files[0];
      toast.success("The profile image has been successfully added.");
    } else {
      d[name] = value;
    }
    setData(d);
  };

  const upload = () => {
    document.getElementById("CoupleProfileUpload").click();
    setIsTempUpload(true);
  };

  const remove = () => {
    let d = { ...data };
    d.imageUrl = "";
    setData(d);
    setIsTempUpload(false);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      let data1 = new FormData();
      if (data.imageUrl !== "") {
        data1.append("file", data.imageUrl);
      }
      let stateRef = { ...data };
      delete stateRef.imageUrl;
      data1.append("fromData", JSON.stringify(stateRef));
      console.log("data1--->", data1);
      authApi
        .editCouple(data1)
        .then((res) => {
          if (res.success) {
            toast.success(res.msg);
            clearfield();
            navigate("/thankyou", {
              state: "Profile",
            });
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
    setValidated(true);
  };

  if (Flag) {
    return (
      <div className="container-scroller mainpannel">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-10 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo ">
                    <img src={companyLogo} alt="logo" className="logo center" />
                  </div>
                  <div className="text-center">
                    <h3>Update Profile Information</h3>
                    <p>
                      Kindly update your information.This profile link works for
                      one time only.
                    </p>
                  </div>
                  <Form
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    className="forms-sample"
                  >
                    <p className="card-description">
                      Enter couple details here..
                    </p>
                    <Row>
                      <Col lg={6} md={6} xs={12}>
                        <Form.Group>
                          <Form.Label>Couple Id</Form.Label>
                          <Form.Control
                            required
                            size="sm"
                            type="text"
                            name="primaryKey"
                            id="primaryKey"
                            placeholder="Couple Id"
                            value={data.primaryKey}
                            className="form-control"
                            onChange={(e) => handleChanges(e)}
                            readOnly
                          />
                          <Form.Control.Feedback type="invalid">
                            Couple Id is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <div className="mt-4">
                          {" "}
                          <label
                            className="bold mt-2"
                            htmlFor="exampleInputEmail1"
                          >
                            If you or your spouse are 35 years of age or
                            younger, click this box:
                          </label>
                          <label className="form-check-label mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input ageLabel"
                              name="under_age_35"
                              checked={
                                data.under_age_35 === "YES" ? true : false
                              }
                              onChange={handleAgeChanges}
                            />
                            Yes
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        <Form.Group>
                          {data.imageUrl == "" || data.imageUrl == undefined ? (
                            <div className="edit-profile-img mb-2 text-center">
                              <img src={Profile} />
                            </div>
                          ) : (
                            <div className="edit-profile-img mb-2 text-center">
                              {isTempUpload ? (
                                <>
                                  <img
                                    src={URL.createObjectURL(data?.imageUrl)}
                                  />
                                </>
                              ) : (
                                <>
                                  <img src={data?.imageUrl} />
                                </>
                              )}
                            </div>
                          )}{" "}
                          <div className="text-center mt-3">
                            {data.imageUrl == "" ||
                            data.imageUrl == undefined ? (
                              <>
                                <input
                                  name="imageUrl"
                                  className="pt-1"
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id="CoupleProfileUpload"
                                  onChange={(e) => handleChanges(e)}
                                />
                                <Button
                                  variant="primary"
                                  className="mb-1 me-3 text-center"
                                  onClick={(e) => {
                                    upload();
                                  }}
                                >
                                  Upload Profile Image
                                </Button>
                                {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                              </>
                            ) : (
                              <Button
                                variant="danger"
                                className="mb-1 mt-2 "
                                onClick={(e) => {
                                  remove();
                                }}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={6} md={6} xs={12}>
                        <label className="badge badge-outline-primary rounded hisLabel">
                          His Info
                        </label>
                        <div id="his-info">
                          <Col lg={12} md={12} xs={12}>
                            <Form.Group className="form-group">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                // required
                                size="sm"
                                type="text"
                                name="hisFirstName"
                                id="hisFirstName"
                                placeholder="First Name"
                                value={data.hisFirstName}
                                className="form-control"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                FirstName is required!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={12} md={12} xs={12}>
                            <Form.Group className="form-group">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                // required
                                size="sm"
                                type="text"
                                name="hisLastName"
                                id="hisLastName"
                                placeholder="First Name"
                                value={data.hisLastName}
                                className="form-control"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                LastName is required!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={12} md={12} xs={12}>
                            <Form.Group className="form-group col">
                              <Form.Label>His Email</Form.Label>
                              <Form.Control
                                // required
                                size="sm"
                                type="text"
                                name="hisEmail"
                                id="hisEmail"
                                placeholder="Email"
                                value={data.hisEmail}
                                onChange={(e) => handleChanges(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                Email is required!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={12} md={12} xs={12}>
                            <Form.Group className="form-group col">
                              <Form.Label>His Mobile</Form.Label>
                              <Form.Control
                                size="sm"
                                type="text"
                                name="hisMobile"
                                id="hisMobile"
                                placeholder="Mobile"
                                value={data.hisMobile}
                                onChange={(e) => handleChanges(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                Mobile is required!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </div>
                      </Col>

                      <Col lg={6} md={6} xs={12}>
                        <label className="badge badge-outline-primary rounded herLabel">
                          Her Info
                        </label>
                        <div id="her-info">
                          <Col lg={12} md={12} xs={12}>
                            <Form.Group className="form-group">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                // required
                                size="sm"
                                type="text"
                                name="herFirstName"
                                id="herFirstName"
                                placeholder="First Name"
                                value={data.herFirstName}
                                onChange={(e) => handleChanges(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                FirstName is required!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={12} md={12} xs={12}>
                            <Form.Group className="form-group">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                // required
                                size="sm"
                                type="text"
                                name="herLastName"
                                id="herLastName"
                                placeholder="Last Name"
                                value={data.herLastName}
                                onChange={(e) => handleChanges(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                LastName is required!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={12} md={12} xs={12}>
                            <Form.Group className="form-group">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                size="sm"
                                type="text"
                                name="herEmail"
                                id="herEmail"
                                placeholder="Email"
                                value={data.herEmail}
                                onChange={(e) => handleChanges(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                Email is required!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={12} md={12} xs={12}>
                            <Form.Group className="form-group">
                              <Form.Label>Mobile</Form.Label>
                              <Form.Control
                                // required
                                size="sm"
                                type="text"
                                name="herMobile"
                                id="herMobile"
                                placeholder="Mobile"
                                value={data.herMobile}
                                onChange={(e) => handleChanges(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                Mobile is required!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                    <div className="row">
                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="address"
                          id="address"
                          placeholder="Address"
                          value={data.address}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Address is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>State</Form.Label>
                        <Form.Select
                          className="form-control minimal"
                          size="sm"
                          type="text"
                          name="state"
                          id="state"
                          placeholder="State"
                          value={data.state}
                          onChange={(e) => handleChanges(e)}
                        >
                          <option value="">Select State</option>
                          <option value="Alaska">Alaska</option>
                          <option value="Alabama">Alabama</option>
                          <option value="Arkansas">Arkansas</option>
                          <option value="Arizona">Arizona</option>
                          <option value="California">California</option>
                          <option value="Colorado">Colorado</option>
                          <option value="Connecticut">Connecticut</option>
                          <option value="District of Columbia">
                            District of Columbia
                          </option>
                          <option value="Delaware">Delaware</option>
                          <option value="Florida">Florida</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Hawaii">Hawaii</option>
                          <option value="Iowa">Iowa</option>
                          <option value="Idaho">Idaho</option>
                          <option value="Illinois">Illinois</option>
                          <option value="Indiana">Indiana</option>
                          <option value="Kansas">Kansas</option>
                          <option value="Kentucky">Kentucky</option>
                          <option value="Louisiana">Louisiana</option>
                          <option value="Massachusetts">Massachusetts</option>
                          <option value="Maryland">Maryland</option>
                          <option value="Maine">Maine</option>
                          <option value="Michigan">Michigan</option>
                          <option value="Minnesota">Minnesota</option>
                          <option value="Missouri">Missouri</option>
                          <option value="Mississippi">Mississippi</option>
                          <option value="Montana">Montana</option>
                          <option value="North Carolina<">
                            North Carolina
                          </option>
                          <option value="North Dakota">North Dakota</option>
                          <option value="Nebraska">Nebraska</option>
                          <option value="New Hampshire">New Hampshire</option>
                          <option value="New Jersey">New Jersey</option>
                          <option value="New Mexico">New Mexico</option>
                          <option value="Nevada">Nevada</option>
                          <option value="New York">New York</option>
                          <option value="Ohio">Ohio</option>
                          <option value="Oklahoma">Oklahoma</option>
                          <option value="Oregon">Oregon</option>
                          <option value="Pennsylvania">Pennsylvania</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Rhode Island">Rhode Island</option>
                          <option value="South Carolina">South Carolina</option>
                          <option value="South Dakota">South Dakota</option>
                          <option value="Tennessee">Tennessee</option>
                          <option value="Texas">Texas</option>
                          <option value="Utah">Utah</option>
                          <option value="Virginia">Virginia</option>
                          <option value="Vermont">Vermont</option>
                          <option value="Washington">Washington</option>
                          <option value="Wisconsin">Wisconsin</option>
                          <option value="West Virginia">West Virginia</option>
                          <option value="Wyoming">Wyoming</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          State is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="city"
                          id="city"
                          placeholder="City"
                          value={data.city}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          City is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="zip"
                          id="zip"
                          placeholder="Zip"
                          value={data.zip}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Zip is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Parish</Form.Label>
                        <Form.Select
                          className="form-control minimal"
                          size="sm"
                          type="text"
                          name="parishId"
                          id="parishId"
                          placeholder="Parish"
                          value={data.parishId}
                          onChange={(e) => handleChanges(e)}
                        >
                          <option value="">Select Parish</option>
                          {parishList.map((item, index) => {
                            return (
                              <option value={item.id}>{item.parish}</option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Parsih is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Anniversary Date (Month/Day)</Form.Label>

                        <DatePicker
                          // required
                          autoComplete="off"
                          dateFormat="MM-dd-yyyy"
                          placeholderText="mm-dd-yyyy"
                          selected={data && data.anniversary_date}
                          onChange={(e) => handleDatePicker(e)}
                          className="form-select setdate datepicker"
                          name="anniversary_date"
                          id="anniversary_date"
                          maxDate={new Date()}
                          showDisabledMonthNavigation
                        />
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="notes"
                          id="notes"
                          placeholder="Notes"
                          value={data.notes}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Notes is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Form.Label>
                          Do you or your spouse have any dietary allergies,
                          dietary restrictions or physical limitations?
                        </Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="allergies"
                          id="allergies"
                          value={data.allergies}
                          onChange={(e) => handleChanges(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="row mt-3">
                      <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h4>Please provide at least one emergency contact:</h4>
                      </div>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="emergency_name1"
                          id="emergency_name1"
                          placeholder="Name"
                          value={data.emergency_name1}
                          onChange={(e) => handleChanges(e)}
                        />
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <Form.Label>Relationship</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="emergency_relationship1"
                          id="emergency_relationship1"
                          placeholder="Relationship"
                          value={data.emergency_relationship1}
                          onChange={(e) => handleChanges(e)}
                        />
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="emergency_phone1"
                          id="emergency_phone1"
                          placeholder="Phone"
                          value={data.emergency_phone1}
                          onChange={(e) => handleChanges(e)}
                        />
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="emergency_name2"
                          id="emergency_name2"
                          placeholder="Name"
                          value={data.emergency_name2}
                          onChange={(e) => handleChanges(e)}
                        />
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <Form.Label>Relationship</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="emergency_relationship2"
                          id="emergency_relationship2"
                          placeholder="Relationship"
                          value={data.emergency_relationship2}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Relationship is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="emergency_phone2"
                          id="emergency_phone2"
                          placeholder="Phone"
                          value={data.emergency_phone2}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Phone is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="row mt-3 ">
                      <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h4>How did you hear about us?</h4>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between hear-options">
                        <label className="form-check-label ml-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input ageLabel"
                            name="Website"
                            value="Website"
                            onChange={handleCheckboxChange}
                            checked={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes("Website")
                            }
                            disabled={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes(data.hear_us === "Website")
                            }
                          />
                          Website
                          <i className="input-helper"></i>
                        </label>

                        <label className="form-check-label ml-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input ageLabel"
                            name="Church Bulletin"
                            value="Church Bulletin"
                            onChange={handleCheckboxChange}
                            checked={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes("Church Bulletin")
                            }
                            disabled={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes(
                                data.hear_us === "Church Bulletin"
                              )
                            }
                          />
                          Church Bulletin
                          <i className="input-helper"></i>
                        </label>

                        <label className="form-check-label ml-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input ageLabel"
                            name="Facebook"
                            value="Facebook"
                            onChange={handleCheckboxChange}
                            checked={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes("Facebook")
                            }
                            disabled={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes(data.hear_us === "Facebook")
                            }
                          />
                          Facebook
                          <i className="input-helper"></i>
                        </label>

                        <label className="form-check-label ml-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input ageLabel"
                            name="School Marquee"
                            value="School Marquee"
                            onChange={handleCheckboxChange}
                            checked={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes("School Marquee")
                            }
                            disabled={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes(
                                data.hear_us === "School Marquee"
                              )
                            }
                          />
                          School Marquee
                          <i className="input-helper"></i>
                        </label>

                        <label className="form-check-label ml-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input ageLabel"
                            name="Pulpit Talk/Table in Church"
                            value="Pulpit Talk/Table in Church"
                            onChange={handleCheckboxChange}
                            checked={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes(
                                "Pulpit Talk/Table in Church"
                              )
                            }
                            disabled={
                              data &&
                              data.hear_us &&
                              data.hear_us.includes(
                                data.hear_us === "Pulpit Talk/Table in Church"
                              )
                            }
                          />
                          Pulpit Talk/Table in Church
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </div>
                    <Col lg={6} md={6} xs={12} sm={12} className=" mt-5">
                      <h4> Referral from a friend/Family member</h4>
                      <Form.Group className="form-group">
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="referral_from_a_friend_or_family_member"
                          placeholder=" Referral from a friend/Family member"
                          id="referral_from_a_friend_or_family_member"
                          value={data.referral_from_a_friend_or_family_member}
                          className="form-control"
                          onChange={(e) => {
                            handleChanges(e);
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-scroller mainpannel">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-10 mx-auto">
                <div className="brand-logo ">
                  <img src={companyLogo} alt="logo" className="logo center" />
                </div>
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="text-center">
                    <h3>This link is expired.</h3>
                    <p>This link works for one time only.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CoupleUpdate;
