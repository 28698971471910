import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import companyLogo from "../../assets/images/logo.png";
import AuthApi from "../../api/authApi";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const ChangePassword = () => {
  let history = new useNavigate();
  const location = useLocation();
  const [isError, setIsError] = useState(false);
  const [isSpaceOrSyb, setIsSpaceOrSyb] = useState(false);
  const [validated, setValidated] = useState(false);
  let api = new AuthApi();
  const [data, setData] = useState({
    email: "",
    otp: "",
    newpassword: "",
    re_pass: "",
  });

  const [cp, setCp] = useState(false);

  useEffect(() => {
    const email = location.state.emailaddress;
    if (email) {
      let d = { ...data };
      d.email = email;
      setData(d);
    } else {
      history("/forgetPassword");
    }
  }, []);

  const handleChanges = async (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    d[name] = value;
    if (name === "newpassword") {
      let regex = /[!@#\\$%\\^&\\*,_ -. +, =,!, %, *, &, ”, :, .,]/g;
      const foundSpecialChar = value.match(regex);
      regex = /[0-9]/g;
      const foundNum = value.match(regex);
      regex = /[A-Z]/g;
      const foundUppercase = value.match(regex);
      regex = /[a-z]/g;
      const lowerCase = value.match(regex);
      let regex2 = /[~,\\,<, ,]/g;
      const isSpace = value.match(regex2);
      if (isSpace?.length) {
        setIsSpaceOrSyb(true);
      } else {
        setIsSpaceOrSyb(false);
      }
      if (
        value?.length > 8 &&
        foundUppercase?.length &&
        foundSpecialChar?.length &&
        foundNum?.length &&
        lowerCase?.length &&
        !isSpace?.length
      ) {
        setIsError(false);
      } else {
        setIsError(true);
      }
      d.password = value;
    }
    if (name === "re_pass") {
      if (d.re_pass !== d.newpassword) {
        setCp(true);
      } else {
        setCp(false);
      }
    } else if (d.re_pass) {
      if (d.re_pass !== d.newpassword) {
        setCp(true);
      } else {
        setCp(false);
      }
    }
    setData(d);
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (!isError) {
      if (form.checkValidity()) {
        api
          .ResetPassWord(data)
          .then((res) => {
            // console.log('res-1: ', res);
            if (res.success) {
              toast.success(res.msg);
              history("/login");
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            // console.log('error-1', error);
            toast.error(error);
          });
      }
    }
    setValidated(true);
  };

  return (
    <div className="container-scroller mainpannel">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo ">
                  <img src={companyLogo} alt="logo" className="logo" />
                </div>
                <h4>Reset Your Password!</h4>
                <h6 className="fw-light">Change Your Password</h6>

                <Form
                  noValidate
                  validated={validated}
                  className="pt-3"
                  onSubmit={handleSubmit}
                >
                  <Form.Group style={{ marginBottom: "5px" }}>
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      className="form-control form-control-sm border-left-0"
                      placeholder="Enter Otp"
                      name="otp"
                      value={data.otp}
                      onChange={(e) => handleChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      OTP is requried
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group style={{ marginBottom: "5px" }}>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      className="form-control form-control-sm border-left-0"
                      placeholder="Enter new password"
                      name="newpassword"
                      value={data.newpassword}
                      isInvalid={isError}
                      onChange={(e) => handleChanges(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {data.password === "" ? (
                        "Password is required"
                      ) : isSpaceOrSyb ? (
                        "Do not use a space or tab and these characters \\, ~ or <"
                      ) : (
                        <>
                          Your password isn’t strong enough It should be minimum
                          8 characters <br></br> with at least one special and
                          one capital and small letter.
                        </>
                      )}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      className="form-control form-control-sm border-left-0"
                      placeholder="re-enter Password"
                      name="re_pass"
                      value={data.re_pass}
                      onChange={(e) => handleChanges(e)}
                      isInvalid={cp}
                    />
                    <Form.Control.Feedback type="invalid">
                      password is not matching
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    type="submit"
                    className="mt-3"
                    variant="primary"
                    disabled={cp ? true : false}
                  >
                    Reset Password
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
