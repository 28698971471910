import React from "react";
import "../assets/css/ViewToggleButton.css";

const ViewToggleButton = ({ isListView, toggleView }) => {
  return (
    <div className="view-toggle-btn" title="Toggle View">
      <button
        className={`toggle-option ${isListView ? "active" : ""}`}
        onClick={() => toggleView(true)}
      >
        <span class="mdi mdi-view-list"></span>
        {/* List Icon */}
      </button>
      <button
        className={`toggle-option ${isListView ? "" : "active"}`}
        onClick={() => toggleView(false)}
      >
        <span className="mdi mdi-grid-large"></span> {/* Grid Icon */}
      </button>
    </div>
  );
};

export default ViewToggleButton;
