import BaseApi from "./BaseApi";
class RolesApi extends BaseApi {
  async addRole(data) {
    return await this.API(
      {
        url: "/roles/addrole",
        data: data,
        method: "post"
      }
    )
  }
  async getAllRoleList(data) {
    return await this.API(
      {
        url: "/roles/getallrolelist",
        method: "get"
      }
    )
  }

  async getAllRoleSearch(data) {
    return await this.API(
      {
        url: "/roles/getAllRoleSearch",
        method: "get"
      }
    )
  }

  async getRole(data, role_id) {
    return await this.API(
      {
        url: `/roles/getrole/${role_id}`,
        method: "get"
      }
    )
  }
  async deleteRole(data, role_id) {
    return await this.API({
      url: `/roles/deleterole/${role_id}`,
      data: data,
      method: 'delete'
    })
  }
  async searchRole(data, key) {
    return await this.API(
      {
        url: `/roles/searchrole/${key}`,
        method: "get"
      }
    )
  }
  async updateRole(data, role_id) {
    return await this.API({
      url: `/roles/updaterole/${role_id}`,
      data: data,
      method: 'put'
    })
  }

}

export default RolesApi;