import React, { useState, useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import DirectoryApi from "../../../api/directoryApi";
import { toast } from "react-toastify";
import { Form, Button, Modal } from "react-bootstrap";
import AdminApi from "../../../api/admin";
import { Cookies } from "react-cookie";

const DirectoryModel = ({
  show,
  onHide,
  directoryField,
  directory,
  retreatData,
}) => {
  const [validated, setValidated] = useState(false);
  const [headerText, setHeaderText] = useState("");
  let directoryApi = new DirectoryApi();
  let adminApi = new AdminApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;

  useEffect(() => {
    if (directoryField === "directoryHeading") {
      setHeaderText(directory && directory.heading);
    } else if (directoryField === "WisdomDescription") {
      setHeaderText(directory && directory.heading);
    } else {
      setHeaderText(directory && directory.footerText);
    }
  }, [directory]);

  const handleSubmit = () => {
    directoryApi
      .createDirectory({
        headerText: headerText,
        directoryField: directoryField,
        retreatId:
          directory && directory.retreatId
            ? directory.retreatId
            : retreatData.id,
        directoryId: directory && directory.id,
      })
      .then(async (res) => {
        if (res.mode === "create") {
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Created new retreat directory (Retreat - ${retreatData.title})`,
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Added new log report");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          if (directoryField === "directoryHeading") {
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: `Directory Heading changed (Retreat - ${retreatData.title})`,
            };
            await adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Added new log report");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: `Directory Footer text changed (Retreat - ${retreatData.title})`,
            };
            await adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Added new log report");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
        if (res.success) {
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton >
          {directoryField === "directoryHeading" ? (
            <Modal.Title id="contained-modal-title-vcenter">
              Directory Upper Heading
            </Modal.Title>
          ) : null}
          {directoryField === "directoryFooterHeading" ? (
            <Modal.Title id="contained-modal-title-vcenter">
              Directory Footer Heading
            </Modal.Title>
          ) : null}
        </Modal.Header>
        <Form noValidate validated={validated} className="forms-sample">
          <Modal.Body>
            <SunEditor
              id="outlined-adornment-quote"
              type="text"
              onChange={(e) => setHeaderText(e)}
              setContents={headerText}
              name="quote"
              inputProps={{}}
              setOptions={{
                buttonList: [
                  ["undo", "redo", "font", "fontSize", "formatBlock"],
                  ["bold", "underline", "italic", "removeFormat"],
                  [
                    "fontColor",
                    "hiliteColor",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                  ],
                  ["fullScreen", "link", "save"],
                ],
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                handleSubmit();
                onHide();
              }}
              variant="primary"
              type="button"
            >
              Save
            </Button>
            <Button onClick={onHide}>Close</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default DirectoryModel;
