import React from "react";
import companyLogo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const LoginHeader = () => {
  let location = useLocation();

  console.log("location", location.pathname);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="registerHeader">
            <Link to="/dashboard">
              <img src={companyLogo} className="registerLogo" alt="logo" />
            </Link>
            <div className="logInRegister">
              {/* <Link
                to="/registration-retreat"
                className={
                  location.pathname === "/registration-retreat"
                    ? "registration-retreat"
                    : "couple-Login"
                }
              >
                <h4>Register For Retreat</h4>
              </Link>

              <hr class="vertical-line" /> */}

              <Link
                to="/login"
                c
                className={
                  location.pathname === "/login"
                    ? "registration-retreat"
                    : "couple-Login"
                }
              >
                <h4>Login</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginHeader;
