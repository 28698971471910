import React, { useState, useEffect } from "react";
import AdminApi from "../../api/admin";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Loader from "../../components/loader";
import CustomDataTable from "../../components/CustomDataTable";
import ViewToggleButton from "../../components/ViewToggleButton"; // Import the toggle button component
import {
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

const ParticipatedInRetreats = () => {
  let adminApi = new AdminApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const [retreatList, setRetreatList] = useState([]);
  const [LoadData, setLoadData] = useState(false);
  const [isListView, setIsListView] = useState(true); // Toggle state

  useEffect(() => {
    participatedInRetreats(userData.coupleId);
  }, [userData.coupleId]);

  const participatedInRetreats = async (coupleId) => {
    setLoadData(true);
    await adminApi
      .participatedInRetreats({ couple_Id: coupleId })
      .then((response) => {
        const parsedData = response.data.map((item) => {
          const { schedule, ...rest } = item;
          const parsedSchedule = schedule ? JSON.parse(schedule) : [];
          return {
            ...rest,
            schedule: parsedSchedule.map((entry) => ({
              date: new Date(entry.date),
              from: entry.from,
              to: entry.to,
            })),
          };
        });
        setLoadData(false);
        setRetreatList(parsedData);
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error.toString());
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    {
      id: "title",
      name: "Retreat Title",
      sortable: true,
      reorder: true,
      width: "300px",
      selector: (row) => (
        <>
          <a
            href={`/registration-retreat/${row.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>{row.title}</div>
          </a>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(
              row.dateFrom === row.dateTo
                ? moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("MMM-DD-YYYY")
                : moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("MMM DD") +
                    " - " +
                    moment(row && row.dateTo)
                      .tz("America/New_York")
                      .format("MMM DD") +
                    ", " +
                    moment(row && row.dateFrom)
                      .tz("America/New_York")
                      .format("YYYY")
            )}
          >
            {row.dateFrom === row.dateTo ? (
              <span>
                {moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM-DD-YYYY")}
              </span>
            ) : (
              <span>
                {moment(row && row.dateFrom)
                  .tz("America/New_York")
                  .format("MMM-DD") +
                  " - " +
                  moment(row.dateTo).tz("America/New_York").format("MMM DD") +
                  ", " +
                  moment(row && row.dateFrom)
                    .tz("America/New_York")
                    .format("YYYY")}
              </span>
            )}
          </OverlayTrigger>
        </>
      )
    },
    {
      id: "language",
      name: "Language",
      selector: (row) =>
        row.language.charAt(0).toUpperCase() + row.language.slice(1),
      sortable: true,
      reorder: true,
      width: "120px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "location",
      name: "Location",
      selector: (row) =>
        row.location.charAt(0).toUpperCase() + row.location.slice(1),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "date",
      name: "Retreat Dates",
      selector: (row) => row.dateFrom,
      sortable: true,
      reorder: true,
      width: "200px",
      cell: (row) => (
        <span>
          {row.dateFrom === row.dateTo
            ? moment(row.dateFrom).tz("America/New_York").format("MMM-DD-YYYY")
            : `${moment(row.dateFrom).tz("America/New_York").format("MMM DD")} - ${moment(
                row.dateTo
              ).tz("America/New_York").format("MMM DD, YYYY")}`}
        </span>
      ),
    },
    {
      id: "attendeeType",
      name: "Attendee Type",
      selector: (row) => row.attendeeType,
      sortable: true,
      reorder: true,
      width: "150px",
    },
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        moment(row.createdAt).format("MM-DD-YYYY"),
      sortable: true,
      reorder: true,
      width: "150px",
    },
  ];

  return (
    <div className="row flex-grow">
      <div className="container overflow-hidden text-center">
        <div className="d-flex justify-content-end mb-3">
          <ViewToggleButton isListView={isListView} toggleView={setIsListView} />
        </div>
        {LoadData ? (
          <Loader />
        ) : retreatList && retreatList.length ? (
          <>
            {isListView ? (
              <CustomDataTable
                data={retreatList}
                columns={columns}
                pointerOnHover={true}
                highlightOnHover={true}
                pagination={true}
              />
            ) : (
              <div className="row">
                {retreatList.map((item, i) => {
                  return (
                    <div key={i} className="col-md-4 col-sm-6">
  <a
    href={`/registration-retreat/${item.id}`}
    className="font-size"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className="p-3">
      <p
        type="button"
        className="btn btn-success btn-fw btn-rect-md-sm btn-rect-md-font"
      >
        {/* Title */}
        {item.title.slice(0, 30) + (item.title.length > 30 ? "..." : "")}
        <br />
        {/* Date */}
        {item.dateFrom === item.dateTo
          ? moment(item.dateFrom).tz("America/New_York").format("MMM-DD-YYYY")
          : `${moment(item.dateFrom).tz("America/New_York").format("MMM DD")} - ${moment(
              item.dateTo
            ).tz("America/New_York").format("MMM DD, YYYY")}`}
        <br />
        {/* Attendee Type */}
        <p>Type: {item.attendeeType}</p>
      </p>
    </div>
  </a>
</div>

                  );
                })}
              </div>
            )}
          </>
        ) : (
          <div className="row">
            <div className="col-lg-12 d-flex flex-column">
              <div className="card custom-card mt-4">
                <div className="card-body">
                  <h3>Not Participated in any retreat.</h3>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticipatedInRetreats;
