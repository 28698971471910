import BaseApi from "./BaseApi";
class ServiceApi extends BaseApi {
  async addService(data) {
    return await this.API(
      {
        url: "/service/addservice",
        data: data,
        method: "post"
      }
    )
  }

  async getAllServiceList(data) {
    return await this.API(
        {
            url:"/service/getallservicelist",
            method: "get"
        }
    )
}

async getAllServiceSearch(data) {
  return await this.API(
      {
          url:"/service/getAllServiceSearch",
          method: "get"
      }
  )
}

async deleteService(data,service_id) {
  return await this.API({
    url: `/service/deleteservice/${service_id}`,
    data: data,
    method: 'delete'
  })
}
async searchKey (data,key) {
  return await this.API(
      {
          url:`/service/search/${key}`,
          method: "get"
      }
  )
}
async updateService(data , service_id) {
  return await this.API({
    url: `/service/updateservice/${service_id}`,
    data: data,
    method: 'put'
  })
}

}

export default ServiceApi;