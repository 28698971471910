import React, { useState, useEffect } from "react";
import adminApi from "../../api/admin";
import { toast } from "react-toastify";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomDataTable from "../../components/CustomDataTable";
import Helpers from "../../components/Helpers";
import { Link } from "react-router-dom";
import { utils, writeFile } from "xlsx";

const LogReport = () => {
  const [LoadData, setLoadData] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [total, setTotal] = useState(20);
  const [searchKey, setSearchKey] = useState(""); //for searching
  let api = new adminApi();

  useEffect(() => {
    getAllLogReport();
  }, []);

  const getAllLogReport = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    loadCount = false
  ) => {
    setLoadData(true);
    let d = {};
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = searchKey;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = false;
    await api
      .getAllLogReport(d)
      .then((res) => {
        if (res.success) {
          setLoadData(false);
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
          setTotal(totalRecords);
          setReportList(res.data);
          setLoadData(false);
        } else {
          setLoadData(false);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error);
      });
  };

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      setSearchKey(event.target.value);
      getAllLogReport(1, 20, event.target.value);
    } else {
      setSearchKey("");
      getAllLogReport(1, 20, "");
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    {
      id: 1,
      name: "By Whom",
      selector: (row) => row.byWhom,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.byWhom)}
        >
          <span>{row.byWhom}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      tag: "allowRowEvents",
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: 3,
      name: "Task",
      selector: (row) => row.description,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.description)}
        >
          <span>
            {row.description &&
              row.description.slice(0, 120) +
                (row.description.length > 120 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      tag: "allowRowEvents",
      width: "530px",
      style: {
        cursor: "text",
      },
    },
    {
      id: 2,
      name: "Page Link",
      selector: (row) => row.pagelink,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.pagelink)}
        >
          <span>
            <Link to={row.pagelink}>Link</Link>
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      tag: "allowRowEvents",
      style: {
        cursor: "text",
      },
    },
    {
      id: 4,
      name: "Date/Time",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {row.createdAt
              ? Helpers.convertUtcToTimezone(
                  row.createdAt,
                  "MM-DD-YYYY hh:mm:a"
                )
              : ""}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      style: {
        cursor: "text",
      },
    },
  ];

  const handleExport = async () => {
    let d = {};
    d.pageNo = 1;
    d.size = 20;
    d.export = true;
    await api
      .getAllLogReport(d)
      .then(async (res) => {
        if (res.success) {
          let exportData = [];
          res.data.forEach((element) => {
            exportData.push({
              byWhom: element.byWhom,
              description: element.description,
              pagelink: element.pagelink,
              createdAt: Helpers.convertUtcToTimezone(
                element.createdAt,
                "MM-DD-YYYY hh:mm:a"
              ),
            });
          });
          const headings = [["By Whom", "Task", "Page Link", "Date/Time"]];
          const wb = utils.book_new();
          const ws = utils.json_to_sheet([]);
          utils.sheet_add_aoa(ws, headings);
          utils.sheet_add_json(ws, exportData, {
            origin: "A2",
            skipHeader: true,
          });
          utils.book_append_sheet(wb, ws, "Data");
          writeFile(wb, `Log Report.xlsx`);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div className="row">
      <div className="col-lg-12 d-flex flex-column">
        <div className="card  custom-card">
          <div className="card-body">
            <div className="d-sm-flex justify-content-between align-items-start Couple_heading">
              <h4 className="card-title">Log Report</h4>
              <div className="d-flex">
                <div className="search">
                  <div className="input-group setSearch">
                    <input
                      onChange={SearchData}
                      type="text"
                      className="form-control searchData"
                      placeholder="Search..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-sm btn-primary" type="button">
                        <i className="icon-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleExport}
                  className="btn btn-primary btn-sm text-white mb-0 me-0 alignbtn export-approval-couple"
                  type="button"
                >
                  Export
                </button>
              </div>
            </div>
            <CustomDataTable
              data={reportList}
              countPerPage={20}
              isServerSide={true}
              pagination={true}
              sortServer={true}
              total={total}
              columns={columns}
              dataFetchApi={getAllLogReport}
              pointerOnHover={true}
              highlightOnHover={true}
              fixedHeader={true}
            />
            {/* } */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LogReport;
