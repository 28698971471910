import BaseApi from "./BaseApi";

class RetreatApi extends BaseApi {
  async createRetreat(data) {
    return this.API({
      url: "/retreat/createRetreat",
      data: data,
      method: "post",
    });
  }

  async getAllRetreats(data) {
    return this.API({
      url: "/retreat/getAllRetreats",
      data: data,
      method: "post",
    });
  }

  async getAllRetreatSearch(data) {
    return this.API({
      url: "/retreat/getAllRetreatSearch",
      data: data,
      method: "post",
    });
  }

  async getRetreatDetail(data) {
    return this.API({
      url: "/retreat/getRetreatDetail",
      data: data,
      method: "post",
    });
  }

  async getRetreatDetailRos(data) {
    return this.API({
      url: "/retreat/getRetreatDetailRos",
      data: data,
      method: "post",
    });
  }

  async editRetreat(data) {
    return this.API({
      url: "/retreat/editRetreat",
      data: data,
      method: "post",
    });
  }

  async tempDeleteRetreat(data) {
    return this.API({
      url: "/retreat/tempDeleteRetreat",
      data: data,
      method: "post",
    });
  }

  async searchKey(key) {
    return await this.API({
      url: `/retreat/search/${key}`,
      method: "get",
    });
  }

  async searchRetreatsAndCouples(query) {
    return await this.API({
      url: `/retreat/searchRetreatsAndCouples`,
      method: "post",
      data: query,
    });
  }

  async getRetreatLocation() {
    return await this.API({
      url: `/retreat/getRetreatLocation`,
      method: "post",
    });
  }

  async autoSaveRetreat(data) {
    return await this.API({
      url: `/retreat/autoSaveRetreat`,
      method: "post",
      data: data,
    });
  }

  async giveAccesssOfApprove(data) {
    return await this.API({
      url: `/retreat/giveAccesssOfApprove`,
      method: "post",
      data: data,
    });
  }

  async removeAccessFromRetreat(data) {
    return await this.API({
      url: `/retreat/removeAccessFromRetreat`,
      method: "post",
      data: data,
    });
  }

  async getAllAccessedRetreats(data) {
    return await this.API({
      url: "/retreat/getAllAccessedRetreats",
      method: "post",
      data: data,
    });
  }

  async getMissionStatement() {
    return await this.API({
      url: "/retreat/getMissionStatement",
      method: "get",
    });
  }

  async allretreatSubAdmins(data) {
    return await this.API({
      url: "/retreat/allretreatSubAdmins",
      method: "post",
      data: data,
    });
  }

  async retreatAdminAccess(data) {
    return await this.API({
      url: "/retreat/retreatAdminAccess",
      method: "post",
      data: data,
    });
  }


}

export default RetreatApi;
