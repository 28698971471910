import React, { useState, useEffect } from "react";
import { Form, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import CustomDataTable from "../../components/CustomDataTable";
import Helpers from "../../components/Helpers";
import AdminApi from "../../api/admin";
import SendEmailApi from "../../api/SendEmailApi";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import DeleteModel from "../../components/DeleteModel";
import "suneditor/dist/css/suneditor.min.css";
import { MultiSelect } from "react-multi-select-component";
import Loader from "../../components/loader";
import { Cookies } from "react-cookie";

const SendEmail = () => {
  const [validated, setValidated] = useState(false);
  const [mailListShow, setMailListShow] = useState(false);
  const [mailshow, setMailShow] = useState(false);
  const [show, setShow] = useState(false);
  const [mail_id, SetMail_id] = useState("");
  const [recipientMailData, SetRecipientMailData] = useState("");
  const [sendEmailList, setSendEmailList] = useState([]);
  const [cp, setCp] = useState(false);
  const [composedMails, setComposedMails] = useState([]);
  const [allCoupleData, setAllCoupleData] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const [LoadData, setLoadData] = useState(false);
  const initialEditorContent =
    "Hello ${firstName} ${lastName},<br/>Please click the below link to update your profile details: <br/>$link <br/><br/><strong>Here we are inviting you on the Retreat- </strong><br /> Scott ,Robot and Maria <br />  March 2-3 , 2023 : St.Mattew's,Eng,San Antonio <br /> \n We look forward to see you there </br>\n Thank you! </br> LOVESTRONG Marriage.";
  const [defaultEditorContent, setDefaultEdtitorContent] =
    useState(initialEditorContent);
  const [maildata, setMailData] = useState({
    couple: "",
    subject: "",
    message: "",
  });

  const [Message, setMessage] = useState(defaultEditorContent);
  let adminApi = new AdminApi();
  let sendEmailApi = new SendEmailApi();
  const [isDisabled, setIsDisabled] = useState(false);
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;

  useEffect(() => {
    getAllCouples();
    AllComposedMailList();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Send Email page.",
      action: "View",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = (item) => {
    let d = { ...maildata };
    d.subject = item.subject;
    setMailData(d);
    setShow(true);
    SetMail_id(item.id);
  };

  const MailListClose = () => setMailListShow(false);

  const MailListShow = async (item) => {
    setMailListShow(true);
    const recipientData = item.couple.map((item, index) => {
      return item;
    });
    SetRecipientMailData(recipientData);

    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: `Viewed Recipient List (Subject : ${item.subject})`,
      action: "View",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Create New Log Report");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    clearfield();
  };

  function handleSelect(data) {
    setselectedData(data);
    let selectedDataa = { ...maildata };
    selectedDataa.couple = data.map((item, index) => {
      let returnOBj = {
        email: item.label,
        value: item.value,
      };
      return returnOBj;
    });
    setMailData(selectedDataa);
  }

  const ShowCompose = () => {
    setMailShow(true);
    setValidated(false);
    clearfield();
    setDefaultEdtitorContent(initialEditorContent);
  };

  const ShowComposedMail = async (item) => {
    setMailShow(true);
    SetMail_id(item.id);
    let selectedDataa = item.couple.map((item, index) => {
      let returnOBj = {
        label: item.email,
        value: item.value,
      };
      return returnOBj;
    });
    setselectedData(selectedDataa);
    setMessage(item.message);
    let d = { ...maildata };
    d.message = Message;
    d.subject = item.subject;
    setMailData(d);
    setDefaultEdtitorContent(item.message);
    setIsDisabled(true);
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: `Viewed composed Mail ( Subject : ${item.subject} )`,
      action: "View",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CloseCompose = () => {
    setValidated(false);
    setMailShow(false);
    setIsDisabled(false);
  };

  const getAllCouples = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    loadCount = false
  ) => {
    let d = { userRole: "COUPLE" };
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }

    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    }

    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = true;
    await adminApi
      .getAllCouples(d)
      .then((res) => {
        if (res.success) {
          let couples = [];
          const filteredData = res.data.filter(
            (item) =>
              item.accountStatus !== "DIVORCED" &&
              item.accountStatus !== "DECEASED" &&
              item.accountStatus !== "INACTIVE"
          );
          filteredData.forEach((item) => {
            if (item.hisEmail) {
              couples.push({
                label:
                  item.hisFirstName +
                  " " +
                  item.hisLastName +
                  " (" +
                  item.hisEmail +
                  ")" +
                  " (" +
                  item.primaryKey +
                  ")",
                value: item.hisEmail,
              });
            }
            if (item.herEmail) {
              couples.push({
                label:
                  item.herFirstName +
                  " " +
                  item.herLastName +
                  " (" +
                  item.herEmail +
                  ")" +
                  " (" +
                  item.primaryKey +
                  ")",
                value: item.herEmail,
              });
            }
          });
          setAllCoupleData(couples);
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...maildata };
    d[name] = value;
    setMailData(d);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      let d = { ...maildata };
      d.message = Message;
      sendEmailApi
        .addSendEmail(d)
        .then(async (res) => {
          if (res.success) {
            toast.success(res.msg);
            clearfield();
            setSendEmailList(res.data);
            AllComposedMailList();
            setMailShow(false);
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: `Send mail (Mail Id  :${res.data.id}, Mail Subject  :${res.data.subject})`,
              action: "Add",
            };
            await adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Created new log report.");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
    setValidated(true);
  };

  const AllComposedMailList = async () => {
    setLoadData(true);
    await sendEmailApi
      .getAllComposedMails()
      .then(async (response) => {
        setComposedMails(response.data);
        setLoadData(false);
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error.toString());
      });
  };

  const DeleteData = async () => {
    await sendEmailApi
      .deleteMail(data, mail_id)
      .then(async (response) => {
        AllComposedMailList();
        setShow(false);
        SetMail_id("");
        toast.success(response.msg);
        let report = {
          byWhom:
            `${userData && userData.userName ? userData.userName : null} ` +
            ` (${userData && userData.userRole ? userData.userRole : null})`,
          pagelink: window.location.href,
          description: `Deleted Composed Mail (Mail Id : ${mail_id}, Mail Subject : ${maildata.subject})`,
          action: "Delete",
        };
        await adminApi
          .addLogReport(report)
          .then((response) => {
            if (response.success) {
              console.log("Created new log report.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setShow(false);
        SetMail_id("");
        toast.error(error.toString());
      });
  };

  const clearfield = () => {
    setMailData({
      couple: [],
      subject: "",
      message: defaultEditorContent,
    });
    SetMail_id("");
    setselectedData([]);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const data = [
    {
      id: 1,
      subject: "Beetlejuice",
      sent: " 12/12/45",
    },
    {
      id: "2",
      subject: "ejugkljfduige",
      sent: " 12/1/200",
    },
  ];

  const columns = [
    {
      id: 1,
      name: "Subject",
      selector: (row) => row.subject,
      cell: (row) => (
        <a onClick={() => ShowComposedMail(row)}>
          {
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(row.subject)}
            >
              <span>
                {row.subject.slice(0, 20) +
                  (row.subject.length > 20 ? "..." : "")}
              </span>
            </OverlayTrigger>
          }
        </a>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Sent",
      selector: (row) => Helpers.convertUtcToTimezone(row.sent),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.sent, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {row.sent
              ? Helpers.convertUtcToTimezone(row.sent, "MM-DD-YYYY")
              : ""}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: 3,
      name: "Recipients",
      selector: (row) => row.couple.length,
      cell: (row) => (
        <>
          <a className="" onClick={() => MailListShow(row)}>
            {row.couple.length}
          </a>
        </>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Action",
      button: true,
      cell: (row) => (
        <>
          <button className="font-size" onClick={() => handleShow(row)}>
            <i
              className="mdi mdi-delete-forever text-md text-danger mx-2 font-size"
              title="Delete"
            ></i>
          </button>
        </>
      ),
    },
  ];

  const col2 = [
    {
      id: 3,
      name: "Name & E-mail ",
      selector: (row) => row.email,
    },
  ];
  const conditionalRowStyles = [
    {
      when: (row) =>
        row.accountStatus === "deceased" ||
        row.accountStatus === "divorced" ||
        row.accountStatus === "inactive",
      style: {
        backgroundColor: "#f8d7da",
        color: "#000",
        borderColor: "#dfc2c4",
      },
    },
  ];

  return (
    <div className="col-lg-12 d-flex flex-column">
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card  custom-card">
            <div className="card-body">
              <div className="d-sm-flex justify-content-between align-items-start Couple_heading">
                <h4 className="card-title">Emails</h4>
                <Button
                  className="set_button"
                  variant="warning"
                  onClick={ShowCompose}
                >
                  {" "}
                  <i className="mdi mdi-plus-circle btn-icon-prepend"></i>{" "}
                  Compose Email
                </Button>
              </div>
              <CustomDataTable
                loading={LoadData}
                data={composedMails}
                columns={columns}
                conditionalRowStyles={conditionalRowStyles}
                pointerOnHover={true}
                highlightOnHover={true}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={mailListShow}
        size="lg"
        onHide={MailListClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Recipient List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomDataTable
            data={recipientMailData}
            columns={col2}
            className="mailTable"
          />
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={mailshow}
        onHide={CloseCompose}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="forms-sample"
        >
          <Modal.Header closeButton={CloseCompose}>
            <h3 className="page-title"> Compose Email </h3>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <label>To:</label>
            </div>
            <div className="form-group row">
              <label>Enter Couple Name</label>
              <MultiSelect
                name="couple"
                options={allCoupleData}
                value={selectedData}
                onChange={handleSelect}
                labelledBy="Select"
                className="multiSelect"
                disabled={isDisabled}
              />
            </div>

            <Form.Group className="form-group">
              <label htmlFor="exampleInputEmail1">Subject:</label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Subject"
                name="subject"
                id="subject"
                value={maildata.subject}
                className="form-control"
                onChange={(e) => handleChanges(e)}
                disabled={isDisabled}
              />
              <Form.Control.Feedback type="invalid">
                Please enter the Subject!
              </Form.Control.Feedback>
            </Form.Group>
            <div className="form-group sunEditor">
              <label htmlFor="exampleInputEmail1">Message:</label>
              <p>
                To send the email for update profile, Please add this variable
                in the compose email :- <span>&#36;link</span>
              </p>
              <p>Please Note :- Update profile link works for one time only.</p>
              <SunEditor
                setContents={defaultEditorContent}
                id="message"
                // value={Message}
                // className='form-control'

                // onChange={(e) => {
                //     e = {
                //         target: {
                //             value: e,
                //             name: "message"
                //         }
                //     }
                //     handleChanges(e)
                // }}
                onChange={(e) => {
                  setMessage(e);
                }}
                readOnly={isDisabled}
                setOptions={{
                  buttonList: [
                    ["undo", "redo", "font", "fontSize", "formatBlock"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                      "removeFormat",
                    ],
                    [
                      "fontColor",
                      "hiliteColor",
                      "outdent",
                      "indent",
                      "align",
                      "horizontalRule",
                      "list",
                      "table",
                    ],
                    [
                      "fullScreen",
                      "showBlocks",
                      "codeView",
                      "link",
                      "image",
                      "save",
                    ],
                  ],
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={CloseCompose}>
              Close
            </Button>
            {isDisabled ? null : (
              <Button
                variant="primary"
                type="submit"
                disabled={selectedData == "" ? true : false}
              >
                Send
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <DeleteModel
        show={show}
        handleClose={handleClose}
        DeleteData={DeleteData}
      ></DeleteModel>
    </div>
  );
};
export default SendEmail;
