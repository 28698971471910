import React, { useEffect, useState } from "react";
import companyLogo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";

const VerifiedThankYou = () => {
  const history = useNavigate();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (redirect) {
      history("/login");
    }
  }, [redirect, history]);

  return (
    <div className="container-scroller mainpannel">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-10 mx-auto">
              <div className="brand-logo ">
                <Link to="/login">
                  {" "}
                  <img src={companyLogo} alt="logo" className="logo center" />
                </Link>
              </div>
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="text-center">
                  {" "}
                  <h3>Thank you for Mail Verification!</h3>
                  <p>Please wait until you are approved by the admin.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedThankYou;
