import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Form, Button, Row, Col } from "react-bootstrap";
import AdminApi from "../../api/admin";
import { useCookies } from "react-cookie";

const AccessSuperAdmin = () => {
  let adminApi = new AdminApi();
  let [allAdmins, setAllAdmins] = useState([]);
  const [cookies, setCookie] = useCookies(["LoveStrongUser"]);
  let [disabledCheck, setDisabledCheck] = useState(true);
  useEffect(() => {
    getAllAdmins();
    if (
      cookies &&
      cookies.LoveStrongUser &&
      cookies.LoveStrongUser.email === "jimmieflores@gmail.com"
    ) {
      setDisabledCheck(false);
    } else {
      setDisabledCheck(true);
    }
  }, [cookies.LoveStrongUser]);

  const getAllAdmins = async () => {
    await adminApi
      .getAllAdmins()
      .then((response) => {
        if (response.data) {
          setAllAdmins(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
  };

  const handleChanges = (e, id) => {
    const updatedAdmins = allAdmins.map((admin) =>
      admin.id === id
        ? { ...admin, deleteAccess: e.target.checked ? 1 : 0 }
        : admin
    );
    setAllAdmins(updatedAdmins);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send a request to the server with updated data
      await adminApi.updateAdmins(allAdmins);
      toast.success("Admins updated successfully!");
    } catch (error) {
      toast.error(error.toString());
      console.error("Error updating admins!", error);
    }
  };
  return (
    <div className="settings">
      <div className="card ext-space">
        <div className="card-body">
          <p className="card-description">
            <div className="media1">
              <div className="media-body">
                <h4 className="card-title">Delete Email Send to SuperAdmin</h4>
                <Form
                  className="forms-sample"
                  onSubmit={handleSubmit}
                  noValidate
                  //   validated={validated}
                >
                  <Row className="m-0">
                    {allAdmins.length
                      ? allAdmins.map((item) => {
                          return (
                            <Col
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="form-check"
                              key={`servTitle-${item.id}`}
                            >
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="deleteAccess"
                                  onChange={(e) => handleChanges(e, item.id)}
                                  value={item.id}
                                  checked={item.deleteAccess}
                                  disabled={disabledCheck}
                                />
                                {`${item.userName}` +
                                  " (" +
                                  `${item.email}` +
                                  ")"}
                                <i className="input-helper"></i>
                              </label>
                            </Col>
                          );
                        })
                      : " "}
                    <></>
                  </Row>
                  <div className="profile-btn">
                    <Button
                      type="submit"
                      className="btn btn-primary me-2"
                      disabled={disabledCheck}
                    >
                      {" "}
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccessSuperAdmin;
