import React, { useState, useEffect } from "react";
import RetreatApi from "../../api/retreatApi";
import AdminApi from "../../api/admin";
import QuickReportApi from "../../api/QuickReportApi";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Helpers from "../../components/Helpers";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Cookies } from "react-cookie";

const QuickReport = () => {
  let retreatApi = new RetreatApi();
  let quickReportApi = new QuickReportApi();
  let navigate = useNavigate();
  const [allRetreatData, setAllRetreatData] = useState([]);
  const [allSearchCounts, setallSearchCounts] = useState([]);
  let adminApi = new AdminApi();

  const searchInitial = {
    retreatId: 0,
    dateFrom: "",
    dateTo: "",
  };

  Object.freeze(searchInitial);
  const [searchQuery, setSearchQuery] = useState(searchInitial);
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;

  useEffect(() => {
    getAllRetreats();
    searchCounts();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Quick Report page.",
      action: "view",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSearchChanges = (event, id = null) => {
    let { name, value } = event.target;
    const search = { ...searchQuery };
    search.retreatId = value;
    setSearchQuery(search);
    searchCounts(search);
  };

  const searchCounts = (search) => {
    if (search && search != null) {
      quickReportApi
        .searchCounts(search)
        .then((res) => {
          if (res.success) {
            setallSearchCounts(res.retreats);
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      quickReportApi
        .searchCounts(searchQuery)
        .then((res) => {
          if (res.success) {
            setallSearchCounts(res.retreats);
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const getAllRetreats = async (
    pageNo = 1,
    size = 15,
    searchKeyword = null,
    sortBy = {},
    loadCount = false
  ) => {
    let d = {};
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.allRetreat = true;
    await retreatApi
      .getAllRetreats(d)
      .then((res) => {
        if (res.success) {
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
          setAllRetreatData(res.data);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const toSearchData = (reportId, isNotHeadRole) => {
    navigate(`/search_data`, {
      state: {
        filter: searchQuery,
        reportId: reportId,
        is_Not_Head_Role: isNotHeadRole,
      },
    });
  };

  return (
    <>
      <div className="row flex-grow">
        <div className="container overflow-hidden text-center">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                {" "}
                <Link to="/manageCouples">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-success btn-fw btn-rect-md"
                  >
                    Full List
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                <button
                  type="button"
                  className="btn btn-success btn-fw btn-rect-md"
                  onClick={() => toSearchData(6, "")}
                >
                  Participated in Last 2 years
                </button>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                <button
                  type="button"
                  className="btn btn-success btn-fw btn-rect-md"
                  onClick={() => toSearchData(1, "")}
                >
                  Served on Team
                </button>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                <button
                  type="button"
                  className="btn btn-success btn-fw btn-rect-md"
                  onClick={() => toSearchData(2, "")}
                >
                  {" "}
                  Never Served on Team
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                {" "}
                <button
                  type="button"
                  className="btn btn-success btn-fw btn-rect-md"
                  onClick={() => toSearchData(8, "")}
                >
                  Served as a Retreat Lead Couple
                </button>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                {" "}
                <button
                  type="button"
                  className="btn btn-success btn-fw btn-rect-md"
                  onClick={() => toSearchData(9, "")}
                >
                  Served as a Head of Service
                </button>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                <button
                  type="button"
                  className="btn btn-success btn-fw btn-rect-md"
                  onClick={() => toSearchData(10, "isNotHeadRole")}
                >
                  Never Served as a Head of Service
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4  justify-content-center">
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                <button
                  type="button"
                  className="btn btn-success btn-fw btn-rect-md"
                  onClick={() => toSearchData(3, "")}
                >
                  Given a Talk
                </button>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="p-3">
                <button
                  type="button"
                  className="btn btn-success btn-fw btn-rect-md"
                  onClick={() => toSearchData(4, "")}
                >
                  Never Given a Talk
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickReport;
