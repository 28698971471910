import moment from 'moment-timezone';
import 'moment/locale/es';
moment.locale('en')
const Helpers = {
  convertUtcToTimezone: function (rows, format) {
    return moment(rows).format(format);
  },

  convertInWordTimming: (dateString, format='MM-DD-YYYY', locale='en') => {
    //locale='en'
  // Create a moment object with the specified locale and timezone
  const date = moment(dateString).tz('America/New_York');
  return date.locale(locale).format('MMMM Do YYYY');
  },

};
export default Helpers;
