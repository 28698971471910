import BaseApi from "./BaseApi";
class SendEmailApi extends BaseApi {
  async addSendEmail(data) {
    return await this.API(
      {
        url: "/email/addsendemail",
        data: data,
        method: "post"
      }
    )
  }


  async getAllComposedMails(data) {
    return await this.API(
        {
            url:"/email/getallcomposedmails",
            method: "get"
        }
    )
}

async deleteMail(data,mail_id) {
  return await this.API({
    url: `/email/deletemail/${mail_id}`,
    data: data,
    method: 'delete'
  })
}


}

export default SendEmailApi;