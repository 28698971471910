import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AuthApi from "../../api/authApi";
import ChangePassword from "./ChangePassword";
import ProfileDetails from "./ProfileDetails";
import { Cookies } from "react-cookie";
import AdminApi from "../../api/admin";

const MyProfile = () => {
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  let api = new AuthApi();
  const [userProfileData, setUserProfileData] = useState({});
  let adminApi = new AdminApi();

  useEffect(() => {
    getProfileData();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Profile page.",
      action: "View",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getProfileData = async () => {
    await api
      .getProfile({ id: userData.id })
      .then((response) => {
        if (response.data) {
          setUserProfileData(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
  };

  return (
    <div className="settings">
      <div className="card ext-space">
        <div className="card-body">
          <p className="card-description">
            Here you can change password and emails.
          </p>
          {/* <ProfileDetails
            userProfileData={userProfileData}
            getProfileData={getProfileData}
          /> */}
          <ChangePassword
            userProfileData={userProfileData}
            getProfileData={getProfileData}
          />
        </div>
      </div>
    </div>
  );
};
export default MyProfile;
