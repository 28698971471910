import React, { useState, useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import DirectoryApi from "../../../api/directoryApi";
import ProgramApi from "../../../api/programApi";
import { toast } from "react-toastify";
import { Form, Button, Modal } from "react-bootstrap";
import { Cookies } from "react-cookie";
import AdminApi from "../../../api/admin";

const ProgramModel = ({
  show,
  onHide,
  programField,
  program,
  coupleTalkData,
  retreatData,
}) => {
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  let adminApi = new AdminApi();
  const [validated, setValidated] = useState(false);
  const [text, setText] = useState("");
  let programApi = new ProgramApi();

  useEffect(() => {
    if (programField === "WisdomDescription") {
      setText(program.wisdom_from_mt);
    } else if (programField === "talk_question") {
      setText(coupleTalkData.talk_question);
    } else if (programField === "tableContent") {
      setText(program.tableContent);
    } else if (programField === "ParishMission") {
      setText(program.parishMission);
    } else if (programField === "fbLink") {
      setText(program.fbLink);
    } else {
      setText(program.guidelines);
    }
  }, [program]);

  const handleSubmit = () => {
    programApi
      .createProgram({
        text: text,
        programField: programField,
        retreatId: retreatData && retreatData.id,
        programId: program && program.id,
        roasterId: coupleTalkData && coupleTalkData.id,
      })
      .then(async (res) => {
        if (res.success) {
          if (res.mode === "create") {
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: `Created new retreat program (Retreat - ${retreatData.title})`,
            };
            await adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Added new log report");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            if (programField === "WisdomDescription") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Wisdom from Mother Theresa text changed in retreat program (Retreat - ${retreatData.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (programField === "talk_question") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `The question of talk  has been updated in retreat program (Retreat - ${retreatData.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (programField === "ParishMission") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `The text of parish mission has been updated in retreat program (Retreat - ${retreatData.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (programField === "tableContent") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Table content has been updated in retreat program (Retreat - ${retreatData.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (programField === "fbLink") {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `Facebook Link changed in retreat program (Retreat - ${retreatData.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description: `The text of program guidelines has been updated in retreat program (Retreat - ${retreatData.title})`,
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Added new log report");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      {programField === "fbLink" ? (
        <Modal
          show={show}
          onHide={onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Facebook Link
            </Modal.Title>
          </Modal.Header>
          <Form noValidate validated={validated} className="forms-sample">
            <Modal.Body>
              <Form.Group className="form-group ml-5 faceBbook-link">
                <Form.Control
                  size="sm"
                  type="text"
                  name="fbLink"
                  id="fbLink"
                  placeholder="Facebook Link"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <h5 className="mt-2">(example - https://www.xyz.com)</h5>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="footer-buttons">
              <Button
                onClick={() => {
                  handleSubmit();
                  onHide();
                }}
                variant="primary"
                type="button"
              >
                Save
              </Button>
              <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : (
        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            {programField === "WisdomDescription" ? (
              <Modal.Title id="contained-modal-title-vcenter">
                Wisdom from Mother Theresa
              </Modal.Title>
            ) : null}

            {programField === "about_program_guidelines" ? (
              <Modal.Title id="contained-modal-title-vcenter">
                Add Guidelines
              </Modal.Title>
            ) : null}

            {programField === "ParishMission" ? (
              <Modal.Title id="contained-modal-title-vcenter">
                Our Parish Mission
              </Modal.Title>
            ) : null}

            {programField === "talk_question" ? (
              <Modal.Title id="contained-modal-title-vcenter">
                Talk Question
                <h5 className="mt-2">
                  {coupleTalkData.talk}, ({" "}
                  {`${
                    coupleTalkData && coupleTalkData.hisFirstName
                      ? coupleTalkData.hisFirstName
                      : ""
                  } ${
                    coupleTalkData && coupleTalkData.hisFirstName ? "&" : ""
                  } ${
                    coupleTalkData && coupleTalkData.herFirstName
                      ? coupleTalkData.herFirstName
                      : ""
                  } ${
                    coupleTalkData && coupleTalkData.hisLastName
                      ? coupleTalkData.hisLastName
                      : ""
                  }`}
                  )
                </h5>
              </Modal.Title>
            ) : null}
          </Modal.Header>
          <Form noValidate validated={validated} className="forms-sample">
            <Modal.Body>
              <SunEditor
                id="outlined-adornment-quote"
                type="text"
                onChange={(e) => setText(e)}
                setContents={text}
                name="quote"
                inputProps={{}}
                setOptions={{
                  buttonList: [
                    ["undo", "redo", "font", "fontSize", "formatBlock"],
                    ["bold", "underline", "italic", "removeFormat"],
                    [
                      "fontColor",
                      "hiliteColor",
                      "outdent",
                      "indent",
                      "align",
                      "horizontalRule",
                      "list",
                    ],
                    ["fullScreen", "link", "save"],
                  ],
                }}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={() => {
                  handleSubmit();
                  onHide();
                }}
                variant="primary"
                type="button"
              >
                Save
              </Button>
              <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ProgramModel;
